import React, { ReactNode } from "react";
import { Dropdown } from "react-bootstrap";

export interface ITitleProps {
  children: ReactNode;
  size?: "lg" | "sm";
  subText?: string | ReactNode;
  rightContent?: ReactNode;
  rightContentStyle?: any;
}

const RightContent: React.FC<{ children: ReactNode; styles: any }> = ({
  children,
  styles,
}) => (
  <div className="nk-block-head-content" style={styles}>
    <div className="toggle-wrap nk-block-tools-toggle">
      <Dropdown className="d-block d-md-none">
        <Dropdown.Toggle
          variant="link"
          className="btn btn-icon btn-trigger toggle-expand me-n1"
        >
          <em className="icon ni ni-more-v" />
        </Dropdown.Toggle>
        <Dropdown.Menu
          className="toggle-expand-content"
          style={{
            width: "350px",
          }}
        >
          {children}
        </Dropdown.Menu>
      </Dropdown>
      <div className="d-none d-md-block">
        <a
          href="#"
          className="btn btn-icon btn-trigger toggle-expand me-n1"
          data-target="more-options"
        >
          <em className="icon ni ni-more-v" />
        </a>
        <div className="toggle-expand-content" data-content="more-options">
          {children}
        </div>
      </div>
    </div>
  </div>
);

const Title: React.FC<ITitleProps> = ({
  size = "lg",
  children,
  subText,
  rightContent: Content,
  rightContentStyle = {},
}) => {
  return (
    <div className="nk-block-head nk-block-head-sm">
      <div className="nk-block-between">
        <div className="nk-block-head-content">
          {size === "lg" ? (
            <h3 className="nk-block-title page-title">{children}</h3>
          ) : (
            <h4 className="nk-block-title page-title">{children}</h4>
          )}
          {subText &&
            (typeof subText === "string" ? (
              <div className="nk-block-des text-soft">
                <p>{subText}</p>
              </div>
            ) : (
              subText
            ))}
        </div>
        {Content && (
          <RightContent styles={rightContentStyle}>{Content}</RightContent>
        )}
      </div>
    </div>
  );
};

export default Title;
