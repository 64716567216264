import { createContext, useContext } from "react";

interface ClientContextInterface {
  consumer: {
    type: "web" | "mobile";
    features: {
      discounts: {
        applyMethod: ("automatic" | "using-code" | "meet-condition")[];
      };
    };
  };

  /* defaultLanguage: "en" | "es";
    defaultTheme: "light" | "dark";
    defaultCurrency: "usd" | "eur"; */
}

const defaultClientContext: ClientContextInterface = {
  consumer: {
    type: "web",
    features: {
      discounts: {
        applyMethod: ["automatic"],
      },
    },
  },
};

export const ClientContext =
  createContext<ClientContextInterface>(defaultClientContext);

export const ClientProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <ClientContext.Provider value={defaultClientContext}>
      {children}
    </ClientContext.Provider>
  );
};

export const useClientContext = () => useContext(ClientContext);
