const MINUTE = 1000 * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;
const MONTH = WEEK * 3.5;
const YEAR = WEEK * 40;

export const useFormatter = () => {
  const locale = "es-DO";

  function getRelativeTime(
    timestamp: number,
    unit: Intl.RelativeTimeFormatUnit = "day"
  ) {
    const rtf = new Intl.RelativeTimeFormat(locale, {
      numeric: "auto",
    });
    const diffTimestamp = timestamp - new Date().getTime();
    const daysDifference = Math.round(diffTimestamp / DAY);

    const absDays = Math.abs(daysDifference);
    let value = daysDifference;

    if (absDays <= 0.3) {
      unit = "minute";
      value = Math.round(diffTimestamp / MINUTE);
    }
    if (absDays > 0.3) {
      unit = "hour";
      value = value = Math.round(diffTimestamp / HOUR);
    }
    if (absDays > 6) {
      unit = "week";
      value = value = Math.round(diffTimestamp / WEEK);
    }
    if (absDays > 30) {
      unit = "month";
      value = value = Math.round(diffTimestamp / MONTH);
    }
    if (absDays > 30 * 12) {
      unit = "year";
      value = value = Math.round(diffTimestamp / YEAR);
    }

    return rtf.format(value, unit);
  }

  type RelativeTimeFormatEnhanced = Intl.RelativeTimeFormat & {
    formatRelative(
      timestamp: number,
      unit: Intl.RelativeTimeFormatUnit
    ): string;
  };

  const formatter = {
    date: new Intl.DateTimeFormat(locale, {
      day: "2-digit",
      month: "long",
      year: "numeric",
    }),
    relativeDate: new Intl.RelativeTimeFormat(locale, {
      style: "long",
    }) as RelativeTimeFormatEnhanced,
    currency: new Intl.NumberFormat(locale, {
      style: "currency",
      currency: "DOP",
    }),
  };

  formatter.relativeDate.formatRelative = getRelativeTime;

  return formatter;
};
