import httpClient from "../clients/http";
import { GetListParams } from "../interfaces/base";
import { INewDiscountForm } from "../interfaces/discounts/forms";
import { clearBlank } from "../utils/object";

export class DiscountService {
  static async changeStatus(id: any): Promise<unknown> {
    const response = await httpClient.put(`/discounts/${id}/status`, {});
    return response.data;
  }
  static async getOne(id: string) {
    const response = await httpClient.get(`/discounts/${id}`);
    return response.data;
  }
  static async create(formData: INewDiscountForm) {
    const response = await httpClient.post("/discounts", formData);
    return response.data;
  }
  static async update(id: string, formData: INewDiscountForm) {
    const response = await httpClient.put(`/discounts/${id}`, formData);
    return response.data;
  }
  static async getList(filters: Partial<GetListParams>) {
    const queryParams = new URLSearchParams(clearBlank(filters));
    const response = await httpClient.get(
      "/discounts?" + queryParams.toString()
    );
    return response.data;
  }
}
