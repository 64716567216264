interface AnyObject {
  [key: string]: any;
}

export function setValue(obj: AnyObject, key: string, value: any): AnyObject {
  const keys: string[] = key.split(".");
  let currentObj: AnyObject = obj;
  for (let i = 0; i < keys.length - 1; i++) {
    const currentKey: string = keys[i];
    if (!currentObj[currentKey]) {
      currentObj[currentKey] = {};
    }
    currentObj = currentObj[currentKey];
  }
  currentObj[keys[keys.length - 1]] = value;
  return obj;
}

export const clearBlank = (obj: AnyObject): AnyObject =>
  Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
