import { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import { useUser } from "../contexts/auth";
import { usePermissions } from "../contexts/auth-permission";
import { useClient } from "../hooks/client";

function SideBar({ isOpen, closeMenu, showAlert }: any) {
  const intl = useIntl();
  const { user } = useUser();
  const { pathname } = useLocation();
  const { ability } = usePermissions();
  const { logo } = useClient();

  const menu = [
    {
      isLabel: true,
      permission: "None",
      text: intl.formatMessage({ id: "Dashboards" }),
    },
    {
      text: intl.formatMessage({ id: "Dashboard" }),
      icon: "ni ni-layout-alt",
      permission: "None",
      link: "/",
      active: false,
    },
    {
      isLabel: true,
      text: intl.formatMessage({ id: "Business" }),
    },
    {
      text: intl.formatMessage({ id: "Customers" }),
      permission: "Customer",
      icon: "ni ni-user-list-fill",
      link: "/customers",
      active: false,
    },
    {
      text: intl.formatMessage({ id: "Products" }),
      permission: "Product",
      icon: "ni ni-card-view",
      link: "/products",
      active: false,
    },
    {
      text: intl.formatMessage({ id: "Inventory" }),
      icon: "ni ni-box",
      link: "/inventory",
      permission: "Inventory",
      active: false,
    },
    {
      text: intl.formatMessage({ id: "Orders" }),
      permission: "Order",
      icon: "ni ni-cc-alt2-fill",
      link: "/orders",
      active: false,
    },
    /*     {
      isLabel: true,
      text: intl.formatMessage({ id: "Marketing" }),
      // permission: ["Coupon"],
    }, */
    {
      text: intl.formatMessage({ id: "Discounts" }),
      permission: "Discount",
      icon: "ni ni-offer-fill",
      link: "/discounts",
      active: false,
    },
    {
      isLabel: true,
      text: "Contenido",
      permission: ["Page", "Collection", "Form", "Media"],
    },
    {
      text: intl.formatMessage({ id: "Pages" }),
      permission: "Page",
      icon: "ni ni-files",
      link: "/cms/pages",
      active: false,
    },
    {
      text: intl.formatMessage({ id: "Collections" }),
      permission: "Collection",
      icon: "ni ni-template",
      link: "/cms/collections",
      active: false,
    },
    {
      text: intl.formatMessage({ id: "Forms" }),
      permission: "Form",
      icon: "ni ni-pen",
      link: "/cms/forms",
      active: false,
    },
    {
      text: intl.formatMessage({ id: "Media Library" }),
      permission: "Media",
      icon: "ni ni-img",
      link: "/cms/media",
      active: false,
    },
    {
      isLabel: true,
      text: intl.formatMessage({ id: "Administration" }),
      permission: ["Setting", "User", "Addon"],
    },
    {
      text: intl.formatMessage({ id: "Users" }),
      permission: "User",
      icon: "ni ni-users-fill",
      link: "/users",
      active: false,
    },
    {
      text: intl.formatMessage({ id: "Catalogs" }),
      permission: "Setting",
      icon: "ni ni-layers",
      link: "/catalogs",
      active: false,
    },
    {
      text: intl.formatMessage({ id: "Settings" }),
      permission: "Setting",
      icon: "ni ni-setting",
      link: "/settings",
      active: false,
    },
    {
      isLabel: true,
      text: intl.formatMessage({ id: "Subscription" }),
      permission: "Subscription",
    },
    {
      text: intl.formatMessage({ id: "Integrations" }),
      permission: "Addon",
      icon: "icon ni ni-server-fill",
      link: "/integrations",
      active: false,
    },
    {
      text: intl.formatMessage({ id: "Subscriptions" }),
      permission: "Subscription",
      icon: "icon ni ni-report-profit",
      link: "/subscriptions",
      active: false,
    },
  ]
    .map((item) => {
      item.active = false;

      if (pathname === "/" && item.link === pathname) {
        item.active = true;
      }
      if (pathname.startsWith(String(item.link)) && item.link !== "/") {
        item.active = true;
      }

      return item;
    })
    .filter((p) => {
      if (p.permission === "None") return true;

      if (user?.groups?.find((g) => g.name === "admin")) return true;

      if (p.isLabel && Array.isArray(p.permission)) {
        return p.permission.some((k) => ability.can("read", k as any));
      }

      return ability.can("read", p.permission as any);
    });

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (
        ref.current &&
        !ref.current.contains(event.target as any) /* &&
        !nav.current.contains(event.target) */
      ) {
        closeMenu();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div
      style={{
        top: showAlert ? "57px" : "0px",
      }}
      ref={ref}
      className={`nk-sidebar nk-sidebar-fixed is-light ${
        isOpen ? "nk-sidebar-active" : ""
      }`}
      data-content="sidebarMenu"
    >
      <div className="nk-sidebar-element nk-sidebar-head">
        <div className="nk-sidebar-brand">
          <Link to="/" className="logo-link nk-sidebar-logo">
            <img
              className="logo-img logo-img-lg"
              src={logo}
              style={{
                filter: "invert(0.5) sepia(1) saturate(53) hue-rotate(235deg)",
                minWidth: "180px"
              }}
              alt="logo"
            />
            <img
              className="logo-small logo-img logo-img-small"
              src="/images/logo-small.png"
              srcSet="/images/logo-small2x.png 2x"
              alt="logo-small"
            />
          </Link>
        </div>
        <div className="nk-menu-trigger me-n2">
          <a
            onClick={closeMenu}
            className="nk-nav-toggle nk-quick-nav-icon d-xl-none"
          >
            <em className="icon ni ni-arrow-left" />
          </a>
          {/* <a className="nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex">
            <em className="icon ni ni-menu" />
          </a> */}
        </div>
      </div>
      {/* .nk-sidebar-element */}
      <div className="nk-sidebar-element">
        <div className="nk-sidebar-content">
          <div className="nk-sidebar-menu" data-simplebar="init">
            <div
              className="simplebar-wrapper"
              style={{ margin: "-16px 0px -40px" }}
            >
              <div className="simplebar-height-auto-observer-wrapper">
                <div className="simplebar-height-auto-observer" />
              </div>
              <div className="simplebar-mask">
                <div
                  className="simplebar-offset"
                  style={{ right: "0px", bottom: "0px" }}
                >
                  <div
                    className="simplebar-content-wrapper"
                    tabIndex={0}
                    role="region"
                    aria-label="scrollable content"
                    style={{ height: "100%", overflow: "hidden scroll" }}
                  >
                    <div
                      className="simplebar-content"
                      style={{ padding: "16px 0px 40px" }}
                    >
                      <ul className="nk-menu">
                        {menu.map((item, index) => {
                          if (item.isLabel) {
                            return (
                              <li key={index} className="nk-menu-heading">
                                <h6 className="overline-title text-primary-alt">
                                  {item.text}
                                </h6>
                              </li>
                            );
                          }

                          return (
                            <li
                              key={index}
                              className={`nk-menu-item ${
                                item.active ? "active current-page" : ""
                              }`}
                            >
                              <Link
                                onClick={() => closeMenu()}
                                to={String(item.link)}
                                className={`nk-menu-link `}
                              >
                                <span className="nk-menu-icon">
                                  <em className={`icon ${item.icon}`} />
                                </span>
                                <span className="nk-menu-text">
                                  {item.text}
                                </span>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="simplebar-placeholder"
                style={{ width: "auto", height: "1886px" }}
              />
            </div>
            <div
              className="simplebar-track simplebar-horizontal"
              style={{ visibility: "hidden" }}
            >
              <div
                className="simplebar-scrollbar"
                style={{
                  width: "0px",
                  display: "none",
                  transform: "translate3d(0px, 0px, 0px)",
                }}
              />
            </div>
            <div
              className="simplebar-track simplebar-vertical"
              style={{ visibility: "visible" }}
            >
              <div
                className="simplebar-scrollbar"
                style={{
                  height: "425px",
                  transform: "translate3d(0px, 0px, 0px)",
                  display: "block",
                }}
              />
            </div>
          </div>
          {/* .nk-sidebar-menu */}
        </div>
        {/* .nk-sidebar-content */}
      </div>
      {/* .nk-sidebar-element */}
    </div>
  );
}

export default SideBar;
