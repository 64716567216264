import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className="nk-block nk-block-middle wide-xs mx-auto">
      <div className="nk-block-content nk-error-ld text-center">
        <h3 className="nk-error-title">
          <FormattedMessage
            id="NotFound.Title"
            defaultMessage="Oops! Why you’re here?"
          />
        </h3>
        <p className="nk-error-text">
          <FormattedMessage
            id="NotFound.Text"
            defaultMessage="We are very sorry for the inconvenience. It looks like you're trying to access a page that either has been deleted or never existed."
          />
        </p>
        <Link to="/" className="btn btn-lg btn-primary mt-2">
          <FormattedMessage
            id="NotFound.BackToHome"
            defaultMessage="Back To Home"
          />
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
