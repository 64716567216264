import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { User, useAuth, useUser } from "../../contexts/auth";
import { getFullName, getInitials } from "../../utils/person";
import { FormattedMessage } from "react-intl";
import { queryClient } from "../../contexts/query";

function NavUserMenu() {
  const { user } = useUser() as { user: User };

  const { logout } = useAuth();

  const navigate = useNavigate();

  const onLogout = () => {
    logout();
    queryClient.removeQueries();
    navigate("/login", { replace: true });
  };

  return (
    <Dropdown className="dropdown user-dropdown" as="li">
      <Dropdown.Toggle
        className="dropdown-toggle me-n1"
        data-bs-toggle="dropdown"
        variant=""
      >
        <div className="user-toggle">
          <div className="user-avatar sm">
            <em className="icon ni ni-user-alt" />
          </div>
          <div className="user-info d-none d-xl-block">
            {/* <div className="user-status user-status-unverified">Unverified</div> */}
            <div className="user-name dropdown-indicator">
              {getFullName(user)}
            </div>
          </div>
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu dropdown-menu-md dropdown-menu-end">
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <div className="user-card">
            <div className="user-avatar">
              <span>{getInitials(user)}</span>
            </div>
            <div className="user-info">
              <span className="lead-text"> {getFullName(user)}</span>
              <span className="sub-text">{user.email}</span>
            </div>
          </div>
        </div>
        {/* <div className="dropdown-inner">
          <ul className="link-list">
            <li>
              <a href="html/user-profile-setting.html">
                <em className="icon ni ni-setting-alt" />
                <span>
                  <FormattedMessage id="Account settings" />
                </span>
              </a>
            </li>  <li>
              <a href="html/user-profile-activity.html">
                <em className="icon ni ni-activity-alt" />
                <span>
                  <FormattedMessage id="Recent activity" />
                </span>
              </a>
            </li> 
          </ul>
        </div> */}
        <div className="dropdown-inner">
          <ul className="link-list">
            <li>
              <a href="#" onClick={onLogout}>
                <em className="icon ni ni-signout" />
                <span>
                  <FormattedMessage id="Sign off" />
                </span>
              </a>
            </li>
          </ul>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default NavUserMenu;
