import { Alert, Form } from "react-bootstrap";
import { Controller, FieldErrors, UseFormRegister } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import CategorySelect from "../../../components/Select/CategorySelect";
import { INewDiscountForm } from "../../../interfaces/discounts/forms";

export interface DiscountFormApplyToProps {
  register: UseFormRegister<INewDiscountForm>;
  errors: FieldErrors<INewDiscountForm>;
  applyType: INewDiscountForm["applyTo"];
  setApplyType: (type: INewDiscountForm["applyTo"]) => void;
  selectedProducts: any[];
  control: any;
  data: any;
}

function DiscountFormApplyTo({
  applyType,
  setApplyType,
  selectedProducts,
  control,
  data,
}: DiscountFormApplyToProps) {
  return (
    <>
      <div className="mt-4 mb-4">
        <span className="preview-title-lg overline-title ">
          <hr />
          <FormattedMessage id="Apply to" />
        </span>
        <p>
          <FormattedMessage id="Select the products to which the discount will be applied" />
        </p>
      </div>
      <div className="custom-control custom-switch checked mb-5">
        <input
          type="checkbox"
          className="custom-control-input"
          id="apply-all-switch"
          onChange={() => {
            const nextVal = applyType === "all" ? "by-products" : "all";
            setApplyType(nextVal);
          }}
          checked={applyType === "all"}
        />
        <label className="custom-control-label" htmlFor="apply-all-switch">
          <FormattedMessage id="Apply to all products?" />
        </label>
      </div>

      {applyType !== "all" ? (
        <Form.Group controlId="type" className="mb-5">
          <div>
            <ul className="custom-control-group row w-100">
              <li className="col">
                <div className="custom-control custom-checkbox custom-control-pro no-control w-100">
                  <input
                    type="radio"
                    className="custom-control-input"
                    checked={applyType === "by-products"}
                    id="entry-radio"
                    value={"by-products"}
                    onChange={() => setApplyType("by-products")}
                  />
                  <label
                    className="custom-control-label w-100"
                    htmlFor="entry-radio"
                  >
                    {/* <em className="icon ni ni-arrow-right"></em> */}
                    <FormattedMessage id="By products" />
                  </label>
                </div>
              </li>
              <li className="col">
                <div className="custom-control custom-checkbox custom-control-pro no-control w-100">
                  <input
                    type="radio"
                    className="custom-control-input"
                    checked={applyType === "by-category"}
                    id="exit-radio"
                    onChange={() => setApplyType("by-category")}
                    value={"by-category"}
                  />
                  <label
                    className="custom-control-label w-100"
                    htmlFor="exit-radio"
                  >
                    {/*                               <em className="icon ni ni-arrow-left"></em> */}
                    <FormattedMessage id="By category" />
                  </label>
                </div>
              </li>
            </ul>
          </div>
        </Form.Group>
      ) : null}

      {applyType === "by-products" ? (
        <div>
          {selectedProducts.length ? (
            <div>
              <div className="text-info mb-2">
                <i className="icon ni ni-alert-circle"></i>{" "}
                <b>
                  {" "}
                  <FormattedMessage
                    id="{N} products are selected"
                    values={{
                      N: selectedProducts.length,
                    }}
                  />
                </b>
              </div>
            </div>
          ) : (
            <Alert variant="warning">
              <i className="icon ni ni-alert-circle"></i>{" "}
              <FormattedMessage id="You must select at least one product" />
            </Alert>
          )}
        </div>
      ) : applyType === "by-category" ? (
        <div>
          <Form.Group className="mb-3">
            <Form.Label>
              <FormattedMessage id="Select a category" />
            </Form.Label>
            <Controller
              control={control}
              name="category"
              rules={{ required: true }}
              render={({ field }) => {
                return <CategorySelect {...field} />;
              }}
            />
          </Form.Group>
        </div>
      ) : (
        <div>
          <div className="text-info">
            <i className="icon ni ni-alert-circle"></i>{" "}
            <b>
              {" "}
              <FormattedMessage
                id="{N} products are selected"
                values={{
                  N: data?.pagination.total,
                }}
              />
            </b>
          </div>
        </div>
      )}
    </>
  );
}

export default DiscountFormApplyTo;
