import React from "react";
import Dropdown, { DropdownMenu, DropdownMenuOption } from "./Dropdown";

export const DynamicDropdown: React.FC<{
  initialText: string;
  options: Record<string, { text: string; class: string; icon?: string }>;
  onClick: (e: any, k: string | undefined) => void;
  selected?: string;
  variant?: "solid" | "outline";
  clearable?: boolean;
  dimmed?: boolean;
}> = ({
  options,
  onClick,
  initialText,
  selected,
  variant = "outline",
  clearable = true,
  dimmed = false,
}) => {
  const dropdownOptions: DropdownMenuOption<any>[] = Object.keys(options).map(
    (k) => ({
      text: options[k].text,
      icon: options[k].icon,
      click: (e: any) => onClick(e, k),
    })
  );

  const variantClass = variant === "outline" ? "outline-" : "";

  return (
    <Dropdown
      indicator
      text={
        selected ? (
          <>
            {options[selected].icon ? (
              <em className={`icon ${options[selected].icon} me-2`} />
            ) : (
              ""
            )}
            {options[selected].text}
          </>
        ) : (
          initialText
        )
      }
      variant={selected ? variantClass + options[selected].class : "light"}
      className={`${selected ? "" : `btn-${variantClass}light`} ${
        variant === "outline" ? "btn-white" : ""
      } ${dimmed ? " btn-dim" : ""}`}
    >
      <DropdownMenu
        options={
          selected && clearable
            ? dropdownOptions.concat({
                type: "item",
                text: "Limpiar",
                click: (e: any) => onClick(e, undefined),
                className: "bg-light",
              } as any)
            : dropdownOptions
        }
        className="py-0"
      />
    </Dropdown>
  );
};

export default DynamicDropdown;
