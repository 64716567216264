export const getFieldDescription = (field: any) =>
  field.fullName ||
  field.name ||
  field.title ||
  field.text ||
  field.displayName;

export const isMainKey = (key: string) =>
  key === "fullName" || key === "name" || key === "title" || key === "email";

export const getFieldKey = (field: any) =>
  field.fullName
    ? { name: "Nombre completo", key: "fullName" }
    : field.name
    ? { name: "Nombre", key: "name" }
    : field.displayName
    ? { name: "Nombre a mostrar", key: "displayName" }
    : field.title
    ? { name: "Titulo", key: "title" }
    : field.text
    ? { name: "Texto", key: "text" }
    : field.content
    ? { name: "Contenido", key: "content" }
    : { name: "-", key: "" };
