import { FormSelect } from "react-bootstrap";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { IPaginationProps } from "./interfaces";

const Pagination: React.FC<IPaginationProps> = ({
  size,
  total,
  current,
  between = 3,
  ellipsis = 2,
  onPageChange,
  onSizeChange,
  compact,
}) => {
  const PaginationControls = () => (
    <div className="nk-block-between-md g-3">
      <div className="g">
        {total > size ? (
          <PaginationControl
            page={current}
            between={between}
            total={total}
            limit={size}
            changePage={onPageChange}
            ellipsis={ellipsis}
          />
        ) : null}
      </div>
      <div className="g">
        <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
          <div>Mostrando</div>
          {total > size ? (
            <>
              <div>
                <FormSelect
                  aria-hidden="true"
                  value={size}
                  onChange={(value) =>
                    onSizeChange && onSizeChange(value.target.value)
                  }
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                </FormSelect>
              </div>
              <div>DE {total}</div>
            </>
          ) : (
            <div>{total}</div>
          )}
        </div>
      </div>
    </div>
  );

  if (compact) return <PaginationControls />;

  return (
    <div className="card">
      <div className="card-inner">
        <PaginationControls />
      </div>
    </div>
  );
};

export default Pagination;
