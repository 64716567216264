const env =
  process.env.REACT_APP_ENV || typeof window !== "undefined"
    ? window.location.hostname.includes("localhost")
      ? "staging"
      : "production"
    : "production";

export default {
  env,
};
