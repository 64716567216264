import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "react-query";
import { CatalogService } from "../../services/catalog";
import { Spinner } from "react-bootstrap";
import { formatMidDateTime } from "../../utils/formatter";
import { useForm } from "react-hook-form";
import FormGenerator from "../../components/ContentManager/generator";

type FieldSchema = { label?: string };

function getLabelFromSchema(
  schema: Record<string, FieldSchema> | undefined,
  fieldName: string
): string {
  const field = schema?.[fieldName];
  return field ? field.label || fieldName : fieldName;
}

function CatalogoDetails() {
  const { id, key } = useParams();
  const intl = useIntl();
  const navigate = useNavigate();
  const methods = useForm();

  const onSubmit = () => {};

  const { data, isLoading } = useQuery(
    ["catalogoDetail", id, key],
    () => CatalogService.getCatalogoId(key as string, id),
    {
      onSuccess(data) {
        methods.reset(data);
      },
    }
  );

  const schemaQuery = useQuery(
    ["catalogSchemaByKey", key],
    (ctx) => {
      const [, key] = ctx.queryKey;
      return CatalogService.getSchema(key as any);
    },
    {
      cacheTime: Infinity,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
    }
  );

  return (
    <div className="components-preview wide-md mx-auto">
      <div className="nk-block-head nk-block-head-lg wide-sm">
        <div className="nk-block-head-content">
          <div className="nk-block-head-sub">
            <a role="button" className="back-to" onClick={() => navigate(-1)}>
              <em className="icon ni ni-arrow-left" />
              <span>{intl.formatMessage({ id: "Go back to catalogs" })}</span>
            </a>
          </div>
          <h2 className="nk-block-title fw-normal">{data?.name}</h2>
          <div className="nk-block-des">
            <p className="lead">
              <FormattedMessage id="Part of the catalogue of" />{" "}
              <b>{intl.formatMessage({ id: `${key}` })}</b>
            </p>
          </div>
        </div>
      </div>
      <div className="nk-block nk-block-lg">
        <div className="nk-block-head">
          <div className="nk-block-head-content">
            <h4 className="title nk-block-title">
              <FormattedMessage id="Available fields" />
            </h4>
          </div>
        </div>
        <div className="card card-bordered card-preview">
          {isLoading || schemaQuery.isLoading ? (
            <div className="card-inner text-center">
              <Spinner />
            </div>
          ) : (
            <div className="card-inner">
              <div className="preview-block">
                <div>
                  <FormGenerator
                    methods={methods}
                    schema={schemaQuery.data}
                    onSubmit={onSubmit}
                    readOnly={true}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CatalogoDetails;
