import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DynamicDropdown from "../../../components/Buttons/DynamicDropdown";
import FormGenerator from "../../../components/ContentManager/generator";
import Datepicker from "../../../components/Inputs/Datepicker";
import { CollectionService } from "../../../services/cms/collection";
import { CollectionPublishStatus } from "../enums/collection-status";

export default function CollectionCreate() {
  const intl = useIntl();
  const { name } = useParams();
  const methods = useForm();
  const navigate = useNavigate();
  const { data: collection, isLoading } = useQuery(
    ["collectionDetails", name],
    () => CollectionService.getSchema(name as string),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      onSuccess(data) {
        const { values } = CollectionService.prepareForForm(data.attributes);
        methods.reset(values);
      },
    }
  );

  const onSubmit = async (formData: any) => {
    try {
      const { data: values } = await CollectionService.parseFormFrom(
        collection,
        formData
      );
      await CollectionService.create(name as string, values);
      toast.success(intl.formatMessage({ id: "TOAST.CREATE_SUCCESS" }));
      navigate(`/cms/collections/${name}`);
    } catch (error) {
      toast.error(intl.formatMessage({ id: "TOAST.CREATE_ERROR" }));
      console.error(error);
    }
  };

  const publishState = methods.watch("publishState");

  return (
    <div className="components-preview wide-md mx-auto">
      <div>
        <div className="nk-block-head-sub">
          <a role="button" className="back-to" onClick={() => navigate(-1)}>
            <em className="icon ni ni-arrow-left" />
            <span>
              <FormattedMessage
                id="List of {collectionName}"
                values={{
                  collectionName: collection?.displayName || "Colecciones",
                }}
              />
            </span>
          </a>
        </div>
      </div>
      <h2>
        <FormattedMessage
          id="Add new record of {collectionName}"
          values={{ collectionName: collection?.displayName || name }}
        />
      </h2>

      <div className="nk-block nk-block-lg mt-5">
        <div className="nk-block-head">
          <div className="nk-block-head-content">
            <Row>
              <Col>
                <h4 className="title nk-block-title d-inline-block me-3">
                  <FormattedMessage id="Save as" />:
                </h4>

                <DynamicDropdown
                  initialText={intl.formatMessage({ id: "Status" })}
                  options={CollectionPublishStatus}
                  selected={publishState || "draft"}
                  variant="solid"
                  clearable={false}
                  onClick={(_, k) => methods.setValue("publishState", k)}
                />
              </Col>
              {publishState === "publishOnDate" ? (
                <Col className="text-end">
                  <Form.Group>
                    <Form.Label className="me-2">
                      <FormattedMessage id="Publish on date" />{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Controller
                      rules={{
                        required: true,
                        validate: {
                          futureDate: (value) => {
                            const today = new Date();
                            const selectedDate = new Date(value);
                            return selectedDate > today;
                          },
                        },
                      }}
                      control={methods.control}
                      name="publishAt"
                      render={({ field }) => (
                        <Datepicker
                          className={`${
                            methods.formState.errors.publishAt
                              ? "is-invalid"
                              : ""
                          }`}
                          style={{ width: "auto", display: "inline" }}
                          label={intl.formatMessage({ id: "Publish on date" })}
                          {...field}
                        />
                      )}
                    />
                    {methods.formState.errors.publishAt &&
                      methods.formState.errors.publishAt.type ===
                        "futureDate" && (
                        <Form.Control.Feedback type="invalid">
                          <FormattedMessage id="Selected date must be in the future" />
                        </Form.Control.Feedback>
                      )}
                  </Form.Group>
                </Col>
              ) : null}
            </Row>
          </div>
        </div>
        <div className="card card-bordered card-preview">
          <div className="card-inner">
            <div className="preview-block">
              {!collection || isLoading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">
                      <FormattedMessage id="loading" />
                    </span>
                  </div>
                </div>
              ) : (
                <FormGenerator
                  methods={methods}
                  schema={collection?.attributes}
                  onSubmit={onSubmit}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
