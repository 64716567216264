import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { PaymentSettingService } from "../../services/payment";
import { useQuery } from "react-query";
import { Spinner } from "react-bootstrap";

function PaymentSetting() {
  const { data, isLoading } = useQuery(
    "payment",
    PaymentSettingService.getPaymentMethodStatus
  );

  return (
    <div className="card-inner">
      <h5 className="card-title">
        <FormattedMessage id="Payment methods" />
      </h5>
      <p>
        <FormattedMessage id="You can configure your payment methods here." />{" "}
      </p>

      {isLoading ? (
        <div
          style={{
            height: "100vh",
            alignItems: "center",
            flexDirection: "column",
          }}
          className="d-flex justify-content-center"
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">
              <FormattedMessage id="Loading" />
            </span>
          </Spinner>
          <FormattedMessage id="Loading" />
        </div>
      ) : (
        <section>
          {data?.enabled ? (
            <div className="alert alert-success alert-icon">
              <em className="icon ni ni-check-circle"></em>
              <FormattedMessage id="You have successfully added your payment method." />
            </div>
          ) : (
            <div className="alert alert-warning alert-icon">
              <em className="icon ni ni-alert-circle"></em>
              <FormattedMessage id="You don't have any payment method added yet." />{" "}
              <Link to="/settings/payment/add" className="alert-link">
                <FormattedMessage id="Go to integrations" />
              </Link>{" "}
              <FormattedMessage id="to add your first payment method." />
            </div>
          )}
        </section>
      )}
    </div>
  );
}

export default PaymentSetting;
