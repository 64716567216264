import { default as http, default as httpClient } from "../clients/http";
import { serializeParams } from "../utils/url";

const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3001";

type InventoryGetListParams = {
  status: string | null;
  search: string | null;
  page: string | null;
  size: string | null;
};

type INewInventoryForm = {
  type: string;
  concept: string;
  value: number;
  productId: string;
};

export class InventoryService {
  static async getList(filters: InventoryGetListParams) {
    const response = await http.get(`/inventory?${serializeParams(filters)}`);
    const { data } = response;
    return {
      data: data.data,
      pagination: data.pagination,
    };
  }

  static async getConceptsList() {
    const response = await http.get(`/inventory/concepts`);
    const { data } = response;
    return {
      data: data.data,
      pagination: data.pagination,
    };
  }

  static async create(data: INewInventoryForm) {
    const response = await http.post(`/inventory`, {
      type: data.type,
      concept: data.concept,
      value: Number(data.value),
      productId: data.productId,
    });
    return response.data;
  }

  static delete(id: string): any {
    return httpClient.delete(`inventory/${id}`);
  }
}
