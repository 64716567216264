import { useEffect } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { AddressType } from "../../enums/types";
import { AddressDto, AddressService } from "../../services/address";

export interface IRecordAddressProps {
  show: boolean;
  handleClose: () => void;
  refetch: () => void;
  customerId: string;
  selected?: AddressDto;
}

export function RecordAddress({
  show,
  handleClose,
  refetch,
  customerId,
  selected,
}: IRecordAddressProps) {
  const intl = useIntl();

  const methods = useForm({
    defaultValues: selected,
  });
  const { handleSubmit, register, reset } = methods;

  const createRecord = useMutation({
    mutationFn: async (data: any) => {
      return await AddressService.createAddress({
        ...data,
        customer: customerId,
      });
    },
    onSuccess(data: any) {
      refetch();
      toast.success(intl.formatMessage({ id: "TOAST.CREATE_SUCCESS" }));
      handleClose();
    },
  });

  const id = selected?.id || "";
  useEffect(() => {
    if (id && selected) {
      reset(selected);
    } else {
      reset({
        address: "",
        municipality: "",
        neighborhood: "",
        reference: "",
        type: "shipping",
        province: "",
      });
    }
  }, [id]);

  const onSubmit = async (formData: any) => {
    await createRecord.mutateAsync({
      ...formData,
    });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {id ? (
              <FormattedMessage id="Edit Address" />
            ) : (
              <FormattedMessage id="Add Address" />
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="type" className="mb-2">
            <Form.Label>
              <FormattedMessage id="Type of entrance" />
            </Form.Label>
            <div>
              <ul className="custom-control-group row w-100">
                <li className="col">
                  <div className="custom-control custom-checkbox custom-control-pro no-control w-100">
                    <input
                      type="radio"
                      className="custom-control-input"
                      {...register("type", { required: true })}
                      id="shipping-radio"
                      value={"shipping"}
                    />
                    <label
                      className="custom-control-label w-100"
                      htmlFor="shipping-radio"
                    >
                      <em className={"me-1 " + AddressType.shipping.icon}></em>
                      <FormattedMessage id={AddressType.shipping.text} />
                    </label>
                  </div>
                </li>
                <li className="col">
                  <div className="custom-control custom-checkbox custom-control-pro no-control w-100">
                    <input
                      type="radio"
                      className="custom-control-input"
                      {...register("type", { required: true })}
                      id="billing-radio"
                      value={"billing"}
                    />
                    <label
                      className="custom-control-label w-100"
                      htmlFor="billing-radio"
                    >
                      <em className={"me-1 " + AddressType.billing.icon}></em>
                      <FormattedMessage id={AddressType.billing.text} />
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </Form.Group>
          <hr />

          <Row>
            <Col xs={12}>
              <Form.Group controlId="address" className="mb-2">
                <Form.Label>
                  <FormattedMessage id="Address" />
                </Form.Label>
                <textarea
                  className="form-control"
                  {...register("address", { required: true })}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Group controlId="neighborhood" className="mb-2">
                <Form.Label>
                  <FormattedMessage id="Neighborhood" />
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register("neighborhood", { required: true })}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={6}>
              <Form.Group controlId="municipality" className="mb-2">
                <Form.Label>
                  <FormattedMessage id="Municipality" />
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register("municipality", { required: true })}
                />
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group controlId="province" className="mb-2">
                <Form.Label>
                  <FormattedMessage id="Province" />
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register("province", { required: true })}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group controlId="reference" className="mb-2">
                <Form.Label>
                  <FormattedMessage id="Reference" />
                </Form.Label>
                <textarea
                  className="form-control"
                  rows={1}
                  {...register("reference")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row></Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" disabled={createRecord.isLoading}>
            {createRecord.isLoading ? (
              <>
                <Spinner animation="border" size="sm" role="status" />{" "}
                <FormattedMessage id="Saving..." />
              </>
            ) : (
              <FormattedMessage id="Save" />
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default RecordAddress;
