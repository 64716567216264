import { useState } from "react";
import { Button } from "react-bootstrap";
import AssetSelectionModal from "./modal/AssetSelectionModal";

interface IAssetSelectionProps {
  name: string;
  id: string;
  defaultChecked?: boolean;
  checkable?: boolean;
  checked?: boolean;
  onSelect: (media: any) => void;
  onClick?: (media: any) => void;
  onRemove?: (media: any) => void;
  defaultAsset?: any;
}

function AssetSelection({
  id,
  name,
  defaultChecked,
  checkable,
  onClick,
  onRemove,
  checked,
  onSelect,
  defaultAsset = null,
}: IAssetSelectionProps) {
  const [isOpen, setOpen] = useState(false);
  const [asset, setAsset] = useState<any>(defaultAsset);

  return asset ? (
    <>
      <div
        style={{
          height: "150px",
          width: "100%",
        }}
        className={`custom-control custom-radio image-control ${
          checked ? "checked" : ""
        }`}
        onClick={onClick}
      >
        {checkable ? (
          <>
            <input
              type="radio"
              className={`custom-control-input `}
              id={id}
              name={name}
              onChange={(e) => (onClick ? onClick(asset) : null)}
              checked={checked}
              defaultChecked={defaultChecked}
            />
            <label className="custom-control-label" htmlFor={id}>
              <img
                src={asset.thumbnail || asset.url}
                style={{
                  width: "100%",
                }}
                alt=""
              />
            </label>
          </>
        ) : (
          <div className="custom-control-label no-selectable">
            <img
              src={asset.thumbnail || asset.url}
              style={{
                width: "100%",
              }}
              alt=""
            />
          </div>
        )}
      </div>
      <div className="my-2">
        <Button
          variant="light"
          onClick={() => {
            if (onRemove) onRemove(asset);
            setAsset(null);
          }}
          className="w-100  justify-content-center"
        >
          <em className="icon ni ni-trash me-1 "></em>
          Remover
        </Button>
      </div>
    </>
  ) : (
    <div className="upload-zone dropzone dz-clickable">
      <AssetSelectionModal
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        onAssetSelect={(asset) => {
          setAsset(asset);
          onSelect(asset);
        }}
      />
      <div className="dz-message" data-dz-message>
        <span className="dz-message-text">Arrastra un archivo</span>
        <span className="dz-message-or">o</span>
        <button
          className="btn btn-primary"
          onClick={(e) => {
            e.preventDefault();
            setOpen(true);
          }}
        >
          SELECCIONA
        </button>
      </div>
    </div>
  );
}

export default AssetSelection;
