import { useMutation, useQuery } from "react-query";
import { AddButton } from "../../components/Buttons/ActionableButton";
import Dropdown, {
  DropdownMenu,
  DropdownMenuOption,
} from "../../components/Buttons/Dropdown";
import SearchBar from "../../components/Inputs/SearchBar";

import { Link } from "react-router-dom";
import DynamicDropdown from "../../components/Buttons/DynamicDropdown";
import Table, { IPaginationProps, ITableHeader } from "../../components/Table";
import { transformToStatus } from "../../components/Table/transformers";
import Title from "../../components/Title";
import { Status } from "../../enums/status";
import { useTableQueryParams } from "../../hooks/router";
import { UserService } from "../../services/user";
import { formatMidDate } from "../../utils/formatter";
import { getFullName, getInitials } from "../../utils/person";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import Authorize from "../../components/Permission/Authorize";
import { Action, BasePermission } from "../../enums/permissions";
import { Spinner } from "react-bootstrap";

let timmerId: any;
let loadingToast: any = null;

function UserList() {
  const intl = useIntl();
  const { page, search, setParam, size, status } = useTableQueryParams();

  const { data, isLoading, refetch } = useQuery(
    ["userList", status, search, page, size],
    (ctx) => {
      const [, status, search, page, size] = ctx.queryKey;
      return UserService.getList({
        status,
        search,
        page,
        size,
      });
    }
  );

  const resetPasswordMutation = useMutation(
    ({ userId }: { userId: string }) => UserService.resetPassword(userId),
    {
      onMutate: () => {
        loadingToast = toast.info(
          <>
            <Spinner size="sm" />{" "}
            {intl.formatMessage({
              id: "Resetting user password. Wait a second...",
            })}
          </>,
          {
            autoClose: false,
            hideProgressBar: true,
            closeButton: false,
            icon: false,
          }
        );
      },
      onError: () => {
        toast.dismiss(loadingToast);
        toast.error(
          intl.formatMessage({ id: "Failed to reset user password" })
        );
      },
      onSuccess: (data) => {
        toast.dismiss(loadingToast);
        toast.success(
          intl.formatMessage({ id: "User password reset successfully" })
        );
      },
    }
  );

  const updateStatusMutation = useMutation(
    ({ userId, status }: { userId: string; status: string }) =>
      UserService.updateStatus(userId, status),
    {
      onMutate: () => {
        loadingToast = toast.info(
          <>
            <Spinner size="sm" />{" "}
            {intl.formatMessage({
              id: "Updating user status. Wait a second...",
            })}
          </>,
          {
            autoClose: false,
            hideProgressBar: true,
            closeButton: false,
            icon: false,
          }
        );
      },
      onError: () => {
        toast.dismiss(loadingToast);

        toast.error(intl.formatMessage({ id: "Failed to update user status" }));
      },
      onSuccess: () => {
        toast.dismiss(loadingToast);

        toast.success(
          intl.formatMessage({ id: "User status updated successfully" })
        );
        refetch();
      },
    }
  );

  const options: DropdownMenuOption<any>[] = [
    {
      text: intl.formatMessage({ id: "See detail" }),
      icon: "ni ni-eye",
      link: (entity) => `/users/${entity.id}`,
    },
    /*     {
      icon: "ni ni-activity-round",
      text: intl.formatMessage({ id: "Activities" }),
      link: (entity) => `/customer/${entity.id}`,
    }, */
    {
      text: intl.formatMessage({ id: "Edit" }),
      icon: "ni ni-edit",
      link: (entity) => `/users/${entity.id}/edit`,
    },
    {
      type: "divider",
    },
    {
      icon: "ni ni-shield-star",
      text: intl.formatMessage({ id: "Reset Password" }),
      click: (entity) => {
        resetPasswordMutation.mutateAsync({ userId: entity.id as string });
      },
    },
    {
      icon: "ni ni-na",
      text: intl.formatMessage({ id: "Block" }),
      click: (entity) => {
        updateStatusMutation.mutateAsync({
          userId: entity.id as string,
          status: "blocked",
        });
      },
    },
  ];

  const headers: ITableHeader[] = [
    {
      path: ["names"],
      name: intl.formatMessage({ id: "Customer" }),
      className: "lg-table-col",
      transform: (value, obj) => {
        return (
          <Link to={`/users/${obj.id}`}>
            <div className="user-card">
              <div className="user-avatar bg-primary">
                <span>{getInitials(obj)}</span>
              </div>
              <div className="user-info">
                <span className="tb-lead">
                  {getFullName(obj)}
                  <span className="dot dot-success d-md-none ms-1"></span>
                </span>
                <span>{obj.email}</span>
              </div>
            </div>
          </Link>
        );
      },
    },
    {
      path: ["lastLogin"],
      hide: "sm",
      name: intl.formatMessage({ id: "Last login" }),
      transform: (value: Date) => (value ? formatMidDate(value) : "Ninguno"),
    },
    {
      path: ["status"],
      name: intl.formatMessage({ id: "Status" }),
      transform: transformToStatus,
    },
    {
      path: ["options"], // no id
      name: "",
      type: "tools",
      transform: (v, entity) => (
        <Dropdown
          key={entity.id}
          icon
          trigger
          text={<em className="icon ni ni-more-h" />}
        >
          <DropdownMenu options={options} entity={entity} />
        </Dropdown>
      ),
    },
  ];

  const pagination: IPaginationProps = {
    current: Number(page) || data?.pagination.page,
    size: Number(size) || data?.pagination.size,
    total: data?.pagination?.total,
    onPageChange(page) {
      setParam("page", String(page));
    },
    onSizeChange(size) {
      setParam("size", String(size));
    },
  };

  const onSearch = (term: string) => {
    clearTimeout(timmerId);
    timmerId = setTimeout(() => {
      setParam("search", term);
    }, Number(process.env.REACT_APP_SEARCH_DELAY || 700));
  };

  return (
    <div className="container-fluid">
      <div className="nk-content-inner">
        <div className="nk-content-body">
          <Title
            subText={intl.formatMessage(
              { id: "You have a total of N users." },
              { number: data?.pagination?.total || 0 }
            )}
            rightContent={
              <ul className="nk-block-tools g-3">
                <li>
                  <SearchBar onChange={(e) => onSearch(e.target.value)} />
                </li>
                <li>
                  <DynamicDropdown
                    initialText="Estado"
                    options={Status}
                    selected={status || undefined}
                    onClick={(_, k) => setParam("status", k)}
                  />
                </li>
                <Authorize
                  actions={[Action.CREATE]}
                  subjects={[BasePermission.User]}
                >
                  <li className="nk-block-tools-opt">
                    <Link to="/users/add">
                      <AddButton />
                    </Link>
                  </li>
                </Authorize>
              </ul>
            }
          >
            <FormattedMessage id="Users list" />
          </Title>

          <div className="nk-block">
            <Table
              headers={headers}
              data={data?.data || []}
              /* selectable */
              loading={isLoading}
              pagination={pagination}
              /*  bulkOptions={[
                {
                  icon: "ni ni-mail",
                  text: intl.formatMessage({ id: "Send email to everyone" }),
                  click: (entities) => console.log(entities),
                },
                {
                  icon: "ni ni-na",
                  text: intl.formatMessage({ id: "Disable all" }),
                },
                {
                  icon: "ni ni-trash",
                  text: intl.formatMessage({ id: "Delete all" }),
                },
                {
                  icon: "ni ni-shield-star",
                  text: intl.formatMessage({ id: "Reset all" }),
                },
              ]} */
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserList;
