import { Form } from "react-router-dom";
import MediaContentManager from "../../../components/ContentManager/ContentManager";
import Title from "../../../components/Title";
import { FormattedMessage } from "react-intl";
import { Col, Row } from "react-bootstrap";
import { useQueries } from "react-query";
import { MediaService } from "../../../services/cms/media";
import { SubscriptionService } from "../../../services/subscriptions";
import { Action, BasePermission } from "../../../enums/permissions";
import Authorize from "../../../components/Permission/Authorize";

function Usage() {
  const [planDetailsQuery, mediaUsageQuery] = useQueries([
    {
      queryKey: ["myPlanDetails"],
      queryFn: SubscriptionService.getMyPlanDetails,
      staleTime: Infinity,
    },
    {
      queryKey: ["mediaUsage"],
      queryFn: MediaService.getUsage,
      staleTime: Infinity,
    },
  ]);

  const percent = Math.round(
    (mediaUsageQuery.data as any)?.stats.usage.raw /
      (planDetailsQuery.data as any)?.benefits.storage.limit.raw || 0
  );

  return (
    <Row>
      <Col>
        <h6 className="nk-fmg-status-title">
          <em className="icon ni ni-hard-drive"></em>
          <span>Almacenamiento</span>
        </h6>
        <div className="progress progress-md bg-light">
          <div
            className="progress-bar"
            data-progress={percent}
            style={{ width: `${percent}%` }}
          ></div>
        </div>
      </Col>
      <Col>
        <div className="nk-fmg-status-info mt-0">
          {!planDetailsQuery.isLoading && !mediaUsageQuery.isLoading ? (
            <>
              {" "}
              {(mediaUsageQuery.data as any)?.stats.usage.display} de{" "}
              {(planDetailsQuery.data as any)?.benefits.storage.limit.display}{" "}
              usado
            </>
          ) : (
            "...cargando"
          )}
        </div>
        <div className="nk-fmg-status-action">
          <a
            href="mailto:admin@chalascreations.com"
            className="link link-primary link-sm"
          >
            Subir tu plan
          </a>
        </div>
      </Col>
    </Row>
  );
}

function MediaList() {
  return (
    <div className="container-fluid">
      <div className="nk-content-inner">
        <div className="nk-content-body">
          <Title
            subText="Aca puedes encontrar todas tus archivos utilizados en la plataforma."
            rightContentStyle={{
              width: "30%",
              maxWidth: "400px",
            }}
            rightContent={
              <div className="nk-fmg-status p-0">
                <Authorize subjects={[BasePermission.Subscription]}>
                  <Usage />
                </Authorize>
              </div>
            }
          >
            <FormattedMessage id="media content" />
          </Title>

          <div className="nk-block">
            <MediaContentManager />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MediaList;
