import React, { ReactNode } from "react";
import { Dropdown as BSDropdown, DropdownProps } from "react-bootstrap";
import { Link } from "react-router-dom";

export type IDropdownProps = {
  children: ReactNode;
  text: ReactNode;
  variant?: string;
  trigger?: boolean;
  indicator?: boolean;
  icon?: boolean;
  disabled?: boolean;
  ref?: React.RefObject<HTMLButtonElement> | null | undefined;
} & DropdownProps;

export const Dropdown: React.FC<IDropdownProps> = ({
  children,
  text,
  variant = "",
  trigger,
  indicator,
  icon,
  disabled,
  ref,
  ...props
}) => {
  return (
    <BSDropdown {...props} className={`dropdown`} ref={ref}>
      <BSDropdown.Toggle
        disabled={disabled}
        variant={variant}
        className={`dropdown-toggle btn ${icon ? "btn-icon" : ""} ${
          indicator ? "dropdown-indicator" : ""
        } ${trigger ? "btn-trigger" : ""} ${props.className || ""} `}
        data-bs-toggle="dropdown"
      >
        {text}
      </BSDropdown.Toggle>
      <BSDropdown.Menu className="dropdown-menu dropdown-menu-end">
        {children}
      </BSDropdown.Menu>
    </BSDropdown>
  );
};

export type DropdownMenuOption<T> =
  | { type: "divider" }
  | {
      type?: "item" | undefined;
      text: string | ((entity: T) => string);
      className?: string;
      icon?: string;
      link?: (entity: T) => string;
      click?: (entity: T) => void;
    };

export interface IDropdownMenuProps<T = any> {
  options: DropdownMenuOption<T>[];
  entity?: T;
  className?: string;
}

export const DropdownMenu: React.FC<IDropdownMenuProps> = ({
  options,
  className = "",
  entity,
}) => {
  return (
    <ul className={`link-list-opt no-bdr ${className}`}>
      {options.map((o, i) => {
        if (o.type && o.type === "divider") {
          return <li key={i} className="divider" />;
        }

        const Action = o.link ? Link : BSDropdown.Item;

        return (
          <li key={i}>
            <Action
              to={o.link ? o.link(entity) : ""}
              onClick={o.click ? () => o.click && o.click(entity) : undefined}
              className={o.className}
            >
              <em className={`icon ${o.icon}`} />
              <span>
                {typeof o.text === "function" ? o.text(entity) : o.text}
              </span>
            </Action>
          </li>
        );
      })}
    </ul>
  );
};

export default Dropdown;
