import httpClient from "../clients/http";
import { Paged } from "../interfaces/base";

export interface Subscription {
  plan: Plan;
}

export interface Plan {
  id: string;
  name: string;
  price: number;
  duration: "Anual" | "Monthly";
  description: string;
  features: {
    feature: {
      name: string;
      displayName: string;
    };
    value: any;
    displayValue: string;
  }[];
}

export interface SubscriptionPayment {
  code: string;
  id: string;
  billFor: string;
  issueDate: string;
  dueDate: string;
  total: number;
  subTotal: number;
  tax: number;
  status: "paid" | "due" | "pending" | "overdue";
  amount: Record<
    string,
    {
      total: number;
      subTotal: number;
      tax: number;
    }
  >;
}

export class SubscriptionService {
  static async getMyPlanDetails() {
    const response = await httpClient.get("/subscriptions/me/plan/details");
    return response.data;
  }

  static async getPaymentHistory(): Promise<Paged<SubscriptionPayment>> {
    const response = await httpClient.get("/subscriptions/payments/history?size=30");
    return response.data;
  }
  static async getMySubscription(): Promise<Subscription> {
    const response = await httpClient.get("/subscriptions/me");
    return response.data;
  }

  static async getPaymentInvoice(id: string | number) {
    const response = await httpClient.get(
      `/subscriptions/payments/${id}/invoice`,
      {
        responseType: "blob",
      }
    );
    return response.data;
  }
}
