import http from "../clients/http";
import { ClientPublicInfo } from "../interfaces/clients";

export class ClientService {
  static async getMyAccount() {
    const response = await http.get(`/clients/me`);
    const { data } = response;
    return data;
  }

  static async getClientByKey(key: string): Promise<ClientPublicInfo> {
    const response = await http.get(`/clients/public/${key}`);
    const { data } = response;
    return data;
  }
}
