import React from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-bootstrap-date-picker";

function Datepicker(props: any) {
  console.log(props);
  return <Form.Control type="date" {...props} />;
}

export default Datepicker;
