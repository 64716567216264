import httpClient from "../clients/http";

type ICompanyParams ={
  name: string;
  ide: string;
  ideType: string;
  address: string;
  telephone: string;
  shortDescription: string;
  infoEmail: string;
}

export class CompanySettingsService {
  static async getSettings(): Promise<ICompanyParams> {
    const response = await httpClient.get("/settings/company");
    return response.data;
  }

  static async updateSettings(settings: ICompanyParams): Promise<ICompanyParams> {
    const response = await httpClient.put("/settings/company", settings);
    return response.data;
  }

}