import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import AsyncCreatableSelect from "react-select/async-creatable";
import AssetSelection from "../../components/ContentManager/AssetSelection";
import CategorySelect from "../../components/Select/CategorySelect";
import TaxesSelect from "../../components/Select/TaxesSelect";
import { CatalogService } from "../../services/catalog";
import { useMutation, useQuery } from "react-query";
import { ProductService } from "../../services/product";
import { toast } from "react-toastify";
import { INewProductForm } from "../../interfaces/products/forms";
import { FormattedMessage, useIntl } from "react-intl";
import { IProductDetails } from "./details/context";
import { useState } from "react";
import { styles } from "../../components/Select";
import { formatCurrency } from "../../utils/formatter";

const IMAGES_POSITION = ["image[0]", "image[1]", "image[2]", "image[3]"];

function ProductEdit() {
  const navigate = useNavigate();
  const intl = useIntl();
  const [isFetched, setIsFetch] = useState(false);

  const { id } = useParams() as { id: string };

  const {
    register,
    control,
    watch,
    handleSubmit,
    formState,
    setValue,
    reset,
    trigger,
    getValues,
  } = useForm<INewProductForm>({
    defaultValues: {
      attributes: {},
      images: [],
    },
  });

  const productDetailQuery = useQuery(
    ["ProductDetails", id],
    () => ProductService.getDetails(String(id)) as Promise<IProductDetails>,
    {
      enabled: !isFetched,
      onSuccess(data) {
        setIsFetch(true);
        reset({
          name: data.name,
          description: data.description,
          initialStock: 0,
          cost: 0,
          gross: data.price,
          applicableTaxes: data.category?.taxes?.map((e) => ({
            label: e.name,
            value: e.id,
            [e.type]: e.value,
          })),
          mainImage: data.mediaAssets[0].id,
          images: data.mediaAssets?.map((d) => d.id),
          category: {
            label: data.category?.name,
            value: (data.category as any)?.id,
            attributes: (data.category as any)?.attributes, // missing
          },
        });

        Object.keys(data.attributes).forEach((key) => {
          const att = data.category?.attributes?.find((e) => e.key === key);

          if (att?.type === "catalog") {
            setValue(`attributes.${key}`, {
              ...data.attributes[key],
              label: data.attributes[key].displayValue,
              value: data.attributes[key].value,
            });
          } else {
            setValue(`attributes.${key}`, data.attributes[key].value);
          }
        });
      },
    }
  );

  const editProductMutation = useMutation({
    mutationFn: async (formData: INewProductForm) => {
      return await ProductService.update(id, formData);
    },
    onSuccess(data: any) {
      toast.success(
        intl.formatMessage(
          { id: "{entity} was updated successfully" },
          { entity: data.name }
        )
      );
      navigate("/products");
    },
  });

  const taxes = watch("applicableTaxes");
  const gross = watch("gross");
  const category = watch("category");
  const mainImage = watch("mainImage");

  console.log("taxes", taxes);

  const appliedTax = Number(
    taxes?.reduce((prev, curr) => prev + (curr.percentage / 100) * gross, 0) ||
      0
  ).toFixed(2);

  const price: number | null = gross
    ? Number(gross) + Number(appliedTax)
    : null;

  const onSubmit = handleSubmit(async (data) => {
    await editProductMutation.mutateAsync({
      ...data,
      category: { id: data.category.value },
    });
  });

  const getAttributeOptions =
    (catalog: string) => async (inputValue: string) => {
      const response = await CatalogService.getList(catalog as any, {
        search: inputValue,
      });
      return response.data.map((e) => ({
        ...e,
        label: e.name,
        value: e.id,
      }));
    };

  return (
    <div className="components-preview wide-md mx-auto">
      <div className="nk-block-head nk-block-head-lg wide-sm">
        <div className="nk-block-head-content">
          <div className="nk-block-head-sub">
            <a role="button" className="back-to" onClick={() => navigate(-1)}>
              <em className="icon ni ni-arrow-left" />
              <span>
                <FormattedMessage
                  id="Product details of {name}"
                  values={{ name: productDetailQuery.data?.name }}
                />
              </span>
            </a>
          </div>
          <h2 className="nk-block-title fw-normal">
            <FormattedMessage
              id="Edit product {name}"
              values={{ name: productDetailQuery.data?.name }}
            />
          </h2>
          <div className="nk-block-des">
            <p className="lead">
              <FormattedMessage
                id="You are editing the product {name}"
                values={{ name: productDetailQuery.data?.name }}
              />
            </p>
          </div>
        </div>
      </div>
      <div className="nk-block nk-block-lg">
        <div className="card card-bordered card-preview">
          <div className="card-inner">
            {productDetailQuery.isLoading ? (
              <div
                style={{
                  display: "flex",
                  height: "40vh",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner />
              </div>
            ) : (
              <div className="preview-block">
                <Form>
                  <span className="preview-title-lg overline-title mb-3">
                    <FormattedMessage id="General Information" />
                  </span>

                  <Form.Group className="mb-3">
                    <Form.Label>
                      <FormattedMessage id="Product name" />
                    </Form.Label>
                    <Form.Control {...register("name", { required: true })} />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>
                      <FormattedMessage id="Description" />
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      {...register("description", { required: true })}
                    />
                  </Form.Group>

                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <FormattedMessage id="categoryHeader" />
                        </Form.Label>
                        <Controller
                          control={control}
                          name="category"
                          render={({ field }) => {
                            return <CategorySelect {...field} />;
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <div className="mt-5">
                    <hr />
                    <span className="preview-title-lg overline-title my-3">
                      <FormattedMessage id="Images" />
                    </span>
                  </div>

                  <h6 className="title mb-0 mt-4">
                    <FormattedMessage id="Selected for this product" />
                  </h6>
                  <span className="text-muted">
                    <FormattedMessage id="Select the images you want to use for this product" />
                  </span>

                  <div className="row g-3 mt-3">
                    {IMAGES_POSITION.map((key, idx) => {
                      const isChecked = mainImage === key;
                      const defaultAsset =
                        productDetailQuery.data?.mediaAssets[idx];

                      return (
                        <div className="col-md-3 col-6">
                          <div
                            className={`preview-block ${
                              !isChecked ? "mt-4" : ""
                            }`}
                          >
                            {isChecked ? (
                              <span className="preview-title overline-title">
                                <FormattedMessage id="Main image" />
                              </span>
                            ) : null}
                            <AssetSelection
                              id={key}
                              checkable
                              name="mainImage"
                              onRemove={() =>
                                isChecked ? setValue("mainImage", "") : null
                              }
                              onSelect={(media) =>
                                setValue(`images.${idx}`, media.id)
                              }
                              onClick={(media) => setValue("mainImage", key)}
                              checked={mainImage === key}
                              defaultAsset={defaultAsset}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <div className="mt-5">
                    <hr />
                    <span className="preview-title-lg overline-title my-3">
                      <FormattedMessage id="priceHeader" />
                    </span>
                  </div>

                  {/* <Form.Group className="mb-3">
                    <Form.Label>
                      <FormattedMessage id="Cost" />
                    </Form.Label>
                    <Form.Control
                      type="number"
                      {...register("cost", { required: true })}
                    />
                  </Form.Group> */}
                </Form>

                <Form.Group className="mb-3">
                  <Form.Label>
                    <FormattedMessage id="gross price" />
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    {...register("gross", { required: true })}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    <FormattedMessage id="Taxes" />
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Controller
                    control={control}
                    name="applicableTaxes"
                    render={({ field, fieldState }) => {
                      const customStyles = {
                        control: (base: any, state: any) => ({
                          ...base,
                          borderColor: !fieldState.invalid ? "#ddd" : "red",
                          "&:hover": {
                            borderColor: !fieldState.invalid ? "#ddd" : "red",
                          },
                        }),
                      };
                      return <TaxesSelect {...field} style={customStyles} />;
                    }}
                  />
                </Form.Group>

                {price && (
                  <div className="my-4">
                    <p className="text-muted mb-1">
                      {" "}
                      <FormattedMessage id="Taxes" />:{" "}
                      {formatCurrency(Number(appliedTax))}
                    </p>
                    <h6>
                      Precio Neto:{" "}
                      <span className="text-primary">
                        {" "}
                        {formatCurrency(Number(price))}
                      </span>
                    </h6>
                  </div>
                )}

                <div className="mt-5">
                  <hr />
                  <span className="preview-title-lg overline-title my-3">
                    <FormattedMessage id="Additional Information" />
                  </span>
                </div>

                {category ? (
                  <>
                    <h6 className="title mb-0 mt-4">
                      <FormattedMessage id="attributesHeader" />
                    </h6>
                    <span className="text-muted">
                      <FormattedMessage id="Add the corresponding attributes to the category" />
                    </span>
                    <Row className="my-3">
                      {category.attributes?.map((att: any) => (
                        <Col className="mt-2" key={att.key} xs={6}>
                          <Form.Group>
                            <Form.Label>
                              {att.displayName}
                              {att.required && (
                                <span className="text-danger">*</span>
                              )}
                            </Form.Label>
                            <Form.Text className="mx-1">
                              {att.description}
                            </Form.Text>
                            <Controller
                              control={control}
                              name={`attributes.${att.key}`}
                              rules={{
                                required: att.required,
                              }}
                              render={({ field }) => {
                                return att.type === "catalog" ? (
                                  <AsyncCreatableSelect
                                    styles={styles}
                                    {...field}
                                    onChange={(e) => {
                                      field.onChange({
                                        ...e,
                                        displayKey: att.displayName,
                                        displayValue: e.label,
                                        value: e.value,
                                        key: att.key,
                                      });
                                    }}
                                    placeholder={
                                      <FormattedMessage id="Select" />
                                    }
                                    noOptionsMessage={() => (
                                      <span>
                                        <FormattedMessage id="No data to display" />
                                      </span>
                                    )}
                                    loadingMessage={() => (
                                      <span>
                                        <FormattedMessage id="Loading" />
                                      </span>
                                    )}
                                    formatCreateLabel={(input) => (
                                      <span>
                                        <FormattedMessage id="create new" />{" "}
                                        {att.displayName} "{input}"{" "}
                                      </span>
                                    )}
                                    cacheOptions
                                    defaultOptions
                                    /* onBlur={props.onBlur}
                           name={props.name}
                           value={props.value}
                           onChange={props.onChange} */
                                    loadOptions={getAttributeOptions(
                                      att.source
                                    )}
                                    // onCreateOption={createOption}
                                  />
                                ) : (
                                  <Form.Control
                                    {...field}
                                    type={att.type}
                                    onChange={(e) => {
                                      field.onChange({
                                        displayKey: att.displayName,
                                        displayValue: e.target.value,
                                        value: e.target.value,
                                        key: att.key,
                                      });
                                    }}
                                    required={att.required}
                                    value={
                                      field.value?.value || field.value || ""
                                    }
                                  />
                                );
                              }}
                            />
                          </Form.Group>
                        </Col>
                      ))}
                    </Row>
                  </>
                ) : (
                  <p className="text-muted text-center mt-5">
                    <i className="icon ni ni-info mr-2"></i>
                    <FormattedMessage id="Select a category to add attributes" />
                  </p>
                )}
              </div>
            )}

            <div className="mt-4 text-right">
              <br />
              <hr />
              <Button
                disabled={editProductMutation.isLoading || !formState.isValid}
                onClick={onSubmit}
                size="lg"
              >
                {editProductMutation.isLoading ? (
                  <Spinner />
                ) : (
                  <em className="icon ni ni-plus me-2"></em>
                )}{" "}
                <FormattedMessage id="Save Changes" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductEdit;
