import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { INewUserForm } from "../../interfaces/users/forms";
import { UserService } from "../../services/user";
import { emailPattern } from "../../utils/form-validation";
import AccessGroupSelect from "../../components/Select/AccessGroupSelect";

function UserAdd() {
  const navigate = useNavigate();
  const intl = useIntl();

  const { register, handleSubmit, formState, control } =
    useForm<INewUserForm>();

  const { errors } = formState;

  const createUserMutation = useMutation({
    mutationFn: async (formData: INewUserForm) => {
      return await UserService.create(formData);
    },
    onSuccess(data: any) {
      toast.success(
        intl.formatMessage(
          { id: "User was created successfully." },
          { user: data.name }
        )
      );
      navigate("/users");
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    await createUserMutation.mutateAsync({
      ...data,
      groups: data.groups.map((e: any) => e.value),
    });
  });

  return (
    <div className="components-preview wide-md mx-auto">
      <div className="nk-block-head nk-block-head-lg wide-sm">
        <div className="nk-block-head-content">
          <h2 className="nk-block-title fw-normal">
            <FormattedMessage id="New User" />
          </h2>
          <div className="nk-block-des">
            <p className="lead">
              <FormattedMessage id="Enter the data of your new user" />
            </p>
          </div>
        </div>
      </div>

      <div className="nk-block nk-block-lg">
        <div className="card card-bordered card-preview">
          <div className="card-inner">
            <Form onSubmit={onSubmit}>
              <span className="preview-title-lg overline-title mb-3">
                <FormattedMessage id="General Information" />
              </span>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <FormattedMessage id="Names" />
                    </Form.Label>
                    <Form.Control
                      maxLength={50}
                      {...register("names", { required: true })}
                      isInvalid={Boolean(errors.names)}
                    />
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <FormattedMessage id="Last Names" />
                    </Form.Label>
                    <Form.Control
                      maxLength={50}
                      {...register("lastNames", { required: true })}
                      isInvalid={Boolean(errors.lastNames)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <FormattedMessage id="ID Number" />
                    </Form.Label>
                    <Form.Control
                      maxLength={50}
                      {...register("ide", { required: true })}
                      isInvalid={Boolean(errors.ide)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <FormattedMessage id="Email" />
                    </Form.Label>
                    <Form.Control
                      isInvalid={Boolean(errors.email)}
                      maxLength={50}
                      {...register("email", {
                        required: true,
                        pattern: emailPattern,
                      })}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <FormattedMessage id="Birthdate" />
                    </Form.Label>
                    <Form.Control
                      type="date"
                      {...register("birthDate", { required: true })}
                      isInvalid={Boolean(errors.birthDate)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <FormattedMessage id="Telephone" />
                    </Form.Label>
                    <Form.Control
                      maxLength={50}
                      {...register("tel", { required: true })}
                      isInvalid={Boolean(errors.tel)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-5">
                <hr />
                <span className="preview-title-lg overline-title my-3">
                  <FormattedMessage id="Account Setting" />
                </span>
              </div>
              <Form.Group className="mb-3">
                <Form.Label>
                  <FormattedMessage id="Access Group" />
                </Form.Label>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="groups"
                  render={({ field }) => {
                    return (
                      <AccessGroupSelect
                        {...field}
                        isInvalid={Boolean(errors.groups)}
                      />
                    );
                  }}
                />
              </Form.Group>

              <div className="mt-4 text-right">
                <br />
                <hr />
                <Button
                  type="submit"
                  disabled={createUserMutation.isLoading}
                  onClick={onSubmit}
                  size="lg"
                >
                  {createUserMutation.isLoading ? (
                    <Spinner />
                  ) : (
                    <em className="icon ni ni-plus me-2"></em>
                  )}{" "}
                  <FormattedMessage id="Create User" />
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserAdd;
