import httpClient from "../clients/http";
import { IGroup } from "../interfaces/users/group";

type GetListParams = {
  status: string | null;
  search: string | null;
  page: string | null;
  size: string | null;
};

export class GroupService {
  static async getOne(id: any) {
    const response = await httpClient.get(`/groups/${id}`);
    return response.data as IGroup;
  }

  static async getList(filters: Partial<GetListParams>) {
    const response = await httpClient.get("/groups");
    return response.data as { data: IGroup[] };
  }
}
