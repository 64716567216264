import httpClient from "../clients/http";

export class PaymentSettingService {
  static async getPaymentMethodStatus(): Promise<{
    enabled: boolean;
  }> {
    const response = await httpClient.get("/settings/payments/status");
    return response.data;
  }
}
