import React from "react";
import { useNavigate } from "react-router-dom";

export const BackButton: React.FC<{ to?: string }> = ({ to }) => {
  const navigate = useNavigate();
  const back = () => (to ? navigate(to) : navigate(-1));
  return (
    <>
      <a
        onClick={back}
        className="btn btn-outline-light bg-white d-none d-sm-inline-flex"
      >
        <em className="icon ni ni-arrow-left" />
        <span>Regresar</span>
      </a>
      <a
        onClick={back}
        className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
      >
        <em className="icon ni ni-arrow-left" />
      </a>
    </>
  );
};

export default BackButton;
