import { FormattedMessage } from "react-intl";

function LoadingText() {
  return (
    <div className="loading-skeleton">
      <div className="skeleton-text">
        <div className="block">
        <FormattedMessage id="Loading" />
          </div>{" "}
      </div>
    </div>
  );
}

export default LoadingText;
