import { IntlProvider } from "react-intl";
import { QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { ModalProvider } from "./contexts/confirmation";
import { queryClient } from "./contexts/query";
import { ToastProvider } from "./contexts/toast";
import messages from "./data/intl/es.json";
import Router from "./pages";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export default function App() {
  return (
    <IntlProvider locale="es" messages={messages}>
      <ToastProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <DndProvider backend={HTML5Backend}>
              <ModalProvider>
                <Router />
              </ModalProvider>
            </DndProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </ToastProvider>
    </IntlProvider>
  );
}
