import { Navigate } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import PermissionContextProvider from "../../contexts/auth-permission";

export const ProtectedRoute: React.FC<any> = ({ children }: any) => {
  const { user } = useAuth();
  if (!user) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  return <PermissionContextProvider>{children}</PermissionContextProvider>;
};
