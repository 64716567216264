import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import MediaContentManager from "../ContentManager";

interface Asset {
  name: string;
  isFolder: boolean;
}

interface AssetSelectionProps {
  isOpen: boolean;
  onClose: () => void;
  onAssetSelect: (file: Asset) => void;
}

const AssetSelection: React.FC<AssetSelectionProps> = ({
  isOpen,
  onClose,
  onAssetSelect,
}) => {
  const [selectedMedia, setSelectedMedia] = useState<any | null>(null);

  return (
    <Modal show={isOpen} onHide={onClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Seleccionar nuevo archivo </Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-0">
        <MediaContentManager
          onSelect={(i, autoSelect) => {
            if (autoSelect) {
              setSelectedMedia(i);
              onAssetSelect(selectedMedia);
              onClose();
            }
            setSelectedMedia(i);
          }}
          selected={selectedMedia}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          disabled={!selectedMedia}
          onClick={() => {
            onAssetSelect(selectedMedia);
            onClose();
          }}
        >
          Seleccionar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AssetSelection;
