import React, { createContext, useContext, useEffect, useMemo } from "react";
import { useLocalStorage } from "../hooks/store";
import { Constant } from "../clients/http";

export type User = {
  // Define the shape of the user object
  id: string;
  username: string;
  names: string;
  lastNames: string;
  email: string;
  groups?: { name: string }[];
};

type AuthContextType = {
  user: User | null;
  login: (
    data: User,
    token: string,
    refresh: string,
    expiryTime: number
  ) => void;
  logout: () => void;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useLocalStorage<User | null>("user", null);
  const [token, setToken] = useLocalStorage<string | null>(
    "access_token",
    null
  );

  // call this function when you want to authenticate the user
  const login = async (
    data: User,
    token: string,
    refreshToken: string,
    expiryTime: number
  ) => {
    setUser(data);
    setToken(token);
    localStorage.setItem(Constant.REFRESH_TOKEN_KEY, refreshToken);
    localStorage.setItem(Constant.TOKEN_EXPIRY_KEY, expiryTime.toString());
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      token,
    }),
    [user, token]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextType => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return authContext;
};

export const useUser = () => {
  const { user } = useAuth();
  return { user };
};
