import { useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation, useQuery } from "react-query";

import { toast } from "react-toastify";
import { AddButton } from "../../components/Buttons/ActionableButton";
import Dropdown, {
  DropdownMenu,
  DropdownMenuOption,
} from "../../components/Buttons/Dropdown";
import SearchBar from "../../components/Inputs/SearchBar";
import RecordInventory from "../../components/Modals/RecordInventory";
import Authorize from "../../components/Permission/Authorize";
import Table, { IPaginationProps, ITableHeader } from "../../components/Table";
import { transformToInventoryType } from "../../components/Table/transformers";
import Title from "../../components/Title";
import { useConfirmationModal } from "../../contexts/confirmation";
import { Action, BasePermission } from "../../enums/permissions";
import { useTableQueryParams } from "../../hooks/router";
import { InventoryService } from "../../services/inventory";
import { formatMidDateTime } from "../../utils/formatter";

let timmerId: any;

function InventoryList() {
  const { page, search, setParam, size, status } = useTableQueryParams();
  const intl = useIntl();
  const { open } = useConfirmationModal();
  const methods = useForm();
  const { handleSubmit, register, reset } = methods;
  const [showModal, setShowModal] = useState(false);

  const { data, isLoading, refetch } = useQuery(
    ["inventoryList", status, search, page, size],
    (ctx) => {
      const [, status, search, page, size] = ctx.queryKey;
      return InventoryService.getList({
        status,
        search,
        page,
        size,
      });
    }
  );

  const deleteRecord = useMutation({
    mutationFn: async (id: string) => {
      return await InventoryService.delete(id);
    },
    onSuccess(data: any) {
      refetch();
      toast.success(intl.formatMessage({ id: "TOAST.DELETE_SUCCESS" }));
    },
  });

  const options: DropdownMenuOption<any>[] = [
    {
      text: intl.formatMessage({ id: "See product" }),
      icon: "ni ni-eye",
      link: (entity) => `/products/${entity.product.id}`,
    },
    {
      type: "divider",
    },
    {
      icon: "ni ni-na",
      text: intl.formatMessage({ id: "removeOption" }),
      click: (entity) => {
        open(
          intl.formatMessage(
            { id: "confirmationMessage" },
            { entityName: entity.name }
          ),
          () => {
            return deleteRecord.mutateAsync(entity.id);
          }
        );
      },
    },
  ];

  const headers: ITableHeader[] = [
    {
      path: ["type"],
      name: intl.formatMessage({ id: "Type" }),
      transform: transformToInventoryType,
    },
    {
      path: ["concept", "description"],
      name: intl.formatMessage({ id: "Description" }),
      hide: "sm",
    },
    {
      path: ["value"],
      name: intl.formatMessage({ id: "Quantity" }),
    },
    {
      path: ["product", "name"],
      name: intl.formatMessage({ id: "Product" }),
    },
    {
      path: ["createdAt"],
      name: intl.formatMessage({ id: "Created At" }),
      transform: (value) => formatMidDateTime(new Date(value)),
      hide: "sm",
    },
    {
      path: ["options"], // no id
      name: "",
      type: "tools",
      transform: (v, entity) => (
        <Dropdown
          key={entity.id}
          icon
          trigger
          text={<em className="icon ni ni-more-h" />}
        >
          <DropdownMenu options={options} entity={entity} />
        </Dropdown>
      ),
    },
  ];

  const pagination: IPaginationProps = {
    current: Number(page) || data?.pagination.page,
    size: Number(size) || data?.pagination.size,
    total: data?.pagination?.total,
    onPageChange(page) {
      setParam("page", String(page));
    },
    onSizeChange(size) {
      setParam("size", String(size));
    },
  };

  const onSearch = (term: string) => {
    clearTimeout(timmerId);
    timmerId = setTimeout(() => {
      setParam("search", term);
    }, Number(process.env.REACT_APP_SEARCH_DELAY || 700));
  };

  const handleOpenModal = () => {
    setShowModal(true);
    reset();
  };

  const handleCloseModal = () => {
    setShowModal(false);
    reset();
  };

  return (
    <div className="container-fluid">
      <div className="nk-content-inner">
        <div className="nk-content-body">
          <Title
            subText={intl.formatMessage(
              { id: "Total of {total} {entities}" },
              {
                total: data?.pagination.total,
                entities: intl.formatMessage({ id: "records" }),
              }
            )}
            rightContent={
              <ul className="nk-block-tools g-3">
                <li>
                  <SearchBar onChange={(e) => onSearch(e.target.value)} />
                </li>
                <Authorize
                  actions={[Action.CREATE]}
                  subjects={[BasePermission.Inventory]}
                >
                  <li className="nk-block-tools-opt">
                    <AddButton onClick={handleOpenModal} />
                  </li>
                </Authorize>
              </ul>
            }
          >
            <FormattedMessage id="Inventory records" />
          </Title>

          <div className="nk-block">
            <RecordInventory
              show={showModal}
              handleClose={handleCloseModal}
              refetch={refetch}
            />
            <Table
              headers={headers}
              data={data?.data}
              /*  selectable */
              loading={isLoading}
              pagination={pagination}
              /*    bulkOptions={[
                {
                  icon: "ni ni-na",
                  text: intl.formatMessage({ id: "suspendAllOption" }),
                },
              ]} */
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default InventoryList;
