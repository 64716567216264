import { StylesConfig } from "react-select";
import http from "../../clients/http";

export const styles: StylesConfig<any, any, any> = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? "var(--bs-primary)" : provided.borderColor,
    boxShadow: state.isFocused
      ? "0 0 0 0.1rem rgba(44, 55, 130, 0.35)"
      : provided.boxShadow,
    "&:hover": {
      borderColor: state.isFocused ? "var(--bs-primary)" : provided.borderColor,
    },
  }),

  menu: (provided, state) => ({
    ...provided,
    zIndex: 9999,
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "var(--bs-primary)"
      : provided.backgroundColor,
    color: state.isSelected ? "var(--bs-light)" : "var(--bs-gray-dark)",
    "&:hover": {
      backgroundColor: state.isSelected
        ? "var(--bs-primary)"
        : provided.backgroundColor,
      color: state.isSelected ? "var(--bs-gray-dark)" : "var(--bs-gray-dark)",
    },
  }),
};

export const getSelectOptionsFactory =
  ({ path }: { path: string }) =>
  async (inputValue: string) => {
    return getSelectOptions(inputValue, path);
  };

export const getSelectOptions = async (inputValue: string, path: string) => {
  const response = await http.get(`${path}?search=${inputValue}`);
  return response.data?.data
    ?.filter((e: any) => !e.isDefault)
    .map((e: any) => ({
      label: e.displayName || e.name,
      value: e.id,
      ...e,
    }));
};
