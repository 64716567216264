import { Tab, Tabs } from "react-bootstrap";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { EditButton } from "../../../components/Buttons/ActionableButton";
import BackButton from "../../../components/Buttons/BackButton";
import Title from "../../../components/Title";
import { useQuerySearchTab } from "../../../hooks/router";
import { ProductService } from "../../../services/product";
import { IProductDetails, ProductDetailContext } from "./context";
import ProductImageGallery from "./gallery";
import ProductInformation from "./info";
import ProductSales from "./sales";
import ProductDetailsInventory from "./inventory";
import { Action, BasePermission } from "../../../enums/permissions";
import Authorize from "../../../components/Permission/Authorize";
import { useAuthorizedArray } from "../../../hooks/permissions";

function ProductDetails() {
  const { id } = useParams();

  const tabs = useAuthorizedArray([
    { key: "info", name: "Información General", Component: ProductInformation },
    {
      key: "stats",
      name: "Reporte de Ventas",
      Component: ProductSales,
      permissions: [[Action.READ, BasePermission.Order]],
    },
    {
      key: "inventory",
      name: "Registro de inventario",
      Component: ProductDetailsInventory,
      permissions: [[Action.READ, BasePermission.Inventory]],
    },
  ]);

  const { activeKey, onSelect } = useQuerySearchTab(tabs);
  const productDetailQuery = useQuery(
    ["ProductDetails", id],
    () => ProductService.getDetails(String(id)) as Promise<IProductDetails>
  );

  return (
    <div className="nk-content-inner">
      <div className="nk-content-body">
        <Title
          subText={
            <>
              <span className="text-muted">
                SKU: <b>#{productDetailQuery.data?.code}</b> Nombre:{" "}
                <b>{productDetailQuery.data?.name}</b>.
              </span>
            </>
          }
          rightContent={
            <ul className="nk-block-tools g-3">
              <li>
                <BackButton to="/products" />
              </li>
              <Authorize
                actions={[Action.UPDATE]}
                subjects={[BasePermission.Product]}
              >
                <li className="nk-block-tools-opt">
                  <Link to={`/products/${id}/edit`}>
                    <EditButton />
                  </Link>
                </li>
              </Authorize>
            </ul>
          }
        >
          Detalle de Producto
        </Title>
        <ProductDetailContext.Provider value={productDetailQuery}>
          <div className="nk-block">
            <div className="row g-gs">
              <div className="col-lg-4">
                <ProductImageGallery />
              </div>
              <div className="col-lg-8">
                <div className="card h-100">
                  <div className="card-inner">
                    <Tabs
                      id="controlled-tab-example"
                      className="nav nav-tabs mt-n3"
                      role="tablist"
                      activeKey={activeKey}
                      onSelect={onSelect}
                    >
                      {tabs.map((t, i) => {
                        const { key, name, Component } = t;
                        return (
                          <Tab
                            eventKey={key}
                            role="presentation"
                            className="nav-item"
                            key={key}
                            title={name}
                          >
                            <Component />
                          </Tab>
                        );
                      })}
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ProductDetailContext.Provider>
      </div>
    </div>
  );
}

export default ProductDetails;
