import { Bar, Line } from "react-chartjs-2";
import { averargeOrder, totalCustomers, totalOrders, totalSales } from "./Data";

import {
  BarElement,
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { CustomerService } from "../../../services/customer";
import { OrderService } from "../../../services/order";
Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  Tooltip,
  Legend
);

const getAllDaysOfCurrentMonth = (): string[] => {
  const daysInMonth = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0
  ).getDate();
  const days = [];
  for (let i = 1; i <= daysInMonth; i++) {
    days.push(
      i.toString() +
        " " +
        new Date().toLocaleString("default", { month: "short" })
    );
  }
  return days;
};

export const TotalSalesChart = () => {
  const {
    data: stats,
    isLoading,
    isError,
  } = useQuery("totalSales", () => OrderService.getSumStatsGraphs(), {
    cacheTime: 1000 * 60 * 60 * 24,
    staleTime: 1000 * 60 * 60 * 12,
  });

  if (isLoading) {
    return null;
  }

  if (isError) {
    return null;
  }

  return (
    <Line
      className="ecommerce-line-chart-s1"
      data={totalSales(getAllDaysOfCurrentMonth(), stats.data)}
      options={{
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            displayColors: false,
            backgroundColor: "#1c2b46",
            titleFont: {
              size: 10,
            },
            titleColor: "#fff",
            titleMarginBottom: 4,
            bodyColor: "#fff",
            bodyFont: {
              size: 10,
            },
            bodySpacing: 4,
            padding: 6,
            footerMarginTop: 0,
            callbacks: {
              label: function (context) {
                return context.parsed.y.toString();
              },
            },
          },
        },
        maintainAspectRatio: false,
        scales: {
          y: {
            display: false,
            /* beginAtZero: false, */
            ticks: {
              color: "#9eaecf",
              font: {
                size: 12,
              },
              padding: 0,
            },
            grid: {
              display: false,
              color: "transparent",
              tickLength: 0,
              tickColor: "rgba(82, 100, 132, 0.2)",
            },
          },
          x: {
            display: false,
            ticks: {
              color: "#9eaecf",
              font: {
                size: 12,
              },
              source: "auto",
              padding: 0,
            },
            grid: {
              display: false,
              color: "transparent",
              tickLength: 0,
              tickColor: "rgba(82, 100, 132, 0.2)",
              offset: true,
            },
          },
        },
      }}
    />
  );
};

export const AverageOrderChart = () => {
  const {
    data: stats,
    isLoading,
    isError,
  } = useQuery(
    "averageOrderStatsGraph",
    () => OrderService.getAvgStatsGraphs(),
    {
      cacheTime: 1000 * 60 * 60 * 24,
      staleTime: 1000 * 60 * 60 * 12,
    }
  );

  const state = "7";
  const [data, setData] = useState();
  /*   useEffect(() => {
    if (state === "7") {
      setData(averargeOrderSet2);
    } else if (state === "15") {
      setData(averargeOrderSet3);
    } else {
      setData(averargeOrderSet4);
    }
  }, [state]); */

  if (isLoading) {
    return null;
  }

  if (isError) {
    return null;
  }

  return (
    <Bar
      data={averargeOrder(getAllDaysOfCurrentMonth(), stats.data)}
      options={{
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            displayColors: false,
            backgroundColor: "#1c2b46",
            titleFont: {
              size: 9,
            },
            titleColor: "#fff",
            titleMarginBottom: 6,
            bodyColor: "#fff",
            bodyFont: {
              size: 9,
            },
            bodySpacing: 4,
            padding: 6,
            footerMarginTop: 0,
            callbacks: {
              label: function (context) {
                return context.parsed.y as any;
              },
            },
          },
        },
        maintainAspectRatio: false,
        scales: {
          y: {
            display: true,
            ticks: {
              /* beginAtZero: false, */
              color: "#9eaecf",
              font: {
                size: 12,
              },
              padding: 0,
              display: false,
              stepSize: 100,
            },
            grid: {
              color: "rgba(82, 100, 132, 0.2)",
              tickLength: 0,
              /* zeroLineColor: "rgba(82, 100, 132, 0.2)", */
            },
          },
          x: {
            display: false,
            ticks: {
              color: "#9eaecf",
              font: {
                size: 12,
              },
              source: "auto",
              padding: 0,
            },
            grid: {
              color: "transparent",
              tickLength: 0,
              /* zeroLineColor: "transparent", */
              offset: true,
            },
          },
        },
      }}
    ></Bar>
  );
};

export const TotalOrderChart = () => {
  const {
    data: stats,
    isLoading,
    isError,
  } = useQuery("totalSales", () => OrderService.getStatsGraphs(), {
    cacheTime: 1000 * 60 * 60 * 24,
    staleTime: 1000 * 60 * 60 * 12,
  });

  if (isLoading) {
    return null;
  }

  if (isError) {
    return null;
  }

  return (
    <Line
      className="ecommerce-line-chart-s1"
      data={totalOrders(getAllDaysOfCurrentMonth(), stats.data)}
      options={{
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            displayColors: false,
            backgroundColor: "#1c2b46",
            titleFont: {
              size: 10,
            },
            titleColor: "#fff",
            titleMarginBottom: 4,
            bodyColor: "#fff",
            bodyFont: {
              size: 10,
            },
            bodySpacing: 4,
            padding: 6,
            footerMarginTop: 0,
            callbacks: {
              label: function (context) {
                return context.parsed.y as any;
              },
            },
          },
        },
        maintainAspectRatio: false,
        scales: {
          y: {
            display: false,
            ticks: {
              /* beginAtZero: false, */
              color: "#9eaecf",
              font: {
                size: 12,
              },
              padding: 0,
            },
            grid: {
              display: false,
              color: "transparent",
              tickLength: 0,
              /* zeroLineColor: "rgba(82, 100, 132, 0.2)", */
            },
          },
          x: {
            display: false,
            ticks: {
              color: "#9eaecf",
              font: {
                size: 12,
              },
              source: "auto",
              padding: 0,
            },
            grid: {
              display: false,
              color: "transparent",
              tickLength: 0,
              /* zeroLineColor: "rgba(82, 100, 132, 0.2)", */
              offset: true,
            },
          },
        },
      }}
    />
  );
};

export const TotalCustomerChart = () => {
  const {
    data: stats,
    isLoading,
    isError,
  } = useQuery("totalCustomers", () => CustomerService.getStats(), {
    cacheTime: 1000 * 60 * 60 * 24,
    staleTime: 1000 * 60 * 60 * 12,
  });

  if (isLoading) {
    return null;
  }

  if (isError) {
    return null;
  }

  return (
    <Line
      className="ecommerce-line-chart-s1"
      data={totalCustomers(getAllDaysOfCurrentMonth(), stats.data)}
      options={{
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            displayColors: false,
            backgroundColor: "#1c2b46",
            titleFont: {
              size: 10,
            },
            titleColor: "#fff",
            titleMarginBottom: 4,
            bodyColor: "#fff",
            bodyFont: {
              size: 10,
            },
            bodySpacing: 4,
            padding: 6,
            footerMarginTop: 0,
            callbacks: {
              label: function (context) {
                return context.parsed.y as any;
              },
            },
          },
        },
        maintainAspectRatio: false,
        scales: {
          y: {
            display: false,
            ticks: {
              /* beginAtZero: false, */
              color: "#9eaecf",
              font: {
                size: 12,
              },
              padding: 0,
            },
            grid: {
              display: false,
              color: "transparent",
              tickLength: 0,
              /* zeroLineColor: "rgba(82, 100, 132, 0.2)", */
            },
          },
          x: {
            display: false,
            ticks: {
              color: "#9eaecf",
              font: {
                size: 12,
              },
              source: "auto",
              padding: 0,
            },
            grid: {
              display: false,
              color: "transparent",
              tickLength: 0,
              /* zeroLineColor: "rgba(82, 100, 132, 0.2)", */
              offset: true,
            },
          },
        },
      }}
    />
  );
};
