import { useState } from "react";
import {
  Modal,
  OverlayTrigger,
  Spinner,
  Tab,
  Tabs,
  Tooltip,
} from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BackButton from "../../components/Buttons/BackButton";
import { useConfirmationModal } from "../../contexts/confirmation";
import { UserStatus } from "../../enums/status";
import { useFormatter } from "../../hooks/formatter";
import { useQuerySearchTab } from "../../hooks/router";
import { UserService } from "../../services/user";
import { getFullName, getInitials } from "../../utils/person";
import { IUserDetails } from "../../interfaces/users";
import { EditButton } from "../../components/Buttons/ActionableButton";
import Authorize from "../../components/Permission/Authorize";
import { Action, BasePermission } from "../../enums/permissions";
import { GroupService } from "../../services/group";

function UserDetails() {
  const { id } = useParams<{ id: string }>();
  const intl = useIntl();
  const formatter = useFormatter();
  const [showAside, setShowAside] = useState(false);

  const tabs = [
    { key: "general", name: intl.formatMessage({ id: "General Information" }) },
    /* { key: "activities", name: intl.formatMessage({ id: "Activity" }) }, */
  ];

  const { activeKey, onSelect } = useQuerySearchTab(tabs, "general");

  const {
    data: user,
    isLoading,
    refetch,
  } = useQuery(
    ["userDetail", id],
    () => UserService.get(id as string) as Promise<IUserDetails>
  );

  return (
    <div className="nk-content">
      <div className="container-fluid">
        <div className="nk-content-inner">
          <div className="nk-content-body">
            <div className="nk-block-head nk-block-head-sm">
              <div className="nk-block-between g-3">
                <div className="nk-block-head-content">
                  <h3 className="nk-block-title page-title">
                    <FormattedMessage id="Users" /> /{" "}
                    <strong className="text-primary small">
                      {user && getFullName(user)}
                    </strong>
                  </h3>
                  <div className="nk-block-des text-soft">
                    <ul className="list-inline">
                      <li>
                        <FormattedMessage id="User ID" />:{" "}
                        <span className="text-base">{user?.id}</span>
                      </li>
                      <li>
                        <FormattedMessage id="Last login" />:{" "}
                        <span className="text-base">
                          {user?.lastLogin
                            ? formatter.date.format(new Date(user?.lastLogin))
                            : "-"}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="nk-block-head-content">
                  <ul className="nk-block-tools g-3">
                    <Authorize
                      actions={[Action.UPDATE]}
                      subjects={[BasePermission.User]}
                    >
                      <li>
                        <Link to={`/users/${id}/edit`}>
                          <EditButton />
                        </Link>
                      </li>
                    </Authorize>
                    <li>
                      <BackButton to="/users" />
                    </li>
                    <li className="nav-item nav-item-trigger d-xxl-none">
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          setShowAside((s) => !s);
                        }}
                        className="toggle btn btn-icon btn-trigger"
                      >
                        <em className="icon ni ni-user-list-fill"></em>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="nk-block">
              <div className="card">
                <div className="card-aside-wrap">
                  <div className="card-content">
                    <Tabs
                      id="controlled-tab-example"
                      className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card"
                      role="tablist"
                      activeKey={activeKey}
                      onSelect={onSelect}
                    >
                      {tabs.map((t, i) => {
                        return (
                          <Tab
                            eventKey={t.key}
                            role="presentation"
                            className="nav-item"
                            key={i}
                            title={t.name}
                          >
                            {t.key === "general" && (
                              <>
                                {isLoading && !user ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      height: "40vh",
                                      width: "100%",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Spinner />
                                  </div>
                                ) : (
                                  <>
                                    {user && (
                                      <>
                                        <UserGeneralInformation {...user} />
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </Tab>
                        );
                      })}
                    </Tabs>
                  </div>
                  {user && (
                    <UserSideMenu
                      refetch={refetch}
                      user={user}
                      show={showAside}
                      setShow={setShowAside}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function UserSideMenu({
  user,
  show: showAside,
  setShow: setShowAside,
  refetch,
}: {
  user: IUserDetails;
  show: boolean;
  setShow: (a: boolean) => void;
  refetch: () => void;
}) {
  const userId = user.id;

  const { open } = useConfirmationModal();
  const intl = useIntl();
  const navigate = useNavigate();
  const [selectedGroup, setSelectedGroup] = useState<null | any>(null);
  const { data, isLoading } = useQuery(
    ["groupById", selectedGroup?.id],
    () => GroupService.getOne(selectedGroup?.id),
    {
      enabled: !!selectedGroup?.id,
    }
  );

  const deleteUserMutation = useMutation(() => UserService.deleteUser(userId), {
    onError: (err: any) => {
      toast.error(
        intl.formatMessage({ id: "Failed to delete user" }) +
          ": " +
          intl.formatMessage({ id: err.response.data?.message })
      );
    },
    onSuccess: () => {
      toast.success(intl.formatMessage({ id: "User deleted successfully" }));
      navigate("/users");
    },
  });

  const resetPasswordMutation = useMutation(
    () => UserService.resetPassword(userId),
    {
      onError: () => {
        toast.error(
          intl.formatMessage({ id: "Failed to reset user password" })
        );
      },
      onSuccess: () => {
        toast.success(
          intl.formatMessage({ id: "User password reset successfully" })
        );
      },
    }
  );

  const updateStatusMutation = useMutation(
    (status: string) => UserService.updateStatus(userId, status),
    {
      onError: () => {
        toast.error("Failed to update user status");
      },
      onSuccess: () => {
        toast.success("User status updated successfully");
        refetch();
      },
    }
  );

  const onClickGroup = (group: any) => {
    setSelectedGroup(group);
  };

  return (
    <>
      <Modal show={!!selectedGroup} onHide={() => setSelectedGroup(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Group Details" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>{selectedGroup?.displayName}</h4>
          <p>
            <FormattedMessage id="The following will display the permissions granted to this group:" />
          </p>
          {isLoading && (
            <div
              style={{
                display: "flex",
                height: "20vh",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner />
            </div>
          )}

          <table className="table">
            <thead>
              <tr>
                <th scope="col">
                  <FormattedMessage id="Description" />
                </th>{" "}
              </tr>
            </thead>
            <tbody>
              {data?.permissions?.map((p: any) => (
                <tr key={p.id}>
                  <td>{p.displayDescription || p.description}</td>{" "}
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setSelectedGroup(false)}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <div
        className={`card-aside card-aside-right user-aside toggle-slide toggle-slide-right toggle-break-xxl ${
          showAside ? "content-active" : ""
        }`}
        data-content="userAside"
        data-toggle-screen="xxl"
        data-toggle-overlay="true"
        data-toggle-body="true"
        onMouseLeave={() => setShowAside(false)}
      >
        <div className="card-inner-group" data-simplebar>
          <div className="card-inner">
            <div className="user-card user-card-s2">
              <div className="user-avatar lg bg-primary">
                <span>
                  {getInitials({
                    names: user?.names || "",
                    lastNames: user?.lastNames || "",
                  })}
                </span>
              </div>
              <div className="user-info">
                <div
                  className={`badge bg-outline-light rounded-pill ucap text-${
                    UserStatus[user.status as keyof typeof UserStatus].class
                  } border-${
                    UserStatus[user.status as keyof typeof UserStatus].class
                  }`}
                >
                  {UserStatus[user.status as keyof typeof UserStatus].text}
                </div>
                <span className="sub-text">{user?.email}</span>
              </div>
            </div>
          </div>
          <div className="card-inner card-inner-sm">
            <Authorize
              actions={[Action.MANAGE]}
              subjects={[BasePermission.User]}
            >
              <ul className="btn-toolbar justify-center gx-1">
                <li>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 100, hide: 400 }}
                    overlay={(props) => (
                      <Tooltip id="button-tooltip" {...props}>
                        {user.status === "blocked" ? "Desbloquear" : "Bloquear"}
                      </Tooltip>
                    )}
                  >
                    <a
                      onClick={() =>
                        updateStatusMutation.mutateAsync(
                          user.status === "blocked" ? "active" : "blocked"
                        )
                      }
                      className="btn btn-trigger btn-icon"
                    >
                      <em
                        className={`icon ni ni-shield${
                          user.status === "blocked" ? "-off" : ""
                        }`}
                      ></em>
                    </a>
                  </OverlayTrigger>
                </li>
                <li>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 100, hide: 400 }}
                    overlay={(props) => (
                      <Tooltip id="button-tooltip" {...props}>
                        Restablecer contraseña
                      </Tooltip>
                    )}
                  >
                    <a
                      onClick={() =>
                        resetPasswordMutation.isLoading
                          ? null
                          : resetPasswordMutation.mutateAsync()
                      }
                      className={`btn btn-trigger btn-icon ${
                        resetPasswordMutation.isLoading
                          ? "btn-loading btn-disabled"
                          : ""
                      }`}
                    >
                      <em className="icon ni ni-mail"></em>
                    </a>
                  </OverlayTrigger>
                </li>
                <li>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 100, hide: 400 }}
                    overlay={(props) => (
                      <Tooltip id="button-tooltip" {...props}>
                        Eliminar
                      </Tooltip>
                    )}
                  >
                    <a
                      onClick={() =>
                        open(
                          intl.formatMessage(
                            { id: "confirmationMessage" },
                            { entityName: getFullName(user) }
                          ),
                          () => {
                            deleteUserMutation.mutateAsync();
                          }
                        )
                      }
                      className="btn btn-trigger btn-icon text-danger"
                    >
                      <em className="icon ni ni-na"></em>
                    </a>
                  </OverlayTrigger>
                </li>
              </ul>
            </Authorize>
          </div>
          <div className="card-inner">
            <h6 className="overline-title-alt mb-3">
              <FormattedMessage id="Groups" />
            </h6>
            <ul className="g-1">
              {user &&
                user.groups?.map((g) => (
                  <li key={g.id} className="btn-group">
                    <a
                      className="btn btn-xs btn-light btn-dim"
                      onClick={() => onClickGroup(g)}
                    >
                      {g.displayName}
                    </a>
                    {/*  <a className="btn btn-xs btn-icon btn-light btn-dim" href="#">
                    <em className="icon ni ni-cross"></em>
                  </a> */}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

function UserGeneralInformation(user: IUserDetails) {
  const formatter = useFormatter();

  return (
    <div className="card-inner">
      <div className="nk-block">
        <div className="nk-block-head">
          <h5 className="title">
            <FormattedMessage id="General Information" />
          </h5>
          <p>
            <FormattedMessage id="Basic information, such as your name and address, that used on the Platform." />
          </p>
        </div>
        <div className="profile-ud-list">
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">
                <FormattedMessage id="full name" />{" "}
              </span>
              <span className="profile-ud-value">
                {getFullName({
                  names: user?.names || "",
                  lastNames: user?.lastNames || "",
                })}
              </span>
            </div>
          </div>
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">
                <FormattedMessage id="Birthdate" />{" "}
              </span>
              <span className="profile-ud-value">
                {user?.information?.birthDate
                  ? formatter.date.format(
                      new Date(user?.information?.birthDate)
                    )
                  : "-"}
              </span>
            </div>
          </div>
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">
                <FormattedMessage id="Identification" />{" "}
              </span>
              <span className="profile-ud-value">{user?.information?.ide}</span>
            </div>
          </div>
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">
                <FormattedMessage id="Phone" />{" "}
              </span>
              <span className="profile-ud-value">{user?.information?.tel}</span>
            </div>
          </div>
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">
                <FormattedMessage id="Email" />{" "}
              </span>
              <span className="profile-ud-value">{user?.email}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="nk-block-head nk-block-head-line">
          <h6 className="title overline-title text-base">
            <FormattedMessage id="Information of the additional" />
          </h6>
        </div>
        <div className="profile-ud-list">
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">
                <FormattedMessage id="Registration date" />{" "}
              </span>
              <span className="profile-ud-value">
                {user?.createdAt
                  ? formatter.date.format(new Date(user?.createdAt))
                  : "-"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserDetails;
