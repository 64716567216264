import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import AsyncCreatableSelect from "react-select/async-creatable";
import AssetSelection from "../../components/ContentManager/AssetSelection";
import CategorySelect from "../../components/Select/CategorySelect";
import TaxesSelect from "../../components/Select/TaxesSelect";
import { CatalogService } from "../../services/catalog";
import { useMutation, useQuery } from "react-query";
import { ProductService } from "../../services/product";
import { toast } from "react-toastify";
import { INewProductForm } from "../../interfaces/products/forms";
import { FormattedMessage, useIntl } from "react-intl";
import { styles } from "../../components/Select";
import { formatCurrency } from "../../utils/formatter";
import { useEffect } from "react";
import { TaxesService } from "../../services/taxes";

const IMAGES_POSITION = ["image[0]", "image[1]", "image[2]", "image[3]"];

function ProductAdd() {
  const navigate = useNavigate();
  const intl = useIntl();

  const { register, control, watch, handleSubmit, formState, setValue } =
    useForm<INewProductForm>({
      defaultValues: {
        attributes: {},
        images: [],
      },
    });

  useQuery(
    ["defaultTaxes", "taxes"],
    () =>
      TaxesService.getAll({
        default: true,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      onSuccess(data) {
        setValue(
          "applicableTaxes",
          data.data?.map((e: any) => ({
            label: e.name,
            value: e.id,
            [e.type]: e.value,
          }))
        );
      },
    }
  );

  const createProductMutation = useMutation({
    mutationFn: async (formData: INewProductForm) => {
      return await ProductService.create({
        ...formData,
        mediaAssets: formData.images,
      });
    },
    onSuccess(data: any) {
      toast.success(
        intl.formatMessage(
          { id: "Product was created successfully." },
          { product: data.name }
        )
      );
      navigate("/products");
    },
  });

  const taxes = watch("applicableTaxes");
  const gross = watch("gross");
  const category = watch("category");
  const mainImage = watch("mainImage");
  const images = watch("images");

  const appliedTax = Number(
    taxes?.reduce((prev, curr) => {
      if (curr.type === "percentage" || curr.percentage) {
        return prev + (curr.percentage / 100) * gross || 0;
      } else {
        return prev + curr.value || 0;
      }
    }, 0) || 0
  ).toFixed(2);

  const price: number | null = gross
    ? Number(gross) + (Number(appliedTax) || 0)
    : null;

  const onSubmit = handleSubmit(async (data) => {
    await createProductMutation.mutateAsync(data);
  });

  const getAttributeOptions =
    (catalog: string) => async (inputValue: string) => {
      const response = await CatalogService.getList(catalog as any, {
        search: inputValue,
      });
      return response.data.map((e) => ({
        label: e.name,
        value: e.id || e._id,
        ...e,
      }));
    };

  return (
    <div className="components-preview wide-md mx-auto">
      <div className="nk-block-head nk-block-head-lg wide-sm">
        <div className="nk-block-head-content">
          <div className="nk-block-head-sub">
            <a role="button" className="back-to" onClick={() => navigate(-1)}>
              <em className="icon ni ni-arrow-left" />
              <span>
                <FormattedMessage id="productListTitle" />
              </span>
            </a>
          </div>
          <h2 className="nk-block-title fw-normal">
            <FormattedMessage id="New product" />
          </h2>
          <div className="nk-block-des">
            <p className="lead">
              <FormattedMessage id="Enter the data of your new product" />
            </p>
          </div>
        </div>
      </div>
      <div className="nk-block nk-block-lg">
        <div className="card card-bordered card-preview">
          <div className="card-inner">
            <div className="preview-block">
              <Form>
                <span className="preview-title-lg overline-title mb-3">
                  <FormattedMessage id="General Information" />
                </span>

                <Form.Group className="mb-3">
                  <Form.Label>
                    <FormattedMessage id="Product name" />
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control {...register("name", { required: true })} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    <FormattedMessage id="Description" />
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    {...register("description", { required: true })}
                  />
                </Form.Group>

                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <FormattedMessage id="categoryHeader" />
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Controller
                        control={control}
                        name="category"
                        rules={{ required: true }}
                        render={({ field }) => {
                          return <CategorySelect {...field} />;
                        }}
                      />
                      <Form.Text className="text-muted">
                        <FormattedMessage id="Select the category to which the product belongs" />
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <FormattedMessage id="Initial existence" />
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        min={0}
                        {...register("initialStock", { required: true })}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col xs="4">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <FormattedMessage id="Product Code" />
                      </Form.Label>
                      <Form.Control
                        {...register("code")}
                        placeholder={intl.formatMessage({
                          id: "Autogenerated",
                        })}
                      />
                      <Form.Text className="text-muted">
                        <FormattedMessage id="Can be empty (Optional)" />
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>

                <div className="mt-5">
                  <hr />
                  <span className="preview-title-lg overline-title my-3">
                    <FormattedMessage id="Images" />
                  </span>
                </div>

                <h6 className="title mb-0 mt-4">
                  <FormattedMessage id="Selected for this product" />
                </h6>
                <span className="text-muted">
                  <FormattedMessage id="Select the images you want to use for this product" />
                </span>

                <div className="row g-3 mt-3">
                  {IMAGES_POSITION.map((key, idx) => {
                    const isChecked = mainImage === key;

                    return (
                      <div className="col-md-3 col-6">
                        <div
                          className={`preview-block ${
                            !isChecked ? "mt-4" : ""
                          }`}
                        >
                          {isChecked ? (
                            <span className="preview-title overline-title">
                              <FormattedMessage id="Main image" />
                            </span>
                          ) : null}
                          <AssetSelection
                            id={key}
                            checkable
                            name="mainImage"
                            onRemove={() =>
                              isChecked ? setValue("mainImage", "") : null
                            }
                            onSelect={(media) =>
                              setValue(`images.${idx}`, media.id)
                            }
                            onClick={(media) => setValue("mainImage", key)}
                            checked={mainImage === key}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="mt-5">
                  <hr />
                  <span className="preview-title-lg overline-title my-3">
                    <FormattedMessage id="priceHeader" />
                  </span>
                </div>

                {/* <Form.Group className="mb-3">
                  <Form.Label>
                    <FormattedMessage id="Cost" />
                  </Form.Label>
                  <Form.Control
                    type="number"
                    min={0}
                    {...register("cost", { required: true })}
                  />
                </Form.Group> */}
              </Form>

              <Form.Group className="mb-3">
                <Form.Label>
                  <FormattedMessage id="gross price" />
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  min={0}
                  {...register("gross", { required: true })}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  <FormattedMessage id="Taxes" />
                  <span className="text-danger">*</span>
                </Form.Label>
                <Controller
                  control={control}
                  name="applicableTaxes"
                  render={({ field, fieldState }) => {
                    const customStyles = {
                      control: (base: any, state: any) => ({
                        ...base,
                        borderColor: !fieldState.invalid ? "#ddd" : "red",
                        "&:hover": {
                          borderColor: !fieldState.invalid ? "#ddd" : "red",
                        },
                      }),
                    };
                    return (
                      <TaxesSelect
                        {...field}
                        defaultValue={field.value}
                        style={customStyles}
                      />
                    );
                  }}
                />
              </Form.Group>

              {price && (
                <div className="my-4">
                  <p className="text-muted mb-1">
                    {" "}
                    <FormattedMessage id="Taxes" />:{" "}
                    {formatCurrency(
                      Number(Number.isNaN(appliedTax) ? "0.00" : appliedTax)
                    )}
                  </p>
                  <h6>
                    Precio Neto:{" "}
                    <span className="text-primary">
                      {formatCurrency(price)}
                    </span>
                  </h6>
                </div>
              )}

              <div className="mt-5">
                <hr />
                <span className="preview-title-lg overline-title my-3">
                  <FormattedMessage id="Additional Information" />
                </span>
              </div>

              {category ? (
                <>
                  <h6 className="title mb-0 mt-4">
                    <FormattedMessage id="attributesHeader" />
                  </h6>
                  <span className="text-muted">
                    <FormattedMessage id="Add the corresponding attributes to the category" />
                  </span>
                  <Row className="my-3">
                    {category.attributes?.map((att: any) => (
                      <Col className="mt-2" key={att.key} xs={6}>
                        <Form.Group>
                          <Form.Label>
                            {att.displayName}
                            {att.required && (
                              <span className="text-danger">*</span>
                            )}
                          </Form.Label>
                          <Form.Text className="mx-1">
                            {att.description}
                          </Form.Text>
                          <Controller
                            control={control}
                            name={`attributes.${att.key}`}
                            rules={{ required: att.required }}
                            render={({ field }) => {
                              return att.type === "catalog" ? (
                                <AsyncCreatableSelect
                                  styles={styles}
                                  {...field}
                                  isValidNewOption={(inputValue, selectValue) =>
                                    false
                                  }
                                  onChange={(e) => {
                                    field.onChange({
                                      ...e,
                                      displayKey: att.displayName,
                                      displayValue: e.label,
                                      value: e.value,
                                      key: att.key,
                                    });
                                  }}
                                  noOptionsMessage={() => (
                                    <span>
                                      <FormattedMessage id="No data to display" />
                                    </span>
                                  )}
                                  placeholder={<FormattedMessage id="Select" />}
                                  loadingMessage={() => (
                                    <span>
                                      <FormattedMessage id="Loading" />
                                    </span>
                                  )}
                                  formatCreateLabel={(input: any) => (
                                    <span>
                                      <FormattedMessage id="create new" />{" "}
                                      {att.displayName} "{input}"{" "}
                                    </span>
                                  )}
                                  cacheOptions
                                  defaultOptions
                                  loadOptions={getAttributeOptions(att.source)}
                                />
                              ) : (
                                <Form.Control
                                  {...field}
                                  onChange={(e) => {
                                    field.onChange({
                                      displayKey: att.displayName,
                                      displayValue: e.target.value,
                                      value: e.target.value,
                                      key: att.key,
                                    });
                                  }}
                                  type={att.type}
                                  required={att.required}
                                  value={
                                    field.value?.value || field.value || ""
                                  }
                                />
                              );
                            }}
                          />
                        </Form.Group>
                      </Col>
                    ))}
                  </Row>
                </>
              ) : (
                <p className="text-muted text-center mt-5">
                  <i className="icon ni ni-info mr-2"></i>
                  <FormattedMessage id="Select a category to add attributes" />
                </p>
              )}
            </div>

            <div className="mt-4 text-right">
              <br />
              <hr />
              <Button
                disabled={createProductMutation.isLoading || !formState.isValid}
                onClick={onSubmit}
                size="lg"
              >
                {createProductMutation.isLoading ? (
                  <Spinner />
                ) : (
                  <em className="icon ni ni-plus me-2"></em>
                )}{" "}
                <FormattedMessage id="Create Product" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductAdd;
