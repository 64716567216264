import AsyncSelect from "react-select/async";
import { styles } from ".";
import { ProductService } from "../../services/product";

const getOptions = async (inputValue: string) => {
  const response = await ProductService.getList({
    search: inputValue,
  });
  return response.data.map((e: any) => ({
    label: e.name,
    value: e.id,
    ...e,
  }));
};

export default (props: any) => {
  return (
    <AsyncSelect
      noOptionsMessage={() => <span>No hay datos para mostrar</span>}
      loadingMessage={() => <span>Cargando...</span>}
      cacheOptions
      placeholder="Seleccione el producto"
      defaultOptions
      onBlur={props.onBlur}
      name={props.name}
      value={props.value}
      isDisabled={props.disabled}
      onChange={(e) => {
        if (props.onChange) props.onChange(e);
        if (props.onChangeId) props.onChangeId(e.map((e: any) => e.value));
      }}
      loadOptions={getOptions}
      className={props.isInvalid ? "is-invalid" : ""}
      styles={styles}
      // onCreateOption={createOption}
      defaultInputValue={props.defaultValue}
    />
  );
};
