import { useQuery } from "react-query";
import { AddButton } from "../../../components/Buttons/ActionableButton";
import Dropdown, {
  DropdownMenu,
  DropdownMenuOption,
} from "../../../components/Buttons/Dropdown";
import SearchBar from "../../../components/Inputs/SearchBar";
import Table, {
  IPaginationProps,
  ITableHeader,
} from "../../../components/Table";
import Title from "../../../components/Title";
import { useTableQueryParams } from "../../../hooks/router";
import { PageService } from "../../../services/cms/page";
import { formatMidDateTime } from "../../../utils/formatter";
import { getFullName, getInitials } from "../../../utils/person";
import { FormattedMessage, useIntl } from "react-intl";

let timmerId: any;

function PageList() {
  const intl = useIntl();
  const { page, search, setParam, size, status } = useTableQueryParams();

  const options: DropdownMenuOption<any>[] = [
    {
      text: intl.formatMessage({ id: "See link" }),
      icon: "ni ni-eye",
      click: (entity) => window.open(entity.link, "_blank"),
    },
    {
      icon: "ni ni-edit",
      text: intl.formatMessage({ id: "Edit" }),
      link: (entity) => `/cms/pages/${entity.apiId}/edit`,
    },
    // {
    //   type: "divider",
    // },
    // {
    //   icon: "ni ni-na",
    //   text: intl.formatMessage({ id: "Change to draft" }),
    //   link: (entity) => `/page/${entity.id}`,
    // },
  ];

  const { data, isLoading } = useQuery(
    ["pageList", status, search, page, size],
    (ctx) => {
      const [, status, search, page, size] = ctx.queryKey;
      return PageService.getList({
        status,
        search,
        page,
        size,
      });
    },
    {
      cacheTime: 1000 * 60 * 24,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    }
  );

  const headers: ITableHeader[] = [
    {
      path: ["name"],
      name: intl.formatMessage({ id: "Title" }),
      className: "lg-table-col",
      transform: (value, obj) => {
        return (
          <span className="tb-product">
            <span className="title">{value}</span>
          </span>
        );
      },
    },
    {
      path: ["author"],
      name: intl.formatMessage({ id: "Author" }),
      className: "lg-table-col",
      transform: (value, obj) => {
        return (
          <a href="#">
            <div className="user-card">
              <div className="user-avatar sm bg-orange-dim">
                <span>{getInitials(value)}</span>
              </div>
              <div className="user-info d-none d-md-block">
                <span className="tb-lead">{getFullName(value)}</span>
              </div>
            </div>
          </a>
        );
      },
    },
    {
      path: ["updatedAt"],
      name: intl.formatMessage({ id: "Last modification" }),
      transform: (updatedAt) => formatMidDateTime(new Date(updatedAt)),
    },
    {
      path: ["options"], // no id
      name: "",
      type: "tools",
      transform: (v, entity) => (
        <Dropdown
          key={entity.id}
          icon
          trigger
          text={<em className="icon ni ni-more-h" />}
        >
          <DropdownMenu options={options} entity={entity} />
        </Dropdown>
      ),
    },
  ];

  const pagination: IPaginationProps = {
    current: Number(page) || data?.pagination.page,
    size: Number(size) || data?.pagination.size,
    total: data?.pagination?.total,
    onPageChange(page) {
      setParam("page", String(page));
    },
    onSizeChange(size) {
      setParam("size", String(size));
    },
  };

  const onSearch = (term: string) => {
    clearTimeout(timmerId);
    timmerId = setTimeout(() => {
      setParam("search", term);
    }, Number(process.env.REACT_APP_SEARCH_DELAY || 700));
  };

  return (
    <div className="container-fluid">
      <div className="nk-content-inner">
        <div className="nk-content-body">
          <Title
            subText={intl.formatMessage(
              { id: "You have a total of N pages." },
              { number: data?.pagination?.total || 0 }
            )}
            rightContent={
              <ul className="nk-block-tools g-3">
                <li>
                  <SearchBar onChange={(e) => onSearch(e.target.value)} />
                </li>
              </ul>
            }
          >
            <FormattedMessage id="pageListTitle" />
          </Title>

          <div className="nk-block">
            <Table
              headers={headers}
              data={data?.data}
              loading={isLoading}
              pagination={pagination}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageList;
