import { useMutation, useQuery } from "react-query";
import { AddButton } from "../../components/Buttons/ActionableButton";
import Dropdown, {
  DropdownMenu,
  DropdownMenuOption,
} from "../../components/Buttons/Dropdown";
import SearchBar from "../../components/Inputs/SearchBar";

import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import FormGenerator from "../../components/ContentManager/generator";
import Authorize from "../../components/Permission/Authorize";
import Table, { IPaginationProps, ITableHeader } from "../../components/Table";
import Title from "../../components/Title";
import { useConfirmationModal } from "../../contexts/confirmation";
import { Action, BasePermission } from "../../enums/permissions";
import { useAuthorizedArray } from "../../hooks/permissions";
import { useTableQueryParams } from "../../hooks/router";
import { CatalogService } from "../../services/catalog";

let timmerId: any;

export function CatalogTable({ current }: { current: any | null }) {
  const intl = useIntl();
  const { open } = useConfirmationModal();
  const { key } = useParams();
  const methods = useForm();
  const [showModal, setShowModal] = useState(false);
  const { page, search, setParam, size, status } = useTableQueryParams();
  let options: DropdownMenuOption<any>[] = useAuthorizedArray([
    {
      text: intl.formatMessage({ id: "See detail" }),
      icon: "ni ni-eye",
      link: (entity) => `/catalogs/${key}/${entity._id || entity.id}/details`,
    },
    {
      text: intl.formatMessage({ id: "Edit" }),
      icon: "ni ni-edit",
      link: (entity) => `/catalogs/${key}/${entity._id || entity.id}/edit`,
      permissions: [[Action.UPDATE, BasePermission.Catalog]],
    },
    {
      type: "divider",
    },
    {
      icon: "ni ni-trash",
      text: intl.formatMessage({ id: "Delete" }),
      click: (entity) => handleDelete(entity),
      permissions: [[Action.DELETE, BasePermission.Catalog]],
    },
  ]);

  options = current?.internal
    ? [
        {
          text: intl.formatMessage({ id: "See detail" }),
          icon: "ni ni-eye",
          link: (entity) => `/catalogs/${key}/${entity.id}/details`,
        },
      ]
    : options;

  const { data, isLoading, refetch } = useQuery(
    ["catalogByKey", status, search, page, size, key],
    (ctx) => {
      const [, status, search, page, size, key] = ctx.queryKey;
      return CatalogService.getList(key as any, {
        status,
        search,
        page,
        size,
      });
    }
  );

  const schemaQuery = useQuery(
    ["catalogSchemaByKey", key],
    (ctx) => {
      const [, key] = ctx.queryKey;
      return CatalogService.getSchema(key as any);
    },
    {
      cacheTime: Infinity,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
    }
  );

  const handleOpenModal = () => {
    setShowModal(true);
    methods.reset();
  };

  const handleCloseModal = () => {
    setShowModal(false);
    methods.reset();
  };

  const onSubmit = async (formData: any) => {
    try {
      await CatalogService.createCatalog(key as any, formData);
      refetch({
        queryKey: ["catalogByKey", status, search, Number(page) - 1, size, key],
      });
      toast.success(intl.formatMessage({ id: "TOAST.CREATE_SUCCESS" }));

      // Actualizar la caché y volver a cargar los datos

      handleCloseModal();
    } catch (error) {
      toast.error(intl.formatMessage({ id: "TOAST.CREATE_ERROR" }));
    }
  };

  const deleteRecord = useMutation(
    (entity: any) =>
      CatalogService.deleteCatalog(key as any, entity._id || entity.id),
    {
      onSuccess() {
        refetch();
        toast.success(intl.formatMessage({ id: "TOAST.DELETE_SUCCESS" }));
      },
    }
  );

  const handleDelete = (entity: any) => {
    open("¿Está seguro de que desea eliminar este registro?", async () => {
      try {
        await deleteRecord.mutateAsync(entity);
      } catch (error) {
        toast.error(intl.formatMessage({ id: "TOAST.DELETE_ERROR" }));
      }
    });
  };

  const example: any = data?.data[0] || {};
  const restOfHeaders = Object.keys(example)
    .map((k) => {
      if (
        [
          "id",
          "_id",
          "name",
          "createdAt",
          "updatedAt",
          "deleteAt",
          "categories",
        ].includes(k)
      )
        return;

      if (typeof example[k] === "object" && example[k] !== null) return;

      return {
        path: [k],
        name: intl.formatMessage({ id: `catalog.${k}` }),
        hide: "sm",
        transform: (v: any, entity: any) =>
          typeof v === "string" && k !== "type" ? (
            v
          ) : typeof v === "string" && k === "type" ? (
            <span>{intl.formatMessage({ id: `${key}.${v}` })}</span>
          ) : typeof v === "number" ? (
            <span className="text-primary">{v}</span>
          ) : typeof v === "boolean" ? (
            v ? (
              <span className="badge badge-dim rounded-pill bg-success">
                <FormattedMessage id="YES" />
              </span>
            ) : (
              <span className="badge badge-dim rounded-pill bg-danger">
                <FormattedMessage id="NO" />
              </span>
            )
          ) : (
            " - "
          ),
      };
    })
    .filter(Boolean) as ITableHeader[];

  const headers: ITableHeader[] = [
    {
      path: ["name"],
      name: intl.formatMessage({ id: "Name" }),
      className: "lg-table-col",
      transform: (v, entity) => v || entity.displayName || "-",
    },
    ...restOfHeaders,
    {
      path: ["options"], // no id
      name: "",
      type: "tools",
      transform: (v, entity) => (
        <Dropdown
          key={entity.id}
          icon
          trigger
          text={<em className="icon ni ni-more-h" />}
        >
          <DropdownMenu options={options} entity={entity} />
        </Dropdown>
      ),
    },
  ];

  const pagination: IPaginationProps = {
    current: Number(page) || data?.pagination.page,
    size: Number(size) || data?.pagination.size,
    total: data?.pagination?.total,
    onPageChange(page) {
      setParam("page", String(page));
    },
    onSizeChange(size) {
      setParam("size", String(size));
    },
  };

  const onSearch = (term: string) => {
    clearTimeout(timmerId);
    timmerId = setTimeout(() => {
      setParam("search", term);
    }, Number(process.env.REACT_APP_SEARCH_DELAY || 700));
  };

  return (
    <div className="container-fluid">
      <div className="nk-content-inner">
        <div className="nk-content-body">
          <Title
            subText={intl.formatMessage(
              { id: "You have a total of N Catalogue." },
              { number: data?.pagination?.total || 0 }
            )}
            rightContent={
              <ul className="nk-block-tools g-3">
                <li>
                  <SearchBar onChange={(e) => onSearch(e.target.value)} />
                </li>
                {/*           <li>
                  <DynamicDropdown
                    initialText="Estado"
                    options={Status}
                    selected={status || undefined}
                    onClick={(_, k) => setParam("status", k)}
                  />
                </li> */}
                {!current?.internal && (
                  <Authorize
                    actions={[Action.CREATE]}
                    subjects={[BasePermission.Catalog]}
                  >
                    <li className="nk-block-tools-opt">
                      <AddButton onClick={handleOpenModal} />
                    </li>
                  </Authorize>
                )}
              </ul>
            }
          >
            {current?.name}{" "}
            {current?.internal && (
              <span className="badge has-bg bg-light">Interno</span>
            )}
          </Title>

          <div className="nk-block">
            <Table
              headersDependency={String((key as string) + isLoading)}
              headers={headers}
              data={data?.data || []}
              /* selectable */
              loading={isLoading}
              pagination={pagination}
              /* bulkOptions={[
                {
                  icon: "ni ni-mail",
                  text: "Enviar email a todos",
                  click: (entities) => console.log(entities),
                },
                {
                  icon: "ni ni-na",
                  text: intl.formatMessage({ id: "Disable all" }),
                },
                {
                  icon: "ni ni-trash",
                  text: intl.formatMessage({ id: "Delete all" }),
                },
                {
                  icon: "ni ni-shield-star",
                  text: intl.formatMessage({ id: "Reset all" }),
                },
              ]} */
            />

            <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>{current?.name || key}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {schemaQuery.isLoading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <FormGenerator
                    schema={schemaQuery.data}
                    onSubmit={onSubmit}
                    methods={methods}
                  />
                )}
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CatalogTable;
