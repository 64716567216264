export const CollectionStatus = {
  published: { class: "success", text: "Publicado" },
  draft: { class: "secondary", text: "Borrador" },
} as const;

export const CollectionPublishStatus = {
  published: { class: "success", text: "Público", icon: "ni ni-eye-alt" },
  draft: { class: "secondary", text: "Borrador", icon: "ni ni-file-docs" },
  publishOnDate: {
    class: "info",
    text: "Programar publicación",
    icon: "ni ni-clock",
  },
} as const;
