import { EditorState } from "draft-js";
import http, { apiUrl } from "../../clients/http";
import { fixIncomingFormat } from "../../utils/media";
import { serializeParams } from "../../utils/url";
import { editorStateToMarkdown } from "../../utils/editor";
import { CmsService } from "./cms";

type CollectionGetListParams = {
  status: string | null;
  search: string | null;
  page: string | null;
  size: string | null;
};

export class CollectionService {
  static async parseFormFrom(schema: any, formData: any) {
    let containsFile = false;
    for (const key in formData) {
      if (formData[key] instanceof EditorState) {
        formData[key] = editorStateToMarkdown(formData[key]);
        continue;
      }

      if (formData[key] instanceof File) {
        formData[key] = formData[key];
        const schemaProps = schema[key];
        const data = await CmsService.processFiles(
          [formData[key]],
          schemaProps.processor
        );
        formData[key] = data[0];
        containsFile = true;
        continue;
      }

      formData[key] = formData[key];
    }

    return {
      data: formData,
      containsFile,
    };
  }
  static async getList(filters: Partial<CollectionGetListParams>) {
    const data = await http.get(
      `${apiUrl}/cms/collections?${serializeParams(filters)}`
    );

    return {
      data: data.data?.data,
      pagination: data.data?.pagination,
    };
  }

  static async getDetails(key: string, id: string) {
    const { data } = await http.get(`/cms/collections/${key}/${id}`);
    return data;
  }

  static async getDetail(key: string) {
    const data = await http.get(`/cms/collections/${key}`);
    return data;
  }

  static async getSchema(key: string) {
    const data = await http.get(`/cms/collections/${key}/schema`);
    return data.data;
  }

  static async create(key: string, data: Record<string, unknown>) {
    const response = await http.post(`/cms/collections/${key}`, {
      content: data,
    });
    return response.data;
  }

  static async deleteCollection(key: string, id: string) {
    const response = await http.delete(`/cms/collections/${key}/${id}`);
    return response.data;
  }

  static async updateContent(
    key: string,
    id: string,
    content: Record<string, unknown>
  ) {
    const { data } = await http.put(`/cms/collections/${key}/${id}`, {
      content,
    });
    return data;
  }

  static async getCollectionList(
    key: string,
    filters: CollectionGetListParams
  ) {
    const response = await http.get(
      `${apiUrl}/cms/collections/${key}?${serializeParams(filters)}`
    );

    const { data } = response;

    return {
      data: data.data,
      pagination: data.pagination,
    };
  }

  static async changePublishState(
    key: string,
    id: any,
    publishState: "draft" | "published"
  ): Promise<unknown> {
    return await http.patch(`/cms/collections/${key}/${id}/publish-state`, {
      state: publishState,
    });
  }

  static prepareForForm(schema: any, values?: any) {
    const initialValues: Record<string, any> = {};
    Object.keys(schema).forEach((k) => {
      initialValues[k] = "";
    });
    return fixIncomingFormat(values || initialValues, schema);
  }
}
