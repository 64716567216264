import { useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  ICustomerDetails,
  IEditCustomerForm,
} from "../../interfaces/customers";
import { CustomerService } from "../../services/customer";
import { getFullName } from "../../utils/person";
import { emailPattern } from "../../utils/form-validation";

function CustomerEdit() {
  const navigate = useNavigate();
  const intl = useIntl();
  const [isFetched, setIsFetch] = useState(false);

  const { id } = useParams() as { id: string };

  const { register, handleSubmit, formState, reset } =
    useForm<IEditCustomerForm>({
      defaultValues: {},
    });

  const { errors } = formState;

  const { data, isLoading } = useQuery(
    ["customerDetails", id],
    () =>
      CustomerService.get(String(id)) as Promise<{ user: ICustomerDetails }>,
    {
      enabled: !isFetched,
      onSuccess(customer) {
        console.log(customer);
        setIsFetch(true);
        reset({
          email: customer.user.email,
          lastNames: customer.user.lastNames,
          names: customer.user.names,
          tel: customer.user.information?.tel,
        });
      },
    }
  );

  const editCustomerMutation = useMutation({
    mutationFn: async (formData: IEditCustomerForm) => {
      return await CustomerService.update(id, formData);
    },
    onSuccess(data: any) {
      toast.success(
        intl.formatMessage(
          { id: "Customer was updated successfully." },
          { product: data.name }
        )
      );
      navigate(`/customers/${id}`);
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    return await editCustomerMutation.mutateAsync(data);
  });

  return (
    <div className="components-preview wide-md mx-auto">
      <div className="nk-block-head nk-block-head-lg wide-sm">
        <div className="nk-block-head-content">
          <h2 className="nk-block-title fw-normal">
            <span className="text-primary">
              <FormattedMessage id="Edit Customer" />/
            </span>{" "}
            {data && getFullName(data?.user)}
          </h2>
          <div className="nk-block-des">
            <p className="lead">
              <FormattedMessage id="Update the data of your selected customer" />
            </p>
          </div>
        </div>
      </div>

      <div className="nk-block nk-block-lg">
        <div className="card card-bordered card-preview">
          <div className="card-inner">
            {isLoading ? (
              <div className="d-flex justify-content-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">
                    <FormattedMessage id="Loading" />
                  </span>
                </Spinner>
              </div>
            ) : (
              <Form onSubmit={onSubmit}>
                <span className="preview-title-lg overline-title mb-3">
                  <FormattedMessage id="General Information" />
                </span>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <FormattedMessage id="Names" />
                      </Form.Label>
                      <Form.Control
                        maxLength={50}
                        {...register("names", { required: true })}
                        isInvalid={Boolean(errors.names)}
                      />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <FormattedMessage id="Last Names" />
                      </Form.Label>
                      <Form.Control
                        maxLength={50}
                        {...register("lastNames", { required: true })}
                        isInvalid={Boolean(errors.lastNames)}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <FormattedMessage id="Email" />
                      </Form.Label>
                      <Form.Control
                        type="email"
                        maxLength={50}
                        {...register("email", {
                          required: true,
                          pattern: emailPattern,
                        })}
                        isInvalid={Boolean(errors.email)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <FormattedMessage id="Telephone" />
                      </Form.Label>
                      <Form.Control
                        maxLength={50}
                        {...register("tel", { required: false })}
                        isInvalid={Boolean(errors.tel)}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <div className="mt-4 text-right">
                  <br />
                  <hr />
                  <Button
                    type="submit"
                    disabled={editCustomerMutation.isLoading}
                    onClick={onSubmit}
                    size="lg"
                  >
                    {editCustomerMutation.isLoading ? (
                      <Spinner />
                    ) : (
                      <em className="icon ni ni-save me-2"></em>
                    )}{" "}
                    <FormattedMessage id="Save Changes" />
                  </Button>
                </div>
              </Form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerEdit;
