import { QueryClient } from "react-query";
import { toast } from "react-toastify";

// Create a client
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      retryDelay: 1100,
      onError(err) {
        console.log(err);
        toast.error(
          "Ups! Ha ocurrido un error inesperado. Por favor, intenta de nuevo."
        );
      },
    },
    mutations: {
      onError(err: any) {
        const errorMessage = err.response?.data.message || err.message;
        toast.error(`No fue posible realizar la operación: ${errorMessage}`);
      },
    },
  },
});
