import { useQuery } from "react-query";
import { AddButton } from "../../components/Buttons/ActionableButton";
import Dropdown, {
  DropdownMenu,
  DropdownMenuOption,
} from "../../components/Buttons/Dropdown";
import SearchBar from "../../components/Inputs/SearchBar";

import DynamicDropdown from "../../components/Buttons/DynamicDropdown";
import Table, { IPaginationProps, ITableHeader } from "../../components/Table";
import { transformToOrderStatus } from "../../components/Table/transformers";
import Title from "../../components/Title";
import { OrderStatus, Status } from "../../enums/status";
import { useTableQueryParams } from "../../hooks/router";
import { OrderService } from "../../services/order";
import { formatCurrency, formatMidDate } from "../../utils/formatter";
import { getFullName, getInitials } from "../../utils/person";
import { getOrderDescription } from "../../utils/product";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

let timmerId: any;

function OrderList() {
  const intl = useIntl();
  const { page, search, setParam, size, status } = useTableQueryParams();

  const options: DropdownMenuOption<any>[] = [
    {
      text: intl.formatMessage({ id: "See detail" }),
      icon: "ni ni-eye",
      link: (entity) => `/orders/${entity.id}`,
    },
    // {
    //   type: "divider",
    // },
    // {
    //   icon: "ni ni-shield-star",
    //   text: intl.formatMessage({ id: "Change Status" }),
    //   link: (entity) => `/order/${entity.id}`,
    // },
    // {
    //   icon: "ni ni-na",
    //   text: intl.formatMessage({ id: "ACTIONS.CANCEL" }),
    //   link: (entity) => `/order/${entity.id}`,
    // },
  ];

  const { data, isLoading } = useQuery(
    ["orderList", status, search, page, size],
    (ctx) => {
      const [_, status, search, page, size] = ctx.queryKey;
      return OrderService.getList({
        status,
        search,
        page,
        size,
      });
    }
  );

  const headers: ITableHeader[] = [
    {
      path: ["code"],
      name: intl.formatMessage({ id: "Order" }),
      transform: (value, obj) => (
        <span className="tb-lead">
          <Link to={`/orders/${obj.id}`}>
            <a href="#">#{value}</a>
          </Link>
        </span>
      ),
    },
    {
      path: ["createdAt"],
      name: intl.formatMessage({ id: "Date" }),
      transform: (value) => formatMidDate(new Date(value)),
      hide: "sm",
    },
    {
      path: ["status"],
      name: intl.formatMessage({ id: "Status" }),
      transform: (s) => transformToOrderStatus(s.name),
    },
    {
      path: ["customer"],
      name: intl.formatMessage({ id: "Customer" }),
      transform: (obj) => {
        return (
          <div className="user-card">
            <div className="user-avatar bg-primary">
              <span>{getInitials(obj.user)}</span>
            </div>
            <div className="user-info d-none d-md-block">
              <span className="tb-lead">
                {getFullName(obj.user)}
                <span className="dot dot-success d-md-none ms-1"></span>
              </span>
              <span>{obj.user.email}</span>
            </div>
          </div>
        );
      },
    },

    {
      path: ["items"],
      name: intl.formatMessage({ id: "Buys" }),
      hide: "sm",

      transform: (items: any[]) => {
        return <a href="#">{getOrderDescription(items)}</a>;
      },
    },
    {
      path: ["total"],
      name: intl.formatMessage({ id: "Total" }),
      transform: formatCurrency,
    },
    {
      path: ["options"], // no id
      name: "",
      type: "tools",
      transform: (v, entity) => (
        <Dropdown
          key={entity.id}
          icon
          trigger
          text={<em className="icon ni ni-more-h" />}
        >
          <DropdownMenu options={options} entity={entity} />
        </Dropdown>
      ),
    },
  ];

  const pagination: IPaginationProps = {
    current: Number(page) || data?.pagination.page,
    size: Number(size) || data?.pagination.size,
    total: data?.pagination?.total,
    onPageChange(page) {
      setParam("page", String(page));
    },
    onSizeChange(size) {
      setParam("size", String(size));
    },
  };

  const onSearch = (term: string) => {
    clearTimeout(timmerId);
    timmerId = setTimeout(() => {
      setParam("search", term);
    }, Number(process.env.REACT_APP_SEARCH_DELAY || 700));
  };

  return (
    <div className="container-fluid">
      <div className="nk-content-inner">
        <div className="nk-content-body">
          <Title
            subText={intl.formatMessage(
              { id: "You have a total of N orders." },
              { number: data?.pagination?.total || 0 }
            )}
            rightContent={
              <ul className="nk-block-tools g-3">
                <li>
                  <SearchBar onChange={(e) => onSearch(e.target.value)} />
                </li>
                <li>
                  <DynamicDropdown
                    initialText="Estado"
                    options={OrderStatus}
                    selected={status || undefined}
                    onClick={(_, k) => setParam("status", k)}
                  />
                </li>
              </ul>
            }
          >
            <FormattedMessage id="Order list" />
          </Title>

          <div className="nk-block">
            <Table
              headers={headers}
              // data={data?.data}
              data={data?.data}
              /*  selectable */
              loading={isLoading}
              pagination={pagination}
              /*    bulkOptions={[
                {
                  icon: "ni ni-mail",
                  text: intl.formatMessage({ id: "Send email to everyone" }),
                  click: (entities) => console.log(entities),
                },
                {
                  icon: "ni ni-na",
                  text: intl.formatMessage({ id: "Disable all" }),
                },
                {
                  icon: "ni ni-trash",
                  text: intl.formatMessage({ id: "Delete all" }),
                },
                {
                  icon: "ni ni-shield-star",
                  text: intl.formatMessage({ id: "Reset all" }),
                },
              ]} */
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderList;
