import { BasePermission, CMSPermission } from "../enums/permissions";
import CatalogList from "./catalogs";
import CatalogoDetails from "./catalogs/details";
import CatalogoEdit from "./catalogs/edit";
import PostList from "./cms/collections";
import CollectionCreate from "./cms/collections/create";
import PostEdit from "./cms/collections/edit";
import FormList from "./cms/forms";
import MediaList from "./cms/media";
import PageList from "./cms/pages";
import PageEdit from "./cms/pages/edit";
import CustomerList from "./customers";
import CustomerDetails from "./customers/details";
import CustomerEdit from "./customers/edit";
import Dashboard from "./dashboards/index";
import IntegrationPage from "./integrations";
import InventoryList from "./inventory";
import OrderList from "./orders";
import OrderDetails from "./orders/details";
import ProductList from "./products";
import ProductAdd from "./products/add";
import ProductDetails from "./products/details";
import ProductEdit from "./products/edit";
import SettingPage from "./settings";
import SubscriptionPage from "./subscriptions";
import ClientProfile from "./subscriptions/account-info";
import SubscriptionPaymentHistory from "./subscriptions/payment-history";
import UserList from "./users";
import UserAdd from "./users/add";
import IntegrationDetails from "./integrations/details";
import UserDetails from "./users/details";
import UserEdit from "./users/edit";
import DiscountsList from "./discounts";
import DiscountAdd from "./discounts/add";
import DiscountEdit from "./discounts/edit";

export const routes = [
  {
    path: "/",
    component: Dashboard,
  },
  /* {
    path: "/dashboard/e-commerce",
    component: Dashboard,
    requiredPermissions: [BasePermission.Stats],
  },
  {
    path: "/dashboard/cms",
    component: DashboardCMS,
    requiredPermissions: [BasePermission.Stats],
  }, */
  {
    path: "/products",
    component: ProductList,
    requiredPermissions: [BasePermission.Product],
  },
  {
    path: "/products/add",
    component: ProductAdd,
    requiredPermissions: [BasePermission.Product],
  },
  {
    path: "/products/:id",
    component: ProductDetails,
    requiredPermissions: [BasePermission.Product],
  },
  {
    path: "/products/:id/edit",
    component: ProductEdit,
    requiredPermissions: [BasePermission.Product],
  },
  {
    path: "/discounts",
    component: DiscountsList,
    requiredPermissions: [BasePermission.Discount],
  },
  {
    path: "/discounts/add",
    component: DiscountAdd,
    requiredPermissions: [BasePermission.Discount],
  },
  {
    path: "/discounts/:id/edit",
    component: DiscountEdit,
    requiredPermissions: [BasePermission.Discount],
  },
  {
    path: "/inventory",
    component: InventoryList,
    requiredPermissions: [BasePermission.Inventory],
  },
  {
    path: "/customers",
    component: CustomerList,
    requiredPermissions: [BasePermission.Customer],
  },
  {
    path: "/customers/:id",
    component: CustomerDetails,
    requiredPermissions: [BasePermission.Customer],
  },
  {
    path: "/customers/:id/edit",
    component: CustomerEdit,
    requiredPermissions: [BasePermission.Customer],
  },
  {
    path: "/orders",
    component: OrderList,
    requiredPermissions: [BasePermission.Order],
  },
  {
    path: "/orders/:id",
    component: OrderDetails,
    requiredPermissions: [BasePermission.Order],
  },
  {
    path: "/cms/pages",
    component: PageList,
    requiredPermissions: [CMSPermission.Page],
  },
  {
    path: "/cms/pages/:id/edit",
    component: PageEdit,
    requiredPermissions: [CMSPermission.Page],
  },
  {
    path: "/cms/collections",
    component: PostList,
    requiredPermissions: [CMSPermission.Collection],
  },
  {
    path: "/cms/collections/:name",
    component: PostList,
    requiredPermissions: [CMSPermission.Collection],
  },
  {
    path: "/cms/forms",
    component: FormList,
    requiredPermissions: [CMSPermission.Form],
  },
  {
    path: "/cms/forms/:name",
    component: FormList,
    requiredPermissions: [CMSPermission.Form],
  },
  {
    path: "/cms/collections/:name/:id/edit",
    component: PostEdit,
    requiredPermissions: [CMSPermission.Collection],
  },
  {
    path: "/cms/collections/:name/create",
    component: CollectionCreate,
    requiredPermissions: [CMSPermission.Collection],
  },
  {
    path: "/cms/media",
    component: MediaList,
    requiredPermissions: [CMSPermission.Media],
  },
  {
    path: "/users",
    component: UserList,
    requiredPermissions: [BasePermission.User],
  },
  {
    path: "/users/add",
    component: UserAdd,
    requiredPermissions: [BasePermission.User],
  },
  {
    path: "/users/:id",
    component: UserDetails,
    requiredPermissions: [BasePermission.User],
  },
  {
    path: "/users/:id/edit",
    component: UserEdit,
    requiredPermissions: [BasePermission.User],
  },
  {
    path: "/catalogs",
    component: CatalogList,
    requiredPermissions: [BasePermission.Catalog],
  },
  {
    path: "/catalogs/:key",
    component: CatalogList,
    requiredPermissions: [BasePermission.Catalog],
  },
  {
    path: "/catalogs/:key/:id/edit",
    component: CatalogoEdit,
    requiredPermissions: [BasePermission.Catalog],
  },
  {
    path: "/catalogs/:key/:id/details",
    component: CatalogoDetails,
    requiredPermissions: [BasePermission.Catalog],
  },
  {
    path: "/integrations",
    component: IntegrationPage,
    requiredPermissions: [BasePermission.Addons],
  },
  {
    path: "/integrations/:key",
    component: IntegrationDetails,
    requiredPermissions: [BasePermission.Addons],
  },
  {
    path: "/subscriptions",
    component: SubscriptionPage,
    requiredPermissions: [BasePermission.Subscription],
  },
  {
    path: "/subscriptions/payments-history",
    component: SubscriptionPaymentHistory,
    requiredPermissions: [BasePermission.Subscription],
  },
  {
    path: "/subscriptions/account-info",
    component: ClientProfile,
    requiredPermissions: [BasePermission.Subscription],
  },
  {
    path: "/settings",
    component: SettingPage,
    requiredPermissions: [BasePermission.Setting],
  },
  {
    path: "/settings/:key",
    component: SettingPage,
    requiredPermissions: [BasePermission.Setting],
  },
];
