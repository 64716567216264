import React from "react";
import { useQuery } from "react-query";
import { useIntl, FormattedMessage } from "react-intl";
import { ClientService } from "../../services/client";
import BackButton from "../../components/Buttons/BackButton";
import Title from "../../components/Title";
import { Spinner } from "react-bootstrap";

const ClientProfile = () => {
  const intl = useIntl();
  const {
    data: client,
    isLoading,
    isError,
    error,
  } = useQuery("client", ClientService.getMyAccount);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">
            <FormattedMessage id="Loading" />
          </span>
        </Spinner>
      </div>
    );
  }

  if (isError) {
    return (
      <div>
        {intl.formatMessage({ id: "Error:" })} {(error as any).message}
      </div>
    );
  }

  return (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <Title
          subText={<FormattedMessage id="Here is your account information." />}
          rightContent={
            <ul className="nk-block-tools g-3">
              <li>
                <BackButton />
              </li>
            </ul>
          }
        >
          <FormattedMessage id="My Account" />
        </Title>

        <div className="card card-bordered">
          <div className="card-inner card-inner-lg">
            <div className="nk-block-head">
              <div className="nk-block-head-content">
                <h4 className="nk-block-title">
                  <FormattedMessage id="Personal Information" />
                </h4>
                <div className="nk-block-des">
                  <p>
                    <FormattedMessage id="Basic info, like your name and address, that you use on Nio Platform." />
                  </p>
                </div>
              </div>
            </div>
            <div className="nk-block">
              <div className="nk-data data-list data-list-s2">
                <div className="data-head">
                  <h6 className="overline-title">
                    <FormattedMessage id="Basics" />
                  </h6>
                </div>
                <div className="data-item">
                  <div className="data-col">
                    <span className="data-label">
                      <FormattedMessage id="Full Name" />
                    </span>
                    <span className="data-value">{client.fullName}</span>
                  </div>
                  <div className="data-col data-col-end">
                    <span className="data-more disable">
                      <em className="icon ni ni-lock-alt" />
                    </span>
                  </div>
                </div>
                <div className="data-item">
                  <div className="data-col">
                    <span className="data-label">
                      <FormattedMessage id="Email" />
                    </span>
                    <span className="data-value">{client.email}</span>
                  </div>
                  <div className="data-col data-col-end">
                    <span className="data-more disable">
                      <em className="icon ni ni-lock-alt" />
                    </span>
                  </div>
                </div>
                <div className="data-item">
                  <div className="data-col">
                    <span className="data-label">
                      <FormattedMessage id="Phone Number" />
                    </span>
                    <span className="data-value">{client.phone}</span>
                  </div>
                  <div className="data-col data-col-end">
                    <span className="data-more disable">
                      <em className="icon ni ni-lock-alt" />
                    </span>
                  </div>
                </div>
                <div className="data-item">
                  <div className="data-col">
                    <span className="data-label">
                      <FormattedMessage id="Address" />
                    </span>
                    <span className="data-value">{client.address}</span>
                  </div>
                  <div className="data-col data-col-end">
                    <span className="data-more disable">
                      <em className="icon ni ni-lock-alt" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientProfile;
