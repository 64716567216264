import http from "../clients/http";
import { IEditCustomerForm } from "../interfaces/customers";
import { serializeParams } from "../utils/url";

type CustomerGetListParams = {
  status: string | null;
  search: string | null;
  page: string | null;
  size: string | null;
};

export class CustomerService {
  static async update(id: string, formData: IEditCustomerForm) {
    const response = await http.put(`/customers/${id}`, formData);
    return response.data;
  }

  static async get(id: string) {
    const { data } = await http.get(`/customers/${id}`);
    return data;
  }

  static async getStats() {
    const { data } = await http.get(`/customers/stats`);
    return data;
  }

  static async getList(filters: CustomerGetListParams) {
    const response = await http.get(`/customers?${serializeParams(filters)}`);
    const { data } = response;

    return {
      data: data.data.map((d: any) => {
        d.lastOrder = d.lastOrder ? new Date(d.lastOrder) : new Date();
        return d;
      }),
      pagination: data.pagination,
    };
  }

  static async getDetails(id: string) {
    const response = await http.get(`/customers/${id}`);
    const { data } = response;
    return data;
  }

  static async getOrdersStats(id: string) {
    const response = await http.get(`/customers/${id}/orders/stats`);
    const { data } = response;
    return data;
  }

  static async resetPassword(id: string) {
    const response = await http.post(`/customers/${id}/reset-password`, {});
    return response.data;
  }

  static async updateStatus(id: string, status: string) {
    const response = await http.put(`/customers/${id}/status`, { status });
    return response.data;
  }
}
