import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export function LazyImage(
  image: React.DOMAttributes<HTMLImageElement> & Partial<HTMLImageElement>
) {
  return (
    <LazyLoadImage
      alt={image.alt}
      height={image.height}
      src={image.src}
      width={image.width}
      wrapperClassName={image.className}
      effect="blur"
    />
  );
}

export default LazyImage;
