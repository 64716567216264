import { FormattedMessage, useIntl } from "react-intl";
import { useMutation, useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AddButton } from "../../../components/Buttons/ActionableButton";
import Dropdown, {
  DropdownMenu,
  DropdownMenuOption,
} from "../../../components/Buttons/Dropdown";
import SearchBar from "../../../components/Inputs/SearchBar";
import Table, {
  IPaginationProps,
  ITableHeader,
} from "../../../components/Table";
import { transformToInventoryType } from "../../../components/Table/transformers";
import Title from "../../../components/Title";
import { useConfirmationModal } from "../../../contexts/confirmation";
import { useTableQueryParams } from "../../../hooks/router";
import { ProductService } from "../../../services/product";
import { formatMidDateTime } from "../../../utils/formatter";
import { useState } from "react";
import RecordInventory from "../../../components/Modals/RecordInventory";
import Authorize from "../../../components/Permission/Authorize";
import { Action, BasePermission } from "../../../enums/permissions";

let timmerId: any;

function ProductDetailsInventory() {
  const { id } = useParams() as { id: string };
  const { page, search, setParam, size, status } = useTableQueryParams();
  const intl = useIntl();
  const { open } = useConfirmationModal();
  const [show, setShow] = useState(false);

  const productQuery = useQuery(["productDetails", id], () =>
    ProductService.getDetails(id)
  );

  const { data, isLoading, refetch } = useQuery(
    ["ProductDetailsInventory", status, search, page, size],
    (ctx) => {
      const [, status, search, page, size] = ctx.queryKey;
      return ProductService.getInventory(id, {
        status,
        search,
        page,
        size,
      });
    }
  );

  const deleteProduct = useMutation({
    mutationFn: async (id: string) => {
      return await ProductService.delete(id);
    },
    onSuccess(data: any) {
      refetch();
      toast.success(`El producto fue eliminado exitosamente`);
    },
  });

  const options: DropdownMenuOption<any>[] = [
    // {
    //   text: intl.formatMessage({ id: "See detail" }),
    //   icon: "ni ni-eye",
    //   link: (entity) => `/products/${entity.id}`,
    // },
    // {
    //   type: "divider",
    // },
    {
      icon: "ni ni-na",
      text: intl.formatMessage({ id: "removeOption" }),
      click: (entity) => {
        open(
          intl.formatMessage(
            { id: "confirmationMessage" },
            { entityName: entity.name }
          ),
          () => {
            return deleteProduct.mutateAsync(entity.id);
          }
        );
      },
    },
  ];

  const headers: ITableHeader[] = [
    {
      path: ["type"],
      name: intl.formatMessage({ id: "Type" }),
      transform: transformToInventoryType,
      className: "nk-tb-col",
    },
    {
      path: ["concept"],
      name: intl.formatMessage({ id: "Description" }),
      transform: (value, ob) => {
        return ob.concept?.description || "Actualización de inventario";
      },
      hide: "sm",
    },
    {
      path: ["value"],
      name: intl.formatMessage({ id: "Quantity" }),
    },
    {
      path: ["createdAt"],
      name: intl.formatMessage({ id: "Created At" }),
      transform: (value) => formatMidDateTime(new Date(value)),
      hide: "sm",
    },
    {
      path: ["options"], // no id
      name: "",
      type: "tools",
      transform: (v, entity) => (
        <Dropdown
          key={entity.id}
          icon
          trigger
          text={<em className="icon ni ni-more-h" />}
        >
          <DropdownMenu options={options} entity={entity} />
        </Dropdown>
      ),
    },
  ];

  const pagination: IPaginationProps = {
    current: Number(page) || data?.pagination.page,
    size: Number(size) || data?.pagination.size,
    total: data?.pagination?.total,
    onPageChange(page) {
      setParam("page", String(page));
    },
    onSizeChange(size) {
      setParam("size", String(size));
    },
  };

  const onSearch = (term: string) => {
    clearTimeout(timmerId);
    timmerId = setTimeout(() => {
      setParam("search", term);
    }, Number(process.env.REACT_APP_SEARCH_DELAY || 700));
  };

  return (
    <div className="container-fluid mt-5">
      <div className="nk-content-inner">
        {!productQuery.isLoading && (
          <RecordInventory
            show={show}
            handleClose={() => setShow(false)}
            refetch={refetch}
            selectedProduct={productQuery.data}
          />
        )}
        <div className="nk-content-body">
          <Title
            size="sm"
            subText={intl.formatMessage(
              { id: "You have a total of N records" },
              { total: data?.pagination.total }
            )}
            rightContent={
              <ul className="nk-block-tools g-3">
                <li>
                  <SearchBar onChange={(e) => onSearch(e.target.value)} />
                </li>
                <Authorize
                  actions={[Action.CREATE]}
                  subjects={[BasePermission.Inventory]}
                >
                  <li className="nk-block-tools-opt">
                    <AddButton onClick={() => setShow(true)} />
                  </li>
                </Authorize>
              </ul>
            }
          >
            <FormattedMessage
              id="Product Inventory N"
              values={{ N: productQuery.data?.name }}
            />
          </Title>

          <div className="nk-block mt-2">
            <Table
              compact
              className="card"
              headers={headers}
              data={data?.data}
              loading={isLoading}
              pagination={pagination}
              /* bulkOptions={[
                {
                  icon: "ni ni-na",
                  text: intl.formatMessage({ id: "suspendAllOption" }),
                },
              ]} */
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetailsInventory;
