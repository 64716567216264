import { Alert, Col, Row } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "react-query";
import Select from "react-select";
import { toast } from "react-toastify";
import { AddButton } from "../../../components/Buttons/ActionableButton";
import { SkeletonRow } from "../../../components/Loading/Skeleton";
import Authorize from "../../../components/Permission/Authorize";
import { useConfirmationModal } from "../../../contexts/confirmation";
import { Action, BasePermission } from "../../../enums/permissions";
import { IGeneralSettingsForm } from "../../../interfaces/settings/general";
import { GeneralSettingsService } from "../../../services/general";

const socialNetworkList = [
  {
    value: "facebook",
    label: (
      <>
        <i className="icon ni ni-facebook-f"></i> Facebook
      </>
    ),
  },
  {
    value: "twitter",
    label: (
      <>
        <i className="icon ni ni-twitter"></i> Twitter
      </>
    ),
  },
  {
    value: "instagram",
    label: (
      <>
        <i className="icon ni ni-instagram"></i> Instagram
      </>
    ),
  },
  {
    value: "youtube",
    label: (
      <>
        <i className="icon ni ni-youtube"></i> Youtube
      </>
    ),
  },
  {
    value: "linkedin",
    label: (
      <>
        <i className="icon ni ni-linkedin"></i> Linkedin
      </>
    ),
  },
  {
    value: "pinterest",
    label: (
      <>
        <i className="icon ni ni-pinterest"></i> Pinterest
      </>
    ),
  },
  {
    value: "tiktok",
    label: (
      <>
        <i className="icon ni ni-tiktok"></i> Tiktok
      </>
    ),
  },
  {
    value: "whatsapp",
    label: (
      <>
        <i className="icon ni ni-whatsapp"></i> Whatsapp
      </>
    ),
  },
  {
    value: <FormattedMessage id="other" />,
    label: (
      <>
        <i className="icon ni ni-link"></i> <FormattedMessage id="Other" />
      </>
    ),
  },
];

function SocialNetworkSetting() {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm<Pick<IGeneralSettingsForm, "ssnn">>({
    defaultValues: {
      ssnn: [
        {
          name: "",
          link: "",
        },
      ],
    },
  });

  const { open } = useConfirmationModal();

  const { isLoading, isError, refetch } = useQuery<IGeneralSettingsForm>(
    "generalSettings",
    GeneralSettingsService.getSettings,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      onSuccess: (data) => {
        setValue("ssnn", data.ssnn);
      },
    }
  );

  const onSubmit = async (data: any) => {
    return open(
      intl.formatMessage(
        { id: "confirmationEditMessage" },
        { entityName: intl.formatMessage({ id: "Social Network Links" }) }
      ),
      async () => {
        try {
          await GeneralSettingsService.updateSSNN(data);
          toast.success(intl.formatMessage({ id: "TOAST.UPDATE_SUCCESS" }));
          refetch(); // Vuelve a cargar los datos después de la actualización exitosa
        } catch (error) {
          toast.error(intl.formatMessage({ id: "TOAST.UPDATE_ERROR" }));
        }
      }
    );
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "ssnn",
  });

  const ssnn = watch("ssnn");

  return (
    <div className="card-inner">
      <Row>
        <Col xs={12} md={8}>
          <h5 className="card-title">
            <FormattedMessage id="Social Network Links" />
          </h5>
          <p>
            <FormattedMessage id="Link your social networks to your store." />
          </p>
        </Col>
        <Col xs={12} md={4} className="text-right">
          <AddButton
            className="btn-link"
            onClick={() => append({ name: "", link: "" })}
          />
        </Col>
      </Row>

      {isError ? (
        <Alert variant="danger">
          <i className="icon ni ni-alert-circle me-1"></i>
          <FormattedMessage id="Error loading configuration, Try again later or contact support." />
        </Alert>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="gy-3 form-settings">
          <div className="row g-3 mt-3 align-center">
            <div className="col-lg-5">
              <div className="form-group">
                <label className="form-label" htmlFor="store-name">
                  <FormattedMessage id="Links" />
                </label>
                <span className="form-note">
                  <FormattedMessage id="Enter the links of your social networks." />
                </span>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group">
                <div className="form-control-wrap">
                  {isLoading ? (
                    <SkeletonRow size="xs" />
                  ) : (
                    <div className="row g-3">
                      {fields.length === 0 && (
                        <div className="col-12">
                          <FormattedMessage id="No social networks added" />
                        </div>
                      )}
                      {fields.map((item, index) => {
                        const currentItem = ssnn ? ssnn[index] : null;
                        return (
                          <div className="col-12" key={item?.id}>
                            <div className="row g-3">
                              <div className="col-3">
                                <Select
                                  options={socialNetworkList}
                                  defaultValue={socialNetworkList.find((item) =>
                                    ssnn
                                      ? item?.value === ssnn[index]?.name
                                      : null
                                  )}
                                  onChange={(e) => {
                                    setValue(
                                      `ssnn.${index}.name` as any,
                                      e?.value
                                    );
                                  }}
                                  placeholder={intl.formatMessage({
                                    id: "Select",
                                  })}
                                />
                              </div>
                              <div className="col-8">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={
                                    currentItem?.name === "whatsapp"
                                      ? intl.formatMessage({
                                          id: "Your phone number",
                                        })
                                      : intl.formatMessage({
                                          id: "Your profile link",
                                        })
                                  }
                                  {...register(`ssnn.${index}.link` as any, {
                                    required: true,
                                  })}
                                />
                                {errors.ssnn && errors.ssnn[index] && (
                                  <div className="invalid-feedback">
                                    <FormattedMessage id="This field is required." />
                                  </div>
                                )}
                              </div>
                              <div className="col-1">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-link text-danger"
                                  onClick={() => remove(index)}
                                >
                                  <i className="icon ni ni-trash"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row g-3">
            <div className="col-lg-7 offset-lg-5">
              <div className="form-group mt-2">
                <Authorize
                  actions={[Action.UPDATE]}
                  subjects={[BasePermission.Setting]}
                >
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-lg btn-primary"
                  >
                    <FormattedMessage id="Update" />
                  </button>
                </Authorize>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

export default SocialNetworkSetting;
