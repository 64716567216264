import { FormattedMessage } from "react-intl";

export const SkeletonRow: React.FC<{ size?: "sm" | "md" | "xs" | "lg" }> = ({
  size = "md",
}) => (
  <>
    <div className={`loading-skeleton ${size}`}>
      <div className="skeleton-row">
        <div className="block">
          <FormattedMessage id="Loading" />
        </div>{" "}
      </div>
    </div>
  </>
);
