import http from "../../clients/http";
import { serializeParams } from "../../utils/url";

type MediaGetListParams = {
  status: string | null;
  search: string | null;
  page: string | null;
  size: string | null;
};

export class MediaService {
  static async getUsage() {
    const response = await http.get("cms/media/usage");
    return response.data;
  }

  static async delete(id: string) {
    const response = await http.delete(`cms/media/${id}`);
    return response.data;
  }

  static async getList(filters: MediaGetListParams) {
    const response = await http.get(`cms/media?${serializeParams(filters)}`);
    const { data } = response;

    return {
      data: data.data,
      pagination: data.pagination,
    };
  }

  static async upload(formData: FormData) {
    const { data } = await http.post(`cms/media`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return data;
  }
}
