import { Navigate, Route, Routes } from "react-router-dom";

import { ProtectedRoute } from "../components/Auth/ProtectedRoute";
import Layout from "../containers/Layout";
import { AuthProvider, useUser } from "../contexts/auth";
import { usePermissions } from "../contexts/auth-permission";
import { PolicyPermission } from "../enums/permissions";
import Login from "./auth/login";
import { routes } from "./route";
import NotFound from "./meta-pages/not-found";
import { useEffect } from "react";
import { useClient } from "../hooks/client";
import { ClientProvider } from "../contexts/client.context";

const AuthenticatedRoutes = () => {
  const { ability } = usePermissions();
  const { user } = useUser();

  const client = useClient();

  useEffect(() => {
    document.title = `Shoppxy ${client.fullName ? `| ${client.fullName}` : ""}`;
  }, [client.fullName]);

  // Function to check if the user has the required permissions for a route
  const hasPermissions = (requiredPermissions: PolicyPermission[]) => {
    if (user?.groups?.find((g) => g.name === "admin")) return true;

    return ability.can("read", requiredPermissions[0]);
  };

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {routes.map(
          ({ component: Component, path, requiredPermissions }, index) => (
            <Route
              key={index}
              path={path}
              element={
                !requiredPermissions || hasPermissions(requiredPermissions) ? (
                  <Component />
                ) : (
                  <NotFound />
                )
              }
            />
          )
        )}
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

const Router = () => {
  return (
    <AuthProvider>
      <ClientProvider>
        <Routes>
          <Route path="/login" Component={Login} />
          <Route
            path="*"
            element={
              <ProtectedRoute>
                <AuthenticatedRoutes />
              </ProtectedRoute>
            }
          />
        </Routes>
      </ClientProvider>
    </AuthProvider>
  );
};

export default Router;
