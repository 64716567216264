import React, { useContext } from "react";
import { UseQueryResult } from "react-query";

export interface IProductDetails {
  name: string;
  code: string;
  description: string;
  stock: { current: number };
  mediaAssets: {
    id: string;
    thumbnail: string;
    url: string;
  }[];
  unitOfMeasurement: string;
  category: {
    name: string;
    attributes: {
      name: any;
      displayName: any;
      key: string;
      value: unknown;
      type: string;
    }[];
    taxes: {
      id: string;
      name: string;
      type: "fixed" | "percentage";
      value: number;
      amount: number;
    }[];
  };
  grossPrice: number;
  price: number;
  endPrice: number;
  attributes: Record<
    string,
    {
      id: string;
      _id: string;
      displayKey: string;
      displayValue: string;
      value: string;
    }
  >;
  modifiedBy: {
    names: string;
    lastNames: string;
    email: string;
  };
  parsedAttributes: Record<
    string,
    {
      displayKey: string;
      displayValue: string;
      label?: string;
      displayName: string;
      keyName: string;
      value: unknown;
    }
  >;
}

export const ProductDetailContext = React.createContext(
  {} as UseQueryResult<IProductDetails, unknown>
);

export const useProductDetailsContext = () => useContext(ProductDetailContext);
