import { useQuery } from "react-query";
import { LineCard } from "../../../components/Charts/Line";
import { transformToOrderStatus } from "../../../components/Table/transformers";
import {
  getAllDaysInCurrentMonth,
  formatCurrency,
  formatRelativeDate,
} from "../../../utils/formatter";
import { getThumbnailUrl } from "../../../utils/media";
import { getOrderDescription } from "../../../utils/product";
import { ProductService } from "../../../services/product";
import { Link, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const labels = getAllDaysInCurrentMonth();

export default function ProductSales() {
  const { id } = useParams() as { id: string };
  const salesQuery = useQuery(["productDetailsSales", id], () =>
    ProductService.getSales(id)
  );
  const statsQuery = useQuery(["productDetailsSalesStats", id], () =>
    ProductService.getSalesStats(id)
  );

  return (
    <div className="nk-block">
      <div className="mb-5">
        <LineCard
          data={statsQuery.data?.graphSource}
          labels={labels}
          title="Reporte"
          total={statsQuery.data?.total}
          /* growth={{ period: "week", value: 5.9 }} */
        />
      </div>

      <hr />
      <div className="card-title-group mt-5 mb-3">
        <div className="card-title">
          <h6 className="title">
            <FormattedMessage id="Recent Orders" />
          </h6>
        </div>
        <Link to={"/orders"}>
          <a className="btn-link">
            <FormattedMessage id="View all orders" />
          </a>
        </Link>
      </div>

      <div className="nk-tb-list nk-tb-ulist is-compact card">
        <div className="nk-tb-item nk-tb-head">
          <div className="nk-tb-col">
            <span className="sub-text">ID de Orden</span>
          </div>
          <div className="nk-tb-col tb-col-sm">
            <span className="sub-text">Descripción</span>
          </div>
          <div className="nk-tb-col tb-col-xxl">
            <span className="sub-text">Precio total</span>
          </div>
          <div className="nk-tb-col">
            <span className="sub-text">Estado</span>
          </div>
          <div className="nk-tb-col">
            <span className="sub-text">Entrega</span>
          </div>
        </div>
        {salesQuery.data?.orderItems?.map((o: any) => {
          const order = o.order;

          if (!order)
            return (
              <div className="nk-tb-item">
                <div className="nk-tb-col">No disponible</div>
              </div>
            );

          return (
            <div className="nk-tb-item" key={order.id}>
              <div className="nk-tb-col">
                <Link to={`/orders/${order.id}`}>
                  <span className="fw-bold">#{order.code}</span>
                </Link>
              </div>
              <div className="nk-tb-col tb-col-sm">
                <span className="tb-product">
                  <span className="title">
                    {order.description || getOrderDescription(order.items)}
                  </span>
                </span>
              </div>
              <div className="nk-tb-col tb-col-xxl">
                <span className="amount">{formatCurrency(order.total)}</span>
              </div>
              <div className="nk-tb-col">
                {transformToOrderStatus(order.status)}
              </div>
              <div className="nk-tb-col">
                <span className="sub-text">
                  {order.delivery
                    ? formatRelativeDate(new Date(order.delivery))
                    : " - "}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
