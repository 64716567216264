export const Status = {
  active: { class: "success", text: "Activo" },
  pending: { class: "warning", text: "Pendiente" },
  suspended: { class: "danger", text: "Suspendido" },
  blocked: { class: "danger", text: "Bloqueado" },
  inactive: { class: "info", text: "Inactivo" },
} as const;

export const ProductStatus = {
  active: { class: "success", text: "Activo" },
  inactive: { class: "info", text: "Inactivo" },
} as const;

export const OrderStatus = {
  created: { class: "info", text: "Creado" },
  delivered: { class: "success", text: "Entregado" },
  shipped: { class: "info", text: "Enviado" },
  canceled: { class: "danger", text: "Cancelado" },
  pending: { class: "warning", text: "Pendiente" },
} as const;

export const UserStatus = {
  active: { class: "success", text: "Activo" },
  blocked: { class: "danger", text: "Bloqueado" },
} as const;

export const AddonsStatus = {
  "waiting-for-config": { class: "warning", text: "Esperando configuración" },
  active: { class: "success", text: "Activo" },
  inactive: { class: "danger", text: "Inactivo" },
} as const;

export const SubscriptionPaymentStatus = {
  pending: {
    class: "light",
    text: "Pendiente",
    icon: "icon ni ni-clock",
    id: "Pending",
  },
  due: {
    class: "warning",
    text: "Vencido",
    icon: "icon ni ni-alert",
    id: "Due",
  },
  overdue: {
    class: "danger",
    text: "Atrasado",
    icon: "icon ni ni-report",
    id: "Overdue",
  },
  paid: {
    class: "success",
    text: "Pagado",
    icon: "icon ni ni-check-circle",
    id: "Paid",
  },
};
