import httpClient from "../clients/http";

type AddressType = "billing" | "shipping";

export interface CreateAddressDto {
  address: string;
  neighborhood: string;
  province: string;
  reference?: string;
  municipality: string;
  type: AddressType;
}

export interface AddressDto {
  id: string;
  address: string;
  neighborhood: string;
  province: string;
  reference?: string;
  municipality: string;
  type: AddressType;
}

export class AddressService {
  static async getCustomerAddresses(id: string) {
    const response = await httpClient.get(`/customers/${id}/addresses`);
    return response.data;
  }

  static async getAddress(id: string) {
    const response = await httpClient.get(`/addresses/${id}`);
    return response.data;
  }

  static async createAddress(formData: CreateAddressDto) {
    const response = await httpClient.post("/addresses", formData);
    return response.data;
  }

  static async deleteAddress(id: string) {
    const response = await httpClient.delete(`/addresses/${id}`);
    return response.data;
  }

  static async updateAddress(id: string, formData: AddressDto) {
    const response = await httpClient.put(`/addresses/${id}`, formData);
    return response.data;
  }
}
