import { useContext, ReactNode } from "react";
import { Spinner } from "react-bootstrap";
import { useFormatter } from "../../../hooks/formatter";
import { getInitials, getFullName } from "../../../utils/person";
import { useProductDetailsContext } from "./context";

export default function ProductInformation() {
  const { data, isLoading } = useProductDetailsContext();
  const { relativeDate, currency, date } = useFormatter();
  const {
    name,
    description,
    stock,
    unitOfMeasurement,
    category,
    price,
    parsedAttributes,
    attributes,
    modifiedBy,
  } = data || {};

  if (isLoading)
    return (
      <div
        style={{
          display: "flex",
          height: "40vh",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );

  const totalTaxes = category?.taxes?.reduce((acc, tax) => {
    if (tax.type === "percentage") {
      return acc + Number(price) * (tax.value / 100);
    }
    return acc + tax.value;
  }, 0);

  return (
    <section className="pt-3">
      <div className="row">
        <div className="col-sm-12 col-md-9 mb-4">
          <div className="fs-14px text-muted">Nombre del producto</div>
          <h3 className="product-title">{name}</h3>
        </div>
        <div className="col-sm-12 col-md-3">
          <div className="user-card mb-3">
            <div className="user-avatar xs bg-primary mb-1">
              <span>{modifiedBy ? getInitials(modifiedBy) : " - "}</span>
            </div>
            <div className="user-name ms-1">
              <span className="tb-lead">
                {modifiedBy ? getFullName(modifiedBy) : " - "}
              </span>
              {/*       <small
                title={date.format(new Date(modifiedBy?.updatedAt || ""))}
                className="d-block text-muted mb-1"
              >
                actualizó{" "}
                <b>
                  {relativeDate.formatRelative(
                    new Date(modifiedBy?.updatedAt || "").getTime(),
                    "days"
                  )}
                </b>
              </small> */}
            </div>
          </div>
        </div>
      </div>

      <div className="product-excrept fs-14px text-muted">Descripción</div>
      <p className="lead">{description}</p>

      <div className="product-meta">
        <ul className="d-flex g-3 gx-5">
          <li>
            <div className="fs-14px text-muted">Categoría</div>
            <div className="fs-16px fw-bold text-secondary">
              {category?.name}
            </div>
          </li>
          <li>
            <div className="fs-14px text-muted">Existencia</div>
            <div className="fs-16px fw-bold text-secondary">
              {stock?.current} {unitOfMeasurement}
            </div>
          </li>
        </ul>
      </div>

      <hr />

      <div className="row">
        <div className="col-lg-4">
          <div className="fs-14px text-muted">Precio bruto</div>
          <span className="fs-16px fw-bold text-secondary">
            {currency.format(price || 0)}
          </span>
          {category?.taxes?.length && (
            <div className="product-excrept fs-14px text-muted">
              Impuestos aplicados
            </div>
          )}

          {category?.taxes?.map((t, key) => (
            <span key={key}>
              <span className="fs-16px fw-bold text-secondary">{t.name}</span>{" "}
              {t.type === "percentage" ? `%${t.value}` : null} (
              <span className="text-muted">
                {currency.format(Number(price) * (t.value / 100))}
              </span>
              )
            </span>
          ))}

          <div className="product-excrept fs-14px text-muted">Precio final</div>
          <h4 className="product-price text-primary">
            {currency.format(Number(price) + Number(totalTaxes) || 0)}
          </h4>
        </div>

        {attributes && parsedAttributes && (
          <div className="col-lg-8">
            <div className="fs-14px text-muted">Atributos</div>
            <table className="table mt-2">
              <tbody>
                {Object.keys(attributes)?.map((k) => {
                  const field = parsedAttributes[k];

                  if (!field) return null;

                  return (
                    <tr key={k}>
                      <th className="ms-1 px-0" scope="row">
                        {field?.displayKey || k}
                      </th>
                      <td>
                        {field?.displayValue || (field.value as ReactNode)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </section>
  );
}
