import http from "../clients/http";
import { serializeParams } from "../utils/url";

type ProductGetListParams = {
  status: string | null;
  search: string | null;
  page: string | null;
  size: string | null;
};

export class WishListService {
  static async getList(filters: Partial<ProductGetListParams>) {
    const response = await http.get(`/wishlists?${serializeParams(filters)}`);
    const { data } = response;

    return {
      data: data.data,
      pagination: data.pagination,
    };
  }

  static async getDetails(customerId: string) {
    const response = await http.get(`/wishlists/${customerId}`);
    const { data } = response;
    return data;
  }
}
