import http from "../clients/http";
import { ITax } from "../interfaces/catalogs/tax";
import { serializeParams } from "../utils/url";

type TaxesGetListParams = {
  default: boolean | null;
  search: string | null;
  page: string | null;
  size: string | null;
};

export class TaxesService {
  static async getAll(
    params: Partial<TaxesGetListParams>
  ): Promise<{ data: ITax[] }> {
    const response = await http.get(`/taxes?${serializeParams(params)}`);
    const { data } = response;

    return data;
  }
}
