import { ReactNode } from "react";
import { usePermissions } from "../../contexts/auth-permission";
import { Action, PolicyPermission } from "../../enums/permissions";
import { useUser } from "../../contexts/auth";

interface AuthorizeProps {
  actions?: Action[];
  subjects: PolicyPermission[];
  children: ReactNode;
}

const Authorize = ({
  actions = [Action.READ],
  subjects,
  children,
}: AuthorizeProps) => {
  const { ability } = usePermissions();

  const isAdmin = !!useUser().user?.groups?.find((g) => g.name === "admin");

  const hasPermission =
    isAdmin ||
    subjects.some((subject) =>
      actions.every((action) => ability.can(action, subject))
    );

  return hasPermission ? <>{children}</> : null;
};

export default Authorize;
