import { ReactNode } from "react";
import {
  DropdownMenu,
  DropdownMenuOption,
  Dropdown,
} from "../Buttons/Dropdown";
import { ITableHeader } from "./interfaces";
import { useTableContext } from "./store";

export const Th: React.FC<{ children: ReactNode; className?: string }> = ({
  children,
  ...props
}) => {
  const { selected, setSelected, headers, bulkOptions, data, onSelected } =
    useTableContext();

  const ch = headers.find((h) => h.name === String(children));

  if (ch && ch.type === "tools" && bulkOptions) {
    return (
      <th {...props} className="nk-tb-col nk-tb-col-tools">
        <Dropdown
          disabled={!selected.length}
          icon
          trigger
          text={<em className="icon ni ni-more-h" />}
        >
          <DropdownMenu options={bulkOptions} entity={selected}></DropdownMenu>
        </Dropdown>
      </th>
    );
  }

  if (ch && ch.type === "checkbox") {
    return (
      <th {...props} className="nk-tb-col nk-tb-col-check">
        <div className="custom-control custom-control-sm custom-checkbox notext">
          <input
            type="checkbox"
            className="custom-control-input"
            id="uid"
            onChange={(e) => {
              const newValue = e.target.checked ? data.map((d) => d.id) : [];
              setSelected(newValue);
              if (onSelected) onSelected("all", newValue);
            }}
          />
          <label className="custom-control-label" htmlFor="uid"></label>
        </div>
      </th>
    );
  }

  return (
    <th {...props} className={`nk-tb-col ${ch?.hide ? "tb-col-md" : ""}`}>
      <span className="sub-text">{children}</span>
    </th>
  );
};

export const HeadCheck: React.FC<{
  value: string;
  onChange: React.InputHTMLAttributes<HTMLInputElement>["onChange"];
}> = ({ value, onChange }) => {
  const { selected } = useTableContext();
  return (
    <div
      key={value}
      className="custom-control custom-control-sm custom-checkbox notext"
    >
      <input
        type="checkbox"
        className="custom-control-input"
        value={value}
        id={`uid-${value}`}
        onChange={onChange}
        checked={selected.includes(String(value))}
      />
      <label className="custom-control-label" htmlFor={`uid-${value}`}></label>
    </div>
  );
};

export const Tr: React.FC<{ children: ReactNode }> = (
  { children, ...props } /// Remover tb-head for hover
) => {
  return (
    <tr {...props} className="nk-tb-item nk-tb-head">
      {children}
    </tr>
  );
};

export const Td: React.FC<{ children: ReactNode; className?: string }> = ({
  children,
  ...props
}) => {
  const { headers } = useTableContext();
  const ch = headers.find((h) => h.name === String(children));
  return (
    <td {...props} className={`nk-tb-col `}>
      {children}
    </td>
  );
};
