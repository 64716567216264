import http from "../clients/http";
import { serializeParams } from "../utils/url";

export class ProductCategoryService {
  static async getList(filters: any) {
    const response = await http.get(
      `products/categories?${serializeParams(filters)}`
    );
    const { data } = response;

    return {
      data: data.data,
      pagination: data.pagination,
    };
  }

  static async getDetails(id: string) {
    const response = await http.get(`products/categories/${id}`);
    const { data } = response;
    return data;
  }
}
