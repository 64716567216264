import React, { useEffect, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../contexts/auth";
import { AuthService } from "../../services/auth";

type LoginFormInputs = {
  email: string;
  password: string;
  newPassword?: string;
};

const LoginForm: React.FC = () => {
  const intl = useIntl();
  const auth = useAuth();

  const ErrorMap: Record<string, string> = {
    "Password is not valid":
      "Tus credenciales no son válidas. Verifica  e intenta nuevamente",
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const [wasNotified, setWasNotified] = useState(false);
  const noSession = searchParams.get("no_session");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (noSession === "1" && !wasNotified) {
      toast.warn("Se sesión ha expirado. Debe iniciar sesión");
      setSearchParams((s) => {
        s.set("no_session", "2");
        return s;
      });
    }
  }, []);

  const navigate = useNavigate();
  const [keyToChangePassword, setKeyToChangePassword] = useState<
    string | undefined
  >(undefined);

  const {
    handleSubmit,
    register,
    formState: { errors, isValid, isDirty },
  } = useForm<LoginFormInputs>({
    mode: "onChange",
  });

  const loginMutation = useMutation<
    {
      accessToken: string;
      user: any;
      refreshToken: string;
      expiryTime: number;
    },
    unknown,
    LoginFormInputs
  >(async (data) => AuthService.signIn(data, keyToChangePassword), {
    onError: (error: any) => {
      if (error.response.status === 428) return;
      const errMessage =
        ErrorMap[error.message] ||
        ErrorMap[error.response.data.message] ||
        "No fue posible iniciar sesión. Verifica tus credenciales e intenta nuevamente";
      toast.error(errMessage); // Display toast notification on login failure
    },
  });

  const onSubmit: SubmitHandler<LoginFormInputs> = async (data) => {
    try {
      const { accessToken, user, refreshToken, expiryTime } =
        await loginMutation.mutateAsync(data);
      await auth.login(user, accessToken, refreshToken, expiryTime);
      navigate("/");
    } catch (e: any) {
      if (e.response.status === 428) {
        setKeyToChangePassword(e.response.data.actionKey as string);
      }
      loginMutation.reset();
    }
  };

  return (
    <Form
      className="form-validate is-alter"
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Form.Group className="mb-3">
        <div className="form-label-group">
          <Form.Label htmlFor="email-address">
            <FormattedMessage id="Email or Username" />
          </Form.Label>
          <a
            className="link link-primary link-sm"
            tabIndex={-1}
            href="mailto:admin@chalascreations.com"
          >
            <FormattedMessage id="Need Help?" />
          </a>
        </div>
        <div className="form-control-wrap">
          <Form.Control
            autoComplete="off"
            type="text"
            size="lg"
            isInvalid={Boolean(errors.email)}
            id="email-address"
            readOnly={Boolean(keyToChangePassword)}
            placeholder={intl.formatMessage({ id: "Email or Username" })}
            spellCheck="false"
            {...register("email", {
              required: intl.formatMessage(
                { id: "F is required" },
                { F: intl.formatMessage({ id: "Email or Username" }) }
              ),
            })}
          />
          {errors.email && (
            <Form.Control.Feedback type="invalid">
              {errors.email.message}
            </Form.Control.Feedback>
          )}
        </div>
      </Form.Group>

      <Form.Group className="mb-3">
        <div className="form-label-group">
          <Form.Label htmlFor="password">
            <FormattedMessage id="Password" />
          </Form.Label>
          {/*  <a
            className="link link-primary link-sm"
            tabIndex={-1}
            href="/demo2/pages/auths/auth-reset.html"
          >
            Forgot Code?
          </a> */}
        </div>
        <div className="form-control-wrap">
          <a
            role="button"
            tabIndex={-1}
            onClick={() => setShowPassword((s) => !s)}
            className="form-icon form-icon-right passcode-switch lg"
            data-target="password"
          >
            {!showPassword ? (
              <em className="passcode-icon icon-show icon ni ni-eye" />
            ) : (
              <em className="passcode-icon icon-show icon ni ni-eye-off" />
            )}
          </a>
          <Form.Control
            autoComplete="password"
            type={showPassword ? "text" : "password"}
            size="lg"
            isInvalid={Boolean(errors.password)}
            id="password"
            placeholder={intl.formatMessage({ id: "Enter your password" })}
            {...register("password", {
              required: intl.formatMessage(
                { id: "F is required" },
                { F: intl.formatMessage({ id: "Password" }) }
              ),
            })}
          />
          {errors.password && (
            <Form.Control.Feedback type="invalid">
              {errors.password.message}
            </Form.Control.Feedback>
          )}
        </div>
      </Form.Group>

      {keyToChangePassword && (
        <Form.Group className="mb-3">
          <div className="form-label-group">
            <Form.Label htmlFor="password">
              <FormattedMessage id="New Password" />
            </Form.Label>
            {/*  <a
          className="link link-primary link-sm"
          tabIndex={-1}
          href="/demo2/pages/auths/auth-reset.html"
        >
          Forgot Code?
        </a> */}
          </div>
          <div className="form-control-wrap">
            <a
              role="button"
              tabIndex={-1}
              onClick={() => setShowPassword((s) => !s)}
              className="form-icon form-icon-right passcode-switch lg"
              data-target="password"
            >
              {!showPassword ? (
                <em className="passcode-icon icon-show icon ni ni-eye" />
              ) : (
                <em className="passcode-icon icon-show icon ni ni-eye-off" />
              )}
            </a>
            <Form.Control
              autoComplete="new-password"
              type={showPassword ? "text" : "password"}
              size="lg"
              isInvalid={Boolean(errors.newPassword)}
              id="password"
              placeholder={intl.formatMessage({
                id: "Enter your new password",
              })}
              {...register("newPassword", {
                minLength: keyToChangePassword
                  ? {
                      value: 8,
                      message: intl.formatMessage(
                        { id: "F must be at least 8 characters" },
                        { F: intl.formatMessage({ id: "Password" }) }
                      ),
                    }
                  : undefined,
                pattern: keyToChangePassword
                  ? {
                      value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
                      message: intl.formatMessage(
                        {
                          id: "F must be at least 8 characters and contain at least one number and one letter",
                        },
                        { F: intl.formatMessage({ id: "Password" }) }
                      ),
                    }
                  : undefined,
                required: keyToChangePassword
                  ? intl.formatMessage(
                      { id: "F is required" },
                      { F: intl.formatMessage({ id: "Password" }) }
                    )
                  : undefined,
              })}
            />
            {errors.newPassword && (
              <Form.Control.Feedback type="invalid">
                {errors.newPassword.message}
              </Form.Control.Feedback>
            )}
          </div>
        </Form.Group>
      )}

      <Form.Group>
        <Button
          variant="primary"
          type="submit"
          size="lg"
          className="btn-lg btn-block"
          disabled={loginMutation.isLoading || !isValid}
        >
          <FormattedMessage id="Sign In" />

          {loginMutation.isLoading && <Spinner size="sm" />}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default LoginForm;
