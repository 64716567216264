export const InventoryType = {
  entry: {
    class: "success",
    text: "Entrada",
    icon: "icon ni ni-arrow-right-round",
  },
  exit: {
    class: "danger",
    text: "Salida",
    icon: "icon ni ni-arrow-left-round",
  },
} as const;

export const AddressType = {
  shipping: {
    class: "success",
    text: "address.Billing",
    icon: "icon ni ni-money",
  },
  billing: {
    class: "info",
    text: "address.Shipping",
    icon: "icon ni ni-truck",
  },
} as const;
