import http, { apiUrl } from "../../clients/http";
import { serializeParams } from "../../utils/url";

type FormGetListParams = {
  status: string | null;
  search: string | null;
  page: string | null;
  size: string | null;
};

export class FormService {
  static async getList(filters: Partial<FormGetListParams>) {
    const response = await http.get(
      `${apiUrl}/cms/forms?${serializeParams(filters)}`
    );
    const { data } = response;

    return {
      data: data.data,
      pagination: data.pagination,
    };
  }

  static async getDetails(key: string, id: string) {
    const { data } = await http.get(`/cms/forms/${key}/${id}`);
    return data;
  }

  static async updateContent(
    key: string,
    id: string,
    content: Record<string, unknown>
  ) {
    const { data } = await http.put(`/cms/forms/${key}/${id}`, { content });
    return data;
  }

  static async getFormList(key: string, filters: FormGetListParams) {
    const response = await http.get(
      `${apiUrl}/cms/forms/${key}?${serializeParams(filters)}`
    );
    const { data } = response;

    return {
      data: data.data,
      pagination: data.pagination,
    };
  }

  static async exportComments(formName: string) {
    const response = await http.get(`${apiUrl}/cms/forms/${formName}/export`, {
      responseType: "blob",
    });
    const blob = new Blob([response.data], {
      type: "application/octet-stream",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${formName}-export.csv`);
    link.click();
    URL.revokeObjectURL(url);
  }
}
