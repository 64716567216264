import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import LoginForm from "../../components/Auth/LoginForm";
import { useClient } from "../../hooks/client";

const fallbackSrc = "logo.svg";

function Login() {
  const client = useClient();

  useEffect(() => {
    document.title = `Shoppxy ${client.fullName ? `| ${client.fullName}` : ""}`;
  }, [client.fullName]);

  const [imgSrc, set_imgSrc] = useState(client.logo || fallbackSrc);

  const { key } = client;

  return (
    <div className="nk-app-root">
      <div className="nk-main ">
        <div className="nk-wrap nk-wrap-nosidebar">
          <div className="nk-content ">
            <div className="nk-split nk-split-page nk-split-lg">
              <div className="nk-split-content nk-block-area nk-block-area-column nk-auth-container bg-white">
                <div className="absolute-top-right d-lg-none p-3 p-sm-5">
                  <a
                    href="#"
                    className="toggle btn-white btn btn-icon btn-light"
                    data-target="athPromo"
                  >
                    <em className="icon ni ni-info" />
                  </a>
                </div>
                <div className="nk-block nk-block-middle nk-auth-body">
                  <div className="brand-logo pb-5">
                    <a href="/" className="logo-link d-block">
                      <img
                        src={imgSrc}
                        className="logo-img logo-img-lg"
                        style={{
                          filter:
                            "invert(0.5) sepia(1) saturate(53) hue-rotate(235deg)",
                        }}
                        alt="logo"
                        onError={() => {
                          set_imgSrc(fallbackSrc);
                        }}
                      />
                      <img />
                    </a>
                    <span
                      className="text-muted"
                      style={{
                        fontSize: "smaller",
                        fontWeight: 600,
                      }}
                    >
                      Shoppxy.com
                    </span>
                  </div>

                  {key ? (
                    <>
                      <div className="nk-block-head">
                        <div className="nk-block-head-content">
                          <h5 className="nk-block-title">
                            <FormattedMessage id="Sign In" />
                          </h5>
                          <div className="nk-block-des">
                            <p>
                              <FormattedMessage id="Access the platform panel using your email and password." />
                            </p>
                          </div>
                        </div>
                      </div>
                      <LoginForm />
                    </>
                  ) : (
                    <div>
                      <h3>
                        <FormattedMessage id="You have to be part of an organization to login" />
                      </h3>
                      <p>
                        <FormattedMessage id="Please contact your organization admin to get the right link" />
                      </p>
                    </div>
                  )}
                  {/* <div className="form-note-s2 pt-4">
                    {" "}
                    New on our platform?{" "}
                    <a href="/demo2/pages/auths/auth-register.html">
                      Create an account
                    </a>
                  </div> */}
                  {/*  <div className="text-center pt-4 pb-3">
                    <h6 className="overline-title overline-title-sap">
                      <span>OR</span>
                    </h6>
                  </div>
                  <ul className="nav justify-center gx-4">
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        Facebook
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        Google
                      </a>
                    </li>
                  </ul> */}
                  {/* <div className="text-center mt-5">
                    <span className="fw-500">
                      I don't have an account? <a href="#">Try 15 days free</a>
                    </span>
                  </div> */}
                </div>
                <div className="nk-block nk-auth-footer">
                  <div className="nk-block-between">
                    <ul className="nav nav-sm">
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          <FormattedMessage id="Terms & Condition" />
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          <FormattedMessage id="Privacy Policy" />
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          <FormattedMessage id="Help" />
                        </a>
                      </li>
                      {/*    <li className="nav-item dropup active current-page">
                        <a
                          className="dropdown-toggle dropdown-indicator has-indicator nav-link"
                          data-bs-toggle="dropdown"
                          data-offset="0,10"
                        >
                          <small>English</small>
                        </a>
                        <div className="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                          <ul className="language-list">
                            <li>
                              <a href="#" className="language-item">
                                <img
                                  src="https://dashlite.net/demo2/images/flags/english.png"
                                  alt=""
                                  className="language-flag"
                                />
                                <span className="language-name">English</span>
                              </a>
                            </li>
                            <li>
                              <a href="#" className="language-item">
                                <img
                                  src="https://dashlite.net/demo2/images/flags/spanish.png"
                                  alt=""
                                  className="language-flag"
                                />
                                <span className="language-name">Español</span>
                              </a>
                            </li>
                            <li>
                              <a href="#" className="language-item">
                                <img
                                  src="https://dashlite.net/demo2/images/flags/french.png"
                                  alt=""
                                  className="language-flag"
                                />
                                <span className="language-name">Français</span>
                              </a>
                            </li>
                            <li>
                              <a href="#" className="language-item">
                                <img
                                  src="https://dashlite.net/demo2/images/flags/turkey.png"
                                  alt=""
                                  className="language-flag"
                                />
                                <span className="language-name">Türkçe</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li> */}
                    </ul>
                  </div>
                  <div className="mt-3">
                    <p>
                      © {new Date().getFullYear()} Shoppxy.
                      <FormattedMessage id="All Rights Reserved" />.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function PromoSlider() {
  return (
    <div
      className="nk-split-content nk-split-stretch bg-lighter d-flex toggle-break-lg toggle-slide toggle-slide-right toggle-screen-lg"
      data-toggle-body="true"
      data-content="athPromo"
      data-toggle-screen="lg"
      data-toggle-overlay="true"
    >
      <div className="slider-wrap w-100 w-max-550px p-3 p-sm-5 m-auto">
        <div
          className="slider-init slick-initialized slick-slider slick-dotted"
          data-slick='{"dots":true, "arrows":false}'
        >
          <div className="slick-list draggable">
            <div
              className="slick-track"
              style={{
                opacity: 1,
                width: "3234px",
                transform: "translate3d(-462px, 0px, 0px)",
              }}
            >
              <div
                className="slider-item slick-slide slick-cloned"
                data-slick-index={-1}
                aria-hidden="true"
                style={{ width: "462px" }}
                tabIndex={-1}
              >
                <div className="nk-feature nk-feature-center">
                  <div className="nk-feature-img">
                    <img
                      className="round"
                      src="https://dashlite.net/demo2/images/slides/promo-c.png"
                      srcSet="https://dashlite.net/demo2/images/slides/promo-c2x.png 2x"
                      alt=""
                    />
                  </div>
                  <div className="nk-feature-content py-4 p-sm-5">
                    <h4>Shoppxy</h4>
                    <p>
                      You can start to create your products easily with its
                      user-friendly design &amp; most completed responsive
                      layout.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="slider-item slick-slide slick-current slick-active"
                data-slick-index={0}
                aria-hidden="false"
                style={{ width: "462px" }}
                tabIndex={0}
                role="tabpanel"
                id="slick-slide00"
                aria-describedby="slick-slide-control00"
              >
                <div className="nk-feature nk-feature-center">
                  <div className="nk-feature-img">
                    <img
                      className="round"
                      src="https://dashlite.net/demo2/images/slides/promo-a.png"
                      srcSet="https://dashlite.net/demo2/images/slides/promo-a2x.png 2x"
                      alt=""
                    />
                  </div>
                  <div className="nk-feature-content py-4 p-sm-5">
                    <h4>Shoppxy</h4>
                    <p>
                      You can start to create your products easily with its
                      user-friendly design &amp; most completed responsive
                      layout.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="slider-item slick-slide"
                data-slick-index={1}
                aria-hidden="true"
                style={{ width: "462px" }}
                tabIndex={-1}
                role="tabpanel"
                id="slick-slide01"
                aria-describedby="slick-slide-control01"
              >
                <div className="nk-feature nk-feature-center">
                  <div className="nk-feature-img">
                    <img
                      className="round"
                      src="https://dashlite.net/demo2/images/slides/promo-b.png"
                      srcSet="https://dashlite.net/demo2/images/slides/promo-b2x.png 2x"
                      alt=""
                    />
                  </div>
                  <div className="nk-feature-content py-4 p-sm-5">
                    <h4>Shoppxy</h4>
                    <p>
                      You can start to create your products easily with its
                      user-friendly design &amp; most completed responsive
                      layout.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="slider-item slick-slide"
                data-slick-index={2}
                aria-hidden="true"
                style={{ width: "462px" }}
                tabIndex={-1}
                role="tabpanel"
                id="slick-slide02"
                aria-describedby="slick-slide-control02"
              >
                <div className="nk-feature nk-feature-center">
                  <div className="nk-feature-img">
                    <img
                      className="round"
                      src="https://dashlite.net/demo2/images/slides/promo-c.png"
                      srcSet="https://dashlite.net/demo2/images/slides/promo-c2x.png 2x"
                      alt=""
                    />
                  </div>
                  <div className="nk-feature-content py-4 p-sm-5">
                    <h4>Shoppxy</h4>
                    <p>
                      You can start to create your products easily with its
                      user-friendly design &amp; most completed responsive
                      layout.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="slider-item slick-slide slick-cloned"
                data-slick-index={3}
                aria-hidden="true"
                style={{ width: "462px" }}
                tabIndex={-1}
              >
                <div className="nk-feature nk-feature-center">
                  <div className="nk-feature-img">
                    <img
                      className="round"
                      src="https://dashlite.net/demo2/images/slides/promo-a.png"
                      srcSet="https://dashlite.net/demo2/images/slides/promo-a2x.png 2x"
                      alt=""
                    />
                  </div>
                  <div className="nk-feature-content py-4 p-sm-5">
                    <h4>Shoppxy</h4>
                    <p>
                      You can start to create your products easily with its
                      user-friendly design &amp; most completed responsive
                      layout.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="slider-item slick-slide slick-cloned"
                data-slick-index={4}
                aria-hidden="true"
                style={{ width: "462px" }}
                tabIndex={-1}
              >
                <div className="nk-feature nk-feature-center">
                  <div className="nk-feature-img">
                    <img
                      className="round"
                      src="https://dashlite.net/demo2/images/slides/promo-b.png"
                      srcSet="https://dashlite.net/demo2/images/slides/promo-b2x.png 2x"
                      alt=""
                    />
                  </div>
                  <div className="nk-feature-content py-4 p-sm-5">
                    <h4>Shoppxy</h4>
                    <p>
                      You can start to create your products easily with its
                      user-friendly design &amp; most completed responsive
                      layout.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="slider-item slick-slide slick-cloned"
                data-slick-index={5}
                aria-hidden="true"
                style={{ width: "462px" }}
                tabIndex={-1}
              >
                <div className="nk-feature nk-feature-center">
                  <div className="nk-feature-img">
                    <img
                      className="round"
                      src="https://dashlite.net/demo2/images/slides/promo-c.png"
                      srcSet="https://dashlite.net/demo2/images/slides/promo-c2x.png 2x"
                      alt=""
                    />
                  </div>
                  <div className="nk-feature-content py-4 p-sm-5">
                    <h4>Shoppxy</h4>
                    <p>
                      You can start to create your products easily with its
                      user-friendly design &amp; most completed responsive
                      layout.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ul className="slick-dots" style={{}} role="tablist">
            <li className="slick-active" role="presentation">
              <button
                type="button"
                role="tab"
                id="slick-slide-control00"
                aria-controls="slick-slide00"
                aria-label="1 of 3"
                tabIndex={0}
                aria-selected="true"
              >
                1
              </button>
            </li>
            <li role="presentation">
              <button
                type="button"
                role="tab"
                id="slick-slide-control01"
                aria-controls="slick-slide01"
                aria-label="2 of 3"
                tabIndex={-1}
              >
                2
              </button>
            </li>
            <li role="presentation">
              <button
                type="button"
                role="tab"
                id="slick-slide-control02"
                aria-controls="slick-slide02"
                aria-label="3 of 3"
                tabIndex={-1}
              >
                3
              </button>
            </li>
          </ul>
        </div>
        <div className="slider-dots" />
        <div className="slider-arrows" />
      </div>
    </div>
  );
}

export default Login;
