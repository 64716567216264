import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

import Dropdown, {
  DropdownMenu,
  DropdownMenuOption,
} from "../../components/Buttons/Dropdown";
import SearchBar from "../../components/Inputs/SearchBar";
import Table, { IPaginationProps, ITableHeader } from "../../components/Table";
import Title from "../../components/Title";
import { WishListService } from "../../services/wish-list";
import { formatCurrency } from "../../utils/formatter";
import { useTableQueryParams } from "../../hooks/router";
import { Button } from "react-bootstrap";

let timerId: any;

function WishlistTab() {
  const intl = useIntl();
  const { id } = useParams();
  const { page, search, setParam, size } = useTableQueryParams();

  const options: DropdownMenuOption<any>[] = [
    {
      text: intl.formatMessage({ id: "See detail" }),
      icon: "ni ni-eye",
      link: (entity) => `/products/${entity?.id}?t=info`,
    },
    // {
    //   type: "divider",
    // },
    // {
    //   icon: "ni ni-shield-star",
    //   text: intl.formatMessage({ id: "Change Status" }),
    //   link: (entity) => `/products/${entity?.products?.id}`,
    // },
    // {
    //   icon: "ni ni-na",
    //   text: intl.formatMessage({ id: "ACTIONS.CANCEL" }),
    //   link: (entity) => `/products/${entity?.products?.id}`,
    // },
  ];

  const { data, isLoading } = useQuery(
    ["wishlistList", search, page, size],
    () => {
      return WishListService.getDetails(id as string);
    }
  );

  const headers: ITableHeader[] = [
    {
      path: ["name"],
      name: intl.formatMessage({ id: "Product" }),
      className: "lg-table-col",
      transform: (value, obj) => {
        return (
          <Link to={`/products/${obj?.id}?t=info`}>
            <Button as="span" variant="link" className="tb-product">
              {obj.mediaAssets?.length ? (
                <img
                  src={obj.mediaAssets[0].thumbnail || obj.mediaAssets[0]?.url}
                  alt={`image-${value}`}
                  className="thumb"
                />
              ) : null}
              <span className="title text-primary">{value}</span>
            </Button>
          </Link>
        );
      },
    },
    {
      path: ["price"],
      name: intl.formatMessage({ id: "Price" }),
      transform: formatCurrency,
    },
    {
      path: ["category", "name"],
      name: intl.formatMessage({ id: "Categories" }),
    },
    {
      path: ["options"],
      name: "",
      type: "tools",
      transform: (v, entity) => (
        <Dropdown
          key={entity?.id}
          icon
          trigger
          text={<em className="icon ni ni-more-h" />}
        >
          <DropdownMenu options={options} entity={entity} />
        </Dropdown>
      ),
    },
  ];

  const pagination: IPaginationProps = {
    current: Number(page) || data?.pagination?.page,
    size: Number(size) || data?.pagination?.size,
    total: data?.pagination?.total,
    onPageChange(page) {
      setParam("page", String(page));
    },
    onSizeChange(size) {
      setParam("size", String(size));
    },
  };

  const onSearch = (term: string) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      setParam("search", term);
    }, Number(process.env.REACT_APP_SEARCH_DELAY || 700));
  };

  const tableData = data?.data[0]?.products || [];

  return (
    <div className="container-fluid">
      <div className="nk-content-inner">
        <div className="nk-content-body">
          <Title
            subText={intl.formatMessage(
              { id: "You have a total of N products in your wishlist." },
              { number: data?.pagination?.total || 0 }
            )}
            rightContent={
              <ul className="nk-block-tools g-3">
                <li>
                  <SearchBar onChange={(e) => onSearch(e.target.value)} />
                </li>
              </ul>
            }
          >
            <FormattedMessage id="Wishlist" />
          </Title>

          <div className="nk-block">
            <Table
              headers={headers}
              data={tableData}
              /*  selectable */
              loading={isLoading}
              /*  bulkOptions={[
                {
                  icon: "ni ni-na",
                  text: intl.formatMessage({ id: "Disable all" }),
                },
                {
                  icon: "ni ni-trash",
                  text: intl.formatMessage({ id: "Delete all" }),
                },
                {
                  icon: "ni ni-shield-star",
                  text: intl.formatMessage({ id: "Reset all" }),
                },
              ]} */
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default WishlistTab;
