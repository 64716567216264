import { useContext, useState, useRef, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import Slider, { Settings } from "react-slick";
import { useProductDetailsContext } from "./context";
import { FormattedMessage } from "react-intl";
import LazyImage from "../../../components/Image/LazyImage";

export default function ProductImageGallery() {
  const { data, isLoading } = useProductDetailsContext();

  const { mediaAssets } = data || {};
  const [img, setImg] = useState<any>(null);

  console.log("images", mediaAssets);

  useEffect(() => {
    if (!img && mediaAssets) setImg(mediaAssets[0]);
  }, [mediaAssets]);

  return (
    <div className="card h-100">
      <div className="card-inner-group">
        <div className="card-inner">
          <div className="m-auto">
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  height: "40vh",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner />
              </div>
            ) : !isLoading && !img ? (
              <div
                style={{
                  display: "flex",
                  height: "40vh",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FormattedMessage id="No image to display" />
              </div>
            ) : (
              <div>
                <div className="d-block product-gallery border-3">
                  <LazyImage
                    src={img?.url}
                    className="rounded w-100"
                    alt={img.name}
                  />
                </div>
                <div className="d-flex justify-around">
                  {mediaAssets?.map((thumbnailImg, i) => (
                    <div
                      key={i}
                      style={{ maxWidth: "100px" }}
                      tabIndex={-1}
                      aria-hidden="true"
                      className="mr-2"
                      onClick={() => setImg(thumbnailImg)}
                    >
                      <div
                        role="button"
                        className={`thumb rounded border border-2 ${
                          img.id === thumbnailImg.id ? "border-primary" : ""
                        }`}
                      >
                        <img
                          src={thumbnailImg.thumbnail || thumbnailImg.url}
                          className="rounded"
                          alt=""
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function ProductImageGalleryWithSlider() {
  const { data, isLoading } = useProductDetailsContext();

  const { mediaAssets } = data || {};

  const [{ mainSlider, subSlider }, setRefs] = useState<{
    mainSlider: Slider | null;
    subSlider: Slider | null;
  }>({
    mainSlider: null,
    subSlider: null,
  });

  const mainSliderRef = useRef(null);
  const subSliderRef = useRef(null);

  useEffect(() => {
    setRefs({
      mainSlider: mainSliderRef.current,
      subSlider: subSliderRef.current,
    });
  }, []);

  const settings: Settings = {
    arrows: false,
    fade: true,
    initialSlide: 1,
    asNavFor: subSlider as Slider,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const subSetting: Settings = {
    arrows: false,
    slidesToShow:
      mediaAssets && mediaAssets?.length > 4 ? 4 : mediaAssets?.length,
    slidesToScroll: 1,
    asNavFor: mainSlider as Slider,
    centerMode: true,
    focusOnSelect: true,
    draggable: true,
    responsive: [
      { breakpoint: 1539, settings: { slidesToShow: 3 } },
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 420, settings: { slidesToShow: 1 } },
    ],
  };
  return (
    <div className="card h-100">
      <div className="card-inner-group">
        <div className="card-inner">
          <div className="m-auto">
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  height: "40vh",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner />
              </div>
            ) : (
              <>
                <Slider
                  className="slider-init product-gallery slick-initialized slick-slider slick-list"
                  ref={mainSliderRef}
                  {...settings}
                >
                  {mediaAssets?.map((img, i) => (
                    <div
                      key={i}
                      className="slider-item rounded slick-slide"
                      aria-hidden="true"
                      style={{
                        width: "433px",
                        position: "relative",
                        left: "0px",
                        top: "0px",
                        zIndex: 998,
                        opacity: 0,
                        transition: "opacity 500ms ease 0s",
                      }}
                      tabIndex={-1}
                    >
                      <img src={img.url} className="rounded w-100" alt="" />
                    </div>
                  ))}
                </Slider>
                {mediaAssets && mediaAssets.length > 1 ? (
                  <Slider {...subSetting}>
                    {mediaAssets?.map((img, i) => (
                      <div
                        key={i}
                        style={{ width: "50px" }}
                        tabIndex={-1}
                        aria-hidden="true"
                      >
                        <div role="button" className="thumb mx-2 border">
                          <img
                            src={img.thumbnail || img.url}
                            className="rounded"
                            alt=""
                          />
                        </div>
                      </div>
                    ))}
                  </Slider>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
