import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "react-query";
import { OrderService } from "../../services/order";
import { Spinner } from "react-bootstrap";
import { useFormatter } from "../../hooks/formatter";
import { Link } from "react-router-dom";
import { getInitials, getFullName } from "../../utils/person";
import { formatCurrency, formatMidDate } from "../../utils/formatter";
import { transformToOrderStatus } from "../../components/Table/transformers";
import {
  AverageOrderChart,
  TotalCustomerChart,
  TotalOrderChart,
  TotalSalesChart,
} from "../../components/Graphs/Ecommerce/Sales";

function Dashboard() {
  const intl = useIntl();
  const { currency } = useFormatter();

  const { data, isLoading } = useQuery(
    ["orderStats"],
    () => OrderService.getAllStats(),
    {
      cacheTime: 1000 * 60 * 60 * 24,
    }
  );

  return (
    <div className="container-fluid">
      <div className="nk-content-inner">
        <div className="nk-content-body">
          <div className="nk-block-head-content mb-5">
            <h3 className="nk-block-title page-title">
              <FormattedMessage id="Ecommerce Analytics Dashboard" />
            </h3>
            <div className="nk-block-des text-soft">
              <p>
                <FormattedMessage id="Welcome to Ecommerce Analytics Dashboard" />
              </p>
            </div>
          </div>
          {/* .nk-block-head */}
          <div className="nk-block">
            <div className="row g-gs">
              <div className="col-xxl-4 col-md-6">
                <div className="card is-dark h-100">
                  <div className="nk-ecwg nk-ecwg1">
                    <div className="card-inner">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h6 className="title">
                            <FormattedMessage id="Total Sales" />
                          </h6>
                        </div>
                        {/*  <div className="card-tools">
                          <a href="#" className="link">
                            <FormattedMessage id="View Report" />
                          </a>
                        </div> */}
                      </div>
                      {isLoading ? (
                        <div className="data">
                          <Spinner />
                        </div>
                      ) : (
                        <div className="data">
                          <div className="amount">
                            {currency.format(data.revenue?.allTime)}
                          </div>
                          <div className="info">
                            <strong>
                              {currency.format(data.revenue?.month)}
                            </strong>{" "}
                            <FormattedMessage id="in last month" />
                          </div>
                        </div>
                      )}
                      <div className="data">
                        <h6 className="sub-title">
                          <FormattedMessage id="This week so far" />
                        </h6>
                        {isLoading ? (
                          <div className="data-group">
                            <Spinner />
                          </div>
                        ) : (
                          <div className="data-group">
                            <div className="amount">
                              {currency.format(data.revenue.week)}
                            </div>
                            {/* <div className="info text-end">
                              <span className="change up text-danger">
                                <em className="icon ni ni-arrow-long-up" />
                                4.63%
                              </span>
                              <br />
                              <span>
                                vs. <FormattedMessage id="last week" />
                              </span>
                            </div> */}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="nk-ck-wrap mt-auto overflow-hidden rounded-bottom">
                      <div
                        className="nk-ecwg1-ck"
                        style={{
                          margin: -6,
                        }}
                      >
                        <TotalSalesChart />
                      </div>
                    </div>
                    {/* .card-inner */}
                    {/* <div className="nk-ecwg1-ck">
                      <div className="chartjs-size-monitor">
                        <div className="chartjs-size-monitor-expand">
                          <div />
                        </div>
                        <div className="chartjs-size-monitor-shrink">
                          <div />
                        </div>
                      </div>
                      <canvas
                        className="ecommerce-line-chart-s1 chartjs-render-monitor"
                        id="totalSales"
                        style={{
                          display: "block",
                          width: "395px",
                          height: "110px",
                        }}
                        width={395}
                        height={110}
                      />
                    </div> */}
                  </div>
                  {/* .nk-ecwg */}
                </div>
                {/* .card */}
              </div>
              {/* .col */}
              <div className="col-xxl-4 col-md-6">
                <div className="card h-100">
                  <div className="nk-ecwg nk-ecwg2">
                    <div className="card-inner">
                      <div className="card-title-group mt-n1">
                        <div className="card-title">
                          <h6 className="title">
                            <FormattedMessage id="Averarge order" />
                          </h6>
                        </div>
                        {/* <div className="card-tools me-n1">
                          <div className="dropdown">
                            <a
                              href="#"
                              className="dropdown-toggle btn btn-icon btn-trigger"
                              data-bs-toggle="dropdown"
                            >
                              <em className="icon ni ni-more-h" />
                            </a>
                            <div className="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <a href="#" className="active">
                                    <span>
                                      15 <FormattedMessage id="Days" />
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <span>
                                      30 <FormattedMessage id="Days" />
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <span>
                                      3 <FormattedMessage id="Months" />
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <div className="data">
                        {isLoading ? (
                          <div className="data-group">
                            <Spinner />
                          </div>
                        ) : (
                          <div className="data-group">
                            <div className="amount">
                              {currency.format(data.avg.order.current)}
                            </div>
                            {/* <div className="info text-end">
                              <span className="change up text-danger">
                                <em className="icon ni ni-arrow-long-up" />
                                4.63%
                              </span>
                              <br />
                              <span>
                                vs. <FormattedMessage id="last week" />
                              </span>
                            </div> */}
                            <br />
                          </div>
                        )}
                      </div>
                      <h6 className="sub-title">
                        <FormattedMessage id="Orders over time" />
                      </h6>
                    </div>
                    <div className="nk-ecwg2-ck">
                      <AverageOrderChart />
                    </div>
                    {/* .card-inner */}
                    {/* <div className="nk-ecwg2-ck">
                      <div className="chartjs-size-monitor">
                        <div className="chartjs-size-monitor-expand">
                          <div />
                        </div>
                        <div className="chartjs-size-monitor-shrink">
                          <div />
                        </div>
                      </div>
                      <canvas
                        className="ecommerce-bar-chart-s1 chartjs-render-monitor"
                        id="averargeOrder"
                        width={395}
                        height={182}
                        style={{
                          display: "block",
                          width: "395px",
                          height: "182px",
                        }}
                      />
                    </div> */}
                  </div>
                  {/* .nk-ecwg */}
                </div>
                {/* .card */}
              </div>
              {/* .col */}
              <div className="col-xxl-4">
                <div className="row g-gs">
                  <div className="col-xxl-12 col-md-6">
                    <div className="card">
                      <div className="nk-ecwg nk-ecwg3">
                        <div className="card-inner pb-0">
                          <div className="card-title-group">
                            <div className="card-title">
                              <h6 className="title">
                                <FormattedMessage id="Orders" />
                              </h6>
                            </div>
                          </div>
                          <div className="data">
                            {isLoading ? (
                              <div className="data-group">
                                <Spinner />
                              </div>
                            ) : (
                              <div className="data-group">
                                <div className="amount">
                                  {data.totals.orders.current}
                                </div>
                                {/*  <div className="info text-end">
                                <span className="change up text-danger">
                                  <em className="icon ni ni-arrow-long-up" />
                                  4.63%
                                </span>
                                <br />
                                <span>
                                  vs. <FormattedMessage id="last week" />
                                </span>
                              </div> */}
                              </div>
                            )}
                          </div>
                        </div>
                        {/* .card-inner */}
                        <div className="nk-ecwg3-ck">
                          <TotalOrderChart />
                        </div>
                      </div>
                      {/* .nk-ecwg */}
                    </div>
                    {/* .card */}
                  </div>
                  {/* .col */}
                  <div className="col-xxl-12 col-md-6">
                    <div className="card">
                      <div className="nk-ecwg nk-ecwg3">
                        <div className="card-inner pb-0">
                          <div className="card-title-group">
                            <div className="card-title">
                              <h6 className="title">
                                <FormattedMessage id="Customers" />
                              </h6>
                            </div>
                          </div>
                          <div className="data">
                            {isLoading ? (
                              <div className="data-group">
                                <Spinner />
                              </div>
                            ) : (
                              <div className="data-group">
                                <div className="amount">
                                  {data.totals.customers.current}
                                </div>
                                {/*   <div className="info text-end">
                                  <span className="change up text-danger">
                                    <em className="icon ni ni-arrow-long-up" />
                                    4.63%
                                  </span>
                                  <br />
                                  <span>
                                    {" "}
                                    vs. <FormattedMessage id="last week" />
                                  </span>
                                </div> */}
                              </div>
                            )}
                          </div>
                        </div>
                        {/* .card-inner */}
                        <div className="nk-ecwg3-ck">
                          <TotalCustomerChart />
                        </div>
                      </div>
                      {/* .nk-ecwg */}
                    </div>
                    {/* .card */}
                  </div>
                  {/* .col */}
                </div>
                {/* .row */}
              </div>
              {/* .col */}
              <div className="col-xxl-12">
                <RecentOrders />
                {/* .card */}
              </div>

              <div className="col-xxl-4 col-md-6">
                {/* <TopProducts /> */}
                {/* .card */}
              </div>
            </div>
            {/* .row */}
          </div>
          {/* .nk-block */}
        </div>
      </div>
    </div>
  );
}

function TopProducts() {
  const { data, isLoading } = useQuery(["getMostSoldProduct"], () =>
    OrderService.getMostSold()
  );

  return (
    <div className="card h-100">
      <div className="card-inner">
        <div className="card-title-group mb-2">
          <div className="card-title">
            <h6 className="title">
              <FormattedMessage id="Top products" />
            </h6>
          </div>
          <div className="card-tools">
            <div className="dropdown">
              <a
                href="#"
                className="dropdown-toggle link link-light link-sm dropdown-indicator"
                data-bs-toggle="dropdown"
              >
                <FormattedMessage id="Weekly" />
              </a>
              <div className="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                <ul className="link-list-opt no-bdr">
                  <li>
                    <a href="#">
                      <span>
                        <FormattedMessage id="Daily" />
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="active">
                      <span>
                        <FormattedMessage id="Weekly" />
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>
                        <FormattedMessage id="Monthly" />
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <ul className="nk-top-products">
          {data?.data.map((o: any) => (
            <li className="item">
              <div className="thumb">
                <img src="/images/product/a.png" alt="" />
              </div>
              <div className="info">
                <div className="title">{o.name}</div>
                <div className="price">$99.00</div>
              </div>
              <div className="total">
                <div className="amount">$990.00</div>
                <div className="count">10 Sold</div>
              </div>
            </li>
          ))}
          <li className="item">
            <div className="thumb">
              <img src="/images/product/a.png" alt="" />
            </div>
            <div className="info">
              <div className="title">Pink Fitness Tracker</div>
              <div className="price">$99.00</div>
            </div>
            <div className="total">
              <div className="amount">$990.00</div>
              <div className="count">10 Sold</div>
            </div>
          </li>
          <li className="item">
            <div className="thumb">
              <img src="/images/product/b.png" alt="" />
            </div>
            <div className="info">
              <div className="title">Purple Smartwatch</div>
              <div className="price">$99.00</div>
            </div>
            <div className="total">
              <div className="amount">$990.00</div>
              <div className="count">10 Sold</div>
            </div>
          </li>
          <li className="item">
            <div className="thumb">
              <img src="/images/product/c.png" alt="" />
            </div>
            <div className="info">
              <div className="title">Black Mi Band Smartwatch</div>
              <div className="price">$99.00</div>
            </div>
            <div className="total">
              <div className="amount">$990.00</div>
              <div className="count">10 Sold</div>
            </div>
          </li>
          <li className="item">
            <div className="thumb">
              <img src="/images/product/d.png" alt="" />
            </div>
            <div className="info">
              <div className="title">Black Headphones</div>
              <div className="price">$99.00</div>
            </div>
            <div className="total">
              <div className="amount">$990.00</div>
              <div className="count">10 Sold</div>
            </div>
          </li>
          <li className="item">
            <div className="thumb">
              <img src="/images/product/e.png" alt="" />
            </div>
            <div className="info">
              <div className="title">iPhone 7 Headphones</div>
              <div className="price">$99.00</div>
            </div>
            <div className="total">
              <div className="amount">$990.00</div>
              <div className="count">10 Sold</div>
            </div>
          </li>
        </ul>
      </div>
      {/* .card-inner */}
    </div>
  );
}

function RecentOrders() {
  const { data, isLoading } = useQuery(["recentOrders"], () =>
    OrderService.getList({ page: "1", size: "10", sort: "createdAt:desc" })
  );

  return (
    <div className="card card-full">
      <div className="card-inner">
        <div className="card-title-group">
          <div className="card-title">
            <h6 className="title">
              <FormattedMessage id="Recent Orders" />
            </h6>
          </div>
          <Link className="btn-link" to={"/orders"}>
            <FormattedMessage id="View all orders" />
          </Link>
        </div>
      </div>
      <div className="nk-tb-list mt-n2">
        <div className="nk-tb-item nk-tb-head">
          <div className="nk-tb-col">
            <span>
              <FormattedMessage id="Order No." />
            </span>
          </div>
          <div className="nk-tb-col tb-col-sm">
            <span>
              <FormattedMessage id="Customer" />
            </span>
          </div>
          <div className="nk-tb-col tb-col-md">
            <span>
              <FormattedMessage id="Date" />
            </span>
          </div>
          <div className="nk-tb-col">
            <span>
              <FormattedMessage id="Total" />
            </span>
          </div>
          <div className="nk-tb-col">
            <span className="d-none d-sm-inline">
              <FormattedMessage id="Status" />
            </span>
          </div>
        </div>

        {data?.data?.length === 0 && !isLoading && (
          <div className="nk-tb-item">
            <div className="nk-tb-col">
              <span className="tb-lead">
                <FormattedMessage id="No orders" />
              </span>
            </div>
          </div>
        )}

        {data?.data?.map((or: any, i: number) => {
          return (
            <div key={i} className="nk-tb-item">
              <div className="nk-tb-col">
                <span className="tb-lead">
                  <Link to={`/orders/${or.id}`}>
                    <a href="#">#{or.code}</a>
                  </Link>
                </span>
              </div>
              <div className="nk-tb-col tb-col-sm">
                {or.customer && (
                  <div className="user-card">
                    <div className="user-avatar bg-primary">
                      <span>{getInitials(or.customer.user)}</span>
                    </div>
                    <div className="user-info">
                      <span className="tb-lead">
                        {getFullName(or.customer.user)}
                        <span className="dot dot-success d-md-none ms-1"></span>
                      </span>
                      <span>{or.customer.user.email}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="nk-tb-col tb-col-md">
                <span className="tb-sub">
                  {formatMidDate(new Date(or.createdAt))}
                </span>
              </div>
              <div className="nk-tb-col">
                <span className="tb-sub tb-amount">
                  {formatCurrency(or.total)}
                </span>
              </div>
              <div className="nk-tb-col">
                {transformToOrderStatus(or.status.name)}
              </div>
            </div>
          );
        })}
      </div>
      {isLoading &&
        Array(7)
          .fill({})
          .map((_, i) => (
            <div className="loading-skeleton w-100" key={i}>
              <div className="skeleton-row">
                <div className="block">
                  <FormattedMessage id="Loading" />
                </div>{" "}
              </div>
            </div>
          ))}
    </div>
  );
}

export default Dashboard;
