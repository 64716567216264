import { useState } from "react";
import { Button, Dropdown, Modal, Spinner } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useMutation, useQuery } from "react-query";
import { Link } from "react-router-dom";
import { useFormatter } from "../../hooks/formatter";
import {
  Notification,
  NotificationPayload,
  NotificationsService,
} from "../../services/notifications";
import moment from "moment";

function NavNotification() {
  const [show, setShow] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState<
    undefined | Notification
  >();

  const [size, setSize] = useState(5);

  const { isLoading, refetch, data } = useQuery(["notifications", size], () =>
    NotificationsService.getAll({ size })
  );

  const { date } = useFormatter();

  const viewMore = () => {
    setSize(size + 5);
  };

  const markAsReadMutation = useMutation(NotificationsService.markAsRead, {
    onSuccess: () => {
      refetch();
    },
  });

  const onMarkAllAsRead = async () => {
    await markAsReadMutation.mutateAsync(undefined);
  };

  const onRead = (notification: Notification) => {
    if (notification.payload) {
      setSelectedNotification(notification);
      setShow(true);
    }

    markAsReadMutation.mutateAsync({ ids: [notification._id] });
  };

  const unreadNotifications = data?.data?.filter(
    (notification: Notification) => !notification.read
  );

  const handleClose = () => setShow(false);
  const payload = selectedNotification?.payload || ({} as NotificationPayload);
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body className="modal-body modal-body-lg text-center">
          <div className="nk-modal">
            <em
              className={`nk-modal-icon icon icon-circle icon-circle-xxl ni ni-${selectedNotification?.type.icon} bg-${selectedNotification?.type.color}`}
            ></em>
            <h4
              className="nk-modal-title"
              dangerouslySetInnerHTML={{ __html: payload.title }}
            ></h4>
            <div className="nk-modal-text mb-3">
              <div
                className="caption-text"
                dangerouslySetInnerHTML={{ __html: payload.content }}
              ></div>
              <span className="sub-text-sm">{payload.subContent}</span>
            </div>
            <div className="nk-modal-action">
              {payload.cta?.link ? (
                <Link to={payload.cta?.link}>
                  <Button variant="primary" onClick={handleClose} size="lg">
                    {payload.cta?.text}
                  </Button>
                </Link>
              ) : (
                <Button onClick={handleClose} size="lg" variant="primary">
                  {payload.cta?.text}
                </Button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Dropdown className="dropdown notification-dropdown" as="li">
        <Dropdown.Toggle
          as={"a"}
          variant=""
          className="dropdown-toggle nk-quick-nav-icon"
          data-bs-toggle="dropdown"
        >
          <div
            className={`icon-status ${
              unreadNotifications?.length
                ? "icon-status-info"
                : "icon-status-na"
            }`}
          >
            <em className="icon ni ni-bell"></em>
          </div>
          {unreadNotifications?.length ? (
            <span
              style={{
                top: "-5px",
                left: "15px",
                position: "absolute",
              }}
              className="badge rounded-pill badge-dim bg-primary"
            >
              <small>
                {unreadNotifications.length > 99
                  ? "99+"
                  : unreadNotifications.length}
              </small>
            </span>
          ) : null}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu dropdown-menu-xl dropdown-menu-end">
          <div className="dropdown-head">
            <span className="sub-title nk-dropdown-title">
              <FormattedMessage id="Notifications" />
            </span>
            <a role="button" className="btn-link p-0" onClick={onMarkAllAsRead}>
              <FormattedMessage id="Mark All as Read" />
            </a>
          </div>
          <div className="dropdown-body">
            {isLoading || !data?.data ? (
              <div className="d-flex justify-content-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">
                    <FormattedMessage id="loading" />
                  </span>
                </Spinner>
              </div>
            ) : (
              <div className="nk-notification">
                {data.data?.map((notification) => {
                  return (
                    <div
                      role="button"
                      key={notification.id}
                      onClick={() => onRead(notification)}
                      style={{
                        backgroundColor: !notification.read
                          ? "#f5f0ff"
                          : undefined,
                      }}
                      className={`nk-notification-item dropdown-inner `}
                    >
                      <div className="nk-notification-icon">
                        <em
                          className={`icon icon-circle bg-${notification.type.color}-dim ni ni-${notification.type.icon}`}
                        />
                      </div>
                      <div className="nk-notification-content">
                        <div
                          className="nk-notification-text"
                          dangerouslySetInnerHTML={{
                            __html: notification.content,
                          }}
                        ></div>
                        <div className="nk-notification-time">
                          {moment(notification.createdAt).fromNow()}
                        </div>
                      </div>
                    </div>
                  );
                })}

                {data.data?.length === 0 && (
                  <div className="nk-notification-item dropdown-inner ">
                    <div className="nk-notification-content">
                      <div className="nk-notification-text text-muted">
                        <FormattedMessage id="No new notifications" />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {/* .nk-notification */}
          </div>
          {/* .nk-dropdown-body */}
          <div className="dropdown-foot center">
            <a role="button" onClick={viewMore}>
              <FormattedMessage id="View more" />
            </a>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export default NavNotification;
