import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "react-query";
import { useConfirmationModal } from "../../../contexts/confirmation";
import { IGeneralSettingsForm } from "../../../interfaces/settings/general";
import { GeneralSettingsService } from "../../../services/general";
import { Alert } from "react-bootstrap";
import { SkeletonRow } from "../../../components/Loading/Skeleton";
import { toast } from "react-toastify";

export interface IOrderSettingsForm {
  allowOrderCreation: boolean;
}

function OrderSettings() {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<IOrderSettingsForm>({
    defaultValues: {
      allowOrderCreation: false,
    },
  });

  const { open } = useConfirmationModal();

  const { isLoading, isError, refetch } = useQuery<IGeneralSettingsForm>(
    "generalSettings",
    GeneralSettingsService.getSettings,
    {
      cacheTime: Infinity,
      onSuccess: (data) => {
        setValue("allowOrderCreation", Boolean(data.allowOrderCreation));
      },
    }
  );

  const onSubmit = async (data: any) => {
    return open(
      intl.formatMessage(
        { id: "confirmationEditMessage" },
        { entityName: intl.formatMessage({ id: "General configuration" }) }
      ),
      async () => {
        try {
          await GeneralSettingsService.updateOrder(data);
          toast.success(intl.formatMessage({ id: "TOAST.UPDATE_SUCCESS" }));
          refetch(); // Vuelve a cargar los datos después de la actualización exitosa
        } catch (error) {
          toast.error(intl.formatMessage({ id: "TOAST.UPDATE_ERROR" }));
        }
      }
    );
  };

  return (
    <div className="card-inner">
      <h5 className="card-title">
        <FormattedMessage id="Order configuration" />
      </h5>
      <p>
        <FormattedMessage id="Configure the order of the products in the store" />
      </p>

      {isError ? (
        <Alert variant="danger">
          <i className="icon ni ni-alert-circle me-1"></i>
          <FormattedMessage id="Error loading configuration, Try again later or contact support." />
        </Alert>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="gy-3 form-settings">
          <div className="row g-3 align-center">
            <div className="col-lg-5">
              <div className="form-group">
                <label className="form-label" htmlFor="allow-order-creation">
                  <FormattedMessage id="Allow order creation" />
                </label>
                <span className="form-note">
                  <FormattedMessage id="Allow users to create orders without having to pay upfront" />
                </span>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="form-group">
                {isLoading ? (
                  <SkeletonRow size="xs" />
                ) : (
                  <>
                    <div className="custom-control custom-switch checked">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="allow-order-creation"
                        disabled={true}
                        {...register("allowOrderCreation")}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="allow-order-creation"
                      ></label>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

export default OrderSettings;
