import React from "react";
import { Button, Modal, ModalProps } from "react-bootstrap";
import { OrderStatus } from "../../enums/status";
import { transformToOrderStatus } from "../Table/transformers";
import { useMutation } from "react-query";
import { OrderService } from "../../services/order";

const getNextStatus = (order: any) => {
  switch (order.status) {
    case "pending":
      return "shipped";
    case "shipped":
      return "delivered";
    case "delivered":
      return null;
  }
};

const ChangeOrderStatus: React.FC<ModalProps & { order: any }> = ({
  order,
  ...props
}) => {
  const nextStatus = getNextStatus(order);

  const updateStatusMutation = useMutation(() =>
    OrderService.updateStatus(order.id)
  );

  const onUpdate = async () => {
    await updateStatusMutation.mutateAsync();
    if (props.onHide) props.onHide();
  };

  return (
    <Modal {...props}>
      <Modal.Header closeButton>
        <Modal.Title>Actualizar estado del pedido</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Haga click en cambiar para mover el pedido al siguiente estado que
          corresponde.
        </p>
        <b>Estado actual:</b> {transformToOrderStatus(order.status)}
      </Modal.Body>
      <Modal.Footer>
        {nextStatus && (
          <Button
            variant={OrderStatus[nextStatus]?.class}
            disabled={updateStatusMutation.isLoading}
            onClick={onUpdate}
          >
            Cambiar a<em className="icon ni ni-arrow-right mx-2"></em>
            {OrderStatus[nextStatus]?.text}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeOrderStatus;
