import React, { ReactNode, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDrag, useDrop } from "react-dnd";
import { ControllerRenderProps, FieldValues } from "react-hook-form";
import AssetSelection from "../AssetSelection";
import LazyImage from "../../Image/LazyImage";
import update from "immutability-helper";
import { IAsset } from "../../../interfaces/cms";

interface ICardProps {
  src: string;
  title: string;
  id: string;
  index: number;
  moveImage: (dragIndex: number, hoverIndex: number) => void;
  reorder: boolean;
  children?: ReactNode;
}

const Card = ({
  src,
  title,
  id,
  index,
  moveImage,
  reorder,
  children,
}: ICardProps) => {
  const ref = React.useRef<HTMLDivElement | null>(null);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "image",
    hover: (item: { index: number }) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      moveImage(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const [{ isDragging }, drag] = useDrag({
    type: "image",
    item: () => {
      return { id, index };
    },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  });

  const opacity = isDragging ? 0.4 : 1;
  drag(drop(ref));

  const style: React.CSSProperties = {
    border:
      isOver && canDrop ? "1px dashed var(--bs-success)" : "1px dashed gray",
    padding: "0.5rem 1rem",
    marginBottom: ".5rem",
    backgroundColor: "white",
    cursor: "move",
    height: "150px",
    width: "100%",
  };

  return (
    <div
      ref={ref}
      style={{
        padding: "1em",
        opacity,
      }}
    >
      {reorder ? (
        <div style={style} className="card">
          <LazyImage src={src} alt={title} />
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export interface IMultimediaFieldProps {
  label: string;
  field: ControllerRenderProps<FieldValues, string>;
  fieldKey: string;
  help?: any;
}

type AssetSpace = IAsset & { isPlaceholder?: boolean };

export function MultimediaField({
  label,
  field,
  fieldKey,
  help,
}: IMultimediaFieldProps) {
  const [reorder, setReorder] = useState(false);
  const [tempItems, setTempItems] = useState<AssetSpace[]>(field.value);

  const moveImage = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setTempItems((prevCards: IAsset[]) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex] as IAsset],
          ],
        })
      );
    },
    []
  );

  return (
    <Form.Group>
      <Row>
        <Col>
          <Form.Label>{label}</Form.Label>
        </Col>

        <Col className="text-right">
          {!reorder ? (
            <Button
              size="sm"
              variant="link"
              onClick={() => {
                setReorder(!reorder);
                setTempItems(field.value);
              }}
            >
              <i className="ni ni-sort"></i>
              Ordenar
            </Button>
          ) : (
            <Button
              size="sm"
              variant="link"
              style={{
                color: "var(--bs-danger)",
              }}
              onClick={() => {
                setReorder(!reorder);
                field.onChange(tempItems);
              }}
            >
              <i className="ni ni-check-bold"></i>
              Dejar de ordenar
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        {React.Children.toArray(
          tempItems.map((f, i) => {
            const asset = f?.isPlaceholder ? undefined : f;
            return (
              <Col md={4} key={i}>
                <Card
                  id={fieldKey + i}
                  src={String(asset?.thumbnail || asset?.url)}
                  index={i}
                  moveImage={moveImage}
                  reorder={reorder}
                  title={String(asset?.name)}
                >
                  {!asset && (
                    <Button
                      size="sm"
                      variant="link"
                      onClick={() => {
                        const values = [...field.value];
                        values[i] = undefined;
                        field.onChange(values.filter(Boolean));
                      }}
                      style={{
                        position: "absolute",
                        right: "25px",
                      }}
                    >
                      {" "}
                      <i className="ni ni-cross" /> remover
                    </Button>
                  )}
                  <AssetSelection
                    id={fieldKey + i}
                    name={fieldKey + i}
                    onRemove={() => {
                      const values = [...field.value];
                      values[i] = {
                        id: "new" + (i + 1),
                        isPlaceholder: true,
                      };
                      field.onChange(values.filter(Boolean));
                    }}
                    onSelect={(media) => {
                      const values = [...field.value];
                      values[i] = media;
                      field.onChange(values);
                    }}
                    defaultAsset={asset}
                  />
                </Card>
              </Col>
            );
          })
        )}

        {!field.value?.find((f: AssetSpace) => f.isPlaceholder) && (
          <Col md={4}>
            <div className="upload-zone dropzone dz-clickable">
              <div className="dz-message" data-dz-message>
                <button
                  className="btn btn-link"
                  onClick={(e) => {
                    if (!field.value) {
                      field.value = [];
                    }
                    const newValue = field.value.concat({
                      id: "new" + field.value.length,
                      isPlaceholder: true,
                    });
                    e.preventDefault();
                    field.onChange(newValue);
                    setTempItems(newValue);
                  }}
                >
                  AGREGAR <i className="ni ni-plus"></i>
                </button>
              </div>
            </div>
          </Col>
        )}
      </Row>
      {help}
    </Form.Group>
  );
}

export default MultimediaField;
