import { useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AccessGroupSelect from "../../components/Select/AccessGroupSelect";
import { IUserDetails } from "../../interfaces/users";
import { IEditUserForm } from "../../interfaces/users/forms";
import { UserService } from "../../services/user";
import { getFullName } from "../../utils/person";

function UserEdit() {
  const navigate = useNavigate();
  const intl = useIntl();
  const [isFetched, setIsFetch] = useState(false);

  const { id } = useParams() as { id: string };

  const { register, control, watch, handleSubmit, formState, setValue, reset } =
    useForm<IEditUserForm>({
      defaultValues: {},
    });

  const { errors } = formState;

  const { data, isLoading } = useQuery(
    ["userDetails", id],
    () => UserService.get(String(id)) as Promise<IUserDetails>,
    {
      enabled: !isFetched,
      onSuccess({ information, ...user }) {
        setIsFetch(true);
        reset({
          birthDate: information?.birthDate as unknown as Date,
          groups: user.groups.map((e) => ({
            label: e.displayName,
            value: e.id,
          })) as any[],
          lastNames: user.lastNames,
          names: user.names,
          tel: information?.tel,
        });
      },
      onError(error) {
        toast.error(intl.formatMessage({ id: "User was not found." }));
        navigate("/users");
      },
    }
  );

  const editUserMutation = useMutation({
    mutationFn: async (formData: IEditUserForm) => {
      return await UserService.update(id, formData);
    },
    onSuccess(data: any) {
      toast.success(
        intl.formatMessage(
          { id: "User was updated successfully." },
          { product: data.name }
        )
      );
      navigate(`/users/${id}`);
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    return await editUserMutation.mutateAsync({
      ...data,
      groups: data.groups.map((e: any) => e.value),
    });
  });

  return (
    <div className="components-preview wide-md mx-auto">
      <div className="nk-block-head nk-block-head-lg wide-sm">
        <div className="nk-block-head-content">
          <h2 className="nk-block-title fw-normal">
            <span className="text-primary">
              <FormattedMessage id="Edit User" />/
            </span>{" "}
            {data && getFullName(data)}
          </h2>
          <div className="nk-block-des">
            <p className="lead">
              <FormattedMessage id="Update the data of your selected user" />
            </p>
          </div>
        </div>
      </div>

      <div className="nk-block nk-block-lg">
        <div className="card card-bordered card-preview">
          <div className="card-inner">
            {isLoading ? (
              <div className="d-flex justify-content-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">
                    <FormattedMessage id="Loading" />
                  </span>
                </Spinner>
              </div>
            ) : (
              <Form onSubmit={onSubmit}>
                <span className="preview-title-lg overline-title mb-3">
                  <FormattedMessage id="General Information" />
                </span>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <FormattedMessage id="Names" />
                      </Form.Label>
                      <Form.Control
                        maxLength={50}
                        {...register("names", { required: true })}
                        isInvalid={Boolean(errors.names)}
                      />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <FormattedMessage id="Last Names" />
                      </Form.Label>
                      <Form.Control
                        maxLength={50}
                        {...register("lastNames", { required: true })}
                        isInvalid={Boolean(errors.lastNames)}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <FormattedMessage id="ID Number" />
                      </Form.Label>
                      <Form.Control
                        maxLength={50}
                        defaultValue={data?.information?.ide}
                        disabled
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <FormattedMessage id="Email" />
                      </Form.Label>
                      <Form.Control
                        defaultValue={data?.email}
                        disabled
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <FormattedMessage id="Birthdate" />
                      </Form.Label>
                      <Form.Control
                        type="date"
                        {...register("birthDate", { required: true })}
                        isInvalid={Boolean(errors.birthDate)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <FormattedMessage id="Telephone" />
                      </Form.Label>
                      <Form.Control
                        maxLength={50}
                        {...register("tel", { required: true })}
                        isInvalid={Boolean(errors.tel)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="mt-5">
                  <hr />
                  <span className="preview-title-lg overline-title my-3">
                    <FormattedMessage id="Account Setting" />
                  </span>
                </div>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <FormattedMessage id="Access Group" />
                  </Form.Label>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="groups"
                    render={({ field }) => {
                      return (
                        <AccessGroupSelect
                          {...field}
                          isInvalid={Boolean(errors.groups)}
                        />
                      );
                    }}
                  />
                </Form.Group>

                <div className="mt-4 text-right">
                  <br />
                  <hr />
                  <Button
                    type="submit"
                    disabled={editUserMutation.isLoading}
                    onClick={onSubmit}
                    size="lg"
                  >
                    {editUserMutation.isLoading ? (
                      <Spinner />
                    ) : (
                      <em className="icon ni ni-save me-2"></em>
                    )}{" "}
                    <FormattedMessage id="Save Changes" />
                  </Button>
                </div>
              </Form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserEdit;
