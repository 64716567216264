import httpClient from "../clients/http";
import { INewUserForm } from "../interfaces/users/forms";
import { clearBlank } from "../utils/object";

type UserGetListParams = {
  status: string | null;
  search: string | null;
  page: string | null;
  size: string | null;
};

export class UserService {
  static async getList(filters: UserGetListParams) {
    const queryParams = new URLSearchParams(clearBlank(filters));
    const response = await httpClient.get("/users?" + queryParams.toString());
    return response.data;
  }

  static async get(id: string) {
    const response = await httpClient.get(`/users/${id}`);
    return response.data;
  }

  static async create(formData: INewUserForm) {
    const response = await httpClient.post(`/users`, formData);
    return response.data;
  }

  static async deleteUser(id: string) {
    const response = await httpClient.delete(`/users/${id}`);
    return response.data;
  }

  static async resetPassword(id: string) {
    const response = await httpClient.post(`/users/${id}/reset-password`, {});
    return response.data;
  }

  static async updateStatus(id: string, status: string) {
    const response = await httpClient.put(`/users/${id}/status`, { status });
    return response.data;
  }

  static async update(id: string, formData: any) {
    const response = await httpClient.put(`/users/${id}`, formData);
    return response.data;
  }
}
