import { useQuery } from "react-query";
import { ClientService } from "../services/client";

export const useClientKey = () => {
  // get the first subdomain in the url
  // for example test.stage.localhost.com will return test
  const subdomain = window.location.hostname.split(".")[0];

  if (subdomain === "localhost" || subdomain === "shoppxy") {
    return undefined;
  }

  return subdomain;
};

export const useClient = () => {
  const key = useClientKey();

  const baseUrl =
    process.env.REACT_APP_BASE_URL_CLIENT_MEDIA ||
    `https://res.cloudinary.com/dofk0ceuv/image/upload/v1701205801/shoppxy-clients`;

  const logo = `${baseUrl}/${key}_svg.svg`;

  // it has to be cache as long as possible
  const { isLoading, data } = useQuery(
    ["clientByKey", key],
    () => ClientService.getClientByKey(key as string),
    {
      cacheTime: 1000 * 60 * 60 * 24 * 15, // 15 days
      staleTime: 1000 * 60 * 60 * 24 * 15, // 15 days
      enabled: !!key,
    }
  );

  return {
    ...data,
    key,
    logo,
    loading: isLoading,
  };
};
