import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler
);

export interface ILineCardProps {
  title: string;
  total: number;
  growth?: {
    value: number;
    period: "week" | "day";
  };
  data: number[];
  labels: string[];
}

export function LineCard({
  labels,
  title,
  growth,
  data,
  total,
}: ILineCardProps) {
  return (
    <div className="nk-ecwg nk-ecwg3">
      <div className="card-inner pb-0">
        <div className="card-title-group">
          <div className="card-title">
            <h6 className="title">{title}</h6>
          </div>
        </div>
        <div className="data">
          <div className="data-group">
            <div className="amount">{total}</div>
            {growth && (
              <div className="info text-end">
                <span className="change up text-danger">
                  <em className="icon ni ni-arrow-long-up" />
                  {growth.value}%
                </span>
                <br />
                <span>vs. last {growth.period}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="nk-ecwg3-ck">
        <div className="chartjs-size-monitor">
          <div className="chartjs-size-monitor-expand">
            <div />
          </div>
          <div className="chartjs-size-monitor-shrink">
            <div />
          </div>
        </div>
        <Line
          className="ecommerce-line-chart-s1 chartjs-render-monitor"
          options={{
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                display: false,
              },
              y: {
                display: false,
              },
            },
            plugins: {
              legend: {
                display: false,
              },

              tooltip: {
                callbacks: {
                  label: function (context) {
                    return `${context.raw} pedidos realizadas`;
                  },
                },

                backgroundColor: "#1c2b46",
                footerMarginTop: 0,
                displayColors: false,
              },
            },
          }}
          data={{
            labels,
            datasets: [
              {
                label: "Dataset",
                fill: true,
                data: data,
                borderColor: "rgb(53, 162, 235)",
                backgroundColor: "rgb(224, 238, 255)",
                tension: 0.4,
              },
            ],
          }}
        />
      </div>
    </div>
  );
}

export default Line;
