import React from "react";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import BackButton from "../../components/Buttons/BackButton";
import Title from "../../components/Title";
import { OrderStatus, Status } from "../../enums/status";
import {
  formatCurrency,
  formatMidDate,
  formatRelativeDate,
} from "../../utils/formatter";
import { useMutation, useQuery } from "react-query";
import { CustomerService } from "../../services/customer";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  transformToOrderStatus,
  transformToStatus,
} from "../../components/Table/transformers";
import { getOrderDescription } from "../../utils/product";
import { getThumbnailUrl } from "../../utils/media";
import { FormattedMessage, useIntl } from "react-intl";
import { EditButton } from "../../components/Buttons/ActionableButton";
import { toast } from "react-toastify";
import { Tab, Tabs } from "react-bootstrap";
import CartTab from "./cart";
import WishlistTab from "./wish-list";
import { useQuerySearchTab } from "../../hooks/router";
import { useConfirmationModal } from "../../contexts/confirmation";
import { getFullName, getInitials } from "../../utils/person";
import AddressTab from "./addresses";

interface IUserDetails {
  names: string;
  lastNames: string;
  email: string;
  photo: string;
  orders: { total: number; progress: number; completed: number };
  id: string;
  billing: {
    email: string;
    address: string;
  };
  lastAccess: Date;
  status: string;
  information: any;
  createdAt: Date;
}

interface IUserOrder {
  id: string;
  code: string;
  description: string;
  items: {
    image?: {
      thumbnail?: string;
      url: string;
    };
  }[];
  total: number;
  status: { name: keyof typeof OrderStatus };
  delivery?: Date;
}

interface IUserAccountDetail {
  ordered: number;
  totalPoints: number;
  orders: IUserOrder[];
}

function CustomerDetails() {
  const { id } = useParams();
  const intl = useIntl();

  const customerDetailsQuery = useQuery(["customerDetails", id], () =>
    CustomerService.getDetails(String(id))
  );

  const tabs = [
    {
      key: "account-details",
      name: intl.formatMessage({ id: "Account details" }),
      Component: (props: any) => <CustomerAccountCard {...props} />,
    },
    {
      key: "cart",
      name: intl.formatMessage({ id: "Trolley" }),
      Component: CartTab,
    },
    {
      key: "wish-list",
      name: intl.formatMessage({ id: "Wish list" }),
      Component: WishlistTab,
    },
    {
      key: "address",
      name: intl.formatMessage({ id: "Addresses" }),
      Component: AddressTab,
    },
  ];

  const { activeKey, onSelect } = useQuerySearchTab(tabs);

  return (
    <div className="nk-content-inner">
      <div className="nk-content-body">
        <Title
          subText={intl.formatMessage({
            id: "Information of the selected client",
          })}
          rightContent={
            <ul className="nk-block-tools g-3">
              <li>
                <Link to={`/customers/${id}/edit`}>
                  <EditButton />
                </Link>
              </li>
              <li>
                <BackButton to="/customers" />
              </li>
            </ul>
          }
        >
          <FormattedMessage id="customer details" />
        </Title>
        <div className="nk-block">
          <div className="row g-gs">
            <div className="col-lg-4 col-xl-4 col-xxl-3">
              {!customerDetailsQuery.isLoading && (
                <CustomerDetailCard
                  {...customerDetailsQuery.data}
                  refetch={customerDetailsQuery.refetch}
                />
              )}
            </div>

            <div className="col-lg-8 col-xl-8 col-xxl-9">
              <div className="card card-preview">
                <div className="card-inner">
                  <Tabs
                    id="customer-tabs"
                    activeKey={activeKey}
                    onSelect={onSelect}
                    className="nav nav-tabs mt-n3"
                  >
                    {tabs.map((t, i) => {
                      const { key, name, Component } = t;
                      return (
                        <Tab
                          eventKey={key}
                          role="presentation"
                          className="nav-item"
                          key={key}
                          title={name}
                        >
                          {!customerDetailsQuery.isLoading && (
                            <Component {...customerDetailsQuery.data} />
                          )}
                        </Tab>
                      );
                    })}
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="row g-gs"></div>
      </div>
    </div>
  );
}

const CustomerAccountCard: React.FC<IUserAccountDetail> = ({
  ordered,
  totalPoints,
  orders,
}) => {
  return (
    <div>
      <div className="nk-block">
        <div className="overline-title-alt mb-2 mt-2">
          <FormattedMessage id="Account details" />
        </div>
        <div className="profile-balance">
          <div className="profile-balance-group gx-4">
            <div className="profile-balance-sub">
              <div className="profile-balance-amount">
                <div className="number">
                  {formatCurrency(ordered, {
                    currencyDisplay: "narrowSymbol",
                  })}
                  <small className="currency currency-usd"> DOP</small>
                </div>
              </div>
              <div className="profile-balance-subtitle">
                <FormattedMessage id="Total spent" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <h6 className="lead-text mb-3">
          <FormattedMessage id="Recent Orders" />
        </h6>
        <div className="nk-tb-list nk-tb-ulist is-compact card">
          <div className="nk-tb-item nk-tb-head">
            <div className="nk-tb-col">
              <span className="sub-text">
                <FormattedMessage id="Order ID" />
              </span>
            </div>
            <div className="nk-tb-col tb-col-sm">
              <span className="sub-text">
                <FormattedMessage id="Description" />
              </span>
            </div>
            <div className="nk-tb-col tb-col-xxl">
              <span className="sub-text">
                <FormattedMessage id="Total price" />
              </span>
            </div>
            <div className="nk-tb-col">
              <span className="sub-text">
                <FormattedMessage id="Status" />
              </span>
            </div>
            <div className="nk-tb-col">
              <span className="sub-text">
                <FormattedMessage id="Delivery" />
              </span>
            </div>
          </div>

          {orders.map((o) => (
            <div className="nk-tb-item" key={o.id}>
              <div className="nk-tb-col">
                <a href="#">
                  <span className="fw-bold">#{o.code}</span>
                </a>
              </div>
              <div className="nk-tb-col tb-col-sm">
                <span className="tb-product">
                  <div className="products-images">
                    {o.items[0]?.image && (
                      <img
                        src={getThumbnailUrl(o.items[0].image)}
                        alt=""
                        className="thumb"
                      />
                    )}
                  </div>

                  <span className="title">
                    {o.description || getOrderDescription(o.items)}
                  </span>
                </span>
              </div>
              <div className="nk-tb-col tb-col-xxl">
                <span className="amount">{formatCurrency(o.total)}</span>
              </div>
              <div className="nk-tb-col">
                {transformToOrderStatus(o.status.name)}
              </div>
              <div className="nk-tb-col">
                <span className="sub-text">
                  {o.delivery
                    ? formatRelativeDate(new Date(o.delivery))
                    : " - "}
                </span>
              </div>
            </div>
          ))}
        </div>
        {orders.length === 0 && (
          <div className="d-block w-100 text-center py-3 text-muted">
            <b>
              <FormattedMessage id="No orders" />
            </b>
          </div>
        )}
      </div>
      {/* <CustomerPaymentMethods /> */}
    </div>
  );
};

const CustomerPaymentMethods: React.FC = () => {
  return (
    <div className="nk-block">
      <h6 className="lead-text mb-3">
        <FormattedMessage id="Payment Methods" />
      </h6>
      <div className="row g-3">
        <div className="col-xl-12 col-xxl-6">
          <div className="card">
            <div className="card-inner">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="icon-circle icon-circle-lg">
                    <em className="icon ni ni-visa" />
                  </div>
                  <div className="ms-3">
                    <h6 className="lead-text">
                      <FormattedMessage id="Visa Card" />
                      <span className="text-soft ml-1">**** 1955</span>
                    </h6>
                    <span className="sub-text">Expires Nov 2023</span>
                  </div>
                </div>
                <ul className="btn-toolbar justify-center gx-1 me-n1 flex-nowrap">
                  <li>
                    <a href="#" className="btn btn-trigger btn-icon">
                      <em className="icon ni ni-edit" />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="btn btn-trigger btn-icon">
                      <em className="icon ni ni-trash" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-12 col-xxl-6">
          <div className="card">
            <div className="card-inner">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="icon-circle icon-circle-lg bg-indigo">
                    <em className="icon ni ni-american-express" />
                  </div>
                  <div className="ms-3">
                    <h6 className="lead-text">
                      <FormattedMessage id="American Express" />
                      <span className="text-soft ml-1">**** 4352</span>
                    </h6>
                    <span className="sub-text">Expires Feb 2024</span>
                  </div>
                </div>
                <ul className="btn-toolbar justify-center gx-1 me-n1 flex-nowrap">
                  <li>
                    <a href="#" className="btn btn-trigger btn-icon">
                      <em className="icon ni ni-edit" />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="btn btn-trigger btn-icon">
                      <em className="icon ni ni-trash" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-12 col-xxl-6">
          <div className="card">
            <div className="card-inner">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="icon-circle icon-circle-lg bg-orange">
                    <em className="icon ni ni-mc" />
                  </div>
                  <div className="ms-3">
                    <h6 className="lead-text">
                      <FormattedMessage id="Master Card" />
                      <span className="text-soft ml-1">**** 9478</span>
                    </h6>
                    <span className="sub-text text-danger">Expired</span>
                  </div>
                </div>
                <ul className="btn-toolbar justify-center gx-1 me-n1 flex-nowrap">
                  <li>
                    <a href="#" className="btn btn-trigger btn-icon">
                      <em className="icon ni ni-edit" />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="btn btn-trigger btn-icon">
                      <em className="icon ni ni-trash" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-12 col-xxl-6">
          <button
            className="h-100 w-100 bg-white card round-sm p-4 d-flex align-items-center justify-content-center"
            data-bs-toggle="modal"
            data-bs-target="#add-card"
          >
            <span className="text-soft">
              <FormattedMessage id="Add New Card" />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

const CustomerOrderStats: React.FC = () => {
  const { id } = useParams();
  const { data, isLoading } = useQuery(["customerOrdersStats", id], () =>
    CustomerService.getOrdersStats(String(id))
  );

  return (
    <div className="card-inner">
      <div className="overline-title-alt mb-2">
        <FormattedMessage id="Orders made" />
      </div>
      <div className="row text-center">
        <div className="col-xs-12 col-4">
          <div className="profile-stats">
            <span className="amount">{data?.total || "0"}</span>
            <span className="sub-text">
              <FormattedMessage id="Total" />
            </span>
          </div>
        </div>

        <div className="col-xs-12 col-4">
          <div className="profile-stats">
            <span className="amount">{data?.enviado || "0"}</span>
            <span className="sub-text">
              <FormattedMessage id="In progress" />
            </span>
          </div>
        </div>

        <div className="col-xs-12 col-4">
          <div className="profile-stats">
            <span className="amount">{data?.entregado || "0"}</span>
            <span className="sub-text">
              <FormattedMessage id="Completed" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const CustomerDetailCard: React.FC<
  { user: IUserDetails; id: string } & { refetch: () => void }
> = ({ user, refetch, id }) => {
  const {
    names,
    lastNames,
    email,
    information,
    lastAccess,
    createdAt,
    status,
    photo,
  } = user;
  const intl = useIntl();
  const { open } = useConfirmationModal();

  const isBlock = status === "blocked" || status === "suspended";

  const resetPasswordMutation = useMutation(
    () => CustomerService.resetPassword(id),
    {
      onError: () => {
        toast.error(
          intl.formatMessage({ id: "Failed to reset user password" })
        );
      },
      onSuccess: () => {
        toast.success(
          intl.formatMessage({ id: "User password reset successfully" })
        );
      },
    }
  );

  const updateStatusMutation = useMutation(
    (status: string) => CustomerService.updateStatus(id, status),
    {
      onError: () => {
        toast.error(intl.formatMessage({ id: "Failed to update user status" }));
      },
      onSuccess: () => {
        refetch();
        toast.success(
          intl.formatMessage({ id: "User status updated successfully" })
        );
      },
    }
  );

  return (
    <div className="card">
      <div className="card-inner-group">
        <div className="card-inner">
          <div className="user-card user-card-s2">
            <div className="user-avatar lg bg-primary">
              {photo ? (
                <img src={photo} alt="" />
              ) : (
                <div className="bg-primary">
                  <span>{getInitials({ names, lastNames })}</span>
                </div>
              )}
            </div>
            <div className="user-info">
              {/* <div className="badge bg-light rounded-pill ucap">Platinam</div> */}
              <h5>
                {names} {lastNames}
              </h5>
              <span className="sub-text">{email}</span>
            </div>
          </div>
        </div>
        <div className="card-inner card-inner-sm">
          <ul className="btn-toolbar justify-center gx-1">
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip">
                  <FormattedMessage id="Reset Password" />
                </Tooltip>
              }
            >
              <a
                onClick={() =>
                  open(
                    intl.formatMessage(
                      {
                        id: "Are you sure you want to reset the password of {name}",
                      },
                      { name: getFullName({ names, lastNames }) }
                    ),
                    () => resetPasswordMutation.mutateAsync()
                  )
                }
                className="btn btn-trigger btn-icon"
              >
                <em className="icon ni ni-mail"></em>
              </a>
            </OverlayTrigger>

            <li>
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip">
                    {isBlock ? (
                      <FormattedMessage id="Unblock" />
                    ) : (
                      <FormattedMessage id="Block" />
                    )}
                  </Tooltip>
                }
              >
                <a
                  onClick={() =>
                    open(
                      intl.formatMessage(
                        {
                          id: "Are you sure you want to change the status of {name} to {status}",
                        },
                        {
                          name: getFullName({ names, lastNames }),
                          status: isBlock ? "activo" : "bloqueado",
                        }
                      ),
                      () =>
                        updateStatusMutation.mutateAsync(
                          isBlock ? "active" : "blocked"
                        )
                    )
                  }
                  className="btn btn-trigger btn-icon"
                >
                  <em
                    className={`icon ni ni-shield${isBlock ? "-off" : ""}`}
                  ></em>
                </a>
              </OverlayTrigger>
            </li>
          </ul>
        </div>
        <CustomerOrderStats />
        <div className="card-inner">
          <h6 className="overline-title mb-2">
            <FormattedMessage id="Information" />
          </h6>
          <div className="row g-3">
            <div className="col-sm-6 col-md-4 col-lg-12">
              <span className="sub-text">
                <FormattedMessage id="userID" />
              </span>
              <Badge bg="secondary">
                {id.slice(-12)}{" "}
                <i
                  onClick={() => {
                    navigator.clipboard.writeText(id);
                    toast.info(
                      intl.formatMessage({ id: "Copied to clipboard" })
                    );
                  }}
                  role="button"
                  className="icon ms-1 ni ni-copy"
                  style={{
                    fontSize: "0.8rem",
                    paddingTop: "0.2rem",
                  }}
                />
              </Badge>
            </div>

            <div className="col-sm-6 col-md-4 col-lg-12">
              <span className="sub-text">
                <FormattedMessage id="Telephone" />:
              </span>
              <span>{information?.tel || "-"}</span>
            </div>

            <div className="col-sm-6 col-md-4 col-lg-12">
              <span className="sub-text">
                <FormattedMessage id="billingEmail" />
              </span>
              <b>{email}</b>
              <i
                onClick={() => {
                  navigator.clipboard.writeText(email);
                  toast.info(intl.formatMessage({ id: "Copied to clipboard" }));
                }}
                role="button"
                className="icon ms-1 ni ni-copy"
                style={{
                  fontSize: "0.8rem",
                  paddingTop: "0.2rem",
                }}
              />
            </div>

            <div className="col-sm-6 col-md-4 col-lg-12">
              <span className="sub-text">
                <FormattedMessage id="lastAccess" />
              </span>
              <span>{formatMidDate(lastAccess)}</span>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-12">
              <span className="sub-text">
                <FormattedMessage id="Status" />:
              </span>

              <b>{transformToStatus(status as any)}</b>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-12">
              <span className="sub-text">
                <FormattedMessage id="registeredAt" />
              </span>
              <span>{formatMidDate(createdAt)}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetails;
