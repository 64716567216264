import { FormattedMessage } from "react-intl";
import { OrderStatus, Status } from "../../enums/status";
import { AddressType, InventoryType } from "../../enums/types";
import { HeadCheck } from "./CustomElements";

export function transformToCheckFactory<T = any>(
  onChange: React.InputHTMLAttributes<HTMLInputElement>["onChange"]
) {
  return function transformToCheck(value: string, obj: T) {
    return <HeadCheck onChange={onChange} value={value} />;
  };
}

export function transformToStatus(value: keyof typeof Status) {
  return (
    <span className={`tb-status text-${Status[value]?.class}`}>
      {Status[value]?.text || " - "}
    </span>
  );
}

export function transformToInventoryType(value: keyof typeof InventoryType) {
  return (
    <span className={`text-${InventoryType[value]?.class}`}>
      <em className={`${InventoryType[value]?.icon} me-1`} />
      {InventoryType[value]?.text || " - "}
    </span>
  );
}

export function transformToOrderStatus(value: keyof typeof OrderStatus) {
  return (
    <>
      <span className={`dot bg-${OrderStatus[value]?.class} d-sm-none`} />
      <span
        className={`badge badge-sm badge-dot has-bg bg-${OrderStatus[value]?.class} d-none d-sm-inline-flex`}
      >
        {OrderStatus[value]?.text || " - "}
      </span>
    </>
  );
}

export function transformToAddressType(value: keyof typeof AddressType) {
  return (
    <>
      <span className={`dot bg-${AddressType[value]?.class} d-sm-none`} />
      <span
        className={`badge bg-${AddressType[value]?.class} d-none d-sm-inline-flex`}
      >
        <i className={AddressType[value]?.icon + " me-1"} />
        <FormattedMessage id={AddressType[value]?.text} />
      </span>
    </>
  );
}
