import http from "../clients/http";
import httpClient from "../clients/http";
import { serializeParams } from "../utils/url";

type ProductGetListParams = {
  status: string | null;
  search: string | null;
  page: string | null;
  size: string | null;
  sort?: string | null;
  category?: string | null;
};

type INewProductForm = any;

export class ProductService {
  static async create(formData: INewProductForm): Promise<unknown> {
    const response = await http.post(`/products`, {
      name: formData.name,
      description: formData.description,
      price: Number(formData.gross),
      initialStock: Number(formData.initialStock),
      status: formData.status,
      category: { id: formData.category.value },
      code: formData.code,
      mediaAssets: formData.mediaAssets,
      cost: formData.cost,
      attributes: formData.attributes,
    });

    if (!(response.status < 299)) throw Error();

    return response.data;
  }

  static async update(id: string, formData: INewProductForm) {
    const response = await http.put(`/products/${id}`, formData);
    return response.data;
  }

  static async getList(filters: Partial<ProductGetListParams>) {
    const response = await http.get(`/products?${serializeParams(filters)}`);
    const { data } = response;

    return {
      data: data.data,
      pagination: data.pagination,
    };
  }

  static async getDetails(id: string) {
    const response = await http.get(`/products/${id}`);
    const { data } = response;
    return data;
  }

  static async getSales(id: string) {
    const response = await httpClient.get(`/products/${id}/sales`);
    return response.data;
  }

  static async getInventory(id: string, filters: ProductGetListParams) {
    const response = await httpClient.get(`/products/${id}/inventory`);
    return response.data;
  }

  static async getSalesStats(id: string) {
    const response = await httpClient.get(`/products/${id}/sales/stats`);
    return response.data;
  }

  static delete(id: string): any {
    return httpClient.delete(`products/${id}`);
  }
}
