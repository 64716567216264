import { useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import SideBar from "./SideBar";
import config from "../config";

function Layout() {
  return <AuthLayout />;
}

const EnvAwareMessage = (props: any) => {
  const subdomain = window.location.host.split(".")[0];

  return (
    <>
      <em className="icon ni ni-alert-circle"></em>
      <strong>¡Atención!</strong> Estás en un ambiente de pruebas. Para acceder
      al ambiente de producción, ingresa a{" "}
      <a href={`https://${subdomain}.shoppxy.com`} target="_blank">
        https://{subdomain}.shoppxy.com
      </a>
      <button
        className="btn btn-sm btn-link btn-icon"
        onClick={() => props.dismiss()}
      >
        <em className="icon ni ni-cross"></em>
      </button>
    </>
  );
};

const messages = [
  {
    Component: EnvAwareMessage,
    show: true,
    type: "warning",
  },
];

function AuthLayout() {
  const [isOpen, setOpen] = useState(false);
  const [showStgMessage, setStgMessage] = useState(config.env === "staging");
  const subdomain = window.location.host.split(".")[0];

  return (
    <>
      {showStgMessage ? (
        <div
          className="text-center mb-3 text-warning w-100 text-center"
          style={{
            position: "fixed",
            zIndex: 9999,
          }}
        >
          <div
            className="alert alert-warning alert-icon"
            style={{
              margin: "0 auto",
              zIndex: 9999,
            }}
          >
            <em className="icon ni ni-alert-circle"></em>
            <strong>¡Atención!</strong> Estás en un ambiente de pruebas. Para
            acceder al ambiente de producción, ingresa a{" "}
            <a href={`https://${subdomain}.shoppxy.com`} target="_blank">
              https://{subdomain}.shoppxy.com
            </a>
            <button
              className="btn btn-sm btn-link btn-icon"
              onClick={() => setStgMessage(false)}
            >
              <em className="icon ni ni-cross"></em>
            </button>
          </div>
        </div>
      ) : null}
      <section
        style={{
          paddingTop: showStgMessage ? "57px" : "0px",
        }}
      >
        <SideBar
          showAlert={showStgMessage}
          isOpen={isOpen}
          closeMenu={() => setOpen(false)}
        />
        <div className="nk-wrap">
          <Navbar
            showAlert={showStgMessage}
            onMenuClick={() => setOpen((s) => !s)}
          />
          <div className="nk-content">
            <div className="nk-content">
              <Outlet />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Layout;
