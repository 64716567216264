import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "react-query";
import { CmsService } from "../../services/cms/cms";

import { ArcElement, Chart } from "chart.js";
import { Link } from "react-router-dom";
import { useMyIntegrations } from "../../hooks/integrations";
Chart.register(ArcElement);

function DashboardCMS() {
  const { isEnabled } = useMyIntegrations("google-analytics");

  const isAnalyticsEnabled = isEnabled;

  return (
    <div className="nk-content ">
      <div className="container-fluid">
        <div className="nk-content-inner">
          <div className="nk-content-body">
            <div className="nk-block-head nk-block-head-sm">
              <div className="nk-block-between">
                <div className="nk-block-head-content">
                  <h3 className="nk-block-title page-title">
                    <FormattedMessage id="CMS Analytics Dashboard" />
                  </h3>
                  <div className="nk-block-des text-soft">
                    <p>
                      <FormattedMessage id="Welcome to CMS Analytics Dashboard." />
                    </p>
                  </div>
                </div>
                <div className="nk-block-head-content">
                  {/* <div className="toggle-wrap nk-block-tools-toggle">
                    <a
                      href="#"
                      className="btn btn-icon btn-trigger toggle-expand me-n1"
                      data-target="pageMenu"
                    >
                      <em className="icon ni ni-more-v" />
                    </a>
                    <div
                      className="toggle-expand-content"
                      data-content="pageMenu"
                    >
                      <ul className="nk-block-tools g-3">
                        <li>
                          <div className="drodown">
                            <a
                              href="#"
                              className="dropdown-toggle btn btn-white btn-dim btn-outline-light"
                              data-bs-toggle="dropdown"
                            >
                              <em className="d-none d-sm-inline icon ni ni-calender-date" />
                              <span>
                                <span className="d-none d-md-inline"><FormattedMessage id="Last"/> </span>{" "}
                                30 <FormattedMessage id="Days"/>
                              </span>
                              <em className="dd-indc icon ni ni-chevron-right" />
                            </a>
                            <div className="dropdown-menu dropdown-menu-end">
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <a href="#">
                                    <span><FormattedMessage id="Last"/> 30 <FormattedMessage id="Days"/></span>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <span><FormattedMessage id="Last"/> 6 <FormattedMessage id="Months"/> </span>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <span><FormattedMessage id="Last"/> 1 <FormattedMessage id="Year"/></span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                        <li className="nk-block-tools-opt">
                          <a href="#" className="btn btn-primary">
                            <em className="icon ni ni-reports" />
                            <span>
                              <FormattedMessage id="Reports"/>
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="nk-block">
              <div className="row g-gs">
                <div className="col-12">
                  <ul className="row g-gs preview-icon-svg">
                    <li className="col-lg-3 col-sm-6 col-12">
                      <div className="preview-icon-box card card-bordered">
                        <div className="preview-icon-wrap">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 90 90"
                          >
                            <rect
                              x={5}
                              y={5}
                              width="53.97"
                              height="69.95"
                              rx={7}
                              ry={7}
                              fill="#e3e7fe"
                              stroke="#6576ff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <path
                              d="M51.66,15H22.4A7.22,7.22,0,0,0,15,22V78a7.21,7.21,0,0,0,7.41,7H61.56A7.2,7.2,0,0,0,69,78V30.5Z"
                              fill="#fff"
                              stroke="#6576ff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <polyline
                              points="68.96 30.98 51.97 30.91 51.97 15.99"
                              fill="none"
                              stroke="#6576ff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <line
                              x1={23}
                              y1={34}
                              x2={44}
                              y2={34}
                              fill="none"
                              stroke="#c4cefe"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <line
                              x1={23}
                              y1={42}
                              x2={57}
                              y2={42}
                              fill="none"
                              stroke="#c4cefe"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <line
                              x1={23}
                              y1={50}
                              x2={57}
                              y2={50}
                              fill="none"
                              stroke="#c4cefe"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <line
                              x1={23}
                              y1={58}
                              x2={32}
                              y2={58}
                              fill="none"
                              stroke="#c4cefe"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <ellipse
                              cx="61.1"
                              cy="61.11"
                              rx="23.9"
                              ry="23.89"
                              fill="#fff"
                              stroke="#6576ff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <polygon
                              points="69.56 74.43 47.7 52.84 52.46 48.15 74.32 69.74 69.56 74.43"
                              fill="none"
                              stroke="#6576ff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <line
                              x1="54.98"
                              y1="51.16"
                              x2="54.22"
                              y2="51.91"
                              fill="none"
                              stroke="#6576ff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <line
                              x1="57.62"
                              y1="53.77"
                              x2="55.59"
                              y2="55.78"
                              fill="none"
                              stroke="#6576ff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <line
                              x1="71.22"
                              y1="67.2"
                              x2="70.46"
                              y2="67.95"
                              fill="none"
                              stroke="#6576ff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <line
                              x1="68.87"
                              y1="64.88"
                              x2="66.84"
                              y2="66.89"
                              fill="none"
                              stroke="#6576ff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <path
                              d="M69.55,48.21l5,4.89L55.42,72H51V67.6Z"
                              fill="none"
                              stroke="#6576ff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <line
                              x1="65.67"
                              y1="52.24"
                              x2="70.35"
                              y2="56.86"
                              fill="none"
                              stroke="#6576ff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                          </svg>
                        </div>
                        <div>
                          {" "}
                          <span className="title  fw-bold fs-17px">
                            <FormattedMessage id="Pages" />
                          </span>{" "}
                        </div>
                        <Link className="fw-medium" to="/cms/pages">
                          <FormattedMessage id="Go to page" />
                        </Link>
                      </div>
                    </li>
                    <li className="col-lg-3 col-sm-6 col-12">
                      <div className="preview-icon-box card card-bordered">
                        <div className="preview-icon-wrap">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 90 90"
                          >
                            <rect
                              x={5}
                              y={7}
                              width={60}
                              height={56}
                              rx={7}
                              ry={7}
                              fill="#e3e7fe"
                              stroke="#6576ff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <rect
                              x={25}
                              y={27}
                              width={60}
                              height={56}
                              rx={7}
                              ry={7}
                              fill="#e3e7fe"
                              stroke="#6576ff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <rect
                              x={15}
                              y={17}
                              width={60}
                              height={56}
                              rx={7}
                              ry={7}
                              fill="#fff"
                              stroke="#6576ff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <line
                              x1={15}
                              y1={29}
                              x2={75}
                              y2={29}
                              fill="none"
                              stroke="#6576ff"
                              strokeMiterlimit={10}
                              strokeWidth={2}
                            />
                            <circle cx={53} cy={23} r={2} fill="#c4cefe" />
                            <circle cx={60} cy={23} r={2} fill="#c4cefe" />
                            <circle cx={67} cy={23} r={2} fill="#c4cefe" />
                            <rect
                              x={22}
                              y={39}
                              width={20}
                              height={20}
                              rx={2}
                              ry={2}
                              fill="none"
                              stroke="#6576ff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <circle
                              cx={32}
                              cy="45.81"
                              r={2}
                              fill="none"
                              stroke="#6576ff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <path
                              d="M29,54.31a3,3,0,0,1,6,0"
                              fill="none"
                              stroke="#6576ff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <line
                              x1={49}
                              y1={40}
                              x2={69}
                              y2={40}
                              fill="none"
                              stroke="#6576ff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <line
                              x1={49}
                              y1={51}
                              x2={69}
                              y2={51}
                              fill="none"
                              stroke="#c4cefe"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <line
                              x1={49}
                              y1={57}
                              x2={59}
                              y2={57}
                              fill="none"
                              stroke="#c4cefe"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <line
                              x1={64}
                              y1={57}
                              x2={66}
                              y2={57}
                              fill="none"
                              stroke="#c4cefe"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <line
                              x1={49}
                              y1={46}
                              x2={59}
                              y2={46}
                              fill="none"
                              stroke="#c4cefe"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <line
                              x1={64}
                              y1={46}
                              x2={66}
                              y2={46}
                              fill="none"
                              stroke="#c4cefe"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                          </svg>
                        </div>
                        <div>
                          {" "}
                          <span className="title fw-bold fs-17px">
                            <FormattedMessage id="Collections" />
                          </span>{" "}
                        </div>
                        <Link className="fw-medium" to="/cms/collections">
                          <FormattedMessage id="View Collections" />
                        </Link>
                      </div>
                    </li>
                    <li className="col-lg-3 col-sm-6 col-12">
                      <div className="preview-icon-box card card-bordered">
                        <div className="preview-icon-wrap">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 90 90"
                          >
                            <rect
                              x={15}
                              y={5}
                              width={56}
                              height={70}
                              rx={6}
                              ry={6}
                              fill="#e3e7fe"
                              stroke="#6576ff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <path
                              d="M69.88,85H30.12A6.06,6.06,0,0,1,24,79V21a6.06,6.06,0,0,1,6.12-6H59.66L76,30.47V79A6.06,6.06,0,0,1,69.88,85Z"
                              fill="#fff"
                              stroke="#6576ff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <polyline
                              points="60 16 60 31 75 31.07"
                              fill="none"
                              stroke="#6576ff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <rect
                              x={40}
                              y={45}
                              width={23}
                              height={19}
                              fill="#e3e7fe"
                              stroke="#6576ff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <rect
                              x={36}
                              y={49}
                              width={23}
                              height={19}
                              fill="#fff"
                              stroke="#6576ff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <polyline
                              points="37 62.88 45.12 55.94 52.81 63.06 55.99 59.44 59 62.76"
                              fill="none"
                              stroke="#6576ff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <circle
                              cx="52.11"
                              cy="54.98"
                              r="2.02"
                              fill="none"
                              stroke="#6576ff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                          </svg>
                        </div>
                        <div>
                          {" "}
                          <span className="title fw-bold fs-17px">
                            <FormattedMessage id="Media" />
                          </span>{" "}
                        </div>
                        <Link className="fw-medium" to="/cms/media">
                          <FormattedMessage id="View Media" />
                        </Link>
                      </div>
                    </li>
                    <li className="col-lg-3 col-sm-6 col-12">
                      <div className="preview-icon-box card card-bordered">
                        <div className="preview-icon-wrap">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 120 118"
                          >
                            <path
                              d="M8.916,94.745C-.318,79.153-2.164,58.569,2.382,40.578,7.155,21.69,19.045,9.451,35.162,4.32,46.609.676,58.716.331,70.456,1.845,84.683,3.68,99.57,8.694,108.892,21.408c10.03,13.679,12.071,34.71,10.747,52.054-1.173,15.359-7.441,27.489-19.231,34.494-10.689,6.351-22.92,8.733-34.715,10.331-16.181,2.192-34.195-.336-47.6-12.281A47.243,47.243,0,0,1,8.916,94.745Z"
                              transform="translate(0 -1)"
                              fill="#f6faff"
                            />
                            <rect
                              x={18}
                              y={32}
                              width={84}
                              height={50}
                              rx={4}
                              ry={4}
                              fill="#fff"
                            />
                            <rect
                              x={26}
                              y={44}
                              width={20}
                              height={12}
                              rx={1}
                              ry={1}
                              fill="#e5effe"
                            />
                            <rect
                              x={50}
                              y={44}
                              width={20}
                              height={12}
                              rx={1}
                              ry={1}
                              fill="#e5effe"
                            />
                            <rect
                              x={74}
                              y={44}
                              width={20}
                              height={12}
                              rx={1}
                              ry={1}
                              fill="#e5effe"
                            />
                            <rect
                              x={38}
                              y={60}
                              width={20}
                              height={12}
                              rx={1}
                              ry={1}
                              fill="#e5effe"
                            />
                            <rect
                              x={62}
                              y={60}
                              width={20}
                              height={12}
                              rx={1}
                              ry={1}
                              fill="#e5effe"
                            />
                            <path
                              d="M98,32H22a5.006,5.006,0,0,0-5,5V79a5.006,5.006,0,0,0,5,5H52v8H45a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2H73a2,2,0,0,0,2-2V94a2,2,0,0,0-2-2H66V84H98a5.006,5.006,0,0,0,5-5V37A5.006,5.006,0,0,0,98,32ZM73,94v4H45V94Zm-9-2H54V84H64Zm37-13a3,3,0,0,1-3,3H22a3,3,0,0,1-3-3V37a3,3,0,0,1,3-3H98a3,3,0,0,1,3,3Z"
                              transform="translate(0 -1)"
                              fill="#798bff"
                            />
                            <path
                              d="M61.444,41H40.111L33,48.143V19.7A3.632,3.632,0,0,1,36.556,16H61.444A3.632,3.632,0,0,1,65,19.7V37.3A3.632,3.632,0,0,1,61.444,41Z"
                              transform="translate(0 -1)"
                              fill="#6576ff"
                            />
                            <path
                              d="M61.444,41H40.111L33,48.143V19.7A3.632,3.632,0,0,1,36.556,16H61.444A3.632,3.632,0,0,1,65,19.7V37.3A3.632,3.632,0,0,1,61.444,41Z"
                              transform="translate(0 -1)"
                              fill="none"
                              stroke="#6576ff"
                              strokeMiterlimit={10}
                              strokeWidth={2}
                            />
                            <line
                              x1={40}
                              y1={22}
                              x2={57}
                              y2={22}
                              fill="none"
                              stroke="#fffffe"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <line
                              x1={40}
                              y1={27}
                              x2={57}
                              y2={27}
                              fill="none"
                              stroke="#fffffe"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <line
                              x1={40}
                              y1={32}
                              x2={50}
                              y2={32}
                              fill="none"
                              stroke="#fffffe"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                            />
                            <line
                              x1="30.5"
                              y1="87.5"
                              x2="30.5"
                              y2="91.5"
                              fill="none"
                              stroke="#9cabff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <line
                              x1="28.5"
                              y1="89.5"
                              x2="32.5"
                              y2="89.5"
                              fill="none"
                              stroke="#9cabff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <line
                              x1="79.5"
                              y1="22.5"
                              x2="79.5"
                              y2="26.5"
                              fill="none"
                              stroke="#9cabff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <line
                              x1="77.5"
                              y1="24.5"
                              x2="81.5"
                              y2="24.5"
                              fill="none"
                              stroke="#9cabff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <circle
                              cx="90.5"
                              cy="97.5"
                              r={3}
                              fill="none"
                              stroke="#9cabff"
                              strokeMiterlimit={10}
                            />
                            <circle
                              cx={24}
                              cy={23}
                              r="2.5"
                              fill="none"
                              stroke="#9cabff"
                              strokeMiterlimit={10}
                            />
                          </svg>
                        </div>
                        <div>
                          {" "}
                          <span className="title fw-bold fs-17px">
                            <FormattedMessage id="Forms" />
                          </span>{" "}
                        </div>
                        <Link className="fw-medium" to="/cms/forms">
                          <FormattedMessage id="View Forms" />
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
                {isAnalyticsEnabled && (
                  <>
                    <div className="col-lg-6 col-xxl-6">
                      <PagesViewByUsers />
                    </div>
                    <div className="col-md-6 col-lg-6">
                      <SessionsByDevice />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

interface Page {
  path: string;
  views: number;
}

const PagesViewByUsers: React.FC = () => {
  const intl = useIntl();
  const { data, isLoading } = useQuery(
    ["cmsTrafficStats"],
    () => CmsService.getTrafficStats() as unknown as Promise<{ data: Page[] }>,
    {
      cacheTime: 1000 * 60 * 60 * 24,
    }
  );

  return (
    <div className="card card-bordered h-100">
      <div className="card-inner mb-n2">
        <div className="card-title-group">
          <div className="card-title card-title-sm">
            <h6 className="title">
              {intl.formatMessage({ id: "Visited paged by users" })}
            </h6>
          </div>
          {/*  <div className="card-tools">
            <div className="drodown">
              <a
                href="#"
                className="dropdown-toggle dropdown-indicator btn btn-sm btn-outline-light btn-white"
                data-bs-toggle="dropdown"
              >
                {intl.formatMessage({
                  id: "dropdown",
                  defaultMessage: "30 Days",
                })}
              </a>
              <div className="dropdown-menu dropdown-menu-end dropdown-menu-xs">
                <ul className="link-list-opt no-bdr">
                  <li>
                    <a href="#">
                      <span>
                        {intl.formatMessage({
                          id: "7 days",
                          defaultMessage: "7 Days",
                        })}
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>
                        {intl.formatMessage({
                          id: "15 days",
                          defaultMessage: "15 Days",
                        })}
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>
                        {intl.formatMessage({
                          id: "30 days",
                          defaultMessage: "30 Days",
                        })}
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="nk-tb-list is-compact">
        <div className="nk-tb-item nk-tb-head">
          <div className="nk-tb-col">
            <span>
              {intl.formatMessage({
                id: "page",
                defaultMessage: "Page",
              })}
            </span>
          </div>
          <div className="nk-tb-col text-end">
            <span>
              {intl.formatMessage({
                id: "Page views",
                defaultMessage: "Page Views",
              })}
            </span>
          </div>
        </div>
        {data?.data?.map((page, index) => (
          <div key={index} className="nk-tb-item">
            <div className="nk-tb-col">
              <span className="tb-sub">
                <span>{page.path}</span>
              </span>
            </div>
            <div className="nk-tb-col text-end">
              <span className="tb-sub tb-amount">
                <span>{page.views}</span>
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const SessionsByDevice: React.FC = () => {
  const intl = useIntl();
  const [selectedPeriod, setSelectedPeriod] = useState("30 Days");

  const { data, isLoading, error } = useQuery("cmsDevicesStats", () => {
    return CmsService.getDevicesStats();
  });

  const results = data?.data.reduce(
    (p: { label: string[]; dataPoints: number[]; total: 0 }, c: any) => {
      return {
        label: [...p.label, c.deviceCategory],
        dataPoints: [...p.dataPoints, Number(c.sessions)],
        total: p.total + Number(c.sessions),
      };
    },
    { label: [], dataPoints: [], total: 0 }
  );

  const getAvg = (value: number) => {
    return ((Number(value) / results?.total) * 100).toFixed(2);
  };

  const chartData = {
    labels: results?.label.map((label: string) =>
      intl.formatMessage({
        id: label[0].toUpperCase() + label.slice(1).toLocaleLowerCase(),
      })
    ),
    datasets: [
      {
        data: results?.dataPoints,
        backgroundColor: ["#798bff", "#baaeff", "#7de1f8"],
      },
    ],
  };

  const handlePeriodChange = (period: string) => {
    setSelectedPeriod(period);
    // Implement data fetching based on the selected period here
  };

  return (
    <div className="card card-bordered h-100">
      <div className="card-inner h-100 stretch flex-column">
        <div className="card-title-group">
          <div className="card-title card-title-sm">
            <h6 className="title">
              {intl.formatMessage({ id: "Sessions by Device" })}
            </h6>
          </div>
          {/*   <div className="card-tools">
            <div className="drodown">
              <a
                href="#"
                className="dropdown-toggle dropdown-indicator btn btn-sm btn-outline-light btn-white"
                data-bs-toggle="dropdown"
              >
                {selectedPeriod}
              </a>
              <div className="dropdown-menu dropdown-menu-end dropdown-menu-xs">
                <ul className="link-list-opt no-bdr">
                  <li>
                    <a href="#" onClick={() => handlePeriodChange("7 Days")}>
                      <span>{intl.formatMessage({ id: "7 Days" })}</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={() => handlePeriodChange("15 Days")}>
                      <span>{intl.formatMessage({ id: "15 Days" })}</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={() => handlePeriodChange("30 Days")}>
                      <span>{intl.formatMessage({ id: "30 Days" })}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
        <div className="device-status my-auto">
          <div className="device-status-ck">
            {isLoading ? (
              <div>Loading...</div>
            ) : error ? (
              <div>Error: {JSON.stringify(error)}</div>
            ) : (
              <Doughnut
                data={chartData}
                options={{
                  maintainAspectRatio: false,
                  responsive: true,
                }}
              />
            )}
          </div>
          <div className="device-status-group">
            <div className="device-status-data">
              <em
                data-color="#798bff"
                className="icon ni ni-monitor"
                style={{ color: "#798bff" }}
              ></em>
              <div className="title">
                {intl.formatMessage({ id: "Desktop" })}
              </div>
              <div className="amount">
                {getAvg(results?.dataPoints[0]) || 0}%
              </div>
            </div>
            <div className="device-status-data">
              <em
                data-color="#baaeff"
                className="icon ni ni-mobile"
                style={{ color: "#baaeff" }}
              ></em>
              <div className="title">
                {intl.formatMessage({ id: "Mobile" })}
              </div>
              <div className="amount">
                {getAvg(results?.dataPoints[1]) || 0}%
              </div>
            </div>
            <div className="device-status-data">
              <em
                data-color="#7de1f8"
                className="icon ni ni-tablet"
                style={{ color: "#7de1f8" }}
              ></em>
              <div className="title">
                {intl.formatMessage({ id: "Tablet" })}
              </div>
              <div className="amount">
                {getAvg(results?.dataPoints[2]) || 0}%
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardCMS;
