import { EditorState } from "draft-js";
import http from "../../clients/http";
import { editorStateToMarkdown } from "../../utils/editor";
import { serializeParams } from "../../utils/url";
import { CmsService } from "./cms";

type PageGetListParams = {
  status: string | null;
  search: string | null;
  page: string | null;
  size: string | null;
};

export class PageService {
  static async parseFormFrom(schema: any, formData: any) {
    let containsFile = false;
    for (const key in formData) {
      if (formData[key] instanceof EditorState) {
        formData[key] = editorStateToMarkdown(formData[key]);
        continue;
      }

      if (formData[key] instanceof File) {
        formData[key] = formData[key];
        const schemaProps = schema[key];
        const data = await CmsService.processFiles(
          [formData[key]],
          schemaProps.processor
        );
        formData[key] = data[0];
        containsFile = true;
        continue;
      }

      formData[key] = formData[key];
    }

    return {
      data: formData,
      containsFile,
    };
  }
  static async getList(filters: PageGetListParams) {
    const { data } = await http.get(`/cms/pages/?${serializeParams(filters)}`);

    return {
      data: data.data,
      pagination: data.pagination,
    };
  }

  static async getDetails(id: string) {
    const { data } = await http.get(`/cms/pages/${id}`);
    return data;
  }

  static async updateContent(
    id: string,
    content: Record<string, unknown> | FormData
  ) {
    const { data } = await http.put(`/cms/pages/${id}`, { content });
    return data;
  }

  static async updateImages(id: string, content: Record<string, unknown>) {
    const { data } = await http.put(
      `/cms/pages/${id}`,
      { content },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  }
}
