// PermissionsContext.tsx

import {
  Ability,
  AbilityBuilder,
  AbilityTuple,
  MongoQuery,
} from "@casl/ability";
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import Spinner from "react-bootstrap/Spinner";
import { FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import { AuthService } from "../services/auth";
type Action = "read" | "update" | "delete" | "create";
type Subject = "all";

interface AbilityContextProps {
  ability: Ability<AbilityTuple, MongoQuery>;
}

export const AbilityContext = createContext<AbilityContextProps>(
  {} as AbilityContextProps
);

interface Props {
  children: ReactNode;
}

const PermissionContextProvider: React.FC<Props> = ({ children }) => {
  const [ability, setAbility] = useState(new Ability());
  const {
    data: permissions,
    error,
    isLoading,
  } = useQuery("permissions", AuthService.getPermissions, {
    retryDelay: 25000,
  });

  useEffect(() => {
    if (permissions) {
      const { can, rules } = new AbilityBuilder(Ability);

      permissions.forEach(
        (permission: {
          id: string;
          name: string;
          description: string;
          operation: string;
          permission: string;
        }) => {
          const [module, resource] = permission.permission.split(".");

          if (permission.permission === `${module}.All`) {
            can(permission.operation, module);
          } else {
            can(permission.operation, permission.permission, { id: resource });
          }
        }
      );

      setAbility(new Ability(rules));
    }
  }, [permissions]);

  if (isLoading) {
    return (
      <div
        style={{
          height: "100vh",
          alignItems: "center",
          flexDirection: "column",
        }}
        className="d-flex justify-content-center"
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">
            <FormattedMessage id="Loading" />
          </span>
        </Spinner>
        <FormattedMessage id="Loading" />
      </div>
    );
  }

  if (error) {
    return <div>An error has occurred: {(error as any).message}</div>;
  }

  return (
    <AbilityContext.Provider value={{ ability }}>
      {children}
    </AbilityContext.Provider>
  );
};

export default PermissionContextProvider;

export const usePermissions = () => useContext(AbilityContext);
