import { useState } from "react";

export const useLocalStorage = <T>(
  keyName: string,
  defaultValue: T
): [T, (newValue: T) => void] => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const value = window.localStorage.getItem(keyName);
      if (value) {
        return JSON.parse(value) as T;
      } else {
        const endValue =
          typeof defaultValue === "string"
            ? defaultValue
            : JSON.stringify(defaultValue);

        window.localStorage.setItem(keyName, endValue);
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });

  const setValue = (newValue: T) => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {
      // Handle any potential errors when setting localStorage
    }
    setStoredValue(newValue);
  };

  return [storedValue, setValue];
};
