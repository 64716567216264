import { useState } from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";

import Dropdown, {
  DropdownMenu,
  DropdownMenuOption,
} from "../../components/Buttons/Dropdown";
import SearchBar from "../../components/Inputs/SearchBar";
import { DisplayAddress } from "../../components/Modals/DisplayAddress";
import RecordAddress from "../../components/Modals/RecordAddress";
import Table, { IPaginationProps, ITableHeader } from "../../components/Table";
import { transformToAddressType } from "../../components/Table/transformers";
import Title from "../../components/Title";
import { useTableQueryParams } from "../../hooks/router";
import { AddressDto, AddressService } from "../../services/address";
import { useConfirmationModal } from "../../contexts/confirmation";
import { toast } from "react-toastify";

function AddressTab() {
  const intl = useIntl();
  const { page, search, setParam, size } = useTableQueryParams();
  const { id } = useParams();
  const [selectedAddress, setSelectedAddress] = useState<null | AddressDto>(
    null
  );
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { open } = useConfirmationModal();

  const { data, isLoading, refetch } = useQuery(
    ["addressList", search, page, size, id],
    () => {
      return AddressService.getCustomerAddresses(id as string);
    }
  );

  const onAdd = () => {
    setSelectedAddress(null);
    setShowModal(true);
  };

  const deleteRecord = useMutation(
    (id: string) => AddressService.deleteAddress(id),
    {
      onSuccess() {
        refetch();
        toast.success(intl.formatMessage({ id: "TOAST.DELETE_SUCCESS" }));
      },
    }
  );

  const options: DropdownMenuOption<any>[] = [
    {
      text: intl.formatMessage({ id: "See detail" }),
      icon: "ni ni-eye",
      click: (entity) => {
        setSelectedAddress(entity);
        setShowDetailModal(true);
      },
    },
    {
      type: "divider",
    },
    {
      icon: "ni ni-edit",
      text: intl.formatMessage({ id: "Edit" }),
      click: (entity) => {
        setSelectedAddress(entity);
        setShowModal(true);
      },
    },
    {
      icon: "ni ni-trash",
      text: intl.formatMessage({ id: "Delete" }),
      click: (entity) => {
        open(
          intl.formatMessage(
            { id: "Are you sure you want to remove {name}" },
            { name: `dirección: ${entity.address}` }
          ),
          () => {
            deleteRecord.mutateAsync(entity.id);
          }
        );
      },
    },
  ];

  const headers: ITableHeader[] = [
    {
      path: ["address"],
      name: intl.formatMessage({ id: "Address" }),
    },
    {
      path: ["neighborhood"],
      name: intl.formatMessage({ id: "Neighborhood" }),
    },
    {
      path: ["province"],
      name: intl.formatMessage({ id: "Province" }),
    },
    {
      path: ["municipality"],
      name: intl.formatMessage({ id: "Municipality" }),
    },
    {
      path: ["type"],
      name: intl.formatMessage({ id: "Type" }),
      transform: transformToAddressType,
    },
    {
      path: ["options"],
      name: "",
      type: "tools",
      transform: (v, entity) => (
        <Dropdown
          key={entity.id}
          icon
          trigger
          text={<em className="icon ni ni-more-h" />}
        >
          <DropdownMenu options={options} entity={entity} />
        </Dropdown>
      ),
    },
  ];

  const pagination: IPaginationProps = {
    current: Number(page) || data?.pagination.page,
    size: Number(size) || data?.pagination.size,
    total: data?.pagination?.total,
    onPageChange(page) {
      setParam("page", String(page));
    },
    onSizeChange(size) {
      setParam("size", String(size));
    },
  };

  const onSearch = (term: string) => {
    setParam("search", term);
  };

  return (
    <div className="container-fluid">
      <div className="nk-content-inner">
        <div className="nk-content-body">
          <Title
            subText={intl.formatMessage(
              { id: "You have a total of {number} {entity}." },
              { number: data?.pagination?.total || 0, entity: "direcciones" }
            )}
            rightContent={
              <ul className="nk-block-tools g-3">
                <li>
                  <SearchBar onChange={(e) => onSearch(e.target.value)} />
                </li>
                <li>
                  <Button onClick={onAdd}>
                    <FormattedMessage id="Add Address" />
                  </Button>
                </li>
              </ul>
            }
          >
            <FormattedMessage id="Address List" />
          </Title>

          <div className="nk-block">
            <Table
              headers={headers}
              data={data?.data}
              /* selectable */
              loading={isLoading}
              pagination={pagination}
              /*  bulkOptions={[
                {
                  icon: "ni ni-na",
                  text: intl.formatMessage({ id: "Disable all" }),
                },
                {
                  icon: "ni ni-trash",
                  text: intl.formatMessage({ id: "Delete all" }),
                },
                {
                  icon: "ni ni-shield-star",
                  text: intl.formatMessage({ id: "Reset all" }),
                },
              ]} */
            />
          </div>
        </div>
      </div>
      {selectedAddress && (
        <DisplayAddress
          show={showDetailModal}
          handleClose={() => setShowDetailModal(false)}
          addressData={selectedAddress}
        />
      )}

      <RecordAddress
        customerId={id as string}
        show={showModal}
        handleClose={() => setShowModal(false)}
        refetch={refetch}
        selected={selectedAddress || undefined}
      />
    </div>
  );
}

export default AddressTab;
