import { useQuery } from "react-query";
import { AddButton } from "../../../components/Buttons/ActionableButton";
import Dropdown, {
  DropdownMenu,
  DropdownMenuOption,
} from "../../../components/Buttons/Dropdown";
import SearchBar from "../../../components/Inputs/SearchBar";
import Table, {
  IPaginationProps,
  ITableHeader,
} from "../../../components/Table";
import Title from "../../../components/Title";
import { useTableQueryParams } from "../../../hooks/router";
import { FormService } from "../../../services/cms/form";
import { formatMidDateTime } from "../../../utils/formatter";
import { getFullName, getInitials } from "../../../utils/person";
import LoadingText from "../../../components/Text/loading";
import {
  getFieldDescription,
  getFieldKey,
  isMainKey,
} from "../../../utils/entity";
import { Button } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

let timmerId: any;

function FormTable({
  name,
  displayName,
  loading,
  apiId,
  baseUrl,
  schema,
}: any) {
  const intl = useIntl();
  const { page, search, setParam, size, status } = useTableQueryParams();

  const options: DropdownMenuOption<any>[] = [
    {
      text: intl.formatMessage({ id: "See details" }),
      icon: "ni ni-eye",
      link: (entity) => `/cms/forms/${name}/${entity._id}`,
    },
    /*     {
      icon: "ni ni-edit",
      text: intl.formatMessage({ id: "Edit" }),
      link: (entity) => `/cms/forms/${name}/${entity._id}/edit`,
    }, */
    /* {
      type: "divider",
    },
    {
      icon: "ni ni-trash",
      text: intl.formatMessage({ id: "Delete" }),
      click: (entity) => {
        console.log("`/cms/forms/${name}/${entity._id}`");
        alert("No disponible");
      },
    }, */
  ];

  const {
    isLoading: exporting,
    isError,
    error,
    refetch,
  } = useQuery(
    "exportComments",
    () => FormService.exportComments(name as string),
    {
      enabled: false,
      onError(error) {
        toast.error(intl.formatMessage({ id: "Error exporting document" }));
      },
    }
  );

  const handleExport = () => {
    refetch();
  };

  const { data, isLoading } = useQuery(
    ["formList", status, search, page, size, name],
    (ctx) => {
      const [_, status, search, page, size, name] = ctx.queryKey;
      return FormService.getFormList(name, {
        status,
        search,
        page,
        size,
      });
    },
    {
      enabled: !!name,
    }
  );

  const headerSchema: ITableHeader[] = Object.keys(schema || {})

    .filter((k) => {
      if (schema[k]?.type === "relation") {
        return false;
      }
      return true;
    })
    .map((k) => {
      const name = schema[k]?.label || k;

      if (isMainKey(k)) {
        return {
          path: [k],
          name: name,
        };
      }

      return {
        path: [k],
        name: name,
        hide: "sm",
      };
    });

  const headers: ITableHeader[] = headerSchema.concat([
    {
      path: ["createdBy"],
      hide: "sm",

      name: intl.formatMessage({ id: "Author" }),
      transform: (value, obj) => {
        if (!value) return "-";
        return (
          <a href="#">
            <div className="user-card">
              <div className="user-avatar sm bg-orange-dim">
                <span>{getInitials(value)}</span>
              </div>
              <div className="user-info d-none d-md-block">
                <span className="tb-lead">{getFullName(value)}</span>
              </div>
            </div>
          </a>
        );
      },
    },
    {
      path: ["id"],
      name: intl.formatMessage({ id: "Last modification" }),
      hide: "sm",
      transform: (_, ob) => {
        return formatMidDateTime(new Date(ob?.updatedAt || ob?.createdAt));
      },
    },
    {
      path: ["options"], // no id
      name: "",
      type: "tools",
      transform: (v, entity) => (
        <Dropdown
          key={entity._id}
          icon
          trigger
          text={<em className="icon ni ni-more-h" />}
        >
          <DropdownMenu options={options} entity={entity} />
        </Dropdown>
      ),
    },
  ]);

  console.log(headers);

  const pagination: IPaginationProps = {
    current: Number(page) || data?.pagination.page,
    size: Number(size) || data?.pagination.size,
    total: data?.pagination?.total,
    onPageChange(page) {
      setParam("page", String(page));
    },
    onSizeChange(size) {
      setParam("size", String(size));
    },
  };

  const onSearch = (term: string) => {
    clearTimeout(timmerId);
    timmerId = setTimeout(() => {
      setParam("search", term);
    }, Number(process.env.REACT_APP_SEARCH_DELAY || 700));
  };

  return (
    <div className="container-fluid mt-5 form-type-container">
      <div className="nk-content-inner">
        <div className="nk-content-body">
          <Title
            size="sm"
            subText={intl.formatMessage(
              { id: "You have a total of N comments." },
              { number: data?.pagination?.total || 0 }
            )}
            rightContent={
              <ul className="nk-block-tools g-3">
                <li>
                  <SearchBar onChange={(e) => onSearch(e.target.value)} />
                </li>
                <li className="nk-block-tools-opt">
                  <Button
                    variant="light"
                    onClick={handleExport}
                    disabled={exporting}
                  >
                    <em className="icon ni ni-download me-1"></em>
                    <FormattedMessage id="Export" />
                  </Button>
                </li>
              </ul>
            }
          >
            {loading ? <LoadingText /> : displayName}
          </Title>

          <div className="nk-block compact-table-loading">
            <Table
              headersDependency={apiId}
              headers={headers}
              data={data?.data}
              /* selectable */
              loading={isLoading || loading}
              pagination={pagination}
              /*  bulkOptions={[
                {
                  icon: "ni ni-mail",
                  text: intl.formatMessage({ id: "Send email to everyone" }),
                  click: (entities) => console.log(entities),
                },
                {
                  icon: "ni ni-na",
                  text: intl.formatMessage({ id: "Disable all" }),
                },
                {
                  icon: "ni ni-trash",
                  text: intl.formatMessage({ id: "Delete all" }),
                },
                {
                  icon: "ni ni-shield-star",
                  text: intl.formatMessage({ id: "Reset all" }),
                },
              ]} */
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormTable;
