export const setParam = (key: string, value: string) => {
  const searchParams = new URLSearchParams();
  searchParams.append(key, value);
  return `?${searchParams.toString()}`;
};

export const serializeParams = (params: Record<string, unknown>) => {
  const obj: Record<string, string> = Object.fromEntries(
    Object.entries(params).filter(([_, v]) => v !== null && v !== undefined)
  ) as any;
  return new URLSearchParams(obj).toString();
};
