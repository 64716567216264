import { Col, Row, Form } from "react-bootstrap";
import { UseFormRegister, FieldErrors } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { INewDiscountForm } from "../../../interfaces/discounts/forms";

export interface DiscountFormGeneralInfoProps {
  register: UseFormRegister<INewDiscountForm>;
  errors: FieldErrors<INewDiscountForm>;
  type: string;
}

function DiscountFormGeneralInfo({
  register,
  errors,
  type,
}: DiscountFormGeneralInfoProps) {
  return (
    <>
      <div className="mb-3">
        <span className="preview-title-lg overline-title ">
          <FormattedMessage id="General Information" />
        </span>
        <p>
          <FormattedMessage id="Enter the general information of your discount" />
        </p>
      </div>
      <Form.Group className="mb-3">
        <Form.Label>
          <FormattedMessage id="Name" />
          <span className="text-danger ms-1">*</span>
        </Form.Label>
        <Form.Control
          maxLength={50}
          {...register("name", { required: true })}
          isInvalid={Boolean(errors.name)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>
          <FormattedMessage id="Code" />
          <small className="text-muted ms-1">
            (
            <FormattedMessage id="This code will be used by your customers to apply the discount" />
            )
          </small>
        </Form.Label>
        <Form.Control
          maxLength={50}
          {...register("code")}
          isInvalid={Boolean(errors.code)}
        />
        <Form.Text>
          <FormattedMessage id="If empty, a random code will be generated" />
        </Form.Text>
      </Form.Group>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>
              <FormattedMessage id="Type" />
              <span className="text-danger ms-1">*</span>
            </Form.Label>
            <Form.Select
              {...register("type", { required: true })}
              isInvalid={Boolean(errors.type)}
            >
              <option value="percentage">
                <FormattedMessage id="Percent" />
              </option>
              <option value="fixed">
                <FormattedMessage id="Amount" />
              </option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>
              {type === "percentage" ? (
                <FormattedMessage id="Percentage of discount" />
              ) : (
                <FormattedMessage id="Amount of discount" />
              )}
              <span className="text-danger ms-1">*</span>
            </Form.Label>

            <div className="form-control-wrap">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    {type === "percentage" ? "%" : "$"}
                  </span>
                </div>
                <Form.Control
                  maxLength={50}
                  {...register("value", { required: true })}
                  isInvalid={Boolean(errors.value)}
                />
              </div>
            </div>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}

export default DiscountFormGeneralInfo;
