import {
  ContentBlock,
  ContentState,
  EditorState,
  convertToRaw,
  genKey,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { marked } from "marked";

export const editorStateToMarkdown = (editorState: EditorState) => {
  const contentState = editorState.getCurrentContent();
  const rawContentState = convertToRaw(contentState);
  const html = draftToHtml(rawContentState);
  return marked(html);
};

export const convertToEditorState = (markdown: string) => {
  const blocks = htmlToDraft(markdown);
  let contentState = ContentState.createFromBlockArray(blocks as any);

  const newBlock = new ContentBlock({
    key: genKey(),
    type: "unstyled",
    text: "\n",
  });
  // Insert the new block at the beginning of the ContentState
  const blockArray = [newBlock].concat(contentState.getBlockMap().toArray());
  contentState = ContentState.createFromBlockArray(blockArray);
  return EditorState.createWithContent(contentState);
};
