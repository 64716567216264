import { useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IPaginationProps } from "../../components/Table";
import { useTableQueryParams } from "../../hooks/router";
import { INewDiscountForm } from "../../interfaces/discounts/forms";
import { DiscountService } from "../../services/discount";
import { ProductService } from "../../services/product";
import DiscountFormApplyTo from "./forms/apply-to";
import DiscountFormGeneralInfo from "./forms/general-info";
import DiscountFormHowToApply from "./forms/how-to-apply";
import DiscountFormSelectedTable from "./forms/selected-table";
import DiscountFormValidityTime from "./forms/validity-time";
import { useClientContext } from "../../contexts/client.context";
import BackButton from "../../components/Buttons/BackButton";

function DiscountAdd() {
  const navigate = useNavigate();
  const intl = useIntl();
  const { page, search, setParam, size, status } = useTableQueryParams();
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);

  const { register, handleSubmit, formState, watch, setValue, control } =
    useForm<INewDiscountForm>({
      defaultValues: {
        applyTo: "by-products",
        applyMethod: "automatic",
      },
    });

  const setApplyType = (type: INewDiscountForm["applyTo"]) => {
    if (type === "all") {
      setValue("category", undefined);
    }
    if (type === "by-products") {
      setValue("category", undefined);
    }
    setValue("applyTo", type);
  };

  const category = watch("category");
  const applyType = watch("applyTo");

  const { errors } = formState;

  const categoryLabel =
    typeof category === "string" ? category : category?.label;

  const { data, isLoading, refetch } = useQuery(
    ["productList", status, search, page, size, applyType, categoryLabel],
    (ctx) => {
      const [, status, search, page, size] = ctx.queryKey;
      return ProductService.getList({
        status,
        search,
        page,
        size,
        category: categoryLabel,
      });
    }
  );

  const createDiscountMutation = useMutation({
    mutationFn: async (formData: INewDiscountForm) => {
      return await DiscountService.create(formData);
    },
    onSuccess(data: any) {
      toast.success(
        intl.formatMessage(
          { id: "Discount was created successfully." },
          { discount: data.name }
        )
      );
      navigate("/discounts");
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    if (applyType === "by-products") {
      if (!selectedProducts.length) {
        return;
      }
      data.products = selectedProducts.map((e) => e.id);
    }

    if (applyType === "by-category") {
      if (!category) {
        return;
      }
      data.category = typeof category === "string" ? category : category.value;
    }

    await createDiscountMutation.mutateAsync({
      ...data,
      value: Number(data.value),
    });
  });

  const type = watch("type");
  const value = watch("value");
  const applyMethod = watch("applyMethod");

  const pagination: IPaginationProps = {
    current: Number(page) || data?.pagination.page,
    size: Number(size) || data?.pagination.size,
    total: data?.pagination?.total,
    onPageChange(page) {
      setParam("page", String(page));
    },
    onSizeChange(size) {
      setParam("size", String(size));
    },
  };

  return (
    <>
      <div className="nk-block-head nk-block-head-lg w-100">
        <Row>
          <Col>
            <div className="nk-block-head-content">
              <h2 className="nk-block-title fw-normal">
                <FormattedMessage id="New Discount" />
              </h2>
              <div className="nk-block-des">
                <p className="lead">
                  <FormattedMessage id="Enter the data of your new discount" />
                </p>
              </div>
            </div>
          </Col>
          <Col className="text-right">
            <BackButton />
          </Col>
        </Row>
      </div>

      <div className="nk-block nk-block-lg">
        <Row>
          <Col sm={12} md={6}>
            <div className="card card-bordered card-preview mb-3">
              <div className="card-inner">
                <Form onSubmit={onSubmit}>
                  <DiscountFormGeneralInfo
                    register={register}
                    errors={errors}
                    type={type}
                  />

                  <DiscountFormApplyTo
                    register={register}
                    errors={errors}
                    control={control}
                    applyType={applyType}
                    setApplyType={setApplyType}
                    selectedProducts={selectedProducts}
                    data={data}
                  />

                  <DiscountFormHowToApply
                    setApplyMethod={(v) => setValue("applyMethod", v)}
                    applyMethod={applyMethod}
                  />

                  <DiscountFormValidityTime
                    register={register}
                    errors={errors}
                  />

                  <div className="mt-4 text-right">
                    <br />
                    <hr />
                    <Button
                      type="submit"
                      disabled={createDiscountMutation.isLoading}
                      onClick={onSubmit}
                      size="lg"
                    >
                      {createDiscountMutation.isLoading ? (
                        <Spinner />
                      ) : (
                        <em className="icon ni ni-plus me-2"></em>
                      )}{" "}
                      <FormattedMessage id="Create" />
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6}>
            <DiscountFormSelectedTable
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
              applyType={applyType}
              category={category}
              categoryLabel={categoryLabel}
              data={data}
              isLoading={isLoading}
              pagination={pagination}
              type={type}
              value={value}
              onSearch={(search) => {
                setParam("search", search);
              }}
            />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default DiscountAdd;
