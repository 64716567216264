import React from "react";
import Spinner from "react-bootstrap/Spinner";
import { FormattedMessage } from "react-intl";
import { Link, NavLink } from "react-router-dom";
import MyPlan from "../../components/Subscriptions/MyPlan";
import SupportCard from "../../components/Support/Support";
import { useUser } from "../../contexts/auth";
import { getFullName } from "../../utils/person";

const SubscriptionPage: React.FC = () => {
  const { user } = useUser();

  if (!user) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">
            <FormattedMessage id="Loading" />
          </span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <div className="nk-content-inner">
        <div className="nk-content-body">
          <div className="nk-content-wrap">
            <div className="nk-block-head nk-block-head-lg">
              <div className="nk-block-between-md g-4">
                <div className="nk-block-head-content">
                  <h2 className="nk-block-title fw-normal">
                    <FormattedMessage
                      id="Welcome, {fullName}"
                      values={{ fullName: getFullName(user) }}
                    />
                  </h2>
                  <div className="nk-block-des">
                    <p>
                      <FormattedMessage id="Welcome to our dashboard. Manage your account and your subscriptions." />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="nk-block">
              <div className="row g-gs">
                <div className="col-md-6">
                  <div className="card card-bordered card-full">
                    <div className="nk-wg1">
                      <div
                        className="nk-wg1-block ps-3 pt-3"
                        style={{ minHeight: 150 }}
                      >
                        <div className="nk-wg1-img">{/** svg */}</div>
                        <div className="nk-wg1-text">
                          <h5 className="title">
                            <FormattedMessage id="Personal Info" />
                          </h5>
                          <p>
                            <FormattedMessage id="See your profile data and manage your Account to choose what is saved in our system." />
                          </p>
                        </div>
                      </div>
                      <div className="nk-wg1-action">
                        <Link to={"/subscriptions/account-info"}>
                          <a className="link">
                            <span>
                              <FormattedMessage id="Manage Your Account" />
                            </span>{" "}
                            <em className="icon ni ni-chevron-right" />
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card card-bordered card-full">
                    <div className="nk-wg1">
                      <div
                        className="nk-wg1-block ps-3 pt-3"
                        style={{ minHeight: 150 }}
                      >
                        <div className="nk-wg1-img">{/** svg */}</div>
                        <div className="nk-wg1-text">
                          <h5 className="title">
                            <FormattedMessage id="Billing History" />
                          </h5>
                          <p>
                            <FormattedMessage id="Check out all your payment history. You can also download or print your invoice." />
                          </p>
                        </div>
                      </div>
                      <div className="nk-wg1-action">
                        <NavLink
                          to={`/subscriptions/payments-history`}
                          className="link"
                        >
                          <span>
                            <FormattedMessage id="Payment History" />
                          </span>{" "}
                          <em className="icon ni ni-chevron-right" />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <MyPlan />
            <div className="nk-block">
              <SupportCard />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPage;
