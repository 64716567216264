import http, { apiUrl } from "../clients/http";
import { Catalog, CatalogName } from "../interfaces/catalogs/base";
import { serializeParams } from "../utils/url";

export class CatalogService {
  static async getAll<T extends Catalog>(filters: any) {
    const response = await http.get(
      `${apiUrl}/catalogs?${serializeParams(filters)}`
    );
    const { data } = response;

    return {
      data: data.data as T[],
      pagination: data.pagination,
    };
  }

  static async getCatalogoId(catalogName: string, id: string | undefined) {
    const response = await http.get(`${apiUrl}/catalogs/${catalogName}/${id}`);
    const { data } = response;

    return data;
  }

  static async getGroupedAll(filters: any) {
    const response = await http.get(
      `${apiUrl}/catalogs?${serializeParams(filters)}`
    );
    const { data } = response;

    let results: { groupName: string; catalogs: any[] }[] = [];

    for (const catalog of data.data) {
      const exists = results.find((e) => e.groupName === catalog.groupName);
      const group = exists || {
        groupName: catalog.groupName,
        catalogs: [] as any[],
      };
      group.catalogs.push(catalog);
      if (!exists) results.push(group);
    }

    return results;
  }

  static async getSchema(catalogName: string) {
    const response = await http.get(`${apiUrl}/catalogs/${catalogName}/schema`);
    const { data } = response;
    return data;
  }

  static async getList<T extends Catalog>(
    catalogName: CatalogName,
    filters: any
  ) {
    const response = await http.get(
      `${apiUrl}/catalogs/${catalogName}?${serializeParams(filters)}`
    );
    const { data } = response;

    return {
      data: data.data as T[],
      pagination: data.pagination,
    };
  }

  static async createCatalog(
    catalogName: string,
    data: Record<string, unknown>
  ) {
    const response = await http.post(`/catalogs/${catalogName}`, {
      data,
    });
    return response.data;
  }

  static async updateContent(
    key: string,
    id: string,
    data: Record<string, unknown>
  ) {
    await http.put(`/catalogs/${key}/${id}`, {
      data,
    });
    return data;
  }

  static async deleteCatalog(catalogName: string, id: string) {
    const response = await http.delete(`/catalogs/${catalogName}/${id}`);
    return response.data;
  }

  static async getDetails(catalogName: string, id: string) {
    const response = await http.get(`/catalogs/${catalogName}/${id}`);
    const { data } = response;
    return data;
  }
}
