import React from "react";
import { useMutation, useQueries, useQuery } from "react-query";
import Spinner from "react-bootstrap/Spinner";
import { Form, Link } from "react-router-dom";
import { Addon, IntegrationsService } from "../../services/integrations";
import { FormattedMessage } from "react-intl";
import Authorize from "../../components/Permission/Authorize";
import { Action, BasePermission } from "../../enums/permissions";
import { AddonsStatus } from "../../enums/status";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useConfirmationModal } from "../../contexts/confirmation";
import Title from "../../components/Title";

const IntegrationPage: React.FC = () => {
  const queries = useQueries([
    {
      queryKey: "addons",
      queryFn: () => IntegrationsService.getAddons(),
    },
    {
      queryKey: "my-integrations",
      queryFn: () => IntegrationsService.getMyAddons(),
    },
  ]);

  const isLoading = queries.some((query) => query.isLoading);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">
            <FormattedMessage id="Loading" />
          </span>
        </Spinner>
      </div>
    );
  }

  const [{ data: addons }, { data: myAddons }] = queries;

  const notMyAddons = addons?.filter((addon) => {
    const myAddon = myAddons?.find((myAddon) => myAddon.id === addon.id);
    return !myAddon;
  });

  const refetch = () => {
    queries.forEach((query) => query.refetch());
  };

  return (
    <div className="container-fluid">
      <div className="nk-content-inner">
        <div className="nk-content-body">
          <Title
            subText={
              <>
                <FormattedMessage id="Here you can integrate your account with other services" />
                .
              </>
            }
          >
            <FormattedMessage id="Integrations" />
          </Title>

          {myAddons?.length ? (
            <div className="nk-block">
              <div className="mb-4">
                <h6>
                  <FormattedMessage id="Your integrations" />
                </h6>
                <hr />
              </div>
              <div className="row g-gs">
                {myAddons?.map((app, index) => (
                  <div key={index} className="col-xxl-6">
                    <AddonCard refetch={refetch} addon={app} />
                  </div>
                ))}
              </div>
            </div>
          ) : null}

          {notMyAddons?.length ? (
            <div className="nk-block">
              <div className="mb-4">
                <h6>Todas las integraciones</h6>
                <hr />
              </div>

              <div className="row g-gs">
                {notMyAddons?.map((app, index) => (
                  <div key={index} className="col-xxl-6">
                    <AddonCard refetch={refetch} addon={app} />
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default IntegrationPage;

function AddonCard({ addon: app, refetch }: { addon: Addon; refetch: any }) {
  const { open } = useConfirmationModal();
  const addNewIntegration = useMutation(
    (addonId: string) => IntegrationsService.integrateAddon(addonId, {}),
    {
      onSuccess: () => {
        toast.success(`Se ha integrado ${app.name}`);
        refetch();
      },
    }
  );

  const onAdd = () => {
    open(`¿Estás seguro que deseas integrar ${app.name}?`, () => {
      return addNewIntegration.mutateAsync(app.id);
    });
  };

  return (
    <div className="nk-download">
      <div className="data">
        <div className="thumb">
          <img src={app.imageUrl} alt="" />
        </div>
        <div className="info">
          <h6 className="title">
            <span className="name">{app.name}</span>
            <span className="badge badge-dim bg-primary rounded-pill">
              {app.status === "New" ? "Nuevo" : ""}
            </span>
          </h6>
          <div className="meta">
            <span className="version">
              <span className="text-soft">
                <FormattedMessage id="Versión" />:{" "}
              </span>{" "}
              <span>{app.version}</span>
            </span>
            {app.integration && (
              <span className="release">
                <span className="text-soft">
                  <FormattedMessage id="Status" />:{" "}
                </span>{" "}
                <span
                  className={`ms-1 badge has-bg text-${
                    AddonsStatus[
                      app.integration.status as keyof typeof AddonsStatus
                    ].class
                  }`}
                >
                  {
                    AddonsStatus[
                      app.integration.status as keyof typeof AddonsStatus
                    ].text
                  }
                </span>
              </span>
            )}
          </div>
        </div>
      </div>

      {app.integration ? (
        <Authorize actions={[Action.UPDATE]} subjects={[BasePermission.Addons]}>
          <div className="actions">
            <Link
              to={`/integrations/${app.key}`}
              className={`btn btn-secondary`}
            >
              Configurar
            </Link>
          </div>
        </Authorize>
      ) : (
        <Authorize actions={[Action.CREATE]} subjects={[BasePermission.Addons]}>
          <div className="actions">
            <Button
              onClick={onAdd}
              disabled={addNewIntegration.isLoading}
              className={`btn btn-primary`}
            >
              Obtener
            </Button>
          </div>
        </Authorize>
      )}
    </div>
  );
}
