import React, { useState } from "react";
import Title from "../../components/Title";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { UseQueryResult, useQuery } from "react-query";
import { OrderService } from "../../services/order";
import { Link, useParams } from "react-router-dom";
import Table, { ITableHeader } from "../../components/Table";
import { formatCurrency, formatRelativeDate } from "../../utils/formatter";
import { getInitials, getFullName } from "../../utils/person";
import { transformToOrderStatus } from "../../components/Table/transformers";
import ChangeOrderStatus from "../../components/Modals/ChangeOrderStatus";
import { OrderStatus } from "../../enums/status";
import { FormattedMessage, useIntl } from "react-intl";
import Authorize from "../../components/Permission/Authorize";
import { Action, BasePermission } from "../../enums/permissions";

function OrderDetails() {
  const { id: orderId } = useParams();
  const orderDetailsQuery = useQuery(["orderDetails", orderId], () =>
    OrderService.getById(String(orderId))
  );

  const handleClose = () => {
    orderDetailsQuery.refetch();
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const [show, setShow] = useState(false);

  const showChangeStatus =
    orderDetailsQuery.isLoading ||
    (orderDetailsQuery.isSuccess &&
      orderDetailsQuery.data.status !== "delivered");

  return (
    <div className="container-fluid">
      <div className="nk-content-inner">
        <div className="nk-content-body">
          {orderDetailsQuery.isSuccess &&
            orderDetailsQuery.data.status !== OrderStatus.delivered && (
              <ChangeOrderStatus
                order={orderDetailsQuery.data}
                show={show}
                onHide={handleClose}
              />
            )}
          <Title
            subText={
              <>
                <FormattedMessage id="Realized by" />{" "}
                <Link to={`/customers/${orderDetailsQuery.data?.customer?.id}`}>
                  <Button variant="link" className="px-0">
                    {orderDetailsQuery.data?.customer &&
                      getFullName(orderDetailsQuery.data?.customer.user)}
                  </Button>
                </Link>
              </>
            }
            rightContent={
              <ul className="nk-block-tools g-3">
                <li className="nk-block-tools-opt">
                  <Button variant="light">
                    <em className="icon ni ni-printer-fill me-1" />
                    <FormattedMessage id="Print" />
                  </Button>
                </li>
                <li className="nk-block-tools-opt">
                  {showChangeStatus ? (
                    <Authorize
                      actions={[Action.UPDATE]}
                      subjects={[BasePermission.Order]}
                    >
                      <Button onClick={handleShow}>
                        <FormattedMessage id="Change Status" />
                      </Button>
                    </Authorize>
                  ) : (
                    <Button variant="secondary" onClick={handleShow}>
                      <em className="icon ni ni-alert me-1" />{" "}
                      <FormattedMessage id="report a problem" />
                    </Button>
                  )}
                </li>
              </ul>
            }
          >
            <FormattedMessage id="order details" /> #
            {orderDetailsQuery.data?.code}
          </Title>

          <div className="nk-block">
            <div className="row">
              <div className="col-xxl-4 col-md-4">
                <OrderHistoryChanges {...orderDetailsQuery} />
              </div>
              <div className="col-xxl-8 col-md-8">
                <div className="h-100">
                  <OrderDeliveryInfo {...orderDetailsQuery} />
                  <OrderItemList {...orderDetailsQuery} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function OrderHistoryChanges({ data, isLoading }: UseQueryResult<any, any>) {
  const { changes = [] } = data || ({} as any);
  return (
    <div className="card h-100">
      <div className="card-inner">
        <div className="card-title-group mb-2">
          <div className="card-title">
            <h6 className="title">
              <FormattedMessage id="change history" />
            </h6>
          </div>
          <div className="card-tools">
            <div className="dropdown">
              <a
                href="#"
                className="dropdown-toggle link link-light link-sm dropdown-indicator"
                data-bs-toggle="dropdown"
              >
                <FormattedMessage id="More recent" />
              </a>
              <div className="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                <ul className="link-list-opt no-bdr">
                  <li>
                    <a href="#">
                      <span>
                        <FormattedMessage id="More recent" />
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="active">
                      <span>
                        <FormattedMessage id="oldest" />
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {changes.length ? (
          <ul className="nk-top-products">
            {changes.map((ch: any) => {
              return (
                <li className="item">
                  <div className="user-card">
                    <div className="user-avatar bg-primary">
                      <span>{getInitials(ch.user)}</span>
                    </div>
                    <div className="user-info">
                      <p className="mb-0">{getFullName(ch.user)}</p>
                      <small>{ch.user.email}</small>
                    </div>
                  </div>

                  <div className="total" style={{ margin: "auto" }}>
                    {transformToOrderStatus(ch.status)}
                  </div>
                  <div>{formatRelativeDate(new Date(ch.createdAt))}</div>
                </li>
              );
            })}
          </ul>
        ) : (
          <div className="py-5">
            <h5 className="text-soft">
              <FormattedMessage id="No data to display" />{" "}
            </h5>
          </div>
        )}
      </div>
    </div>
  );
}

function OrderDeliveryInfo({ isLoading, data }: UseQueryResult<any, unknown>) {
  return (
    <Row className="mb-4">
      <Col>
        <div className="card slim-table-loading">
          <div className="card-inner">
            <div className="card-title-group">
              <div className="card-title">
                <h6 className="title">
                  <FormattedMessage id="Shipping info" />
                </h6>
              </div>
            </div>
            {isLoading &&
              Array(2)
                .fill({})
                .map((_, i) => (
                  <div className="loading-skeleton" key={i}>
                    <div className="skeleton-row">
                      <div className="block">
                        <FormattedMessage id="Loading" />
                      </div>{" "}
                    </div>
                  </div>
                ))}
            {!isLoading && data?.address ? (
              <div className="mt-3">
                <b>
                  {data?.address?.address} en {data?.address?.neighborhood}
                </b>
                <p>
                  {data?.address?.province}, {data?.address?.municipality}
                </p>

                <p className="mt-4 ff-italic text-soft">
                  <b className="mr-2">Referencia:</b> {data?.address?.reference}
                </p>
              </div>
            ) : !isLoading && data?.shippingAddress ? (
              <div className="mt-3">{data?.shippingAddress}</div>
            ) : !isLoading ? (
              <div className="py-5">
                <h5 className="text-soft">
                  <FormattedMessage id="No data to display" />{" "}
                </h5>
              </div>
            ) : null}
          </div>
        </div>
      </Col>
      <Col>
        <div className="card slim-table-loading">
          <div className="card-inner">
            <div className="card-title-group">
              <div className="card-title">
                <h6 className="title">
                  <FormattedMessage id="Billing info" />
                </h6>
              </div>
            </div>

            {isLoading &&
              Array(2)
                .fill({})
                .map((_, i) => (
                  <div className="loading-skeleton" key={i}>
                    <div className="skeleton-row">
                      <div className="block">
                        <FormattedMessage id="Loading" />
                      </div>{" "}
                    </div>
                  </div>
                ))}

            {!isLoading && data?.billing ? (
              <>
                {data?.billing?.address && (
                  <div className="mt-3">
                    Dirección de pago:
                    <p>{data?.billing.address}, </p>
                  </div>
                )}
                {data?.billing.taxInfo && (
                  <p className="mt-4 ff-italic text-soft">
                    <b className="mr-2">Facturar a nombre de:</b>{" "}
                    {data.billing.taxInfo.name} - Identificación:{" "}
                    {data.billing.taxInfo.ide}
                  </p>
                )}
                {!data?.billing?.address &&
                  !data?.billing.taxInfo &&
                  !isLoading && (
                    <div className="py-5">
                      <h5 className="text-soft">
                        <FormattedMessage id="No data to display" />{" "}
                      </h5>
                    </div>
                  )}{" "}
              </>
            ) : !isLoading ? (
              <div className="py-3">
                <p className="text-soft">
                  <FormattedMessage id="No data to display" />{" "}
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </Col>
    </Row>
  );
}

function OrderItemList({ isLoading, data }: UseQueryResult<any, unknown>) {
  const intl = useIntl();
  const headers: ITableHeader[] = [
    {
      path: ["id"],
      name: "ID",
      transform: (value, obj) => (
        <span className="tb-lead">
          <a href="#">#{obj.product?.code}</a>
        </span>
      ),
    },
    {
      path: ["productName"],
      name: intl.formatMessage({ id: "productNameHeader" }),
      className: "lg-table-col",
      transform: (value, obj) => {
        return (
          <span className="tb-product">
            <img
              src={
                obj.product?.mediaAssets[0]?.url ||
                obj.product?.mediaAssets[0]?.thumbnail
              }
              height={50}
              alt=""
              className="thumb"
            />
            <span className="title">{value}</span>
          </span>
        );
      },
    },
    {
      path: ["quantity"],
      name: intl.formatMessage({ id: "amount" }),
    },
    {
      path: ["price"],
      name: intl.formatMessage({ id: "priceHeader" }),
      transform: formatCurrency,
    },
    {
      path: ["price"],
      name: intl.formatMessage({ id: "Total" }),
      transform: (_, obj) => {
        const value = obj.price * obj.quantity;
        return formatCurrency(value);
      },
    },
    /*  {
      path: ["options"], // no id
      name: "",
      type: "tools",
      transform: (v, entity) => (
        <Dropdown
          key={entity.id}
          icon
          trigger
          text={<em className="icon ni ni-more-h" />}
        >
          <DropdownMenu options={options} entity={entity} />
        </Dropdown>
      ),
    }, */
  ];

  return (
    <div className="card slim-table-loading">
      <div className="card-inner">
        <div className="card-title-group">
          <div className="card-title">
            <h6 className="title">
              <FormattedMessage id="order items" />
            </h6>
          </div>
        </div>
      </div>
      <Table
        className="slim-borders"
        loading={isLoading}
        headers={headers}
        data={data?.items}
      />
      <div className="card-inner text-right">
        <div className="row">
          {isLoading ? (
            <>
              <div className="col-md-6"></div>
              <div className="col-md-6">
                {Array(2)
                  .fill({})
                  .map((_, i) => (
                    <div className="loading-skeleton" key={i}>
                      <div className="skeleton-row">
                        <div className="block">
                          <FormattedMessage id="Loading" />
                        </div>{" "}
                      </div>
                    </div>
                  ))}
              </div>
            </>
          ) : (
            <div className="col">
              <div className="row">
                <div className="col text-right px-0">
                  <h6>
                    <FormattedMessage id="Total" />:
                  </h6>
                </div>
                <div className="col-sm-3 pl-0">
                  {" "}
                  {formatCurrency(data?.subTotal)}
                </div>
              </div>

              <div className="row">
                <div className="col text-right px-0">Impuestos:</div>
                <div className="col-sm-3 pl-0">
                  {" "}
                  {formatCurrency(data?.tax)}
                </div>
              </div>
              <div className="row mt-1">
                <div className="col text-right px-0">
                  Envió{" "}
                  <span className="text-muted">
                    ({data?.delivery?.description})
                  </span>
                  :
                </div>
                <div className="col-sm-3 pl-0">
                  {formatCurrency(data?.delivery?.price)}
                </div>
              </div>

              <div className="row">
                <div
                  className="col px-0"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <h6
                    style={{ marginRight: "0.5em", fontWeight: "normal" }}
                    className="d-inline-block"
                  >
                    Total Gral:
                  </h6>
                </div>
                <div className="col-sm-3 pl-0">
                  <h4 className="mt-3 d-inline-block">
                    {formatCurrency(data?.total)}
                  </h4>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default OrderDetails;
