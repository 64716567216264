import React, { ReactNode, createContext, useContext, useState } from "react";
import { toast, ToastContainer, ToastOptions, useToast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface ToastContextType {
  show: (message: string, type?: ToastOptions["type"]) => void;
  toggle: () => void;
  isOpen: boolean;
}

export const ToastContext = createContext<ToastContextType>({
  show: () => {},
  toggle: () => {},
  isOpen: false,
});

export const useContextToast = () => useContext(ToastContext);

export const ToastProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isToastOpen, setIsToastOpen] = useState(false);

  const show = (message: string, type: ToastOptions["type"] = "success") => {
    toast(message, {
      type,
    });
  };

  const toggle = () => {
    setIsToastOpen((prev) => !prev);
  };

  return (
    <ToastContext.Provider value={{ show, toggle, isOpen: isToastOpen }}>
      {children}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </ToastContext.Provider>
  );
};
