import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import FormGenerator from "../../../components/ContentManager/generator";
import { useContextToast } from "../../../contexts/toast";
import { PageService } from "../../../services/cms/page";
import { fixIncomingFormat } from "../../../utils/media";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Col, Row } from "react-bootstrap";

export default function PageEdit() {
  const { id } = useParams();
  const intl = useIntl();
  const navigate = useNavigate();
  const methods = useForm();
  const toaster = useContextToast();

  const { data, isLoading } = useQuery(
    ["pageDetails", id],
    () => PageService.getDetails(String(id)),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      onSuccess(data) {
        const { values } = fixIncomingFormat(data.values, data.schema);
        methods.reset(values);
      },
    }
  );

  const onSubmit = async (formData: any) => {
    const { data: values } = await PageService.parseFormFrom(
      data?.schema,
      formData
    );

    await PageService.updateContent(String(id), values);
    toaster.show(intl.formatMessage({ id: "TOAST.UPDATE_SUCCESS" }));
  };

  return (
    <div className="components-preview wide-md mx-auto">
      <div className="nk-block-head nk-block-head-lg">
        <div className="nk-block-head-content">
          <div className="nk-block-head-sub">
            <Row>
              <Col>
                <a
                  role="button"
                  className="back-to"
                  onClick={() => navigate(-1)}
                >
                  <em className="icon ni ni-arrow-left" />
                  <span>
                    <FormattedMessage id="pageListTitle" />
                  </span>
                </a>
              </Col>
              <Col className="text-right">
                <Button
                  variant="link"
                  onClick={() => {
                    window.open(data?.link, "_blank");
                  }}
                >
                  <span>
                    <FormattedMessage id="View Page" />
                  </span>
                  <em className="icon ni ni-external" />
                </Button>
              </Col>
            </Row>
          </div>

          <h2 className="nk-block-title fw-normal">{data?.name}</h2>
          <div className="nk-block-des">
            <p className="lead">{data?.desc}</p>
          </div>
        </div>
      </div>
      <div className="nk-block nk-block-lg">
        <div className="nk-block-head">
          <div className="nk-block-head-content">
            <h4 className="title nk-block-title">
              <FormattedMessage id="Available fields" />
            </h4>
          </div>
        </div>
        <div className="card card-bordered card-preview">
          <div className="card-inner">
            <div className="preview-block">
              {!data || isLoading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">
                      <FormattedMessage id="loading" />
                    </span>
                  </div>
                </div>
              ) : (
                <FormGenerator
                  methods={methods}
                  schema={data.schema}
                  onSubmit={onSubmit}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
