import React from "react";
import { Accordion, Button, Form, Spinner } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import {
  Controller,
  FieldValues,
  FormProvider,
  UseFormReturn,
  useFieldArray,
} from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import AsyncSelect from "react-select/async";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { getFieldDescription } from "../../utils/entity";
import { getSelectOptions, styles } from "../Select";
import AssetSelection from "./AssetSelection";
import MultimediaField from "./MediaFields/MultimediaField";

type SchemaType =
  | "string"
  | "enumeration"
  | "object"
  | "component"
  | "repeatable-component";

type Schema = {
  [key: string]: {
    type: SchemaType;
    label: string;
    options?: string[] | { label: string; value: string }[];
    schema?: Schema;
  };
};

interface FormGeneratorProps {
  schema: Schema;
  onSubmit: (data: any) => void;
  loading?: boolean;
  methods: UseFormReturn<FieldValues, any>;
  readOnly?: boolean;
}

const ArrayGenerator = ({
  keyName: key,
  label,
  methods,
  schema,
  renderField,
}: any) => {
  const { append, remove, fields } = useFieldArray({
    name: key,
    control: methods.control,
  });
  const intl = useIntl();

  return (
    <div className="child-form" key={key}>
      <section className="title">
        <h3>{label}</h3>
        <Button size="sm" variant="light" onClick={() => append({})}>
          <em className="icon ni ni-plus"></em>
        </Button>
        <hr />
      </section>
      <section className="content">
        {fields?.length ? (
          <Accordion>
            {fields.map((field, index: number) => {
              return (
                <Accordion.Item eventKey={field.id} key={field.id}>
                  <Accordion.Header>
                    {getFieldDescription(field) || `${label} #${index + 1}`}
                  </Accordion.Header>
                  <Accordion.Body className="px-4">
                    <React.Fragment key={field.id}>
                      <div className="item">
                        {Object.entries(schema || {}).map(
                          ([nestedKey, nestedFieldSchema]) =>
                            renderField(
                              `${key}[${index}].${nestedKey}`,
                              nestedFieldSchema
                            )
                        )}
                        <div className="option">
                          <Button
                            size="sm"
                            variant="danger"
                            onClick={() => {
                              remove(index);
                            }}
                          >
                            <FormattedMessage id="Delete" />
                            <em className="icon ni ni-cross"></em>
                          </Button>
                        </div>
                      </div>
                    </React.Fragment>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        ) : (
          <small>
            <FormattedMessage id="No records found" />
          </small>
        )}
      </section>
    </div>
  );
};

const FormAsyncSelect = React.memo(
  ({ onChange, onBlur, value, name, ref, path }: any) => {
    return (
      <AsyncSelect
        styles={styles}
        noOptionsMessage={() => (
          <span>
            <FormattedMessage id="No data to display" />
          </span>
        )}
        loadingMessage={() => (
          <span>
            <FormattedMessage id="Loading" />
          </span>
        )}
        cacheOptions
        defaultOptions
        placeholder={<FormattedMessage id="Select" />}
        onBlur={onBlur}
        name={name}
        value={value}
        onChange={onChange}
        loadOptions={async (s) => getSelectOptions(s, path)}
      />
    );
  }
);

const FormGenerator: React.FC<FormGeneratorProps> = ({
  schema,
  onSubmit,
  methods,
  loading,
  readOnly,
}) => {
  const intl = useIntl(); // Internationalization

  const renderField = (key: string, fieldSchema: any) => {
    const {
      type = "string",
      label: schemaLabel,
      options,
      schema,
      conditionals,
      source = [],
      ...schemaProps
    } = fieldSchema;

    const label = schemaLabel || key;

    const errors = methods.formState.errors;

    const error =
      methods.formState.errors && methods.formState.errors[key] ? (
        <Form.Text className="d-block text-danger">
          {(methods.formState.errors[key] as any)?.message || "Error"}
        </Form.Text>
      ) : null;

    const attributes = {
      required: schemaProps?.required || false,
      ...schemaProps.attributes,
    };

    const help = schemaProps.subText ? (
      <Form.Text id={`${key}-sub-text`} muted>
        {schemaProps.subText}
      </Form.Text>
    ) : (
      <></>
    );

    switch (type) {
      case "relation":
        if (schemaProps.private) return;
        return (
          <Controller
            rules={{
              required: attributes.required
                ? intl.formatMessage({ id: "This field is required" })
                : undefined,
            }}
            key={key}
            defaultValue={source[0]?._id}
            name={key}
            control={methods.control}
            render={({ field }) => {
              return (
                <Form.Group>
                  <Form.Label>
                    {label}{" "}
                    {attributes?.required ? (
                      <span className="text-danger">*</span>
                    ) : (
                      ""
                    )}
                  </Form.Label>
                  <Form.Select
                    {...attributes}
                    disabled={readOnly}
                    onChange={(event) => field.onChange(event.target.value)}
                    value={field.value}
                  >
                    <option
                      selected
                      disabled
                      value=""
                      style={{ opacity: "0.6" }}
                    >
                      <FormattedMessage id="Select" />
                    </option>
                    {source?.map((e: any) => (
                      <option key={e._id} value={e._id}>
                        {getFieldDescription(e)}
                      </option>
                    ))}
                  </Form.Select>
                  {help}
                  {error}
                </Form.Group>
              );
            }}
          />
        );
      case "uid":
        return (
          <Controller
            rules={{
              required: attributes.required
                ? intl.formatMessage({ id: "This field is required" })
                : undefined,
            }}
            key={key}
            name={key}
            control={methods.control}
            render={({ field }) => (
              <Form.Group>
                <Form.Label>
                  {label}{" "}
                  {attributes?.required ? (
                    <span className="text-danger">*</span>
                  ) : (
                    ""
                  )}
                </Form.Label>
                <Form.Control
                  isInvalid={Boolean(errors[(field as any)[key]])}
                  {...field}
                  disabled
                  readOnly
                />
                {help}
                {error}
              </Form.Group>
            )}
          />
        );
      case "text":
        return (
          <Controller
            rules={{
              required: attributes.required
                ? intl.formatMessage({ id: "This field is required" })
                : undefined,
            }}
            key={key}
            name={key}
            control={methods.control}
            render={({ field }) => (
              <Form.Group>
                <Form.Label>
                  {label}{" "}
                  {attributes?.required ? (
                    <span className="text-danger">*</span>
                  ) : (
                    ""
                  )}
                </Form.Label>
                <Form.Control
                  disable={readOnly}
                  isInvalid={Boolean(errors[key])}
                  as="textarea"
                  {...field}
                  {...attributes}
                />
                {help}
                {error}
              </Form.Group>
            )}
          />
        );
      case "date":
        return (
          <Controller
            rules={{
              required: attributes.required
                ? intl.formatMessage({ id: "This field is required" })
                : undefined,
            }}
            key={key}
            name={key}
            control={methods.control}
            render={({ field }) => (
              <Form.Group>
                <Form.Label>
                  {label}{" "}
                  {attributes?.required ? (
                    <span className="text-danger">*</span>
                  ) : (
                    ""
                  )}
                </Form.Label>
                <Form.Control
                  disable={readOnly}
                  isInvalid={Boolean(errors[key])}
                  type="date"
                  {...field}
                  {...attributes}
                />
                {help}
                {error}
              </Form.Group>
            )}
          />
        );
      case "richtext":
        return (
          <Controller
            rules={{
              required: attributes.required
                ? intl.formatMessage({ id: "This field is required" })
                : undefined,
            }}
            key={key}
            name={key}
            control={methods.control}
            render={({ field }) => {
              const toolbarOptions = {
                options: [
                  "inline",
                  "blockType",
                  "list",
                  "history",
                  "image",
                  "embedded",
                ],
                inline: {
                  options: ["bold", "italic", "underline"],
                },
                blockType: {
                  inDropdown: false,
                  options: ["Normal", "H1", "H2", "H3", "Blockquote", "Code"],
                  className: undefined,
                  component: undefined,
                },
                list: { options: ["unordered", "ordered"] },
              };

              return (
                <Form.Group>
                  <Form.Label>
                    {label}{" "}
                    {attributes?.required ? (
                      <span className="text-danger">*</span>
                    ) : (
                      ""
                    )}
                  </Form.Label>
                  <Editor
                    editorClassName="form-control form-editor"
                    {...attributes}
                    localization={{
                      locale: "es",
                    }}
                    toolbar={toolbarOptions}
                    onEditorStateChange={(state) => field.onChange(state)}
                    editorState={field.value}
                  />
                </Form.Group>
              );
            }}
          />
        );
      case "boolean":
        return (
          <Controller
            rules={{
              required: attributes.required
                ? intl.formatMessage({ id: "This field is required" })
                : undefined,
            }}
            key={key}
            name={key}
            control={methods.control}
            render={({ field }) => {
              return (
                <Form.Group>
                  <div
                    className={`custom-control custom-switch me-n2 mt-4 mb-2 ${
                      field.value ? "checked" : ""
                    }`}
                  >
                    <input
                      type="checkbox"
                      id={`switch-${key}`}
                      className="custom-control-input"
                      {...field}
                      {...attributes}
                      defaultChecked={field.value}
                    />
                    <Form.Label
                      htmlFor={`switch-${key}`}
                      className="custom-control-label"
                    >
                      {label}
                    </Form.Label>
                  </div>
                  {help ? (
                    <>
                      <br /> {help}
                      {error}
                    </>
                  ) : (
                    ""
                  )}
                </Form.Group>
              );
            }}
          />
        );
      case "string":
        return (
          <Controller
            rules={{
              required: attributes.required
                ? intl.formatMessage({ id: "This field is required" })
                : undefined,
            }}
            key={key}
            name={key}
            control={methods.control}
            render={({ field }) => (
              <Form.Group>
                <Form.Label>
                  {label}{" "}
                  {attributes?.required ? (
                    <span className="text-danger">*</span>
                  ) : (
                    ""
                  )}
                </Form.Label>
                <Form.Control
                  disable={readOnly}
                  isInvalid={Boolean(errors[key])}
                  type="text"
                  {...field}
                  {...attributes}
                />
                {help}
                {error}
              </Form.Group>
            )}
          />
        );
      case "number":
        return (
          <Controller
            rules={{
              required: attributes.required
                ? intl.formatMessage({ id: "This field is required" })
                : undefined,
            }}
            key={key}
            name={key}
            control={methods.control}
            render={({ field }) => (
              <Form.Group>
                <Form.Label>
                  {label}{" "}
                  {attributes?.required ? (
                    <span className="text-danger">*</span>
                  ) : (
                    ""
                  )}
                </Form.Label>
                <Form.Control
                  disable={readOnly}
                  isInvalid={Boolean(errors[key])}
                  type="number"
                  {...field}
                  {...attributes}
                />
                {help}
                {error}
              </Form.Group>
            )}
          />
        );
      case "select":
        return (
          <Form.Group key={key}>
            <Form.Label>
              {label}{" "}
              {attributes?.required ? (
                <span className="text-danger">*</span>
              ) : (
                ""
              )}
            </Form.Label>
            <Controller
              rules={{
                required: attributes.required
                  ? intl.formatMessage({ id: "This field is required" })
                  : undefined,
              }}
              name={key}
              control={methods.control}
              render={({ field }) => (
                <Form.Select
                  onChange={(event) => field.onChange(event.target.value)}
                  disabled={readOnly}
                  value={field.value}
                  aria-label="Select"
                >
                  <option value="">
                    <FormattedMessage id="Select" />
                  </option>
                  {options &&
                    options.map((option: any, index: number) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                </Form.Select>
              )}
            />
            {help}
            {error}
          </Form.Group>
        );
      case "async-select":
        /*  const load = getSelectOptionsFactory({
          path: schemaProps.path,
        }); */
        return (
          <Form.Group key={key}>
            <Form.Label>
              {label}{" "}
              {attributes?.required ? (
                <span className="text-danger">*</span>
              ) : (
                ""
              )}
            </Form.Label>
            <Controller
              rules={{
                required: attributes.required
                  ? intl.formatMessage({ id: "This field is required" })
                  : undefined,
              }}
              name={key}
              control={methods.control}
              render={({ field }) => (
                <FormAsyncSelect
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  value={field.value}
                  name={key}
                  ref={field.ref}
                  path={schemaProps.path}
                />
              )}
            />
            {help}
            {error}
          </Form.Group>
        );
      case "enumeration":
        return (
          <Form.Group key={key}>
            <Form.Label>
              {label}{" "}
              {attributes?.required ? (
                <span className="text-danger">*</span>
              ) : (
                ""
              )}
            </Form.Label>
            <Controller
              rules={{
                required: attributes.required
                  ? intl.formatMessage({ id: "This field is required" })
                  : undefined,
              }}
              name={key}
              control={methods.control}
              render={({ field }) => (
                <Form.Select
                  onChange={(event) => field.onChange(event.target.value)}
                  disabled={readOnly}
                  value={field.value}
                  aria-label="Select"
                >
                  <option value="">
                    <FormattedMessage id="Select" />
                  </option>
                  {options &&
                    options.map((option: string, index: number) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                </Form.Select>
              )}
            />
            {help}
            {error}
          </Form.Group>
        );
      case "multiple-media":
        return (
          <Controller
            rules={{
              required: attributes.required
                ? "Este campo es requerido"
                : undefined,
            }}
            key={key}
            name={key}
            control={methods.control}
            render={({ field }) => {
              return (
                <MultimediaField
                  label={label}
                  field={field}
                  key={key}
                  fieldKey={key}
                  help={help}
                />
              );
            }}
          />
        );
      case "media":
        return (
          <Controller
            rules={{
              required: attributes.required
                ? intl.formatMessage({ id: "This field is required" })
                : undefined,
            }}
            key={key}
            name={key}
            control={methods.control}
            render={({ field }) => {
              return (
                <Form.Group>
                  <Form.Label>{label}</Form.Label>
                  <AssetSelection
                    id={key}
                    name={key}
                    onRemove={() => field.onChange(null)}
                    onSelect={(media) => {
                      console.log(media);
                      field.onChange(media);
                    }}
                    defaultAsset={field.value}
                  />
                  {help}
                  {error}
                </Form.Group>
              );
            }}
          />
        );
      case "component":
        return (
          <div key={key}>
            <h3>{label}</h3>
            {Object.entries(schema || {}).map(
              ([nestedKey, nestedFieldSchema]) =>
                renderField(`${key}.${nestedKey}`, nestedFieldSchema)
            )}
          </div>
        );
      case "repeatable-component":
        return (
          <ArrayGenerator
            key={key}
            keyName={key}
            label={label}
            methods={methods}
            renderField={renderField}
            schema={schema}
          />
        );
      case "file":
        return (
          <Controller
            rules={{
              required: attributes.required
                ? intl.formatMessage({ id: "This field is required" })
                : undefined,
            }}
            key={key}
            name={key}
            control={methods.control}
            render={({ field }) => {
              return (
                <Form.Group>
                  <Form.Label>{label}</Form.Label>
                  <div className="form-control-wrap mb-2">
                    <div className="form-file p-2">
                      <input
                        type="file"
                        className={`form-file-input ${
                          errors[key] ? "is-invalid" : ""
                        }`}
                        id="customFile"
                        onChange={(e) => {
                          console.log(
                            e.target.files,
                            attributes,
                            attributes.maxSize
                          );

                          if (attributes.maxSize) {
                            if (
                              e.target.files &&
                              e.target.files[0].size > attributes.maxSize
                            ) {
                              methods.setError(key, {
                                type: "manual",
                                message: `El archivo no puede superar los ${
                                  attributes.maxSize / 1000000 // MB. maxSize is in bytes
                                }MB.`,
                              });

                              return;
                            }
                          }

                          if (e.target.files != null) {
                            field.onChange(e.target.files[0]);
                          }
                        }}
                        {...attributes}
                      />
                      <label className="form-file-label" htmlFor="customFile">
                        {field.value ? (
                          <span className="badge bg-gray badge-sm ">
                            <i
                              className={`me-1 icon ni ni-file${
                                attributes.accept?.includes("pdf")
                                  ? "-pdf"
                                  : attributes.accept?.includes("xls")
                                  ? "-xls"
                                  : attributes.accept?.includes("doc")
                                  ? "-doc"
                                  : ""
                              }`}
                            ></i>{" "}
                            {field.value.name}
                          </span>
                        ) : (
                          <FormattedMessage id="Choose file" />
                        )}
                      </label>
                    </div>
                  </div>
                  {help}
                  {error}
                </Form.Group>
              );
            }}
          />
        );
      default:
        return (
          <span>
            {type} {intl.formatMessage({ id: "not supported" })}
          </span>
        );
    }
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="row gy-4">
          {Object.entries(schema).map(([key, fieldSchema]) => (
            <div key={key} className="col-sm-12">
              {renderField(key, fieldSchema)}
            </div>
          ))}
        </div>
        {!readOnly && (
          <>
            <hr />
            <div className="mt-4">
              <Button
                size="lg"
                disabled={loading || methods.formState.isSubmitting}
                type="submit"
              >
                {intl.formatMessage({ id: "Save Changes" })}

                {(loading || methods.formState.isSubmitting) && (
                  <Spinner size="sm"></Spinner>
                )}
              </Button>
            </div>
          </>
        )}
      </form>
    </FormProvider>
  );
};

export default FormGenerator;
