import { useMutation, useQuery } from "react-query";
import { AddButton } from "../../components/Buttons/ActionableButton";
import Dropdown, {
  DropdownMenu,
  DropdownMenuOption,
} from "../../components/Buttons/Dropdown";
import SearchBar from "../../components/Inputs/SearchBar";
import { FormattedMessage, useIntl } from "react-intl";

import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import DynamicDropdown from "../../components/Buttons/DynamicDropdown";
import Table, {
  IPaginationProps,
  ITableHeader,
  ITableProps,
} from "../../components/Table";
import Title from "../../components/Title";
import { ProductStatus, Status } from "../../enums/status";
import { useTableQueryParams } from "../../hooks/router";
import { ProductService } from "../../services/product";
import { formatCurrency } from "../../utils/formatter";
import { toast } from "react-toastify";
import { useConfirmationModal } from "../../contexts/confirmation";
import { Action, BasePermission } from "../../enums/permissions";
import Authorize from "../../components/Permission/Authorize";
import LazyImage from "../../components/Image/LazyImage";

let timmerId: any;

export const ProductTable = (
  props: Omit<ITableProps, "headers"> & {
    options?: DropdownMenuOption<any>[];
  }
) => {
  const intl = useIntl();

  const headers: ITableHeader[] = [
    {
      path: ["name"],
      name: intl.formatMessage({ id: "productNameHeader" }),
      className: "md-table-col",
      transform: (value, obj) => {
        return (
          <Link to={`/products/${obj.id}?t=info`}>
            <Button as="span" variant="link" className="tb-product">
              {obj.mediaAssets?.length ? (
                <LazyImage
                  src={obj.mediaAssets[0]?.thumbnail || obj.mediaAssets[0]?.url}
                  alt={`image-${value}`}
                  className="thumb"
                />
              ) : null}
              <span className="title text-primary">{value}</span>
            </Button>
          </Link>
        );
      },
    },
    {
      path: ["code"],
      name: intl.formatMessage({ id: "codeHeader" }),
      hide: "sm",
    },
    {
      path: ["price"],
      name: intl.formatMessage({ id: "priceHeader" }),
      transform: formatCurrency,
      hide: "sm",
    },
    {
      path: ["stock"],
      name: intl.formatMessage({ id: "stockHeader" }),
      hide: "sm",
      transform(value, obj) {
        return (
          <span
            className={`${value.current > 10 ? "text-black" : "text-danger"}`}
          >
            {value.current}
          </span>
        );
      },
    },
    {
      path: ["category"],
      name: intl.formatMessage({ id: "categoryHeader" }),
      transform: (value) => value?.name,
      hide: "sm",
    },
    {
      path: ["options"], // no id
      name: "",
      type: "tools",
      className: "nk-tb-col-tools",
      transform: (v, entity) => (
        <Dropdown
          key={entity.id}
          icon
          trigger
          text={<em className="icon ni ni-more-h" />}
        >
          <DropdownMenu options={props.options || []} entity={entity} />
        </Dropdown>
      ),
    },
  ];

  return (
    <Table
      {...props}
      headers={headers}

      /*  bulkOptions={[
    {
      icon: "ni ni-na",
      text: intl.formatMessage({ id: "suspendAllOption" }),
    },
  ]} */
    />
  );
};

function ProductList() {
  const { page, search, setParam, size, status } = useTableQueryParams();
  const intl = useIntl();
  const { open } = useConfirmationModal();

  const { data, isLoading, refetch } = useQuery(
    ["productList", status, search, page, size],
    (ctx) => {
      const [, status, search, page, size] = ctx.queryKey;
      return ProductService.getList({
        status,
        search,
        page,
        size,
      });
    }
  );

  const deleteProduct = useMutation({
    mutationFn: async (id: string) => {
      return await ProductService.delete(id);
    },
    onSuccess(data: any) {
      toast.success(`El producto fue eliminado exitosamente`);
      refetch();
    },
  });

  const options: DropdownMenuOption<any>[] = [
    {
      text: intl.formatMessage({ id: "See detail" }),
      icon: "ni ni-eye",
      link: (entity) => `/products/${entity.id}`,
    },
    {
      text: intl.formatMessage({ id: "Edit" }),
      icon: "ni ni-edit",
      link: (entity) => `/products/${entity.id}/edit`,
    },
    {
      type: "divider",
    },
    {
      icon: "ni ni-na",
      text: intl.formatMessage({ id: "removeOption" }),
      click: (entity) => {
        open(
          intl.formatMessage(
            { id: "confirmationMessage" },
            { entityName: entity.name }
          ),
          () => {
            return deleteProduct.mutateAsync(entity.id);
          }
        );
      },
    },
  ];

  const pagination: IPaginationProps = {
    current: Number(page) || data?.pagination.page,
    size: Number(size) || data?.pagination.size,
    total: data?.pagination?.total,
    onPageChange(page) {
      setParam("page", String(page));
    },
    onSizeChange(size) {
      setParam("size", String(size));
    },
  };

  const onSearch = (term: string) => {
    clearTimeout(timmerId);
    timmerId = setTimeout(() => {
      setParam("search", term);
    }, Number(process.env.REACT_APP_SEARCH_DELAY || 700));
  };

  return (
    <div className="container-fluid">
      <div className="nk-content-inner">
        <div className="nk-content-body">
          <Title
            subText={intl.formatMessage(
              { id: "totalProductsText" },
              { total: data?.pagination.total }
            )}
            rightContent={
              <ul className="nk-block-tools g-3">
                <li>
                  <SearchBar onChange={(e) => onSearch(e.target.value)} />
                </li>
                <li>
                  <DynamicDropdown
                    initialText={intl.formatMessage({ id: "Status" })}
                    options={ProductStatus}
                    selected={status || undefined}
                    onClick={(_, k) => setParam("status", k)}
                  />
                </li>
                <Authorize
                  actions={[Action.CREATE]}
                  subjects={[BasePermission.Product]}
                >
                  <li className="nk-block-tools-opt">
                    <Link to="/products/add">
                      <AddButton />
                    </Link>
                  </li>
                </Authorize>
              </ul>
            }
          >
            <FormattedMessage id="productListTitle" />
          </Title>

          <div className="nk-block">
            <ProductTable
              data={data?.data}
              /* selectable */
              loading={isLoading}
              pagination={pagination}
              options={options}
              /*  bulkOptions={[
                {
                  icon: "ni ni-na",
                  text: intl.formatMessage({ id: "suspendAllOption" }),
                },
              ]} */
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductList;
