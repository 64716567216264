import { FormattedMessage, useIntl } from "react-intl";
import { formatRelativeDate } from "../../utils/formatter";
import Dropdown, { DropdownMenu } from "../Buttons/Dropdown";
import { useState } from "react";
import LazyImage from "../Image/LazyImage";

type Props = {
  img: any;
  selected: any;
  onSelect: any;
  setSelectedView: any;
  onDelete: any;
};

export default function AssetCard({
  img,
  selected,
  onSelect,
  setSelectedView,
  onDelete,
}: Props) {
  const intl = useIntl();

  const [imgUrl, setImgUrl] = useState<any>(img.thumbnail || img.url);

  return (
    <div
      className="nk-file-item nk-file"
      onClick={onSelect ? () => onSelect(img) : undefined}
      onDoubleClick={onSelect ? () => onSelect(img, true) : undefined}
      style={{
        border: `1px solid ${
          selected && selected.id === img.id ? "var(--bs-primary)" : "#dbdfea"
        }`,
      }}
    >
      <div className="nk-file-info">
        <div className="nk-file-title">
          <div
            className="nk-file-icon bg-light pt-5 px-3"
            style={{
              borderColor: "#e7e7e7",
              background: "#f3f3f3",
            }}
          >
            <div
              className="gallery-images"
              style={{
                height: "150px",
                overflow: "hidden",
              }}
            >
              {imgUrl ? (
                <LazyImage
                  className="rounded"
                  src={imgUrl}
                  alt={img.alternativeText || ""}
                  onError={() => setImgUrl(null)}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FormattedMessage id="No image to display" />
                </div>
              )}
            </div>
          </div>
          <div className="nk-file-name pt-3">
            <div className="nk-file-name-text">
              <a
                role="button"
                onClick={() => setSelectedView(img)}
                className="title"
              >
                {img.name}
              </a>
            </div>
          </div>
        </div>
        <ul className="nk-file-desc">
          <li className="date">{formatRelativeDate(img.createdAt)}</li>
          <li className="size">{img.displaySize}</li>
          <li className="members">3 Members</li>
        </ul>
      </div>
      <div className="nk-file-actions">
        <Dropdown icon trigger text={<em className="icon ni ni-more-h" />}>
          <DropdownMenu
            options={[
              {
                icon: "ni ni-eye",
                text: intl.formatMessage({ id: "See detail" }),
                click: () => {
                  setSelectedView(img);
                },
              },
              /* {
              icon: "ni ni-download",
              text: intl.formatMessage({ id: "Download" }),
              click: () => {
                onDownload(img);
              },
            }, */
              /* {
              icon: "ni ni-pen",
              text: intl.formatMessage({ id: "Rename" }),
            }, */
              {
                icon: "ni ni-trash",
                text: intl.formatMessage({ id: "Delete" }),
                click: () => {
                  onDelete(img);
                },
              },
            ]}
            entity="media"
          ></DropdownMenu>
        </Dropdown>
      </div>
    </div>
  );
}
