import httpClient from "../clients/http";

type GeneralParams = {
  name: string;
  mainLocation: string;
  supportEmail: string;
  orderEmail: string;
  supportPhone: string;
  isOnMaintenance: boolean;
  ssnn: { name: string; link: string }[];
  allowOrderCreation?: boolean;
};

export class GeneralSettingsService {
  static async getSettings(): Promise<GeneralParams> {
    const response = await httpClient.get("/settings/general");
    return response.data;
  }

  static async updateSettings(settings: GeneralParams): Promise<GeneralParams> {
    const response = await httpClient.put("/settings/general", settings);
    return response.data;
  }

  static async updateOrder(settings: GeneralParams): Promise<GeneralParams> {
    const response = await httpClient.put("/settings/general/order", settings);
    return response.data;
  }

  static async updateSSNN(settings: GeneralParams): Promise<GeneralParams> {
    const response = await httpClient.put("/settings/general/ssnn", settings);
    return response.data;
  }
}
