import { FormattedMessage } from "react-intl";

function SupportCard() {
  return (
    <div className="card card-bordered">
      <div className="card-inner">
        <div className="nk-help">
          {/*   <div className="nk-help-img">
           
           
          </div> */}
          <div className="nk-help-text">
            <h5>
              <FormattedMessage id="We’re here to help you!" />
            </h5>
            <p className="text-soft">
              <FormattedMessage id="Ask a question or file a support ticketn or report an issues. Our team support team will get back to you by email." />
            </p>
          </div>
          <div className="nk-help-action">
            <a
              href="mailto:info@chalascreations.com?subject=Solicitud de soporte [Shoppxy]: !"
              className="btn btn-lg btn-outline-primary"
            >
              <FormattedMessage id="Request Support" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupportCard;
