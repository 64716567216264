import http, { apiUrl } from "../clients/http";
import { serializeParams } from "../utils/url";

type OrderGetListParams = {
  status: string | null;
  search: string | null;
  page: string | null;
  size: string | null;
  sort: string | null;
};

export class OrderService {
  static async getById(orderId: string) {
    const response = await http.get(`${apiUrl}/orders/${orderId}`);
    const { data } = response;

    return data;
  }

  static async getList(filters: Partial<OrderGetListParams>) {
    const response = await http.get(
      `${apiUrl}/orders?${serializeParams(filters)}`
    );
    const { data } = response;

    return {
      data: data.data,
      pagination: data.pagination,
    };
  }

  static async getSumStatsGraphs() {
    const response = await http.get(`${apiUrl}/orders/stats/graphs/sum`);
    const { data } = response;

    return data;
  }

  static async getAvgStatsGraphs() {
    const response = await http.get(`${apiUrl}/orders/stats/graphs/avg`);
    const { data } = response;

    return data;
  }

  static async getStatsGraphs() {
    const response = await http.get(`${apiUrl}/orders/stats/graphs/count`);
    const { data } = response;

    return data;
  }

  static async updateStatus(orderId: any): Promise<unknown> {
    const response = await http.put(`${apiUrl}/orders/${orderId}/status`, {
      method: "PUT",
    });
    const { data } = response;
    return data;
  }

  static async getAllStats() {
    const response = await http.get(`/orders/stats`);
    const { data } = response;
    return data;
  }

  static async getMostSold() {
    const response = await http.get(`/products/stats`);
    const { data } = response;
    return data;
  }
}
