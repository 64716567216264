import { Button, Modal, Spinner, Tab, Tabs } from "react-bootstrap";
import { useQuery } from "react-query";
import { useTableQueryParams } from "../../hooks/router";
import { MediaService } from "../../services/cms/media";
import { formatRelativeDate } from "../../utils/formatter";
import { IPaginationProps } from "../Table";
import Pagination from "../Table/Pagination";
import Dropdown from "../Buttons/Dropdown";
import { DropdownMenu } from "../Buttons/Dropdown";

import React, { useRef, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { FormattedMessage, useIntl } from "react-intl";
import { Action, BasePermission, CMSPermission } from "../../enums/permissions";
import Authorize from "../Permission/Authorize";
import { useFormatter } from "../../hooks/formatter";
import { useConfirmationModal } from "../../contexts/confirmation";
import AssetCard from "./AssetCard";

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes

const uploadImage = async (file: File) => {
  const formData = new FormData();
  formData.append("image", file);
  return MediaService.upload(formData);
};

let timmerId: any;

export default function MediaContentManager({
  onSelect,
  selected,
}: {
  onSelect?: (p: any, autoSelect?: boolean) => any;
  selected?: any;
}) {
  const intl = useIntl();

  const { page, search, setParam, size, status } = useTableQueryParams();

  const { data, isLoading, refetch } = useQuery(
    ["mediaList", status, search, page, size],
    (ctx) => {
      const [_, status, search, page, size] = ctx.queryKey;
      return MediaService.getList({
        status,
        search,
        page,
        size,
      });
    }
  );

  const pagination: IPaginationProps = {
    current: Number(page) || data?.pagination.page,
    size: Number(size) || data?.pagination.size,
    total: data?.pagination?.total,
    onPageChange(page) {
      setParam("page", String(page));
    },
    onSizeChange(size) {
      setParam("size", String(size));
    },
  };

  const onSearch = (term: string) => {
    clearTimeout(timmerId);
    timmerId = setTimeout(() => {
      setParam("search", term);
    }, Number(process.env.REACT_APP_SEARCH_DELAY || 700));
  };

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedView, setSelectedView] = useState<any>(null);
  const { mutate: uploadImageMutation, isLoading: isUploadLoading } =
    useMutation(uploadImage, {
      onSuccess() {
        refetch();
        toast.success(intl.formatMessage({ id: "TOAST.UPDATE_IMAGE" }));
      },
    });
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { date } = useFormatter();

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file && file.size <= MAX_FILE_SIZE) {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
      alert(intl.formatMessage({ id: "ERRORS.FILE_TOO_LARGE" }));
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      uploadImageMutation(selectedFile);
      setSelectedFile(null);
    } else {
      alert(intl.formatMessage({ id: "Please select an image file." }));
    }
  };

  const deleteMutation = useMutation((id: string) => MediaService.delete(id));

  const { open } = useConfirmationModal();

  const onDelete = (img: any) => {
    open(
      intl.formatMessage(
        { id: "confirmationMessage" },
        { entityName: img.name }
      ),
      () => {
        return deleteMutation.mutateAsync(img.id);
      }
    );
  };

  const onDownload = (img: any) => {
    const url = img.url.replace("upload/", "upload/fl_attachment/");
    var link = document.createElement("a");
    link.href = url;
    link.download = img.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <Modal
        className="zoom"
        size="lg"
        onHide={() => setSelectedView(null)}
        show={!!selectedView}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Media Details" />
          </Modal.Title>
        </Modal.Header>
        {!!selectedView && (
          <Modal.Body>
            <h5 className="title mb-1">
              <span className="text-muted">
                <FormattedMessage id="Name" />:
              </span>{" "}
              {selectedView.name.legnth > 15
                ? selectedView.name.substring(0, 15) + "..."
                : selectedView.name}
            </h5>
            <p className="mb-0">
              <span className="text-muted">
                <FormattedMessage id="Created At" />:{" "}
              </span>
              {date.format(new Date(selectedView.createdAt))}
            </p>

            <div
              className="nk-file-icon bg-light p-3 mt-3 text-center"
              style={{
                borderColor: "#e7e7e7",
                background: "#f3f3f3",
              }}
            >
              <div
                className="gallery-images"
                style={{
                  height: "200px",
                  overflow: "hidden",
                }}
              >
                <img
                  className="rounded"
                  src={selectedView?.url}
                  alt={selectedView?.alternativeText || ""}
                />
              </div>
            </div>

            <hr />
            <ul className="team-statistics">
              <li>
                <b>{selectedView.id}</b>
                <span>
                  <FormattedMessage id="Id." />
                </span>
              </li>
              <li>
                <b>{selectedView.displaySize}</b>
                <span>
                  <FormattedMessage id="Size" />
                </span>
              </li>
              <li>
                <b>{selectedView.ext}</b>
                <span>
                  <FormattedMessage id="Extension" />
                </span>
              </li>
              <li>
                <b>
                  {selectedView.width}x{selectedView.height}
                </b>
                <span>
                  <FormattedMessage id="Dimension" />
                </span>
              </li>
            </ul>
          </Modal.Body>
        )}
        <Modal.Footer>
          <Button onClick={() => setSelectedView(null)} variant="secondary">
            <FormattedMessage id="Close" />
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="nk-fmg-body">
        <div className="nk-fmg-body-head d-lg-flex">
          <div className="nk-fmg-search">
            <em className="icon ni ni-search" />

            <input
              type="text"
              className="form-control border-transparent form-focus-none"
              placeholder={intl.formatMessage({ id: "Search files, folders" })}
              spellCheck="false"
              onChange={(e) => onSearch(e.target.value)}
              data-ms-editor="true"
            />
          </div>
          <Authorize actions={[Action.CREATE]} subjects={[CMSPermission.Media]}>
            <>
              <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <div className="nk-fmg-actions">
                <ul className="nk-block-tools g-3">
                  {!selectedFile ? (
                    <li>
                      <Button
                        variant="primary"
                        onClick={handleButtonClick}
                        disabled={isUploadLoading}
                      >
                        <em className="icon ni ni-upload-cloud" />
                        <span className="me-2">
                          <FormattedMessage id="Upload file" />
                        </span>
                        {isUploadLoading && (
                          <Spinner animation="border" size="sm" />
                        )}
                      </Button>
                    </li>
                  ) : (
                    <>
                      <li>
                        <Button
                          variant="primary"
                          onClick={handleUpload}
                          disabled={!selectedFile || isUploadLoading}
                        >
                          <em className="icon ni ni-upload-cloud" />
                          <span>
                            Subir {selectedFile.name} ({selectedFile.size} kb)
                          </span>
                        </Button>
                      </li>
                      <li>
                        <Button
                          onClick={() => {
                            if (fileInputRef.current) {
                              fileInputRef.current.value = "";
                            }
                            setSelectedFile(null);
                          }}
                          variant="danger"
                        >
                          <FormattedMessage id="ACTIONS.CANCEL" />
                        </Button>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </>
          </Authorize>
        </div>

        <div className="nk-fmg-body-content">
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between position-relative">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">
                  <FormattedMessage id="your files" />
                </h3>
              </div>
            </div>
          </div>
          <div className="nk-fmg-listing nk-block">
            <div className="nk-files nk-files-view-grid">
              <div className="nk-files-head">
                <div className="nk-file-item">
                  <div className="nk-file-info">
                    <div className="dropdown">
                      <Dropdown
                        text={intl.formatMessage({ id: "Last created" })}
                        className="p-0"
                        indicator
                      >
                        <ul className="link-list-opt no-bdr">
                          <li>
                            <a className="active" href="#">
                              <span>
                                <FormattedMessage id="Last created" />
                              </span>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <span>
                                <FormattedMessage id="Name" />
                              </span>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <span>
                                <FormattedMessage id="Size" />
                              </span>
                            </a>
                          </li>
                        </ul>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nk-files-list">
                {isLoading && (
                  <div
                    style={{
                      display: "flex",
                      height: "40vh",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Spinner />
                  </div>
                )}
                {data?.data.map((img: any) => {
                  return (
                    <AssetCard
                      key={img.id}
                      img={img}
                      selected={selected}
                      onSelect={onSelect}
                      setSelectedView={setSelectedView}
                      onDelete={onDelete}
                    />
                  );
                })}
                {data?.data?.length === 0 && (
                  <div
                    style={{
                      display: "flex",
                      height: "35vh",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <h4>
                      <FormattedMessage id="No data to display" />
                    </h4>
                    <p>
                      <FormattedMessage id="Check your search or filters and try again" />
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="mt-4">
            <Pagination {...pagination} />
          </div>
        </div>
      </div>
      {/*  </Tab>
       <Tab eventKey="selected" title="Archivos seleccionados">
          Tab content for Profile
        </Tab> 
   </Tabs> */}

      {/*   <Form.Group>
          <Form.Control
            type="file"
            id="fileInput"
            onChange={handleAssetChange}
          />
          {selectedAsset && (
            <div>
              <p>Selected Asset: {selectedAsset.name}</p>
            </div>
          )}
        </Form.Group> */}
      {/*     <ListGroup>
          <ListGroup.Item
            action
            active={currentFolder === "root"}
            onClick={() => handleFolderClick("root")}
          >
            root
          </ListGroup.Item>
          <ListGroup.Item
            action
            active={currentFolder === "folder1"}
            onClick={() => handleFolderClick("folder1")}
          >
            folder1
          </ListGroup.Item>
          <ListGroup.Item
            action
            active={currentFolder === "folder2"}
            onClick={() => handleFolderClick("folder2")}
          >
            folder2
          </ListGroup.Item>
        </ListGroup> */}
    </div>
  );
}
