import { useQuery } from "react-query";
import { IntegrationsService } from "../services/integrations";

export const useMyIntegrations = (key?: string) => {
  const { data, isLoading } = useQuery(
    "my-integrations",
    IntegrationsService.getMyAddons,
    {
      cacheTime: 1000 * 60 * 5,
    }
  );

  const isEnabled = key
    ? !isLoading &&
      data?.find((i) => i.key === key)?.integration?.status === "active"
    : null;

  return {
    integrations: data,
    isLoading,
    isEnabled,
  };
};
