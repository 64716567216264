import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { SkeletonRow } from "../../../components/Loading/Skeleton";
import Authorize from "../../../components/Permission/Authorize";
import { useConfirmationModal } from "../../../contexts/confirmation";
import { Action, BasePermission } from "../../../enums/permissions";
import { IGeneralSettingsForm } from "../../../interfaces/settings/general";
import { GeneralSettingsService } from "../../../services/general";
import { emailPattern, phonePattern } from "../../../utils/form-validation";
import { Alert } from "react-bootstrap";

function StoreSettings() {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();

  const { open } = useConfirmationModal();

  const { isLoading, isError, refetch } = useQuery<IGeneralSettingsForm>(
    "generalSettings",
    GeneralSettingsService.getSettings,
    {
      cacheTime: Infinity,
      onSuccess: (data) => {
        setValue("name", data.name);
        setValue("mainLocation", data.mainLocation);
        setValue("supportEmail", data.supportEmail);
        setValue("orderEmail", data.orderEmail);
        setValue("isOnMaintenance", data.isOnMaintenance);
        setValue("supportPhone", data.supportPhone);
      },
    }
  );

  const onSubmit = async (data: any) => {
    return open(
      intl.formatMessage(
        { id: "confirmationEditMessage" },
        { entityName: intl.formatMessage({ id: "General configuration" }) }
      ),
      async () => {
        try {
          await GeneralSettingsService.updateSettings(data);
          toast.success(intl.formatMessage({ id: "TOAST.UPDATE_SUCCESS" }));
          refetch(); // Vuelve a cargar los datos después de la actualización exitosa
        } catch (error) {
          toast.error(intl.formatMessage({ id: "TOAST.UPDATE_ERROR" }));
        }
      }
    );
  };

  return (
    <div className="card-inner">
      <h5 className="card-title">
        <FormattedMessage id="Store configuration" />
      </h5>
      <p>
        <FormattedMessage id="Data from your virtual store and website." />
      </p>

      {isError ? (
        <Alert variant="danger">
          <i className="icon ni ni-alert-circle me-1"></i>
          <FormattedMessage id="Error loading configuration, Try again later or contact support." />
        </Alert>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="gy-3 form-settings">
          <div className="row g-3 align-center">
            <div className="col-lg-5">
              <div className="form-group">
                <label className="form-label" htmlFor="store-name">
                  <FormattedMessage id="Store's name" />
                </label>
                <span className="form-note">
                  <FormattedMessage id="Specify the name of your store." />
                </span>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="form-group">
                <div className="form-control-wrap">
                  {isLoading ? (
                    <SkeletonRow size="xs" />
                  ) : (
                    <>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.name ? "is-invalid" : ""
                        }`}
                        id="store-name"
                        {...register("name", { required: true })}
                        maxLength={30}
                      />
                      {errors.name && (
                        <div className="invalid-feedback">
                          <FormattedMessage id="This field is required." />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row g-3 align-center">
            <div className="col-lg-5">
              <div className="form-group">
                <label className="form-label" htmlFor="main-location">
                  <FormattedMessage id="main location" />
                </label>
                <span className="form-note">
                  <FormattedMessage id="Specify the main location of your store." />
                </span>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="form-group">
                <div className="form-control-wrap">
                  {isLoading ? (
                    <>
                      <SkeletonRow size="lg" />
                    </>
                  ) : (
                    <>
                      <textarea
                        className={`form-control ${
                          errors.mainLocation ? "is-invalid" : ""
                        }`}
                        id="main-location"
                        {...register("mainLocation", { required: true })}
                        maxLength={180}
                      ></textarea>
                      {errors.mainLocation && (
                        <div className="invalid-feedback">
                          <FormattedMessage id="This field is required." />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row g-3 align-center">
            <div className="col-lg-5">
              <div className="form-group">
                <label className="form-label" htmlFor="support-phone">
                  <FormattedMessage id="help phone" />
                </label>
                <span className="form-note">
                  <FormattedMessage id="Specify the help phone number of your store." />
                </span>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="form-group">
                <div className="form-control-wrap">
                  {isLoading ? (
                    <SkeletonRow size="xs" />
                  ) : (
                    <>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.supportPhone ? "is-invalid" : ""
                        }`}
                        id="support-phone"
                        {...register("supportPhone", {
                          required: intl.formatMessage({
                            id: "This field is required.",
                          }),
                          pattern: phonePattern,
                        })}
                        maxLength={15}
                      />
                      {errors.supportPhone && (
                        <div className="invalid-feedback">
                          {errors.supportPhone.message as string}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row g-3 align-center">
            <div className="col-lg-5">
              <div className="form-group">
                <label className="form-label" htmlFor="support-email">
                  <FormattedMessage id="help email" />
                </label>
                <span className="form-note">
                  <FormattedMessage id="Specify the help email of your store." />
                </span>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="form-group">
                <div className="form-control-wrap">
                  {isLoading ? (
                    <SkeletonRow size="xs" />
                  ) : (
                    <>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.supportEmail ? "is-invalid" : ""
                        }`}
                        id="support-email"
                        {...register("supportEmail", {
                          required: intl.formatMessage({
                            id: "This field is required.",
                          }),
                          pattern: emailPattern,
                        })}
                        maxLength={40}
                      />
                      {errors.supportEmail && (
                        <div className="invalid-feedback">
                          {errors.supportEmail.message as string}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row g-3 align-center">
            <div className="col-lg-5">
              <div className="form-group">
                <label className="form-label" htmlFor="order-email">
                  <FormattedMessage id="Order Mail" />
                </label>
                <span className="form-note">
                  <FormattedMessage id="Specify the order mail of your store." />
                </span>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="form-group">
                <div className="form-control-wrap">
                  {isLoading ? (
                    <SkeletonRow size="xs" />
                  ) : (
                    <>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.orderEmail ? "is-invalid" : ""
                        }`}
                        id="order-email"
                        {...register("orderEmail", {
                          required: intl.formatMessage({
                            id: "This field is required.",
                          }),
                          pattern: emailPattern,
                        })}
                        maxLength={40}
                      />
                      {errors.orderEmail && (
                        <div className="invalid-feedback">
                          {errors.orderEmail.message as string}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row g-3 align-center">
            <div className="col-lg-5">
              <div className="form-group">
                <label className="form-label" htmlFor="maintenance-mode">
                  <FormattedMessage id="maintenance mode" />
                </label>
                <span className="form-note">
                  <FormattedMessage id="Turn maintenance mode on or off." />
                </span>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="form-group">
                {isLoading ? (
                  <SkeletonRow size="xs" />
                ) : (
                  <>
                    <div className="custom-control custom-switch checked">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="site-off"
                        {...register("isOnMaintenance")}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="site-off"
                      ></label>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="row g-3">
            <div className="col-lg-7 offset-lg-5">
              <div className="form-group mt-2">
                <Authorize
                  actions={[Action.UPDATE]}
                  subjects={[BasePermission.Setting]}
                >
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-lg btn-primary"
                  >
                    <FormattedMessage id="Update" />
                  </button>
                </Authorize>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

export default StoreSettings;
