import { Fragment, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useQuery } from "react-query";
import EmptyPage from "../../components/Empty/EmptyPage";
import SearchBar from "../../components/Inputs/SearchBar";
import { useParamTab } from "../../hooks/router";
import { CatalogService } from "../../services/catalog";
import CatalogTable from "./catalog-table";

let timmerId: any;

function CatalogList() {
  const intl = useIntl();
  const { activeKey, onSelect } = useParamTab("/catalogs");

  const [search, setSearchTerm] = useState<string>("");
  const [activeCatalog, setActiveCatalog] = useState<any>(null);

  const { data, isLoading } = useQuery(
    ["catalogs"],
    () => CatalogService.getGroupedAll({ search: "" }),
    {
      cacheTime: 1000 * 60 * 24,
    }
  );

  const onSearch = (term: string) => {
    clearTimeout(timmerId);
    timmerId = setTimeout(() => {
      setSearchTerm(term);
    }, Number(process.env.REACT_APP_SEARCH_DELAY || 700));
  };

  useEffect(() => {
    if (data) {
      const cat = data
        .reduce((p, c) => p.concat(c.catalogs), [] as any)
        .find((e: any) => e.key === activeKey);
      if (cat) setActiveCatalog(cat);
    }
  }, [activeKey, data]);

  const onCatalogSelect = (catalog: any) => {
    onSelect(catalog.key);
  };

  return (
    <>
      <Row>
        <Col lg={3} md={3} style={{ maxWidth: "300px" }}>
          <div>
            <SearchBar
              onChange={(e) => onSearch(e.target.value)}
              disabled={isLoading}
            />
            <br />
            <ul className="nk-menu nk-menu-md">
              {data
                ?.filter((f) => {
                  if (!search) return true;
                  return f.catalogs.some((c: any) =>
                    c.name.toLowerCase().includes(search.toLowerCase())
                  );
                })
                ?.map((c) => (
                  <Fragment key={c.groupName}>
                    <li className="nk-menu-heading">
                      <h6 className="overline-title text-primary-alt">
                        {c.groupName}
                      </h6>
                    </li>
                    {c.catalogs.map((cc) => (
                      <li
                        key={cc.id}
                        className={`nk-menu-item mb-3 ${
                          activeKey === cc.key ? "active current-page" : ""
                        }`}
                      >
                        <span
                          role="button"
                          onClick={() => onCatalogSelect(cc)}
                          className="nk-menu-link pe-3 "
                        >
                          <span className="nk-menu-text">{cc.name}</span>
                        </span>
                      </li>
                    ))}
                  </Fragment>
                ))}
            </ul>

            <div className="d-block d-md-none my-4">
              <hr />
            </div>
          </div>
        </Col>
        <Col>
          {activeKey ? (
            <CatalogTable current={activeCatalog} />
          ) : (
            <EmptyPage
              subTitle={intl.formatMessage({
                id: "Select a catalog",
              })}
              text={intl.formatMessage({
                id: "Click on the left menu to manage a catalog.",
              })}
            />
          )}{" "}
        </Col>
      </Row>
    </>
  );
}

export default CatalogList;
