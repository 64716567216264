import http from "../../clients/http";

export class CmsService {
  static async getTrafficStats() {
    const response = await http.get("/cms/traffic");
    return response.data;
  }

  static async getBrowserStats() {
    const response = await http.get("/cms/audience/browsers");
    return response.data;
  }

  static async getDevicesStats() {
    const response = await http.get("/cms/audience/devices");
    return response.data;
  }

  static async getStats() {
    const response = await http.get("/cms");
    return response.data;
  }

  static async processFiles(files: File[], processor: string) {
    const formData = new FormData();
    for (const key in files) {
      if (Object.prototype.hasOwnProperty.call(files, key)) {
        const value = files[key];
        formData.append(key, value);
      }
    }

    const response = await http.post(
      `/cms/process-file?p=${processor}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  }
}
