import http from "../clients/http";
import { Paged } from "../interfaces/base";

export interface NotificationPayload {
  title: string;
  content: string;
  subContent: string;
  cta: {
    text: string;
    link?: string;
  };
}

export interface Notification {
  read: boolean;
  id: string;
  _id: string;
  content: string;
  type: {
    color: string;
    icon: string;
  };
  createdAt: string;
  payload: NotificationPayload;
}

export class NotificationsService {
  static async markAsRead(markAsRead?: { ids: string[] }) {
    const response = await http.post("/notifications/read", markAsRead);
    return response.data;
  }

  static async getAll(arg?: { size: number }): Promise<Paged<Notification>> {
    const response = await http.get(
      `/notifications${arg ? "?size=" + arg.size : ""}`
    );
    return response.data;
  }
}
