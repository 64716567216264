import { useMutation, useQuery } from "react-query";
import { AddButton } from "../../components/Buttons/ActionableButton";
import Dropdown, {
  DropdownMenu,
  DropdownMenuOption,
} from "../../components/Buttons/Dropdown";
import SearchBar from "../../components/Inputs/SearchBar";

import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import DynamicDropdown from "../../components/Buttons/DynamicDropdown";
import Authorize from "../../components/Permission/Authorize";
import Table, { IPaginationProps, ITableHeader } from "../../components/Table";
import { transformToStatus } from "../../components/Table/transformers";
import Title from "../../components/Title";
import { useContextToast } from "../../contexts/toast";
import { Action, BasePermission } from "../../enums/permissions";
import { Status } from "../../enums/status";
import { useTableQueryParams } from "../../hooks/router";
import { INewDiscountForm } from "../../interfaces/discounts/forms";
import { DiscountService } from "../../services/discount";
import { formatShortDate } from "../../utils/formatter";

let timmerId: any;
let loadingToast: any = null;

function DiscountsList() {
  const intl = useIntl();
  const { page, search, setParam, size, status } = useTableQueryParams();
  const { show } = useContextToast();

  const { data, isLoading, refetch } = useQuery(
    ["discountList", status, search, page, size],
    (ctx) => {
      const [, status, search, page, size] = ctx.queryKey;
      return DiscountService.getList({
        status,
        search,
        page,
        size,
      });
    }
  );

  const updateStatusMutation = useMutation(
    (entity: any) => DiscountService.changeStatus(entity.id),
    {
      onSuccess: () => {
        refetch();
        show(intl.formatMessage({ id: "Updated successfully" }));
      },
    }
  );

  const options: DropdownMenuOption<any>[] = [
    /*  {
      text: intl.formatMessage({ id: "See detail" }),
      icon: "ni ni-eye",
      link: (entity) => `/discounts/${entity.id}`,
    }, */
    /*     {
      icon: "ni ni-activity-round",
      text: intl.formatMessage({ id: "Activities" }),
      link: (entity) => `/customer/${entity.id}`,
    }, */
    /*   {
      type: "divider",
    }, */
    {
      text: intl.formatMessage({ id: "Edit" }),
      icon: "ni ni-edit",
      link: (entity) => {
        return `/discounts/${entity.id}/edit`;
      },
    },
    {
      type: "divider",
    },
    {
      text: (entity) => {
        return entity.status === "active"
          ? intl.formatMessage({ id: "Deactivate" })
          : intl.formatMessage({ id: "Activate" });
      },
      icon: "ni ni-exchange",
      click: (entity) => {
        updateStatusMutation.mutateAsync(entity);
      },
    },
  ];

  const headers: ITableHeader[] = [
    {
      path: ["code"],
      name: intl.formatMessage({ id: "Code" }),
      transform: (value, obj) => (
        <span className="tb-lead">
          <Link to={`/discounts/${obj.id}`}>
            <Link to={`/discounts/${obj.id}/edit`} className="fw-bold">
              #{value}
            </Link>
          </Link>
        </span>
      ),
    },
    {
      path: ["name"],
      name: intl.formatMessage({ id: "Discount" }),
      className: "lg-table-col",
    },
    {
      path: ["startDate"],
      name: intl.formatMessage({ id: "Period" }),
      transform: (value, obj) =>
        `${formatShortDate(
          new Date(value).toISOString().split("T")[0]
        )} - ${formatShortDate(
          new Date(obj.endDate).toISOString().split("T")[0]
        )}`,
      hide: "sm",
    },

    {
      path: ["applyMethod"],
      name: intl.formatMessage({ id: "Apply Method" }),
      transform: (value: INewDiscountForm["applyMethod"]) => {
        const { color, text, icon } = {
          automatic: {
            color: "success",
            text: "Automatic",
            icon: "ni ni-invest",
          },
          "using-code": {
            color: "warning",
            text: "Using code",
            icon: "ni ni-wallet-in",
          },
          "meet-condition": {
            color: "info",
            text: "Meet condition",
            icon: "ni ni-list-check",
          },
        }[value];

        return (
          <span className={`badge badge-dim bg-${color}`}>
            <em className={`icon ni ${icon} me-1`} />
            {intl.formatMessage({ id: text }).toLocaleLowerCase()}
          </span>
        );
      },
      hide: "sm",
    },
    {
      path: ["products"],
      name: intl.formatMessage({ id: "Selected products" }),
      transform: (value, obj) => {
        return (
          <span className="badge rounded-pill badge-dim bg-primary">
            {obj.isDefault ? (
              "Todos"
            ) : (
              <>
                {value?.length}{" "}
                {intl.formatMessage({ id: "Products" }).toLocaleLowerCase()}
              </>
            )}
          </span>
        );
      },
    },
    {
      path: ["status"],
      name: intl.formatMessage({ id: "Status" }),
      transform: transformToStatus,
    },
    {
      path: ["options"], // no id
      name: "",
      type: "tools",
      transform: (v, entity) => (
        <Dropdown
          key={entity.id}
          icon
          trigger
          text={<em className="icon ni ni-more-h" />}
        >
          <DropdownMenu options={options} entity={entity} />
        </Dropdown>
      ),
    },
  ];

  const pagination: IPaginationProps = {
    current: Number(page) || data?.pagination.page,
    size: Number(size) || data?.pagination.size,
    total: data?.pagination?.total,
    onPageChange(page) {
      setParam("page", String(page));
    },
    onSizeChange(size) {
      setParam("size", String(size));
    },
  };

  const onSearch = (term: string) => {
    clearTimeout(timmerId);
    timmerId = setTimeout(() => {
      setParam("search", term);
    }, Number(process.env.REACT_APP_SEARCH_DELAY || 700));
  };

  return (
    <div className="container-fluid">
      <div className="nk-content-inner">
        <div className="nk-content-body">
          <Title
            subText={intl.formatMessage(
              { id: "You have a total of {number} {entity}." },
              {
                number: data?.pagination?.total || 0,
                entity: intl
                  .formatMessage({ id: "Discounts" })
                  .toLocaleLowerCase(),
              }
            )}
            rightContent={
              <ul className="nk-block-tools g-3">
                <li>
                  <SearchBar onChange={(e) => onSearch(e.target.value)} />
                </li>
                <li>
                  <DynamicDropdown
                    initialText="Estado"
                    options={{
                      active: Status.active,
                      inactive: Status.inactive,
                    }}
                    selected={status || undefined}
                    onClick={(_, k) => setParam("status", k)}
                  />
                </li>
                <Authorize
                  actions={[Action.CREATE]}
                  subjects={[BasePermission.Discount]}
                >
                  <li className="nk-block-tools-opt">
                    <Link to="/discounts/add">
                      <AddButton />
                    </Link>
                  </li>
                </Authorize>
              </ul>
            }
          >
            <FormattedMessage id="Discounts list" />
          </Title>

          <div className="nk-block">
            <Table
              headers={headers}
              data={data?.data || []}
              /* selectable */
              loading={isLoading}
              pagination={pagination}
              /*  bulkOptions={[
                {
                  icon: "ni ni-mail",
                  text: intl.formatMessage({ id: "Send email to everyone" }),
                  click: (entities) => console.log(entities),
                },
                {
                  icon: "ni ni-na",
                  text: intl.formatMessage({ id: "Disable all" }),
                },
                {
                  icon: "ni ni-trash",
                  text: intl.formatMessage({ id: "Delete all" }),
                },
                {
                  icon: "ni ni-shield-star",
                  text: intl.formatMessage({ id: "Reset all" }),
                },
              ]} */
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DiscountsList;
