export var totalSales = (daysOfMonth: string[], data: number[]) => ({
  labels: daysOfMonth,
  dataUnit: "Sales",
  datasets: [
    {
      label: "Sales",
      borderColor: "#9d72ff",
      backgroundColor: "rgba(157, 114, 255, 0.25)",
      tension: 0.35,
      borderWidth: 2,
      borderJoinStyle: "round" as const, // "miter", "bevel", "round
      fill: true,
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#9d72ff",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      data: data /* [
        130, 105, 125, 115, 110, 105, 131, 110, 115, 120, 111, 127, 113, 107,
        122, 100, 115, 110, 130, 107, 110, 105, 123, 115, 100, 117, 125, 95,
        137, 101,
      ], */,
    },
  ],
});

export var totalOrders = (daysOfMonth: string[], data: number[]) => ({
  labels: daysOfMonth,
  dataUnit: "Orders",
  lineTension: 0.3,
  datasets: [
    {
      label: "Orders",
      borderColor: "#7de1f8",
      backgroundColor: "rgba(125, 225, 248, 0.25)",
      borderWidth: 2,
      fill: true,
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#7de1f8",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      data: data /* [
        85, 125, 105, 115, 130, 106, 141, 110, 95, 120, 111, 105, 113, 107, 122,
        100, 95, 110, 120, 107, 100, 105, 123, 115, 110, 117, 125, 75, 95, 101,
      ] */,
    },
  ],
});

export var totalCustomers = (daysOfMonth: string[], data: number[]) => ({
  labels: daysOfMonth,
  dataUnit: "Customers",
  lineTension: 0.3,
  datasets: [
    {
      label: "Customers",
      borderColor: "#83bcff",
      backgroundColor: "rgba(131, 188, 255, 0.25)",
      borderWidth: 2,
      fill: true,
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#83bcff",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      data: data,
    },
  ],
});

export var averargeOrder = (daysOfMonth: string[], data: number[]) => ({
  labels: daysOfMonth,
  dataUnit: "People",
  lineTension: 0.1,
  datasets: [
    {
      label: "Active Users",
      borderColor: "#b695ff",
      backgroundColor: "#b695ff",
      barPercentage: 0.7,
      categoryPercentage: 0.7,
      borderWidth: 2,
      data: data,
    },
  ],
});

export var averargeOrderSet2 = {
  labels: [
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "08 Jan",
    "09 Jan",
    "10 Jan",
    "11 Jan",
    "12 Jan",
    "13 Jan",
    "14 Jan",
    "15 Jan",
    "16 Jan",
    "17 Jan",
    "18 Jan",
    "19 Jan",
    "20 Jan",
    "21 Jan",
    "22 Jan",
    "23 Jan",
    "24 Jan",
    "25 Jan",
    "26 Jan",
    "27 Jan",
    "28 Jan",
    "29 Jan",
    "30 Jan",
  ],
  dataUnit: "People",
  lineTension: 0.1,
  datasets: [
    {
      label: "Active Users",
      borderColor: "#b695ff",
      backgroundColor: "#b695ff",
      barPercentage: 0.7,
      categoryPercentage: 0.7,
      borderWidth: 2,
      data: [
        1510, 200, 1010, 1980, 500, 1770, 1560, 1830, 800, 230, 590, 950, 1100,
        800, 1250, 750, 250, 800, 100, 1500, 1700, 1650, 750, 900, 1700, 1500,
        1850, 400, 1000, 850,
      ],
    },
  ],
};

export var averargeOrderSet3 = {
  labels: [
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "08 Jan",
    "09 Jan",
    "10 Jan",
    "11 Jan",
    "12 Jan",
    "13 Jan",
    "14 Jan",
    "15 Jan",
    "16 Jan",
    "17 Jan",
    "18 Jan",
    "19 Jan",
    "20 Jan",
    "21 Jan",
    "22 Jan",
    "23 Jan",
    "24 Jan",
    "25 Jan",
    "26 Jan",
    "27 Jan",
    "28 Jan",
    "29 Jan",
    "30 Jan",
  ],
  dataUnit: "People",
  lineTension: 0.1,
  datasets: [
    {
      label: "Active Users",
      borderColor: "#b695ff",
      backgroundColor: "#b695ff",
      barPercentage: 0.7,
      categoryPercentage: 0.7,
      borderWidth: 2,
      data: [
        1510, 1720, 310, 1580, 200, 1770, 600, 730, 890, 750, 700, 1050, 1200,
        1300, 1450, 1550, 850, 750, 1500, 1500, 1500, 1950, 1200, 1200, 1500,
        1700, 1550, 1650, 550, 650,
      ],
    },
  ],
};

export var averargeOrderSet4 = {
  labels: [
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "08 Jan",
    "09 Jan",
    "10 Jan",
    "11 Jan",
    "12 Jan",
    "13 Jan",
    "14 Jan",
    "15 Jan",
    "16 Jan",
    "17 Jan",
    "18 Jan",
    "19 Jan",
    "20 Jan",
    "21 Jan",
    "22 Jan",
    "23 Jan",
    "24 Jan",
    "25 Jan",
    "26 Jan",
    "27 Jan",
    "28 Jan",
    "29 Jan",
    "30 Jan",
  ],
  dataUnit: "People",
  lineTension: 0.1,
  datasets: [
    {
      label: "Active Users",
      borderColor: "#b695ff",
      backgroundColor: "#b695ff",
      barPercentage: 0.7,
      categoryPercentage: 0.7,
      borderWidth: 2,
      data: [
        1510, 1020, 1510, 1980, 500, 270, 1260, 320, 650, 750, 780, 910, 1500,
        800, 1250, 850, 950, 450, 900, 1000, 1200, 1250, 900, 950, 1300, 1200,
        1250, 650, 950, 750,
      ],
    },
  ],
};

export var trafficSources = {
  labels: ["Organic Search", "Social Media", "Referrals", "Others"],
  dataUnit: "People",
  legend: false,
  datasets: [
    {
      borderColor: "#fff",
      backgroundColor: ["#b695ff", "#b8acff", "#ffa9ce", "#f9db7b"],
      data: [4305, 859, 482, 138],
    },
  ],
};

export var trafficSourcesSet2 = {
  labels: ["Organic Search", "Social Media", "Referrals", "Others"],
  dataUnit: "People",
  legend: false,
  datasets: [
    {
      borderColor: "#fff",
      backgroundColor: ["#b695ff", "#b8acff", "#ffa9ce", "#f9db7b"],
      data: [2505, 482, 859, 138],
    },
  ],
};
export var trafficSourcesSet3 = {
  labels: ["Organic Search", "Social Media", "Referrals", "Others"],
  dataUnit: "People",
  legend: false,
  datasets: [
    {
      borderColor: "#fff",
      backgroundColor: ["#b695ff", "#b8acff", "#ffa9ce", "#f9db7b"],
      data: [3505, 800, 1650, 150],
    },
  ],
};

export var trafficSourcesSet4 = {
  labels: ["Organic Search", "Social Media", "Referrals", "Others"],
  dataUnit: "People",
  legend: false,
  datasets: [
    {
      borderColor: "#fff",
      backgroundColor: ["#b695ff", "#b8acff", "#ffa9ce", "#f9db7b"],
      data: [4000, 1250, 3250, 250],
    },
  ],
};

export var storeVisitors = {
  labels: [
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "08 Jan",
    "09 Jan",
    "10 Jan",
    "11 Jan",
    "12 Jan",
    "13 Jan",
    "14 Jan",
    "15 Jan",
    "16 Jan",
    "17 Jan",
    "18 Jan",
    "19 Jan",
    "20 Jan",
    "21 Jan",
    "22 Jan",
    "23 Jan",
    "24 Jan",
    "25 Jan",
    "26 Jan",
    "27 Jan",
    "28 Jan",
    "29 Jan",
    "30 Jan",
  ],
  dataUnit: "People",
  lineTension: 0.6,
  datasets: [
    {
      label: "Current Month",
      borderColor: "#9d72ff",
      borderWidth: 2,
      borderJoinStyle: "round",
      backgroundColor: "transparent",
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#9d72ff",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      data: [
        4110, 4220, 4810, 5480, 4600, 5670, 6660, 4830, 5590, 5730, 4790, 4950,
        5100, 5800, 5950, 5850, 5950, 4450, 4900, 8000, 7200, 7250, 7900, 8950,
        6300, 7200, 7250, 7650, 6950, 4750,
      ],
    },
  ],
};
