import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { INewDiscountForm } from "../../../interfaces/discounts/forms";
import { useClientContext } from "../../../contexts/client.context";

export interface DiscountFormHowToApplyProps {
  applyMethod: INewDiscountForm["applyMethod"];
  setApplyMethod: (type: INewDiscountForm["applyMethod"]) => void;
}

const apply = [
  {
    id: "automatic",
    label: "Automatic",
    description: "Discount will be applied automatically",
    icon: "ni ni-invest",
    disabled: false,
  },
  {
    id: "using-code",
    label: "Using code",
    description: "Customer need to enter a code to apply discount",
    icon: "ni ni-wallet-in",
    disabled: false,
  },
  {
    id: "meet-condition",
    label: "Meet condition",
    description: "Discount will be applied if customer meet condition",
    icon: "ni ni-list-check",
    disabled: true,
  },
] as const;

const renderTooltip = (props: any) => (
  <Tooltip id="button-tooltip" {...props}>
    <b>
      <FormattedMessage id="How to Apply?" />
    </b>
    <hr className="my-1" />
    <ul>
      {apply.map((e) => (
        <li key={e.id} className="my-2">
          <em className={`icon ni ni-${e.icon} me-1`}></em>
          <FormattedMessage id={e.label} />
          <span className="text-muted">
            <FormattedMessage id={e.description} />.
          </span>
        </li>
      ))}
    </ul>
  </Tooltip>
);

function DiscountFormHowToApply({
  applyMethod,
  setApplyMethod,
}: DiscountFormHowToApplyProps) {
  const { consumer } = useClientContext();

  return (
    <>
      <div className="mt-5 mb-4">
        <span className="preview-title-lg overline-title ">
          <hr />
          <FormattedMessage id="How to Apply?" />
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
          >
            <em
              role="button"
              className="icon ni ni-question text-primary ms-1"
            />
          </OverlayTrigger>
        </span>
        <p>
          <FormattedMessage id="Select the way the discount will be applied" />
        </p>
      </div>
      <Form.Group controlId="type" className="mb-5">
        <div>
          <ul className="custom-control-group row w-100">
            {apply.map((e) => (
              <li className="col" key={e.id}>
                <OverlayTrigger
                  show={
                    consumer.features.discounts.applyMethod.includes(e.id)
                      ? false
                      : undefined
                  }
                  overlay={
                    <Tooltip id={`tooltip-${e.id}`}>
                      <FormattedMessage id="Coming soon" />
                    </Tooltip>
                  }
                >
                  <div className="custom-control custom-checkbox custom-control-pro no-control w-100">
                    <input
                      type="radio"
                      className="custom-control-input"
                      checked={applyMethod === e.id}
                      id={e.id}
                      value={e.id}
                      onChange={() => {
                        console.log(e.id);
                        setApplyMethod(e.id);
                      }}
                      disabled={
                        e.disabled ||
                        !consumer.features.discounts.applyMethod.includes(e.id)
                      }
                    />
                    <label
                      className="custom-control-label w-100"
                      htmlFor={e.id}
                    >
                      <em className={`icon ni ni-${e.icon} me-1`}></em>
                      <FormattedMessage id={e.label} />
                    </label>
                  </div>
                </OverlayTrigger>
              </li>
            ))}
          </ul>
        </div>
      </Form.Group>
    </>
  );
}

export default DiscountFormHowToApply;
