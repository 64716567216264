import React, { ReactNode, createContext, useContext, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

interface ConfirmationModalType {
  open: (message: string, cb: (action: boolean) => void) => void;
  isOpen: boolean;
}

export const ConfirmationModal = createContext<ConfirmationModalType>({
  open: () => {},
  isOpen: false,
});

export const useConfirmationModal = () => useContext(ConfirmationModal);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalProps, setModalProps] = useState({
    message: "",
    cb: (action: boolean) => {},
  });

  const open = (message: string, cb: (action: boolean) => void) => {
    setModalProps({
      message,
      cb,
    });
    setIsModalOpen(true);
  };

  const onAccept = async () => {
    setIsLoading(true);
    try {
      await modalProps.cb(true);
    } catch (e: any) {
      console.log(e);
    }
    setIsLoading(false);
    setIsModalOpen(false);
  };

  const onCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <ConfirmationModal.Provider value={{ open, isOpen: isModalOpen }}>
      {children}

      <Modal show={isModalOpen} onHide={onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="You're sure?" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{modalProps.message}</p>
        </Modal.Body>
        <Modal.Footer className="bg-light">
          <Button onClick={onCancel} variant="secondary">
            <FormattedMessage id="ACTIONS.CANCEL" />
          </Button>
          <Button disabled={isLoading} onClick={onAccept} variant="primary">
            {isLoading ? (
              <>
                <FormattedMessage id="processing" /> <Spinner size="sm" />
              </>
            ) : (
              "Aceptar"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </ConfirmationModal.Provider>
  );
};
