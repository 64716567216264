import OrderSettings from "./order";
import SocialNetworkSetting from "./ssnn";
import StoreSettings from "./store";

function GeneralSettings() {
  return (
    <>
      <StoreSettings />
      <hr />
      <OrderSettings />
      <hr />
      <SocialNetworkSetting />
    </>
  );
}

export default GeneralSettings;
