import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import SimpleDataTable from "react-very-simple-data-table";
import { Td, Th, Tr } from "./CustomElements";
import Pagination from "./Pagination";
import { ITableProps } from "./interfaces";
import TableProvider, { useTableContext } from "./store";

export * from "./interfaces";

const TableLoading: React.FC = () => (
  <>
    {Array(7)
      .fill({})
      .map((_, i) => (
        <div className="loading-skeleton" key={i}>
          <div className="skeleton-row">
            <div className="block">
              <FormattedMessage id="Loading" />
            </div>{" "}
          </div>
        </div>
      ))}
  </>
);

const Table: React.FC = () => {
  const { data, loading, headers, pagination, empty, className, compact } =
    useTableContext();

  const indexes: number[] = [];

  headers.forEach((element, index) => {
    if (element.hide) {
      indexes.push(index);
    }
  });

  const hideOnMobile = useMemo(
    () => `
    @media (max-width: 768px) {
    ${indexes
      .map((index) => {
        return `table > tbody > tr > td:nth-child(${index + 1}) {
          display: none;
        }`;
      })
      .join("")}
    }
  `,
    [JSON.stringify(indexes)]
  );

  return (
    <>
      <style type="text/css">
        {`
          ${hideOnMobile} 
        `}
      </style>
      <SimpleDataTable
        className={`nk-tb-list ${className} ${loading ? "" : "mb-3"} ${
          compact ? "is-compact" : "is-separate"
        }`}
        data={loading ? [] : data || []}
        headers={headers}
        customElements={{
          th: Th,
          tr: Tr,
          td: Td,
        }}
      />
      {loading && <TableLoading />}
      {empty && (
        <div className="table-empty">
          <FormattedMessage id="No records found" />
        </div>
      )}
      {!empty && pagination && <Pagination {...pagination} compact={compact} />}
    </>
  );
};

const TableContainer: React.FC<ITableProps> = (props) => {
  return (
    <TableProvider {...props}>
      <Table />
    </TableProvider>
  );
};

export default TableContainer;
