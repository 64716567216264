import AsyncSelect from "react-select/async";
import { IGroup } from "../../interfaces/users/group";
import { GroupService } from "../../services/group";
import { StylesConfig } from "react-select";
import Group from "react-select/dist/declarations/src/components/Group";

const getOptions = async (inputValue: string) => {
  const response = await GroupService.getList({
    search: inputValue,
  });
  return response.data.map((e) => ({
    label: e.displayName,
    value: e.id,
  }));
};

export default (props: any) => {
  const customStyles: StylesConfig<any, true, any> = {
    control: (base, state) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      borderColor: state.isFocused ? "#ddd" : !props.isInvalid ? "#ddd" : "red",
      // overwrittes hover style
      "&:hover": {
        borderColor: state.isFocused
          ? "#ddd"
          : !props.isInvalid
          ? "#ddd"
          : "red",
      },
    }),
  };
  return (
    <AsyncSelect
      isMulti
      noOptionsMessage={() => <span>No hay datos para mostrar</span>}
      loadingMessage={() => <span>Cargando...</span>}
      cacheOptions
      placeholder="Seleccione el grupo de acceso"
      defaultOptions
      onBlur={props.onBlur}
      name={props.name}
      value={props.value}
      onChange={(e) => {
        if (props.onChange) props.onChange(e);
        if (props.onChangeId) props.onChangeId(e.map((e) => e.value));
      }}
      loadOptions={getOptions}
      className={props.isInvalid ? "is-invalid" : ""}
      styles={customStyles}
      // onCreateOption={createOption}
      /* defaultInputValue={props.value} */
    />
  );
};
