import http from "../clients/http";
import { serializeParams } from "../utils/url";

const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3001";

type ProductGetListParams = {
  status: string | null;
  search: string | null;
  page: string | null;
  size: string | null;
};

export class ProductCartService {
  static async getList(filters: Partial<ProductGetListParams>) {
    const response = await http.get(
      `${apiUrl}/carts?${serializeParams(filters)}`
    );
    const { data } = response;

    return {
      data: data.data,
      pagination: data.pagination,
    };
  }

  static async getDetails(customerId: string) {
    const response = await http.get(`${apiUrl}/carts/${customerId}`);
    const { data } = response;
    return data;
  }
}
