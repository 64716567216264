import React from "react";
import { Alert, Button } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import Table, {
  IPaginationProps,
  ITableHeader,
} from "../../../components/Table";
import { formatCurrency } from "../../../utils/formatter";
import SearchBar from "../../../components/Inputs/SearchBar";

export interface DiscountFormSelectedTableProps {
  applyType: any;
  category: any;
  categoryLabel: any;
  selectedProducts: any[];
  setSelectedProducts: React.Dispatch<React.SetStateAction<any[]>>;
  data?: {
    data: any[];
    pagination: {
      page: number;
      size: number;
      total: number;
    };
  };
  isLoading: boolean;
  pagination: IPaginationProps;
  type: "percentage" | "fixed";
  value: number;
  onSearch: (value: string) => void;
}

function DiscountFormSelectedTable({
  applyType,
  category,
  categoryLabel,
  selectedProducts,
  setSelectedProducts,
  data,
  isLoading,
  type,
  value,
  pagination,
  onSearch,
}: DiscountFormSelectedTableProps) {
  const intl = useIntl();

  const headers: ITableHeader[] = [
    {
      path: ["name"],
      name: intl.formatMessage({ id: "productNameHeader" }),
      className: "md-table-col",
      transform: (value, obj) => {
        return (
          <Link to={`/products/${obj.id}?t=info`} target="_blank">
            <Button as="span" variant="link" className="tb-product">
              {obj.mediaAssets?.length ? (
                <img
                  src={obj.mediaAssets[0]?.thumbnail || obj.mediaAssets[0]?.url}
                  alt={`image-${value}`}
                  className="thumb"
                />
              ) : null}
              <span className="title text-primary">{value}</span>
            </Button>
          </Link>
        );
      },
    },
    {
      path: ["code"],
      name: intl.formatMessage({ id: "codeHeader" }),
      hide: "sm",
    },
    {
      path: ["price"],
      name: intl.formatMessage({ id: "priceHeader" }),
      transform: (v) => {
        let discount: number | null = null;

        if (type === "percentage") {
          discount = (v * value) / 100;
        }

        if (type === "fixed") {
          discount = v - value < 0 ? v : v - value;
        }

        return discount ? (
          <>
            <span
              style={{
                textDecoration: "line-through",
              }}
            >
              {formatCurrency(v)}
            </span>
            /<b>{formatCurrency(v - discount)}</b>
          </>
        ) : (
          formatCurrency(v)
        );
      },
      hide: "sm",
    },
    {
      path: ["id"],
      name: intl.formatMessage({ id: "Discount" }),
      transform: (_, obj) => {
        const v = obj.price;
        let discount: number | null = null;

        if (type === "percentage") {
          discount = (v * value) / 100;
        }

        if (type === "fixed") {
          discount = v - value < 0 ? v : v - value;
        }

        return discount ? (
          <span className="text-primary">{formatCurrency(discount)}</span>
        ) : (
          "-"
        );
      },
    },
  ];

  return (
    <>
      <Alert variant={"info"}>
        <i className="icon ni ni-info"></i>{" "}
        {applyType === "by-products" ? (
          <FormattedMessage id="Select the products to which you want to apply the discount" />
        ) : applyType === "by-category" ? (
          category ? (
            <FormattedMessage
              id="You selected to apply the discount to the category {category}"
              values={{
                category: categoryLabel,
              }}
            />
          ) : (
            <FormattedMessage id="Select the categories to which you want to apply the discount" />
          )
        ) : (
          <FormattedMessage id="You selected to apply the discount to all products" />
        )}
      </Alert>

      <div
        className="nk-block pb-1 "
        style={{
          textAlign: "right",
        }}
      >
        <div
          style={{
            maxWidth: 400,
            width: "100%",
            display: "inline-block",
          }}
        >
          <SearchBar size="lg" onChange={(e) => onSearch(e.target.value)} />
        </div>
      </div>
      <div className="nk-block pt-1">
        <Table
          headers={headers}
          data={
            applyType === "by-category" && !category
              ? []
              : (data?.data as any[])
          }
          selectable={applyType === "by-products"}
          loading={isLoading}
          pagination={pagination}
          selected={selectedProducts.map((e) => e.id)}
          onSelect={(items) => {
            if (!Array.isArray(items)) {
              setSelectedProducts((s) => {
                const newItems = items(s.map((e) => e.id));

                const currentSelected = selectedProducts.filter((e) => {
                  return newItems.includes(e.id);
                });

                const missingSelected = newItems.filter((e) => {
                  return !currentSelected.find((p) => p.id === e);
                });

                const productMissing = missingSelected.map((id: string) => {
                  return data?.data.find((e: any) => e.id === id);
                });

                return [...currentSelected, ...productMissing];
              });

              return;
            }

            const currentSelected = selectedProducts.filter((e) => {
              return items.includes(e.id);
            });

            const missingSelected = items.filter((e) => {
              return !currentSelected.find((p) => p.id === e);
            });

            const productMissing = missingSelected.map((id: string) => {
              return data?.data.find((e: any) => e.id === id);
            });

            setSelectedProducts([...currentSelected, ...productMissing]);
          }}

          /*  bulkOptions={[
                  {
                    icon: "ni ni-na",
                    text: intl.formatMessage({ id: "suspendAllOption" }),
                  },
                ]} */
        />
      </div>
      {applyType === "by-products" ? (
        selectedProducts.length ? (
          <div className="mt-4">
            <hr />
            <h5 className="nk-block-title mt-4 mb-2">
              <FormattedMessage id="Selected products" />
            </h5>
            <div className="card card-preview">
              <table className="table table-ulogs">
                <thead className="table-light">
                  <tr>
                    <th className="tb-col-os">
                      <span className="overline-title">
                        <FormattedMessage id="Products" />{" "}
                      </span>
                    </th>
                    <th className="tb-col-ip">
                      <span className="overline-title">
                        <FormattedMessage id="Code" />
                      </span>
                    </th>
                    <th className="tb-col-action">
                      <span className="overline-title">&nbsp;</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {selectedProducts.map((e) => (
                    <tr key={e?.id}>
                      <td className="tb-col-os">{e?.name}</td>
                      <td className="tb-col-ip">
                        <span className="sub-text">{e?.code}</span>
                      </td>
                      <td className="tb-col-action">
                        <a
                          onClick={() => {
                            setSelectedProducts((prev) =>
                              prev.filter((p) => p.id !== e.id)
                            );
                          }}
                          className="link-cross me-sm-n1"
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : null
      ) : null}
    </>
  );
}

export default DiscountFormSelectedTable;
