import { useQuery } from "react-query";
import Dropdown, {
  DropdownMenu,
  DropdownMenuOption,
} from "../../components/Buttons/Dropdown";
import SearchBar from "../../components/Inputs/SearchBar";

import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import DynamicDropdown from "../../components/Buttons/DynamicDropdown";
import Table, { IPaginationProps, ITableHeader } from "../../components/Table";
import { transformToStatus } from "../../components/Table/transformers";
import Title from "../../components/Title";
import { Status } from "../../enums/status";
import { useTableQueryParams } from "../../hooks/router";
import { CustomerService } from "../../services/customer";
import { formatCurrency, formatMidDate } from "../../utils/formatter";
import { getFullName, getInitials } from "../../utils/person";

let timmerId: any;

function CustomerList() {
  const { page, search, setParam, size, status } = useTableQueryParams();
  const intl = useIntl();

  const { data, isLoading } = useQuery(
    ["customerList", status, search, page, size],
    (ctx) => {
      const [_, status, search, page, size] = ctx.queryKey;
      return CustomerService.getList({
        status,
        search,
        page,
        size,
      });
    }
  );

  const options: DropdownMenuOption<any>[] = [
    {
      text: intl.formatMessage({ id: "See detail" }),
      icon: "ni ni-eye",
      link: (entity) => `/customers/${entity.id}`,
    },
    // {
    //   icon: "ni ni-repeat",
    //   text: intl.formatMessage({ id: "Orders" }),
    //   link: (entity) => `/customer/${entity.id}`,
    // },
    // {
    //   icon: "ni ni-activity-round",
    //   text: intl.formatMessage({ id: "Activity" }),
    //   link: (entity) => `/customer/${entity.id}`,
    // },
    // {
    //   type: "divider",
    // },
    // {
    //   icon: "ni ni-shield-star",
    //   text: intl.formatMessage({ id: "Reset" }),
    //   link: (entity) => `/customer/${entity.id}`,
    // },
    // {
    //   icon: "ni ni-na",
    //   text: intl.formatMessage({ id: "Disable" }),
    //   link: (entity) => `/customer/${entity.id}`,
    // },
  ];

  const headers: ITableHeader[] = [
    {
      path: ["user"],
      name: intl.formatMessage({ id: "Customer" }),
      className: "lg-table-col",
      transform: (value, obj) => {
        return (
          <Link to={`/customers/${obj.id}`}>
            <div className="user-card">
              <div className="user-avatar bg-primary d-none d-md-flex">
                <span>{getInitials(obj.user)}</span>
              </div>
              <div className="user-info">
                <span className="tb-lead">
                  {getFullName(obj.user)}
                  <span
                    className={`dot dot-${
                      (Status as any)[obj.user.status as any]?.class
                    } d-md-none ms-1`}
                  ></span>
                </span>
                <span>{obj.user.email}</span>
              </div>
            </div>
          </Link>
        );
      },
    },
    {
      path: ["ordered"],
      name: intl.formatMessage({ id: "Orders" }),
      transform: formatCurrency,
    },
    {
      path: ["user", "info", "phone"],
      name: intl.formatMessage({ id: "Phone" }),
      hide: "sm",
    },
    {
      path: ["lastOrderDate"],
      name: intl.formatMessage({ id: "Last order" }),
      transform: formatMidDate,
      hide: "sm",
    },
    {
      path: ["user", "status"],
      name: intl.formatMessage({ id: "Status" }),
      transform: transformToStatus,
      hide: "sm",
    },
    {
      path: ["options"], // no id
      name: "",
      type: "tools",
      transform: (v, entity) => (
        <Dropdown
          key={entity.id}
          icon
          trigger
          text={<em className="icon ni ni-more-h" />}
        >
          <DropdownMenu options={options} entity={entity} />
        </Dropdown>
      ),
    },
  ];

  const pagination: IPaginationProps = {
    current: Number(page) || data?.pagination.page,
    size: Number(size) || data?.pagination.size,
    total: data?.pagination?.total,
    onPageChange(page) {
      setParam("page", String(page));
    },
    onSizeChange(size) {
      setParam("size", String(size));
    },
  };

  const onSearch = (term: string) => {
    clearTimeout(timmerId);
    timmerId = setTimeout(() => {
      setParam("search", term);
    }, Number(process.env.REACT_APP_SEARCH_DELAY || 700));
  };

  return (
    <div className="container-fluid">
      <div className="nk-content-inner">
        <div className="nk-content-body">
          <Title
            subText={intl.formatMessage(
              { id: "You have a total of N customers." },
              { number: data?.pagination?.total || 0 }
            )}
            rightContent={
              <ul className="nk-block-tools g-3">
                <li>
                  <SearchBar onChange={(e) => onSearch(e.target.value)} />
                </li>
                <li>
                  <DynamicDropdown
                    initialText="Estado"
                    options={Status}
                    selected={status || undefined}
                    onClick={(_, k) => setParam("status", k)}
                  />
                </li>
              </ul>
            }
          >
            <FormattedMessage id="Client list" />
          </Title>

          <div className="nk-block">
            <Table
              headers={headers}
              data={data?.data}
              /* selectable */
              loading={isLoading}
              pagination={pagination}
              /* bulkOptions={[
                {
                  icon: "ni ni-mail",
                  text: intl.formatMessage({ id: "Send email to everyone" }),
                  click: (entities) => console.log(entities),
                },
                {
                  icon: "ni ni-na",
                  text: intl.formatMessage({ id: "Disable all" }),
                },
                {
                  icon: "ni ni-trash",
                  text: intl.formatMessage({ id: "Delete all" }),
                },
                {
                  icon: "ni ni-shield-star",
                  text: intl.formatMessage({ id: "Reset all" }),
                },
              ]} */
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerList;
