import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import { LegalSettingsService } from "../../services/legal";
import { ILegalSettingsForm } from "../../interfaces/settings/legal";
import { FormattedMessage, useIntl } from "react-intl";
import { SkeletonRow } from "../../components/Loading/Skeleton";
import { useConfirmationModal } from "../../contexts/confirmation";
import { Alert, Badge } from "react-bootstrap";

function LegalSettings() {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<ILegalSettingsForm>();

  const { isLoading, isError, refetch } = useQuery<ILegalSettingsForm>(
    "legalSettings",
    LegalSettingsService.getSettings,
    {
      onSuccess: (data) => {
        setValue("privacyPolicy", data.privacyPolicy);
        setValue("termsAndCondition", data.termsAndCondition);
      },
    }
  );

  const { open } = useConfirmationModal();

  const onSubmit = async (data: ILegalSettingsForm) => {
    return open(
      intl.formatMessage(
        { id: "confirmationEditMessage" },
        { entityName: intl.formatMessage({ id: "Legal Settings" }) }
      ),
      async () => {
        try {
          await LegalSettingsService.updateSettings(data);
          toast.success(intl.formatMessage({ id: "TOAST.UPDATE_SUCCESS" }));
          refetch(); // Vuelve a cargar los datos después de la actualización exitosa
        } catch (error) {
          toast.error(intl.formatMessage({ id: "TOAST.UPDATE_ERROR" }));
        }
      }
    );
  };

  if (isError) {
    return (
      <div>
        <FormattedMessage id="Error loading configuration" />
      </div>
    );
  }

  return (
    <div className="card-inner">
      <h5 className="card-title">
        <FormattedMessage id="Legal Settings" />
      </h5>
      <p>
        <FormattedMessage id="Site use policies." />
      </p>
      <form onSubmit={handleSubmit(onSubmit)} className="gy-3 form-settings">
        <div className="row g-3 align-center">
          <div className="col-lg-5">
            <div className="form-group">
              <label className="form-label" htmlFor="privacy-policy">
                <FormattedMessage id="Privacy Policy" />
              </label>
              <span className="form-note">
                <FormattedMessage id="The privacy policies of the site." />
              </span>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="form-group">
              <div className="form-control-wrap">
                {isLoading ? (
                  <SkeletonRow size="lg" />
                ) : (
                  <>
                    <textarea
                      className={`form-control ${
                        errors.privacyPolicy ? "is-invalid" : ""
                      }`}
                      id="privacy-policy"
                      {...register("privacyPolicy", { required: true })}
                    ></textarea>
                    {errors.privacyPolicy && (
                      <div className="invalid-feedback">
                        <FormattedMessage id="This field is required" />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row g-3 align-center">
          <div className="col-lg-5">
            <div className="form-group">
              <label className="form-label" htmlFor="terms-and-condition">
                <FormattedMessage id="Terms and Conditions" />
              </label>
              <span className="form-note">
                <FormattedMessage id="The terms and conditions of the site." />
              </span>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="form-group">
              <div className="form-control-wrap">
                {isLoading ? (
                  <SkeletonRow size="lg" />
                ) : (
                  <>
                    <textarea
                      className={`form-control ${
                        errors.termsAndCondition ? "is-invalid" : ""
                      }`}
                      id="terms-and-condition"
                      {...register("termsAndCondition", { required: true })}
                    ></textarea>
                    {errors.termsAndCondition && (
                      <div className="invalid-feedback">
                        <FormattedMessage id="This field is required" />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row g-3">
          <div className="col-lg-7 offset-lg-5">
            <div className="form-group mt-2">
              {/*  <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-lg btn-primary"
                >
                <FormattedMessage id="Update" />
              </button> */}
            </div>
          </div>
        </div>
        <Alert variant="warning">
          <i className="icon ni ni-alert-circle"></i>{" "}
          <FormattedMessage id="If you need to update this section, please contact the support team" />
        </Alert>
      </form>
    </div>
  );
}

export default LegalSettings;
