import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { UseFormRegister, FieldErrors } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { INewDiscountForm } from "../../../interfaces/discounts/forms";

export interface DiscountFormValidityTimeProps {
  register: UseFormRegister<INewDiscountForm>;
  errors: FieldErrors<INewDiscountForm>;
}

function DiscountFormValidityTime({
  register,
  errors,
}: DiscountFormValidityTimeProps) {
  return (
    <>
      <div className="mt-5 mb-4">
        <span className="preview-title-lg overline-title ">
          <hr />
          <FormattedMessage id="Validity time" />
        </span>
        <p>
          <FormattedMessage id="Enter the valid period of your discount" />
        </p>
      </div>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>
              <FormattedMessage id="Start Date" />
              <span className="text-danger ms-1">*</span>
            </Form.Label>
            <Form.Control
              type="date"
              {...register("startDate", { required: true })}
              isInvalid={Boolean(errors.startDate)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>
              <FormattedMessage id="End Date" />
              <span className="text-danger ms-1">*</span>
            </Form.Label>
            <Form.Control
              type="date"
              {...register("endDate", { required: true })}
              isInvalid={Boolean(errors.endDate)}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}

export default DiscountFormValidityTime;
