import moment from "moment";
import "moment/locale/es-do";

moment.locale("es-DO");

export const formatCurrency = (
  value: number,
  opts: Intl.NumberFormatOptions = {}
) => {
  return Intl.NumberFormat("es-DO", {
    currency: "DOP",
    compactDisplay: "long",
    currencyDisplay: "symbol",
    currencySign: "accounting",
    style: "currency",
    ...opts,
  }).format(value);
};

export const formatMidDate = (value: Date | string) => {
  if (!value) return " - ";
  return moment(value).format("LLL");
};

export const formatShortDate = (value: Date | string) => {
  console.log(value);
  return moment(value).format("LL");
};

export const formatMidDateTime = (value: Date) => {
  return moment(value).format("LLL");
};

export const formatRelativeDate = (value: Date) => {
  return moment(value).subtract(4, "h").startOf("hour").fromNow();
};

export function getAllDaysInCurrentMonth() {
  var date = new Date();
  date.setDate(1);
  var days = [];
  const current = date.getMonth();
  while (date.getMonth() === current) {
    days.push(formatShortDate(new Date(date)));
    date.setDate(date.getDate() + 1);
  }
  return days;
}
