import { Button, Spinner, Tab, Tabs } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import ReactMarkdown from "react-markdown";
import { useMutation, useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import BackButton from "../../components/Buttons/BackButton";
import FormGenerator from "../../components/ContentManager/generator";
import Authorize from "../../components/Permission/Authorize";
import { Action, BasePermission } from "../../enums/permissions";
import { useFormatter } from "../../hooks/formatter";
import { useQuerySearchTab } from "../../hooks/router";
import { Addon, IntegrationsService } from "../../services/integrations";
import { SkeletonRow } from "../../components/Loading/Skeleton";
import { toast } from "react-toastify";
import { query } from "firebase/firestore";
import { queryClient } from "../../contexts/query";

function IntegrationDetails() {
  const { key } = useParams<{ key: string }>();
  const intl = useIntl();
  const formatter = useFormatter();
  const tabs = [
    {
      key: "general",
      name: intl.formatMessage({ id: "General Information" }),
      Component: IntegrationGeneralInformation,
    },
    {
      key: "settings",
      name: intl.formatMessage({ id: "Setting" }),
      Component: IntegrationSettingsForm,
    },
  ];

  const { activeKey, onSelect } = useQuerySearchTab(tabs, "t");

  const {
    data: Integration,
    isLoading,
    refetch,
  } = useQuery(
    ["integrationByKey", key],
    () => IntegrationsService.getByKey(key as string) as Promise<any>
  );

  return (
    <div className="nk-content">
      <div className="container-fluid">
        <div className="nk-content-inner">
          <div className="nk-content-body">
            <div className="nk-block-head nk-block-head-sm">
              <div className="nk-block-between g-3">
                <div className="nk-block-head-content">
                  <h5>
                    <FormattedMessage id="Integrations" />
                  </h5>
                  <h3 className="nk-block-title page-title">
                    {isLoading ? (
                      <SkeletonRow />
                    ) : (
                      <>
                        <img
                          src={Integration?.imageUrl}
                          alt="logo"
                          width="35px"
                          height="35px"
                          className="rounded-circle me-1"
                        />
                        <strong className="text-primary small">
                          {Integration?.name}
                        </strong>
                      </>
                    )}
                  </h3>
                  <div className="nk-block-des text-soft">
                    <ul className="list-inline">
                      <li>
                        <FormattedMessage id="Created At" />:{" "}
                        <span className="text-base">
                          {Integration?.integration?.createdAt
                            ? formatter.date.format(
                                new Date(Integration?.integration?.createdAt)
                              )
                            : "-"}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="nk-block-head-content">
                  <ul className="nk-block-tools g-3">
                    <Authorize
                      actions={[Action.UPDATE]}
                      subjects={[BasePermission.Addons]}
                    >
                      <li>
                        <Button variant="secondary">
                          <em className="icon ni ni-na me-1" />{" "}
                          <FormattedMessage id="Disable" />
                        </Button>
                      </li>
                    </Authorize>
                    <li>
                      <BackButton to="/integrations" />
                    </li>
                  </ul>

                  <a
                    href="/demo2/Integration-list-regular.html"
                    className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                  >
                    <em className="icon ni ni-arrow-left"></em>
                  </a>
                </div>
              </div>
            </div>

            <div className="nk-block">
              <div className="card">
                <div className="card-aside-wrap">
                  <div className="card-content">
                    {isLoading ? (
                      <div
                        style={{
                          display: "flex",
                          height: "40vh",
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Spinner />
                      </div>
                    ) : (
                      <Tabs
                        id="controlled-tab-example"
                        className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card"
                        role="tablist"
                        activeKey={activeKey}
                        onSelect={onSelect}
                      >
                        {tabs.map((t, i) => {
                          return (
                            <Tab
                              eventKey={t.key}
                              role="presentation"
                              className="nav-item"
                              key={i}
                              title={t.name}
                            >
                              <t.Component {...Integration} refetch={refetch} />
                            </Tab>
                          );
                        })}
                      </Tabs>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function IntegrationGeneralInformation(integration: Addon) {
  const { key } = useParams<{ key: string }>();

  return (
    <div className="card-inner">
      <div className="nk-block">
        <div className="nk-block-head">
          <h5 className="title">
            <FormattedMessage id="Description" />
          </h5>
          <p>{integration.shortDescription}</p>

          <hr />

          <h5 className="title mt-4">
            <FormattedMessage id="Instructions" />
          </h5>

          <div className="mb-4">
            <ReactMarkdown className="md-container" linkTarget="_blank">
              {integration.instructions}
            </ReactMarkdown>
          </div>

          <hr />

          <div className="mt-4">
            <Link to={`/integrations/${key}?t=settings`}>
              <Button size="lg" variant="primary">
                <FormattedMessage id="Go to the configuration" />
                <em className="icon ni ni-arrow-right ms-1"></em>
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

function IntegrationSettingsForm(integration: Addon & { refetch: any }) {
  const methods = useForm({
    defaultValues: integration?.integration?.values || undefined,
  });
  const { key } = useParams<{ key: string }>();

  const updateIntegrationSettingMutation = useMutation(
    (values: any) =>
      IntegrationsService.updateIntegrationSetting(key as string, values),
    {
      onSuccess: () => {
        toast.success("Se ha actualizado la configuración");
        methods.reset();
        // invalidate my-integration key of useQuery

        queryClient.invalidateQueries([
          ["integrationByKey", key],
          "my-integrations",
        ]);

        integration.refetch();
      },
    }
  );

  const onSubmit = (values: any) => {
    return updateIntegrationSettingMutation.mutateAsync(values);
  };

  return (
    <div className="card-inner">
      <div className="nk-block">
        <div className="nk-block-head">
          <h5 className="title">
            <FormattedMessage id="Required Fields" />
          </h5>
          <p>
            Debe completar los campos de acuerdo a lo detallado en las
            instrucciones
          </p>

          <hr />
          <div className="mt-5">
            <FormGenerator
              onSubmit={onSubmit}
              schema={integration.schema}
              methods={methods}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default IntegrationDetails;
