import React from "react";
import { FormControl, FormControlProps } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useParams, useSearchParams } from "react-router-dom";

const SearchBar: React.FC<FormControlProps> = (props) => {
  const [params] = useSearchParams();
  const intl = useIntl();

  const defaultValue = params.get("search") || "";
  return (
    <div className="form-control-wrap">
      <div className="form-icon form-icon-right">
        <em className="icon ni ni-search" />
      </div>
      <FormControl
        {...props}
        type="text"
        id="searchBar"
        placeholder={intl.formatMessage({ id: "Search" })}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export default SearchBar;
