import { useMutation, useQuery } from "react-query";
import { AddButton } from "../../../components/Buttons/ActionableButton";
import Dropdown, {
  DropdownMenu,
  DropdownMenuOption,
} from "../../../components/Buttons/Dropdown";
import SearchBar from "../../../components/Inputs/SearchBar";
import Table, {
  IPaginationProps,
  ITableHeader,
} from "../../../components/Table";
import LoadingText from "../../../components/Text/loading";
import Title from "../../../components/Title";
import { useContextToast } from "../../../contexts/toast";
import { useTableQueryParams } from "../../../hooks/router";
import { CollectionService } from "../../../services/cms/collection";
import { formatMidDateTime } from "../../../utils/formatter";
import { getFullName, getInitials } from "../../../utils/person";
import {
  CollectionPublishStatus,
  CollectionStatus,
} from "../enums/collection-status";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useConfirmationModal } from "../../../contexts/confirmation";
import { usePermissions } from "../../../contexts/auth-permission";
import {
  Action,
  BasePermission,
  CMSPermission,
} from "../../../enums/permissions";
import Authorize from "../../../components/Permission/Authorize";
import { ReactNode } from "react";
import moment from "moment";

let timmerId: any;

function CollectionTable({ id, name, displayName, loading, baseUrl }: any) {
  const { show } = useContextToast();
  const { open } = useConfirmationModal();
  const { page, search, setParam, size, status } = useTableQueryParams();
  const { ability } = usePermissions();

  const intl = useIntl();

  const { data, isLoading, refetch } = useQuery(
    ["collectionList", status, search, page, size, name],
    (ctx) => {
      const [_, status, search, page, size, name] = ctx.queryKey;
      return CollectionService.getCollectionList(name, {
        status,
        search,
        page,
        size,
      });
    },
    {
      enabled: !!name,
    }
  );

  const updatePublishState = useMutation(
    (entity: any) =>
      CollectionService.changePublishState(
        name,
        entity._id,
        entity.publishState === "published" ? "draft" : "published"
      ),
    {
      onSuccess(data: any) {
        refetch();
        show(
          `La colección '${data.data?.title}' ha sido actualizado a ${
            (CollectionStatus as any)[data.data?.publishState].text
          }`
        );
      },
    }
  );

  const options: DropdownMenuOption<any>[] = [
    {
      text: intl.formatMessage({ id: "See link" }),
      icon: "ni ni-eye",
      click: (entity) => window.open(baseUrl + entity.slug, "_blank"),
    },
    {
      icon: "ni ni-edit",
      text: intl.formatMessage({ id: "Edit" }),
      link: (entity) => `/cms/collections/${name}/${entity._id}/edit`,
    },
    {
      type: "divider",
    },
    {
      icon: "ni ni-exchange",
      text: (entity) =>
        `Cambiar a ${(!entity.publishedAt
          ? CollectionStatus.published.text
          : CollectionStatus.draft.text
        ).toLocaleLowerCase()}`,
      click: (entity) => {
        updatePublishState.mutateAsync(entity);
      },
    },
    {
      icon: "ni ni-trash",
      text: intl.formatMessage({ id: "Delete" }),
      click: (entity) => handleDelete(entity),
    },
  ];

  const headers: ITableHeader[] = [
    {
      path: ["title"],
      name: intl.formatMessage({ id: "Title" }),
      className: "xl-table-col",
      transform: (value, obj) => {
        return (
          <span className="tb-product">
            <span className="title">{obj.title || obj.name}</span>
          </span>
        );
      },
    },
    {
      path: ["publishState"],
      name: "Estado",
      transform: (p: keyof typeof CollectionPublishStatus, o) => {
        const state = CollectionPublishStatus[p];
        let text: ReactNode = state.text;

        if (p === "publishOnDate") {
          text = intl.formatMessage(
            { id: "Will be publish in $date" },
            { date: <b>{moment(o.publishAt).format("l")}</b> }
          );
        }

        return (
          <>
            <span className={`badge bg-${state.class} d-none d-md-inline`}>
              <em className={`ni ${state.icon} me-1`} />
              {text}
            </span>
            <span className={`badge bg-${state.class} d-sm-inline d-md-none`}>
              <em className={`ni ${state.icon} me-1`} />
            </span>
          </>
        );
      },
    },
    {
      path: ["createdBy"],
      name: intl.formatMessage({ id: "Author" }),
      transform: (value, obj) => {
        if (!value) return "-";
        return (
          <a href="#">
            <div className="user-card">
              <div className="user-avatar sm bg-orange-dim">
                <span>{getInitials(value)}</span>
              </div>
              <div className="user-info d-none d-md-block">
                <span className="tb-lead">{getFullName(value)}</span>
              </div>
            </div>
          </a>
        );
      },
    },
    {
      path: ["id"],
      name: intl.formatMessage({ id: "Last modification" }),
      hide: "sm",

      transform: (_, ob) => {
        return formatMidDateTime(new Date(ob?.updatedAt || ob?.createdAt));
      },
    },
    {
      path: ["options"], // no id
      name: "",
      type: "tools",
      transform: (v, entity) => (
        <Dropdown
          key={entity.id}
          icon
          trigger
          disabled={updatePublishState.isLoading}
          text={<em className="icon ni ni-more-h" />}
        >
          <DropdownMenu options={options} entity={entity} />
        </Dropdown>
      ),
    },
  ];

  const pagination: IPaginationProps = {
    current: Number(page) || data?.pagination.page,
    size: Number(size) || data?.pagination.size,
    total: data?.pagination?.total,
    onPageChange(page) {
      setParam("page", String(page));
    },
    onSizeChange(size) {
      setParam("size", String(size));
    },
  };

  const onSearch = (term: string) => {
    clearTimeout(timmerId);
    timmerId = setTimeout(() => {
      setParam("search", term);
    }, Number(process.env.REACT_APP_SEARCH_DELAY || 700));
  };

  const deleteRecord = useMutation(
    (entity: any) => CollectionService.deleteCollection(name, entity._id),
    {
      onSuccess() {
        refetch();
        toast.success(intl.formatMessage({ id: "TOAST.DELETE_SUCCESS" }));
      },
    }
  );

  const handleDelete = (entity: any) => {
    open("¿Está seguro de que desea eliminar este registro?", () => {
      deleteRecord.mutate(entity);
    });
  };

  return (
    <div className="container-fluid mt-5 collection-type-container">
      <div className="nk-content-inner">
        <div className="nk-content-body">
          <Title
            size="sm"
            subText={intl.formatMessage(
              { id: "You have a total of N post." },
              { number: data?.pagination?.total || 0 }
            )}
            rightContent={
              <ul className="nk-block-tools g-3">
                <li>
                  <SearchBar onChange={(e) => onSearch(e.target.value)} />
                </li>

                <Authorize
                  actions={[Action.CREATE]}
                  subjects={[CMSPermission.Collection]}
                >
                  <li className="nk-block-tools-opt">
                    <Link to={`/cms/collections/${name}/create`}>
                      <AddButton />
                    </Link>
                  </li>
                </Authorize>
              </ul>
            }
          >
            {loading ? <LoadingText /> : "Lista de " + displayName}
          </Title>

          <div className="nk-block compact-table-loading">
            <Table
              headersDependency={name}
              headers={headers}
              data={data?.data}
              /* selectable */
              loading={isLoading || loading}
              pagination={pagination}
              /*    bulkOptions={[
                {
                  icon: "ni ni-mail",
                  text: intl.formatMessage({ id: "Send email to everyone" }),
                  click: (entities) => console.log(entities),
                },
                {
                  icon: "ni ni-na",
                  text: intl.formatMessage({ id: "Disable all" }),
                },
                {
                  icon: "ni ni-trash",
                  text: intl.formatMessage({ id: "Delete all" }),
                },
                {
                  icon: "ni ni-shield-star",
                  text: intl.formatMessage({ id: "Reset all" }),
                },
              ]} */
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CollectionTable;
