import { Spinner } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "react-query";
import BackButton from "../../components/Buttons/BackButton";
import { SubscriptionPaymentStatus } from "../../enums/status";
import {
  SubscriptionPayment,
  SubscriptionService,
} from "../../services/subscriptions";
import { formatCurrency, formatShortDate } from "../../utils/formatter";
import { toast } from "react-toastify";
import { useState } from "react";
import { set } from "react-hook-form";

const SubscriptionPaymentHistory = () => {
  const [isFileLoading, setIsFileLoading] = useState(false);
  const intl = useIntl();

  const { isLoading, error, data } = useQuery(
    "paymentHistory",
    SubscriptionService.getPaymentHistory
  );

  const blobToSaveAs = (fileName: string, blob: Blob) => {
    console.log("blobToSaveAs", fileName, blob);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const onDisplayInvoice = async (item: SubscriptionPayment) => {
    try {
      setIsFileLoading(true);
      const buffer = await SubscriptionService.getPaymentInvoice(item.id);
      window.open(URL.createObjectURL(buffer));
      setIsFileLoading(false);
    } catch (e) {
      const message = `${intl.formatMessage({
        id: "Error displaying file",
      })}, ${intl.formatMessage({ id: "please try again later" })}`;
      toast.error(message);
      setIsFileLoading(false);
    }
  };

  const onDownloadInvoice = async (item: SubscriptionPayment) => {
    try {
      setIsFileLoading(true);
      const buffer = await SubscriptionService.getPaymentInvoice(item.id);
      blobToSaveAs(`${item.code}.pdf`, buffer);
      setIsFileLoading(false);
    } catch (e) {
      const message = `${intl.formatMessage({
        id: "Error downloading file",
      })}, ${intl.formatMessage({ id: "please try again later" })}`;
      toast.error(message);
      setIsFileLoading(false);
    }
  };

  return (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head nk-block-head-lg mt-5">
          <div className="nk-block-between-md g-4">
            <div className="nk-block-head-content">
              <h2 className="nk-block-title fw-normal">
                <FormattedMessage id="Payment History" />
              </h2>
              <div className="nk-block-des">
                <p>
                  <FormattedMessage id="List of all your payments can be found here" />
                  .
                </p>
              </div>
            </div>
            <div className="nk-block-head-content">
              <ul className="nk-block-tools gx-3">
                <li>
                  <BackButton />
                </li>
                <li>
                  <a
                    href="mailto:admin@chalascreations.com?subject=Solicitud de factura&body=Hola, me gustaría solicitar una factura por el pago de mi suscripción. Mi nombre de usuario es: "
                    className="btn btn-white btn-dim btn-outline-primary"
                  >
                    <em className="icon ni ni-reports"></em>
                    <span>
                      <FormattedMessage id="Request Invoice" />
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {isFileLoading && (
          <Spinner
            style={{
              position: "absolute",
              zIndex: 9999,
              right: "40px",
              top: "40px",
            }}
            animation="border"
            role="status"
            aria-hidden="true"
          />
        )}
        <div className="nk-block">
          <div className="card card-bordered">
            <table className="table table-tranx table-billing">
              <thead>
                <tr className="tb-tnx-head">
                  <th className="tb-tnx-id">
                    <span className="">#</span>
                  </th>
                  <th className="tb-tnx-info">
                    <span className="tb-tnx-desc d-none d-sm-inline-block">
                      <span>
                        <FormattedMessage id="Bill for" />
                      </span>
                    </span>
                    <span className="tb-tnx-date d-md-inline-block d-none">
                      <span className="d-md-none">
                        <FormattedMessage id="Date" />
                      </span>
                      <span className="d-none d-md-block">
                        <span>
                          <FormattedMessage id="Issue Date" />
                        </span>
                        <span>
                          <FormattedMessage id="Due Date" />
                        </span>
                      </span>
                    </span>
                  </th>
                  <th className="tb-tnx-amount">
                    <span className="tb-tnx-total">
                      <FormattedMessage id="Total" />
                    </span>
                    <span className="tb-tnx-status d-none d-md-inline-block">
                      <FormattedMessage id="Status" />
                    </span>
                  </th>
                  <th className="tb-tnx-action">
                    <span>&nbsp;</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {isLoading && (
                  <tr>
                    <td colSpan={3}>
                      <div
                        className="d-flex p-2 text-center"
                        style={{
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            border: "none",
                          }}
                        >
                          <Spinner
                            animation="border"
                            role="status"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                {error ? (
                  <>Ha ocurrido un error al cargar los datos...</>
                ) : null}

                {data?.data.length === 0 && (
                  <tr>
                    <td colSpan={3}>
                      <div
                        className="d-flex p-2 text-center"
                        style={{
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            border: "none",
                          }}
                        >
                          <FormattedMessage id="No data to display" />
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                {data?.data.map((item, index) => {
                  const status = item.status;
                  const {
                    class: statusClass,
                    icon: statusIcon,
                    id: statusId,
                  } = SubscriptionPaymentStatus[status] ||
                  SubscriptionPaymentStatus.pending;
                  return (
                    <tr key={index} className="tb-tnx-item">
                      <td className="tb-tnx-id">
                        <a
                          role="button"
                          className="text-link"
                          onClick={(e) => {
                            e.preventDefault();
                            onDisplayInvoice(item);
                          }}
                        >
                          <span>{item.code}</span>
                        </a>
                      </td>
                      <td className="tb-tnx-info">
                        <div className="tb-tnx-desc">
                          <span className="title">{item.billFor}</span>
                        </div>
                        <div className="tb-tnx-date">
                          <span className="date">
                            {formatShortDate(item.issueDate)}
                          </span>
                          <span className="date">
                            {formatShortDate(item.dueDate)}
                          </span>
                        </div>
                      </td>
                      <td className="tb-tnx-amount">
                        <div className="tb-tnx-total">
                          <span className="amount">
                            {item.amount?.DOP?.subTotal
                              ? formatCurrency(item.amount?.DOP?.subTotal)
                              : `US$ ${item.subTotal}`}
                          </span>
                        </div>
                        <div className="tb-tnx-status">
                          <span
                            className={`badge bg-${statusClass}`}
                            style={{
                              paddingBottom: "3px",
                              paddingTop: "3px",
                            }}
                          >
                            <i className={statusIcon + " me-1"} />{" "}
                            <FormattedMessage id={statusId} />
                          </span>
                        </div>
                      </td>
                      <td>
                        <ul className="d-flex justify-content-center gx-3">
                          <li>
                            <a
                              href="#"
                              className="btn btn-sm btn-link"
                              onClick={(e) => {
                                e.preventDefault();
                                onDisplayInvoice(item);
                              }}
                            >
                              <em className="icon ni ni-eye"></em>
                              <span>
                                <FormattedMessage id="View" />
                              </span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="btn btn-sm btn-link"
                              onClick={(e) => {
                                e.preventDefault();
                                onDownloadInvoice(item);
                              }}
                            >
                              <em className="icon ni ni-download"></em>
                              <span>
                                <FormattedMessage id="Download" />
                              </span>
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPaymentHistory;
