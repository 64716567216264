import { useUser } from "../contexts/auth";
import { usePermissions } from "../contexts/auth-permission";
import { Action, PolicyPermission } from "../enums/permissions";

interface PermissionValidation extends Object {
  permissions: [Action, PolicyPermission][];
}

export const useAuthorizedArray = <T>(
  array: (Partial<PermissionValidation> & T)[]
) => {
  const userPermissions = usePermissions();
  const { user } = useUser();

  const isAdmin = user?.groups?.find((g) => g.name === "admin");

  return array.filter((e) => {
    if (!e.permissions || isAdmin) return true;

    return e.permissions.every((p) => {
      const [action, permission] = p;
      return userPermissions.ability.can(action, permission);
    });
  });
};
