import React from "react";

interface IEmptyPageProps {
  subTitle: string;
  text: string;
}

function EmptyPage({ subTitle, text }: IEmptyPageProps) {
  return (
    <div className="nk-block-content nk-error-ld text-center">
      <h3 className="nk-error-title">{subTitle}</h3>
      <p className="nk-error-text">{text}</p>
    </div>
  );
}

export default EmptyPage;
