import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

export const serverUrl =
  process.env.REACT_APP_API_URL ||
  /* "https://cc-api-backend.herokuapp.com"; */ "http://localhost:3001";
export const apiUrl = `${serverUrl}/api`;

let isRefreshing = false;

const getSubdomain = () => {
  const parts = window.location.hostname.split(".");
  if (parts.length === 1) return null;
  return parts[0];
};

export const Constant = {
  API_URL: apiUrl,
  SERVER_URL: serverUrl,
  ACCESS_TOKEN_KEY: "access_token",
  REFRESH_TOKEN_KEY: "refresh_token",
  TOKEN_EXPIRY_KEY: "token_expiry",
  USER_KEY: "user",
};

const _axios = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

// Function to get the token's expiration time.
const getTokenExpiry = () => {
  const tokenExpiry = localStorage.getItem(Constant.TOKEN_EXPIRY_KEY);
  if (!tokenExpiry) return null;
  return new Date(Number(tokenExpiry) * 1000);
};

// Function to check if token needs to be refreshed.
const shouldRefreshToken = async (
  config: AxiosRequestConfig
): Promise<boolean> => {
  // If the request is to refresh the token, return false.
  if (isRefreshing)
    return new Promise<boolean>((resolve) =>
      setTimeout(() => resolve(shouldRefreshToken(config)), 500)
    );

  console.log(config.url);
  if (config.url === "/auth/refresh") return false;
  if (config.url === "/auth/sign-in") return false;

  const expiryTime = getTokenExpiry();
  if (!expiryTime) return false;

  // Get the current date.
  const currentDate = new Date();

  // Get the time difference in milliseconds.
  const timeDifference = Number(expiryTime) - Number(currentDate);

  // Convert the difference to minutes.
  const differenceInMinutes = timeDifference / (1000 * 60);

  // If the difference is 3 minutes or less, return true, else false.
  return differenceInMinutes <= 20;
};

// Refresh token function.
const refreshToken = async () => {
  try {
    isRefreshing = true;
    const response = await axios.post(
      Constant.API_URL + "/auth/refresh",
      {
        refreshToken: localStorage.getItem(Constant.REFRESH_TOKEN_KEY),
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            Constant.ACCESS_TOKEN_KEY
          )}`,
          "x-client-key": getSubdomain(),
        },
      }
    );

    console.log(response);

    // Save new tokens and expiration time to local storage or other places as needed.
    localStorage.setItem(Constant.ACCESS_TOKEN_KEY, response.data.accessToken);
    localStorage.setItem(Constant.TOKEN_EXPIRY_KEY, response.data.expiryTime);
    localStorage.setItem(
      Constant.REFRESH_TOKEN_KEY,
      response.data.refreshToken
    );

    isRefreshing = false;
    return response.data.accessToken;
  } catch (error) {
    console.error("Error refreshing token", error);
    isRefreshing = false;
    throw error;
  }
};

const logoutReload = () => {
  let url = window.location.origin + "/login";

  if (url.indexOf("?") > -1) {
    url += "&no_session=1";
  } else {
    url += "?no_session=1";
  }
  window.location.href = url;
};

export class HttpClient {
  private readonly client: AxiosInstance;

  constructor() {
    this.client = _axios;

    // Add a request interceptor
    this.client.interceptors.request.use(
      async (config) => {
        const subdomain = getSubdomain();
        config.headers["x-client-Key"] = subdomain;

        // Retrieve the token from local storage
        const token = localStorage.getItem(Constant.ACCESS_TOKEN_KEY);

        const _token = token?.startsWith('"') ? JSON.parse(token) : token;

        // Set the token in the request header
        if (token) {
          config.headers["Authorization"] = `Bearer ${_token}`;
        }

        if (await shouldRefreshToken(config)) {
          try {
            const newAccessToken = await refreshToken();
            config.headers["Authorization"] = `Bearer ${newAccessToken}`;
          } catch (error) {
            localStorage.removeItem(Constant.USER_KEY);
            localStorage.removeItem(Constant.ACCESS_TOKEN_KEY);
            localStorage.removeItem(Constant.REFRESH_TOKEN_KEY);
            localStorage.removeItem(Constant.TOKEN_EXPIRY_KEY);
            logoutReload();
          }
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.config.url !== "/auth/sign-in"
        ) {
          // Remove the 'user' key from localStorage
          localStorage.removeItem(Constant.USER_KEY);
          localStorage.removeItem(Constant.ACCESS_TOKEN_KEY);
          localStorage.removeItem(Constant.REFRESH_TOKEN_KEY);
          localStorage.removeItem(Constant.TOKEN_EXPIRY_KEY);
          logoutReload();
        }
        return Promise.reject(error);
      }
    );
  }

  get(path: string, config?: AxiosRequestConfig<any>) {
    return this.client.get(path, config);
  }

  put(path: string, body: any, config?: AxiosRequestConfig<any>) {
    return this.client.put(path, body, config);
  }

  patch(path: string, body: any, config?: AxiosRequestConfig<any>) {
    return this.client.patch(path, body, config);
  }

  post(path: string, body: any, config?: AxiosRequestConfig<any>) {
    return this.client.post(path, body, config);
  }

  delete(path: string, config?: AxiosRequestConfig<any>) {
    return this.client.delete(path, config);
  }
}

export default new HttpClient();
