import httpClient from "../clients/http";

export interface Addon {
  schema: any;
  instructions: string;
  shortDescription: string;
  id: string;
  imageUrl: string;
  name: string;
  key: string;
  status: string;
  version: string;
  link: string;
  integration?: {
    id: string;
    addonId: string;
    values: Record<string, any>;
    status: string;
  };
}
export class IntegrationsService {
  static async updateIntegrationSetting(
    key: string,
    values: any
  ): Promise<unknown> {
    const response = await httpClient.patch(
      `/addons/integrations/${key}`,
      values
    );
    return response.data;
  }
  static async getByKey(key: string): Promise<any> {
    const response = await httpClient.get(`/addons/integrations/${key}`);
    return response.data;
  }
  static async getAddons(): Promise<Addon[]> {
    const response = await httpClient.get("/addons");
    return response.data;
  }

  static async getMyAddons(): Promise<Addon[]> {
    const response = await httpClient.get("/addons/me");
    return response.data;
  }

  static async integrateAddon(id: string, config: any): Promise<any> {
    const response = await httpClient.post(`/addons/${id}/integrate`, config);
    return response.data;
  }

  static async getAddon(id: string): Promise<Addon> {
    const response = await httpClient.get(`/addons/${id}`);
    return response.data;
  }
}
