import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import FormGenerator from "../../components/ContentManager/generator";
import { useContextToast } from "../../contexts/toast";
import { CatalogService } from "../../services/catalog";
import { getFieldDescription } from "../../utils/entity";

export default function CatalogoEdit() {
  const { id, key } = useParams();
  const intl = useIntl();
  const navigate = useNavigate();
  const methods = useForm();
  const toaster = useContextToast();

  const schemaQuery = useQuery(
    ["catalogSchemaByKey", key],
    (ctx) => {
      const [, key] = ctx.queryKey;
      return CatalogService.getSchema(key as any);
    },
    {
      cacheTime: Infinity,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
    }
  );

  const { data, isLoading } = useQuery(
    ["postDetails", id],
    () => CatalogService.getDetails(String(key), String(id)),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      onSuccess(data) {
        methods.reset(data);
      },
    }
  );

  const onSubmit = async (formData: any) => {
    await CatalogService.updateContent(String(key), String(id), formData);
    toaster.show(intl.formatMessage({ id: "TOAST.UPDATE_SUCCESS" }));
  };

  return (
    <div className="components-preview wide-md mx-auto">
      <div className="nk-block-head nk-block-head-lg wide-sm">
        <div className="nk-block-head-content">
          <div className="nk-block-head-sub">
            <a role="button" className="back-to" onClick={() => navigate(-1)}>
              <em className="icon ni ni-arrow-left" />
              <span>
                <FormattedMessage id="Go back to catalogs" />
              </span>
            </a>
          </div>
          <h2 className="nk-block-title fw-normal">
            {data?.values ? getFieldDescription(data?.values) : null}
          </h2>
          <div className="nk-block-des">
            <p className="lead">
              <FormattedMessage id="Part of the collection of" />{" "}
              <small>
                <i>({key})</i>
              </small>{" "}
              - <b>{data?.name}</b>
            </p>
          </div>
        </div>
      </div>
      <div className="nk-block nk-block-lg">
        <div className="nk-block-head">
          <div className="nk-block-head-content">
            <h4 className="title nk-block-title">
              <FormattedMessage id="Available fields" />
            </h4>
          </div>
        </div>
        <div className="card card-bordered card-preview">
          <div className="card-inner">
            <div className="preview-block">
              {!data || isLoading || schemaQuery.isLoading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">
                      <FormattedMessage id="loading" />
                    </span>
                  </div>
                </div>
              ) : (
                <FormGenerator
                  methods={methods}
                  schema={schemaQuery.data}
                  onSubmit={onSubmit}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
