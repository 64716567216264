import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import { CompanySettingsService } from "../../services/company";
import { ICompanySettingsForm } from "../../interfaces/settings/company";
import { emailPattern, phonePattern } from "../../utils/form-validation";
import { FormattedMessage, useIntl } from "react-intl";
import { SkeletonRow } from "../../components/Loading/Skeleton";
import { useConfirmationModal } from "../../contexts/confirmation";

function CompanySettings() {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<ICompanySettingsForm>();

  const { isLoading, isError, refetch } = useQuery<ICompanySettingsForm>(
    "companySettings",
    CompanySettingsService.getSettings,
    {
      onSuccess: (data) => {
        setValue("name", data.name);
        setValue("ide", data.ide);
        setValue("ideType", data.ideType);
        setValue("address", data.address);
        setValue("telephone", data.telephone);
        setValue("shortDescription", data.shortDescription);
        setValue("infoEmail", data.infoEmail);
      },
    }
  );

  const { open } = useConfirmationModal();

  const onSubmit = async (data: ICompanySettingsForm) => {
    return open(
      intl.formatMessage(
        { id: "confirmationEditMessage" },
        { entityName: intl.formatMessage({ id: "Company settings" }) }
      ),
      async () => {
        try {
          await CompanySettingsService.updateSettings(data);
          toast.success(intl.formatMessage({ id: "TOAST.UPDATE_SUCCESS" }));
          refetch(); // Vuelve a cargar los datos después de la actualización exitosa
        } catch (error) {
          toast.error(intl.formatMessage({ id: "TOAST.UPDATE_ERROR" }));
        }
      }
    );
  };

  if (isError) {
    return (
      <div>
        <FormattedMessage id="Error loading company settings" />
      </div>
    );
  }

  return (
    <div className="card-inner">
      <h5 className="card-title">
        <FormattedMessage id="Company settings" />
      </h5>
      <p>
        <FormattedMessage id="Your company data." />
      </p>
      <form onSubmit={handleSubmit(onSubmit)} className="gy-3 form-settings">
        <div className="row g-3 align-center">
          <div className="col-lg-5">
            <div className="form-group">
              <label className="form-label" htmlFor="company-name">
                <FormattedMessage id="Name" />
              </label>
              <span className="form-note">
                <FormattedMessage id="Specify the name of your company." />
              </span>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="form-group">
              <div className="form-control-wrap">
                {isLoading ? (
                  <SkeletonRow size="xs" />
                ) : (
                  <>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.name ? "is-invalid" : ""
                      }`}
                      id="company-name"
                      {...register("name", { required: true, maxLength: 40 })}
                    />
                    {errors.name && (
                      <div className="invalid-feedback">
                        Este campo es requerido y debe tener máximo 40
                        caracteres.
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row g-3 align-center">
          <div className="col-lg-5">
            <div className="form-group">
              <label className="form-label" htmlFor="company-ide">
                <FormattedMessage id="Identity card or RNC" />
              </label>
              <span className="form-note">
                <FormattedMessage id="Specify the ID or RNC of your company." />
              </span>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="form-group">
              <div className="form-control-wrap">
                {isLoading ? (
                  <SkeletonRow size="xs" />
                ) : (
                  <>
                    <input
                      type="text"
                      className="form-control"
                      id="company-ide"
                      {...register("ide", { required: true, maxLength: 12 })}
                      maxLength={12}
                      required
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row g-3 align-center">
          <div className="col-lg-5">
            <div className="form-group">
              <label className="form-label" htmlFor="company-address">
                <FormattedMessage id="Address" />
              </label>
              <span className="form-note">
                <FormattedMessage id="Specify the address of your company." />
              </span>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="form-group">
              <div className="form-control-wrap">
                {isLoading ? (
                  <SkeletonRow size="lg" />
                ) : (
                  <>
                    <textarea
                      className={`form-control ${
                        errors.address ? "is-invalid" : ""
                      }`}
                      id="company-address"
                      {...register("address", {
                        required: true,
                        maxLength: 180,
                      })}
                    ></textarea>
                    {errors.address && (
                      <div className="invalid-feedback">
                        Este campo es requerido y debe tener máximo 180
                        caracteres.
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row g-3 align-center">
          <div className="col-lg-5">
            <div className="form-group">
              <label className="form-label" htmlFor="company-tel">
                <FormattedMessage id="Phone" />
              </label>
              <span className="form-note">
                <FormattedMessage id="Specify the phone number of your company." />
              </span>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="form-group">
              <div className="form-control-wrap">
                {isLoading ? (
                  <SkeletonRow size="xs" />
                ) : (
                  <>
                    <input
                      type="tel"
                      className={`form-control ${
                        errors.telephone ? "is-invalid" : ""
                      }`}
                      id="company-tel"
                      {...register("telephone", {
                        required: true,
                        maxLength: 15,
                        pattern: phonePattern,
                      })}
                    />
                    {errors.telephone && (
                      <div className="invalid-feedback">
                        {errors.telephone.message}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row g-3 align-center">
          <div className="col-lg-5">
            <div className="form-group">
              <label className="form-label" htmlFor="company-description">
                <FormattedMessage id="Short description" />
              </label>
              <span className="form-note">
                <FormattedMessage id="Specify a short description of your company." />
              </span>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="form-group">
              <div className="form-control-wrap">
                {isLoading ? (
                  <SkeletonRow size="lg" />
                ) : (
                  <>
                    <textarea
                      className={`form-control ${
                        errors.shortDescription ? "is-invalid" : ""
                      }`}
                      id="company-description"
                      {...register("shortDescription", {
                        required: true,
                        maxLength: 180,
                      })}
                    ></textarea>
                    {errors.shortDescription && (
                      <div className="invalid-feedback">
                        Este campo es requerido y debe tener máximo 180
                        caracteres.
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row g-3 align-center">
          <div className="col-lg-5">
            <div className="form-group">
              <label className="form-label" htmlFor="company-email">
                <FormattedMessage id="informative mail" />
              </label>
              <span className="form-note">
                <FormattedMessage id="Specify the informative mail of your company." />
              </span>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="form-group">
              <div className="form-control-wrap">
                {isLoading ? (
                  <SkeletonRow size="xs" />
                ) : (
                  <>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.infoEmail ? "is-invalid" : ""
                      }`}
                      id="company-email"
                      {...register("infoEmail", {
                        required: "Este campo es requerido",
                        pattern: emailPattern,
                      })}
                      maxLength={40}
                    />
                    {errors.infoEmail && (
                      <div className="invalid-feedback">
                        {errors.infoEmail.message as string}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row g-3">
          <div className="col-lg-7 offset-lg-5">
            <div className="form-group mt-2">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-lg btn-primary"
              >
                <FormattedMessage id="Update" />
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CompanySettings;
