import Authorize from "../../components/Permission/Authorize";
import { Action, BasePermission } from "../../enums/permissions";
import CMS from "./cms";
import Ecommerce from "./e-commerce";

function Dashboard() {
  return (
    <>
      <Authorize actions={[Action.READ]} subjects={[BasePermission.Order]}>
        <>
          <Ecommerce />
          <hr />
        </>
      </Authorize>
      <CMS />
    </>
  );
}

export default Dashboard;
