import httpClient from "../clients/http";

type LegalParams = {
  privacyPolicy: string;
  termsAndCondition: string;
}

export class LegalSettingsService {
  static async getSettings(): Promise<LegalParams> {
    const response = await httpClient.get("/settings/legal");
    return response.data;
  }

  static async updateSettings(settings: LegalParams): Promise<LegalParams> {
    const response = await httpClient.put("/settings/legal", settings);
    return response.data;
  }
}