import AsyncCreatableSelect from "react-select/async-creatable";
import { ProductCategoryService } from "../../services/product-category";
import { Form } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { styles } from ".";

const getOptions = async (inputValue: string) => {
  const response = await ProductCategoryService.getList({ search: inputValue });
  console.log(response);
  return response.data.map((e: any) => ({
    label: e.name,
    value: e.id,
    attributes: e.attributes,
  }));
};

const createOption = async (inputValue: string) => {
  return [];
};
export default (props: any) => (
  <AsyncCreatableSelect
    styles={styles}
    noOptionsMessage={() => (
      <span>
        <FormattedMessage id="No data to display" />
      </span>
    )}
    loadingMessage={() => (
      <span>
        <FormattedMessage id="Loading" />
      </span>
    )}
    formatCreateLabel={(input) => (
      <span>
        <FormattedMessage id="Create new category" /> "{input}"{" "}
      </span>
    )}
    cacheOptions
    defaultOptions
    placeholder={<FormattedMessage id="Select the product category" />}
    onBlur={props.onBlur}
    name={props.name}
    value={props.value}
    onChange={props.onChange}
    loadOptions={getOptions}
    onCreateOption={createOption}
    isValidNewOption={() => false}

    /* defaultInputValue={props.value} */
  />
);
