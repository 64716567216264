import { EditorState } from "draft-js";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import FormGenerator from "../../../components/ContentManager/generator";
import { useContextToast } from "../../../contexts/toast";
import { CollectionService } from "../../../services/cms/collection";
import { editorStateToMarkdown } from "../../../utils/editor";
import { getFieldDescription } from "../../../utils/entity";
import { fixIncomingFormat, fixMediaFormat } from "../../../utils/media";
import Datepicker from "../../../components/Inputs/Datepicker";
import { Col, Form, Row } from "react-bootstrap";
import DynamicDropdown from "../../../components/Buttons/DynamicDropdown";
import { CollectionPublishStatus } from "../enums/collection-status";

export default function PostEdit() {
  const { id, name } = useParams();
  const intl = useIntl();
  const navigate = useNavigate();
  const methods = useForm();
  const toaster = useContextToast();

  const { data, isLoading } = useQuery(
    ["postDetails", id],
    () => CollectionService.getDetails(String(name), String(id)),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      onSuccess(data) {
        const { values } = fixIncomingFormat(data.values, data.schema);
        methods.reset({
          ...values,
          publishAt: values.publishAt ? values.publishAt.split("T")[0] : null,
        });
      },
    }
  );

  const values = methods.getValues();

  const onSubmit = async (formData: any) => {
    for (const key in formData) {
      if (formData[key] instanceof EditorState) {
        formData[key] = editorStateToMarkdown(formData[key]);
      }
    }

    await CollectionService.updateContent(String(name), String(id), formData);
    toaster.show(intl.formatMessage({ id: "TOAST.UPDATE_SUCCESS" }));
  };

  const publishState = methods.watch("publishState");

  return (
    <div className="components-preview wide-md mx-auto">
      <div className="nk-block-head nk-block-head-lg wide-sm">
        <div className="nk-block-head-content">
          <div className="nk-block-head-sub">
            <a role="button" className="back-to" onClick={() => navigate(-1)}>
              <em className="icon ni ni-arrow-left" />
              <span>
                <FormattedMessage id="pageListTitle" />
              </span>
            </a>
          </div>
          <h2 className="nk-block-title fw-normal">
            {data?.values ? getFieldDescription(data?.values) : null}
          </h2>
          <div className="nk-block-des">
            <p className="lead">
              <FormattedMessage id="Part of the collection of" />{" "}
              <b>{data?.name}</b>
            </p>
          </div>
        </div>
      </div>
      <div className="nk-block nk-block-lg">
        <div className="nk-block-head">
          <div className="nk-block-head-content">
            <Row>
              <Col>
                <h4 className="title nk-block-title d-inline-block me-3">
                  <FormattedMessage id="Save as" />:
                </h4>

                <DynamicDropdown
                  initialText={intl.formatMessage({ id: "Status" })}
                  options={CollectionPublishStatus}
                  selected={publishState || "draft"}
                  variant="solid"
                  clearable={false}
                  onClick={(_, k) => methods.setValue("publishState", k)}
                />
              </Col>
              {publishState === "publishOnDate" ? (
                <Col className="text-end">
                  <Form.Group>
                    <Form.Label className="me-2">
                      <FormattedMessage id="Publish on date" />{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Controller
                      defaultValue={new Date(values.publishAt)}
                      rules={{
                        required: true,
                        validate: {
                          futureDate: (value) => {
                            const today = new Date();
                            const selectedDate = new Date(value);
                            return selectedDate > today;
                          },
                        },
                      }}
                      control={methods.control}
                      name="publishAt"
                      render={({ field }) => (
                        <Datepicker
                          className={`${
                            methods.formState.errors.publishAt
                              ? "is-invalid"
                              : ""
                          }`}
                          style={{ width: "auto", display: "inline" }}
                          label={intl.formatMessage({ id: "Publish on date" })}
                          {...field}
                        />
                      )}
                    />
                    {methods.formState.errors.publishAt &&
                      methods.formState.errors.publishAt.type ===
                        "futureDate" && (
                        <Form.Control.Feedback type="invalid">
                          <FormattedMessage id="Selected date must be in the future" />
                        </Form.Control.Feedback>
                      )}
                  </Form.Group>
                </Col>
              ) : null}
            </Row>
          </div>
        </div>
        <div className="card card-bordered card-preview">
          <div className="card-inner">
            <div className="preview-block">
              {!data || isLoading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">
                      <FormattedMessage id="loading" />
                    </span>
                  </div>
                </div>
              ) : (
                <FormGenerator
                  methods={methods}
                  schema={data.schema}
                  onSubmit={onSubmit}
                  loading={isLoading}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
