import React from "react";

interface AddButtonProps {
  onClick?: () => void;
  className?: string;
}

export function AddButton({ onClick, className = "" }: AddButtonProps) {
  return (
    <>
      <a
        className={"btn btn-icon btn-primary d-md-none " + className}
        onClick={onClick}
      >
        <em className="icon ni ni-plus" />
      </a>
      <a
        className={"btn btn-primary d-none d-md-inline-flex " + className}
        onClick={onClick}
      >
        <em className="icon ni ni-plus" />
        <span>Agregar</span>
      </a>
    </>
  );
}

interface EditButtonProps {
  onClick?: () => void;
}

export function EditButton({ onClick }: EditButtonProps) {
  return (
    <>
      <a
        href="#"
        className="btn btn-icon btn-primary d-md-none"
        onClick={onClick}
      >
        <em className="icon ni ni-edit" />
      </a>
      <a
        href="#"
        className="btn btn-primary d-none d-md-inline-flex"
        onClick={onClick}
      >
        <em className="icon ni ni-edit" />
        <span>Editar</span>
      </a>
    </>
  );
}
