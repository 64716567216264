import React, { ReactNode, useContext, useMemo, useState } from "react";
import { ITableHeader, ITableProps } from "./interfaces";
import { transformToCheckFactory } from "./transformers";

export const TableContext = React.createContext<
  {
    selected: string[];
    empty: boolean;
    setSelected: React.Dispatch<React.SetStateAction<string[]>>;
  } & ITableProps
>({
  selected: [],
  setSelected: () => {},
} as any);

export const useTableContext = () => useContext(TableContext);

export const TableProvider: React.FC<{ children: ReactNode } & ITableProps> = ({
  children,
  headers,
  data,
  bulkOptions,
  loading,
  pagination,
  selectable,
  className = "",
  headersDependency = "null",
  onSelected = (s, itm) => {},
  selected: _selected,
  onSelect: _onSelected,
  compact,
}) => {
  const [selected, setSelected] = useState<string[]>([]);

  const selectedItems = _selected || selected;
  const selectItem = _onSelected || setSelected;

  const _headers = useMemo<ITableHeader[]>(() => {
    if (selectable)
      return [
        {
          path: ["id"],
          type: "checkbox",
          name: "id",
          appendValue: true,
          className: "nk-tb-col-check",
          transform: transformToCheckFactory((e) => {
            const newValue = e.target.checked
              ? selectedItems.concat([e.target.value as unknown as string])
              : selectedItems.filter((v) => v !== e.target.value);

            if (e.target.checked) {
              selectItem((s) =>
                s.concat([e.target.value as unknown as string])
              );
            } else {
              selectItem((s) => s.filter((v) => v !== e.target.value));
            }
            onSelected(e.target.value, newValue);
          }),
        },
        ...headers,
      ];

    return headers;
  }, [selectable, headersDependency, onSelected, selectedItems, selectItem]);

  const empty = !loading && Array.isArray(data) && !data.length;

  return (
    <TableContext.Provider
      value={{
        className,
        selected: selectedItems,
        setSelected: _onSelected || setSelected,
        headers: _headers,
        data,
        bulkOptions,
        loading,
        pagination,
        selectable,
        empty,
        compact,
        onSelected,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};

export default TableProvider;
