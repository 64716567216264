import { convertToEditorState } from "./editor";

export const getThumbnailUrl = (item: any) => {
  return item?.thumbnail || item?.url;
};

export function readFileAsDataURL(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = () => {
      reject(reader.error);
    };

    reader.readAsDataURL(file);
  });
}

interface Schema {
  type: "media" | "object" | "relation";
  schema?: Schema;
  multiple?: boolean;
}

export const fixIncomingFormat = (values: any, schema: any): any => {
  for (const key of Object.keys(values)) {
    if (schema[key]?.type === "richtext") {
      values[key] = convertToEditorState(values[key]);
    }
  }

  return {
    values,
    schema,
  };
};

export const fixMediaFormat = (
  data: Record<string, unknown>,
  schema: Record<string, Schema>,
  formData: FormData,
  from = ""
): Record<string, unknown> => {
  for (const [key, valueSchema] of Object.entries(schema)) {
    const valueData = data[key];

    // type: media
    /* if (valueSchema.type === "media") {
      if (valueData instanceof FileList) {
        if (!valueSchema.multiple) {
          formData.append(from + key, valueData[0]);
        } else {
          // multiple images
        }
      } else {
        // need to be removed
        delete data[key];
      }
    } else  */
    if (valueSchema.type === "relation") {
      continue;
    }

    // type: object
    else if (typeof valueData === "object") {
      if (Array.isArray(valueData)) {
        valueData.forEach((childValueData, index) => {
          fixMediaFormat(
            childValueData as Record<string, unknown>,
            valueSchema.schema as unknown as Record<string, Schema>,
            formData,
            `${key}.${index}.`
          );
        });
        return data;
      }

      return fixMediaFormat(
        valueData as Record<string, unknown>,
        valueSchema.schema as unknown as Record<string, Schema>,
        formData,
        from + `${key}.`
      );
    }
  }

  return data;
};
