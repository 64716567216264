import { Modal, Spinner } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "react-query";
import { useFormatter } from "../../hooks/formatter";
import { Plan, SubscriptionService } from "../../services/subscriptions";
import { useState } from "react";

const PlanCard = ({ plan }: { plan: Plan }) => {
  const { currency } = useFormatter();
  const intl = useIntl();

  return (
    <div className="pricing">
      <div className="pricing-head">
        <div className="pricing-title">
          <h4 className="card-title title">{plan.name}</h4>
          <p className="sub-text">{plan.description}</p>
        </div>
        <div className="card-text">
          <div className="row">
            <div className="col-6">
              <span className="h4 fw-500">US$ {plan.price}</span>
              <span className="sub-text">
                <FormattedMessage id="Price" />
              </span>
            </div>
            <div className="col-6">
              <span className="h4 fw-500">
                {intl.formatMessage({ id: plan.duration })}
              </span>
              <span className="sub-text">
                <FormattedMessage id="Recurrence" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="pricing-body">
        <ul className="pricing-features">
          {plan.features?.map((feature, index) => (
            <li key={index}>
              <b className="w-50">{feature.feature.displayName}</b> -{" "}
              <span className="ms-auto">{feature.displayValue}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const MyPlan = () => {
  const { isLoading, error, data } = useQuery(
    "mySubscriptionDetails",
    SubscriptionService.getMySubscription
  );

  const intl = useIntl();

  const [show, setShow] = useState(false);

  const { currency } = useFormatter();

  const plan = data?.plan;

  return (
    <div className="nk-block">
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="My current plan" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{plan && <PlanCard plan={plan} />}</Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-light"
            onClick={() => setShow(false)}
          >
            <FormattedMessage id="Close" />
          </button>
        </Modal.Footer>
      </Modal>
      <div className="card card-bordered">
        <div className="card-inner-group">
          <div className="card-inner">
            {isLoading && (
              <div className="d-flex align-items-center">
                <strong>
                  <FormattedMessage id="Loading" />
                </strong>
                <Spinner
                  animation="border"
                  className="spinner-border ms-auto"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            )}
            {error ? (
              <>
                '
                <FormattedMessage id="An error occurred while loading the data..." />
                '
              </>
            ) : null}
            {data && plan && (
              <div className="between-center flex-wrap flex-md-nowrap g-3">
                <div className="nk-block-text">
                  <h6>
                    Plan {plan.name} -{" "}
                    <span className="text-base">
                      US${" "}
                      {`${plan.price} / ${intl.formatMessage({
                        id: plan.duration,
                      })}`}
                    </span>
                  </h6>
                  <p className="text-soft">{plan.description}</p>
                </div>
                <div className="nk-block-actions flex-shrink-0">
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      setShow(true);
                    }}
                    className="btn btn-primary"
                  >
                    <FormattedMessage id="See my plan" />
                  </a>
                </div>
              </div>
            )}
          </div>
          <div className="card-inner">
            <div className="between-center flex-wrap flex-md-nowrap g-3">
              <div className="nk-block-text">
                <p>
                  <FormattedMessage id="Learn more about our" />{" "}
                  <a href="https://www.shoppxy.com/planes" target="blank">
                    <FormattedMessage id="other plans" />
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyPlan;
