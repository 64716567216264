import http from "../clients/http";

type User = any;

export class AuthService {
  static async signIn(
    data: any,
    key?: string
  ): Promise<{
    user: User;
    accessToken: string;
    refreshToken: string;
    expiryTime: number;
  }> {
    const response = key
      ? await http.post("/auth/sign-in/reset-password", {
          ...data,
          actionKey: key,
        })
      : await http.post("/auth/sign-in", data);

    return response.data;
  }

  static async getPermissions() {
    const response = await http.get("/auth/permissions/me");
    return response.data;
  }
}
