import { useEffect } from "react";
import {
  matchRoutes,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { TabsProps } from "react-bootstrap/Tabs";

const routes = [{ path: "/members/:id" }];

export const useCurrentPath = () => {
  const location = useLocation();
  const [{ route }] = matchRoutes(routes, location) as any[];

  return route.path;
};

export const useTableQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setParam = (k: string, v: string | null | undefined) => {
    if (v) {
      return setSearchParams((s) => {
        if (k !== "page" && k !== "size") {
          for (const k of ["page", "size"]) s.delete(k);
        }
        s.set(k, v);
        return s;
      });
    }
    setSearchParams((s) => {
      s.delete(k);
      return s;
    });
  };

  return {
    status: searchParams.get("status"),
    search: searchParams.get("search"),
    page: searchParams.get("page"),
    size: searchParams.get("size"),
    setParam,
  };
};

export const useParamTab = (basePath: string, tabs?: { key: string }[]) => {
  const { key } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!tabs) return;

    if (!key) {
      navigate(`${basePath}/${tabs[0].key}`);
    }
  }, [key]);

  return {
    activeKey: key,
    onSelect: (k: string) => navigate(`${basePath}/${k}`),
  };
};

export const useQuerySearchTab = (
  tabs: { key: string }[],
  tabKey: string = "t"
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const key = searchParams.get(tabKey) as TabsProps["activeKey"];

  useEffect(() => {
    if (!key) {
      setSearchParams((s) => {
        s.set(tabKey, tabs[0].key);
        return s;
      });
    }
  }, [key]);

  const onSelect: TabsProps["onSelect"] = (k) => {
    setSearchParams((s) => {
      if (k) s.set(tabKey, k);
      else s.delete(tabKey);
      return s;
    });
  };

  return {
    activeKey: key,
    onSelect,
  };
};
