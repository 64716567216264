import AsyncCreatableSelect from "react-select/async-creatable";
import { CatalogService } from "../../services/catalog";
import { ITax } from "../../interfaces/catalogs/tax";
import { FormattedMessage, useIntl } from "react-intl";
import { styles } from ".";

const getOptions = async (inputValue: string) => {
  const response = await CatalogService.getList<ITax>("taxes", {
    search: inputValue,
  });
  return response.data.map((e) => ({
    label: e.name,
    value: e.id,
    [e.type]: e.value,
  }));
};

export default (props: any) => {
  const intl = useIntl();
  return (
    <AsyncCreatableSelect
      isMulti
      styles={styles}
      noOptionsMessage={() => (
        <span>
          <FormattedMessage id="No data to display" />
        </span>
      )}
      loadingMessage={() => (
        <span>
          <FormattedMessage id="Loading" />
        </span>
      )}
      cacheOptions
      placeholder={intl.formatMessage({ id: "Select taxes" })}
      defaultOptions
      onBlur={props.onBlur}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      loadOptions={getOptions}
      isValidNewOption={() => false}
      defaultValue={props.defaultValue}
      // onCreateOption={createOption}
      /* defaultInputValue={props.value} */
    />
  );
};
