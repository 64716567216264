import { useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import GeneralSettings from "./general";
import CompanySettings from "./company";
import LegalSettings from "./legal";
import { FormattedMessage, useIntl } from "react-intl";
import PaymentSetting from "./payment";

export default function SettingPage() {
  const intl = useIntl();
  const { key } = useParams();
  const navigate = useNavigate();

  const tabs = [
    {
      key: "general",
      name: intl.formatMessage({ id: "General configuration" }),
    },
    { key: "company", name: intl.formatMessage({ id: "Company settings" }) },
    { key: "legal", name: intl.formatMessage({ id: "Legal Information" }) },
    { key: "payment", name: intl.formatMessage({ id: "Payment methods" }) },
    { key: "notifications", name: intl.formatMessage({ id: "Notifications" }) },
  ];

  useEffect(() => {
    if (!key) {
      navigate(`/settings/${tabs[0].key}`);
    }
  }, [key]);

  return (
    <div className="container-fluid">
      <div className="nk-content-inner">
        <div className="nk-content-body">
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">
                  <FormattedMessage id="Settings" />
                </h3>
              </div>
            </div>
          </div>
          <div className="nk-block">
            <div className="card card-preview">
              <div className="card-inner">
                <Tabs
                  id="controlled-tab-example"
                  className="nav nav-tabs mt-n3"
                  role="tablist"
                  activeKey={key}
                  onSelect={(k) => navigate(`/settings/${k}`)}
                >
                  {tabs.map((t, i) => {
                    return (
                      <Tab
                        eventKey={t.key}
                        role="presentation"
                        className="nav-item"
                        key={i}
                        title={
                          t.key === "notifications"
                            ? `${t.name} (${intl.formatMessage({
                                id: "Coming soon",
                              })})
                        `
                            : t.name
                        }
                        disabled={t.key === "notifications"}
                      >
                        {t.key === "general" && <GeneralSettings />}
                        {t.key === "company" && <CompanySettings />}
                        {t.key === "legal" && <LegalSettings />}
                        {t.key === "payment" && <PaymentSetting />}
                      </Tab>
                    );
                  })}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
