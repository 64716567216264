import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { serverUrl } from "../clients/http";
import NavNotification from "../components/Nav/NavNotification";
import NavUserMenu from "../components/Nav/NavUserMenu";
import config from "../config";
import { useClient } from "../hooks/client";

function Navbar({ onMenuClick, showAlert }: any) {
  const intl = useIntl();
  const { logo } = useClient();

  return (
    <div
      style={{
        top: showAlert ? "57px" : "0px",
      }}
      className="nk-header nk-header-fixed is-light"
    >
      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger d-xl-none ms-n1">
            <a
              onClick={onMenuClick}
              className="nk-nav-toggle nk-quick-nav-icon"
              data-target="sidebarMenu"
            >
              <em className="icon ni ni-menu" />
            </a>
          </div>
          <div className="nk-header-brand d-xl-none">
            <Link to="/" className="logo-link">
              <img
                className="logo-img logo-img-lg"
                src={logo}
                style={{
                  minWidth: "180px",
                  filter:
                    "invert(0.5) sepia(1) saturate(53) hue-rotate(235deg)",
                }}
                alt="logo"
              />
            </Link>
          </div>
          {/* .nk-header-brand */}
          <div className="nk-header-search ms-3 ms-xl-0">
            {/*   <em className="icon ni ni-search" />
            <input
              type="text"
              className="form-control border-transparent form-focus-none"
              placeholder={intl.formatMessage({ id: "Search" })}
            /> */}
          </div>
          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              {/* <NavLanguageSelector /> */}
              <NavNotification />
              <NavUserMenu />
            </ul>
          </div>
        </div>
        {/* .nk-header-wrap */}
      </div>
      {/* .container-fliud */}
    </div>
  );
}

export default Navbar;
