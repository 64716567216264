import React from "react";
import { Form, Modal, Row, Col, Button, Spinner } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation, useQuery } from "react-query";
import { InventoryService } from "../../services/inventory";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ProductSelect from "../Select/ProductSelect";

export interface IRecordInventoryProps {
  show: boolean;
  handleClose: () => void;
  refetch: () => void;
  selectedProduct?: { id: string; name: string };
}

export function RecordInventory({
  show,
  handleClose,
  refetch,
  selectedProduct,
}: IRecordInventoryProps) {
  const intl = useIntl();

  const methods = useForm();
  const { handleSubmit, register, control } = methods;

  const { data: concepts, isLoading: isLoadingConcepts } = useQuery(
    ["concepts"],
    () => {
      return InventoryService.getConceptsList();
    }
  );

  const createRecord = useMutation({
    mutationFn: async (data: any) => {
      return await InventoryService.create(data);
    },
    onSuccess(data: any) {
      refetch();
      toast.success(intl.formatMessage({ id: "TOAST.CREATE_SUCCESS" }));
      handleClose();
    },
  });

  const onSubmit = async (formData: any) => {
    await createRecord.mutateAsync({
      ...formData,
      productId: selectedProduct?.id || formData.productId.id,
    });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Add Inventory Record" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="type" className="mb-2">
            <Form.Label>
              <FormattedMessage id="Type of entrance" />
            </Form.Label>
            <div>
              <ul className="custom-control-group row w-100">
                <li className="col">
                  <div className="custom-control custom-checkbox custom-control-pro no-control w-100">
                    <input
                      type="radio"
                      className="custom-control-input"
                      {...register("type", { required: true })}
                      id="entry-radio"
                      value={"entry"}
                    />
                    <label
                      className="custom-control-label w-100"
                      htmlFor="entry-radio"
                    >
                      <em className="icon ni ni-arrow-right"></em>
                      <FormattedMessage id="Entry" />
                    </label>
                  </div>
                </li>
                <li className="col">
                  <div className="custom-control custom-checkbox custom-control-pro no-control w-100">
                    <input
                      type="radio"
                      className="custom-control-input"
                      {...register("type", { required: true })}
                      id="exit-radio"
                      value={"exit"}
                    />
                    <label
                      className="custom-control-label w-100"
                      htmlFor="exit-radio"
                    >
                      <em className="icon ni ni-arrow-left"></em>

                      <FormattedMessage id="Exit" />
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </Form.Group>
          <hr />
          <Form.Group controlId="Product" className="mb-2">
            <Form.Label>
              <FormattedMessage id="Product" />
            </Form.Label>

            {selectedProduct ? (
              <div>
                <b>
                  <span className="text-primary">ID. {selectedProduct.id}</span>{" "}
                  - {selectedProduct.name}
                </b>
                <hr />
              </div>
            ) : (
              <Controller
                control={control}
                name="productId"
                rules={{ required: true }}
                render={({ field }) => {
                  return <ProductSelect {...field} />;
                }}
              />
            )}
          </Form.Group>

          <Row>
            <Col xs={6} sm={8}>
              <Form.Group controlId="concept" className="mb-2">
                <Form.Label>
                  <FormattedMessage id="Concept" />
                </Form.Label>
                <Form.Select
                  {...register("concept", { required: true })}
                  disabled={isLoadingConcepts}
                >
                  <option value="">
                    <FormattedMessage id="Select a concept" />
                  </option>
                  {concepts?.data.map((concept: any) => (
                    <option key={concept.id} value={concept.id}>
                      {concept.description}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={6} sm={4}>
              <Form.Group controlId="value" className="mb-2">
                <Form.Label>
                  <FormattedMessage id="Quantity" />
                </Form.Label>
                <Form.Control
                  type="number"
                  {...register("value", { required: true })}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" disabled={createRecord.isLoading}>
            <FormattedMessage id="Save" />
            {createRecord.isLoading && <Spinner size="sm" />}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default RecordInventory;
