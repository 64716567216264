import { useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IPaginationProps } from "../../components/Table";
import { useTableQueryParams } from "../../hooks/router";
import { INewDiscountForm } from "../../interfaces/discounts/forms";
import { IProduct } from "../../interfaces/products";
import { DiscountService } from "../../services/discount";
import { ProductService } from "../../services/product";
import DiscountFormApplyTo from "./forms/apply-to";
import DiscountFormGeneralInfo from "./forms/general-info";
import DiscountFormHowToApply from "./forms/how-to-apply";
import DiscountFormSelectedTable from "./forms/selected-table";
import DiscountFormValidityTime from "./forms/validity-time";
import BackButton from "../../components/Buttons/BackButton";

function DiscountEdit() {
  const navigate = useNavigate();
  const intl = useIntl();
  const { page, search, setParam, size, status } = useTableQueryParams();
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
  const { id } = useParams<{ id: string }>();

  const {
    register,
    handleSubmit,
    formState,
    watch,
    setValue,
    control,
    getValues,
  } = useForm<INewDiscountForm>({
    defaultValues: {
      applyTo: "by-products",
    },
  });

  useQuery(
    ["discountDetails", id],
    () => {
      return DiscountService.getOne(id as string);
    },
    {
      enabled: !!id,
      onSuccess(data) {
        setValue("name", data.name);
        setValue("code", data.code);
        setValue("type", data.type);
        setValue("value", data.value);
        setValue("applyMethod", data.applyMethod);
        setValue(
          "startDate",
          new Date(data.startDate).toISOString().split("T")[0]
        );
        setValue("endDate", new Date(data.endDate).toISOString().split("T")[0]);

        const applyTo = data.isDefault
          ? "all"
          : data.products.every(
              (e: IProduct) => e.category.id === data.products[0].category.id
            )
          ? "by-category"
          : "by-products";

        setValue("applyTo", applyTo);

        if (applyTo === "by-products") {
          setSelectedProducts(data.products);
        }

        if (applyTo === "by-category") {
          setValue("category", {
            value: data.products[0].category.id,
            label: data.products[0].category.name,
          } as any);
        }
      },
    }
  );

  const setApplyType = (type: INewDiscountForm["applyTo"]) => {
    if (type === "all") {
      setValue("category", undefined);
    }
    if (type === "by-products") {
      setValue("category", undefined);
    }
    setValue("applyTo", type);
  };

  const category = watch("category");
  const applyType = watch("applyTo");

  const { errors } = formState;

  const categoryLabel =
    typeof category === "string" ? category : category?.label;

  const { data, isLoading, refetch } = useQuery(
    ["productList", status, search, page, size, applyType, categoryLabel],
    (ctx) => {
      const [, status, search, page, size] = ctx.queryKey;
      return ProductService.getList({
        status,
        search,
        page,
        size,
        category: categoryLabel,
      });
    }
  );

  const pagination: IPaginationProps = {
    current: Number(page) || data?.pagination.page,
    size: Number(size) || data?.pagination.size,
    total: data?.pagination?.total,
    onPageChange(page) {
      setParam("page", String(page));
    },
    onSizeChange(size) {
      setParam("size", String(size));
    },
  };

  const editDiscountMutation = useMutation({
    mutationFn: async (formData: INewDiscountForm) => {
      return await DiscountService.update(id as string, formData);
    },
    onSuccess(data: any) {
      toast.success(
        intl.formatMessage(
          { id: "Discount was updated successfully" },
          { discount: data.name }
        )
      );
      navigate("/discounts");
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    if (applyType === "by-products") {
      if (!selectedProducts.length) {
        return;
      }
      data.products = selectedProducts.map((e) => e.id);
    }

    if (applyType === "by-category") {
      if (!category) {
        return;
      }
      data.category = typeof category === "string" ? category : category.value;
    }

    await editDiscountMutation.mutateAsync({
      ...data,
      value: Number(data.value),
    });
  });

  const type = watch("type");
  const value = watch("value");
  const applyMethod = watch("applyMethod");

  return (
    <>
      <div className="nk-block-head nk-block-head-lg w-100">
        <Row>
          <Col>
            <div className="nk-block-head-content">
              <h2 className="nk-block-title fw-normal">
                <FormattedMessage id="Edit Discount" />
              </h2>
              <div className="nk-block-des">
                <p className="lead">
                  <FormattedMessage id="Enter the data to update the discount" />
                </p>
              </div>
            </div>
          </Col>
          <Col className="text-right">
            <BackButton />
          </Col>
        </Row>
      </div>

      <div className="nk-block nk-block-lg">
        <Row>
          <Col sm={12} md={5}>
            <div className="card card-bordered card-preview">
              <div className="card-inner">
                <Form onSubmit={onSubmit}>
                  <DiscountFormGeneralInfo
                    register={register}
                    errors={errors}
                    type={type}
                  />

                  <DiscountFormApplyTo
                    register={register}
                    errors={errors}
                    control={control}
                    applyType={applyType}
                    setApplyType={setApplyType}
                    selectedProducts={selectedProducts}
                    data={data}
                  />

                  <DiscountFormHowToApply
                    setApplyMethod={(v) => {
                      console.log(v);
                      setValue("applyMethod", v);
                    }}
                    applyMethod={applyMethod}
                  />

                  <DiscountFormValidityTime
                    register={register}
                    errors={errors}
                  />
                  <div className="mt-4 text-right">
                    <br />
                    <hr />
                    <Button
                      type="submit"
                      disabled={editDiscountMutation.isLoading}
                      onClick={onSubmit}
                      size="lg"
                    >
                      {editDiscountMutation.isLoading ? (
                        <Spinner />
                      ) : (
                        <em className="icon ni ni-plus me-2"></em>
                      )}{" "}
                      <FormattedMessage id="Edit" />
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
          <Col sm={12} md={7}>
            <DiscountFormSelectedTable
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
              applyType={applyType}
              category={category}
              categoryLabel={categoryLabel}
              data={data}
              isLoading={isLoading}
              pagination={pagination}
              type={type}
              value={value}
              onSearch={(search) => {
                setParam("search", search);
              }}
            />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default DiscountEdit;
