import { Button, Col, Modal, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { AddressType } from "../../enums/types";
import { AddressDto } from "../../services/address";

export interface DisplayAddressProps {
  show: boolean;
  handleClose: () => void;
  addressData: AddressDto; // Pass the address data object as a prop
}

export function DisplayAddress({
  show,
  handleClose,
  addressData,
}: DisplayAddressProps) {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="Display Address" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-2">
          <Col>
            <small>
              <FormattedMessage id="Address of type of" />{" "}
            </small>
            <h3>
              <strong>
                {addressData.type === "shipping" ? (
                  <>
                    <em className={"me-1 " + AddressType.shipping.icon}></em>
                    <FormattedMessage id={AddressType.shipping.text} />
                  </>
                ) : (
                  <>
                    <em className={"me-1 " + AddressType.billing.icon}></em>
                    <FormattedMessage id={AddressType.billing.text} />
                  </>
                )}
              </strong>{" "}
            </h3>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={12}>
            <small>
              <FormattedMessage id="Address" />:
            </small>
            <h4 className="ff-base fw-medium mt-1">{addressData.address}</h4>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={6}>
            <small>
              <FormattedMessage id="Municipality" />:
            </small>
            <h4 className="ff-base fw-medium mt-1">
              {addressData.municipality}
            </h4>
          </Col>
          <Col xs={6}>
            <small>
              <FormattedMessage id="Province" />:
            </small>
            <h4 className="ff-base fw-medium mt-1">{addressData.province}</h4>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={12}>
            <small>
              <FormattedMessage id="Neighborhood" />:
            </small>
            <h4 className="ff-base fw-medium mt-1">
              {addressData.neighborhood}
            </h4>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <small>
              <FormattedMessage id="Reference" />:
            </small>
            <p>
              {addressData.reference || <span className="text-soft">N/A</span>}
            </p>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>
          <FormattedMessage id="Close" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
