import { useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { CollectionService } from "../../../services/cms/collection";
import CollectionTable from "./collection-table";
import LoadingText from "../../../components/Text/loading";
import Title from "../../../components/Title";
import { FormattedMessage, useIntl } from "react-intl";

function PostList() {
  const intl = useIntl();
  const { name } = useParams();

  const navigate = useNavigate();

  const { data, isLoading } = useQuery(["postList"], () => {
    return CollectionService.getList({});
  });

  useEffect(() => {
    if (!name && !isLoading && data) {
      navigate(`/cms/collections/${data.data[0].name}`);
    }
  }, [name, isLoading, data]);

  const type = data?.data?.find((t: any) => t.name === name);

  console.log(data, name);

  return (
    <>
      <Title
        subText={intl.formatMessage(
          { id: "You have a total of N post." },
          { number: data?.pagination?.total || 0 }
        )}
      >
        <FormattedMessage id="Collection list" />
      </Title>

      <div className="card card-bordered card-preview">
        <div className="card-inner">
          <Tabs
            id="controlled-tab-example"
            className="nav nav-tabs mt-n3"
            role="tablist"
            activeKey={name}
            onSelect={(k) => navigate(`/cms/collections/${k}`)}
          >
            {!isLoading
              ? data?.data?.map((t: any, i: number) => {
                  return (
                    <Tab
                      eventKey={t.name}
                      role="presentation"
                      className="nav-item"
                      key={i}
                      title={t.displayName}
                    />
                  );
                })
              : [1, 2].map((k) => (
                  <Tab
                    role="presentation"
                    className="nav-item"
                    key={k}
                    title={<LoadingText />}
                  />
                ))}
          </Tabs>
          <TabContainer type={type} loading={isLoading} />
        </div>
      </div>
    </>
  );
}

const TabContainer: React.FC<{
  type?: any;
  loading: boolean;
}> = ({ type, loading }) => {
  return (
    <CollectionTable
      id={type?.id}
      displayName={type?.displayName}
      name={type?.name}
      apiId={type?.schemaId}
      loading={loading}
      baseUrl={type?.link}
    />
  );
};

export default PostList;
