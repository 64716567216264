export enum BasePermission {
  Product = "Product",
  Customer = "Customer",
  Catalog = "Catalog",
  Inventory = "Inventory",
  Stats = "Stats",
  Setting = "Setting",
  Subscription = "Subscription",
  Order = "Order",
  User = "User",
  Group = "Group",
  Wishlist = "Wishlist",
  Cart = "Cart",
  Addons = "Addons",
  Discount = "Discount",
}

export enum CMSPermission {
  Page = "Page",
  Collection = "Collection",
  Form = "Form",
  Media = "Media",
  Stats = "Stats",
}

export type PolicyPermission = BasePermission | CMSPermission;

export enum Action {
  CREATE = "create",
  READ = "read",
  UPDATE = "update",
  DELETE = "delete",
  MANAGE = "manage",
  // Add more operations as necessary
}
